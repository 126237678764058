import { Button, Modal, Form, Select, Input, message } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useState } from 'react';
import { useTypedSelector } from '../../../../redux/hooks';
import CitySelect from '../../../MyCompanies/CitySelect';
import RegionSelect from '../../../PromotionSettings/RegionSelect';
import { createProject, getDevices, updateProject } from '../../ProjectService';

import SelectDevice from './SelectDevice';
import SelectSphere from './SelectSphere';

const UpdateProjectModal = ({ project, devices, setProjects, showUpdateModal, setShowUpdateModal, create, setRerenderDevice }) => {
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [status, setStatus] = useState(false);
    const [selectDevices, setSelectDevices] = useState([]);
    const [projectDevices, setProjectDevices] = useState(project?.projectDevices ?? [])
    const handbookRedux = useTypedSelector((state) => state.handbook);
    const [region, setRegion] = useState("");
    const [deletedDevices, setDeletedDevices] = useState([])
    const [sphere, setSphere] = useState(null);
    const [form] = Form.useForm();
    const [sphereError, setSphereError] = useState(false);
    const [locationError, setLocationError] = useState(false);

    useEffect(() => {
        setProjectDevices(selectDevices.map(device_id => {
            const device = projectDevices.find(item => item.project_device_id === device_id);
            if (device) {
                return device;
            } else {
                return {
                    project_device_id: device_id,
                    count: 1
                }
            }
        }))
    }, [selectDevices])

    useEffect(() => {
        if (showUpdateModal) {
            setSphereError(false);
            setLocationError(false);
            setDeletedDevices([])
            if (create) {

                setSelectDevices([])
                setSphere(null)
                setProjectDevices([])

                setRegion(null)
            } else {
                console.log(project);
                setSelectDevices(project?.projectDevices.map(item => item.project_device_id) ?? [])
                setProjectDevices(project?.projectDevices ?? [])
                setSphere(project?.sphere_id ?? null);
                setSphere(project?.sphere_id ?? null);
                setRegion(project?.location ? { label: project?.city, value: project.location } : null);
            }
            form.setFieldsValue({ title: project.title, description: project.description, status: project?.status })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showUpdateModal]);
    const handleOk = () => {
        let err;
        if (!sphere) {
            setSphereError(true);
            err = true;
        }
        if (!region?.value) {
            err = true;
            setLocationError(true);
        }

        form
            .validateFields()
            .then((values) => {
                if (err)
                    return
                if (create) {
                    createProject({
                        title: values.title,
                        projectDevices: projectDevices,
                        sphere_id: sphere,
                        location: region.value,
                        city: region.label,
                        description: values.description,
                        status: 0
                    }).then(response => {
                        console.log(response.data);
                        setProjects(prev => {
                            return [...prev, response.data]
                        })
                        message.success("Проект создан!");
                        setShowUpdateModal(false);
                    }).catch(e => {
                        message.error("Не удалось создать проект");
                    })
                } else {
                    updateProject({
                        ...project,
                        title: values.title,
                        projectDevices: projectDevices,
                        sphere_id: sphere,
                        location: region.value,
                        city: region.label,
                        description: values.description,
                        deletedDevices: deletedDevices,
                        status: values.status
                    }).then(response => {
                        console.log(response.data);
                        setProjects(prev => {
                            return prev.map(item => {
                                if (item.id === project.id) {
                                    return response.data
                                }
                                return item;
                            })
                        })
                        message.success("Проект обновлён!");
                        setShowUpdateModal(false);
                        setRerenderDevice(response.data.id);

                    }).catch(e => {
                        message.error("Не удалось обновить проект");
                    })
                    // ProjectService.updateProjects(project.id, { project_coordinators: coordinatorIds, project_brigadiers: bridaierIds, project_users: usersIds, project_supervisors: supervisordIds, title: values.name }).then(response => {
                    //     console.log("add user done!", response.data);
                    //     dispatch(updateProjects(response.data))
                    //     setShowUpdateModal(false);
                    // }).catch(error => console.log("Error add supervisor", error))
                }


            }).catch(error => {
                console.log("Не удалось создать проект", error)
            });

    };

    const handleCancel = () => {
        setConfirmLoading(false);
        setShowUpdateModal(false);
    };

    return (
        <>

            <Modal
                onClick={e => e.stopPropagation()}
                title={project.title}
                forceRender
                destroyOnClose
                visible={showUpdateModal}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Отмена
                    </Button>,
                    <Button key="submit" type="primary" loading={confirmLoading} onClick={handleOk}>
                        Сохранить
                    </Button>]}
            >
                <Form layout="vertical" form={form} >
                    <Form.Item label="Имя проекта" name="title"

                        rules={[
                            {
                                required: true,
                                message: "Поле обязательно к заполнению",
                            },
                        ]}>
                        <Input placeholder="Имя проекта" />
                    </Form.Item>
                    <Form.Item label="Сфера"
                        required
                        validateStatus={sphereError ? "error" : undefined}
                        help={sphereError ? "Поле обязательно к заполнению" : undefined}
                    >
                        <SelectSphere sphere={sphere} d_isManager={handbookRedux.d_isManager || handbookRedux.d_isAdminManager} setSphere={setSphere} />
                    </Form.Item>
                    <Form.Item label="Город"
                        required
                        validateStatus={locationError ? "error" : undefined}
                        help={locationError ? "Поле обязательно к заполнению" : undefined}
                    >
                        <CitySelect region={region} setRegion={setRegion} />
                    </Form.Item>
                    <Form.Item
                        name="description"
                        label="Описание">
                        <TextArea placeholder='Описание проекта' />
                    </Form.Item>
                    {!create && <Form.Item
                        name="status"
                        label="Статус">
                        <Select

                            options={[
                                {
                                    label: "В работе",
                                    value: 0
                                },
                                {
                                    label: "Завершен",
                                    value: 1
                                }
                            ]}
                        />
                    </Form.Item>}

                    <Form.Item label="Приборы">
                        <SelectDevice
                            setDeletedDevices={!create ? setDeletedDevices : undefined}
                            setProjectDevices={setProjectDevices}
                            projectDevices={projectDevices}
                            selectDevices={selectDevices}
                            setSelectDevices={setSelectDevices}
                            canUpdate={true}
                            devices={devices} />
                    </Form.Item>

                </Form>
            </Modal>
        </>
    );
};

export default UpdateProjectModal;