import React, { useState, useEffect } from "react";
import {
    Card,
    Typography,
    Divider,
    Button,
    Tooltip,
    Empty,
    Pagination,
    Input,
    Spin,
    Select,
    Radio,
    Checkbox,
    Popconfirm,
    message,
    Space,
    Badge,
} from "antd";
import { PlusOutlined, UnorderedListOutlined, SyncOutlined, InboxOutlined, PayCircleOutlined } from "@ant-design/icons";
import { ResponsivePie } from "@nivo/pie";
import { useHistory } from "react-router-dom";
import { useTypedDispatch, useTypedSelector } from "../../redux/hooks";
import { setHeaderTitle } from "../../redux/slices/layoutSlice";
import Filter from "./Filter";

import "../../styles/Card.scss";
import "../../styles/FilterField.scss";
import "./Orders.scss";
import { setOrders } from "../../redux/slices/handbookSlice";
const { Text, Link, Title } = Typography;
const OrdersWidget = ({ orgFilter }) => {
    const history = useHistory();
    const dispatch = useTypedDispatch();
    const handbookRedux = useTypedSelector((state) => state.handbook);
    const orders = useTypedSelector((state) => state.handbook.orders);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    // const [orders, setOrders] = useState<Array<any>>([]);
    const [page, setPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(50);
    const [ordersFullCount, setOrdersFullCount] = useState<number>(30);
    const [searchData, setSearchData] = useState<string | null>(null);
    const [searchTrigger, setSearchTrigger] = useState<boolean>(false);
    const [orgsForFilter, setOrgsForFilter] = useState<any[]>([]);
    const [orgFromFilter, setOrgFromFilter] = useState<string>(orgFilter ? orgFilter : "");
    const [radioFilter, setRadioFilter] = useState<string>("all");
    const [selectedOrders, setSelectedOrders] = useState<any[]>([]);
    const [deptSum, setDeptSum] = useState<string>(null);
    const [role, setRole] = useState<any>("d_user");

    useEffect(() => {
        setOrgsForFilter(handbookRedux.partners);
    }, [handbookRedux.partners]);

    useEffect(() => {
        setRole(JSON.parse(localStorage.getItem("user")).capabilities);
    }, []);

    const radioFilterOption = [
        { label: "Все", value: "all" },
        //{ label: "На оплате", value: "wait" },
        { label: "В работе", value: "processing" },
        //{ label: "Оплаченные", value: "fullPaid" },
        { label: "На отгрузке", value: "preFullSold" },
        { label: "Дебиторка", value: "waitingPayment" },
        { label: "Успешные", value: "finished" },
        //{ label: "Отменённые", value: "canceled" },
        //{ label: "Черновики", value: "drafts" },
    ];
    const convertOrg = (uuid: string) => {
        return handbookRedux.orgs.length ? handbookRedux.orgs.find((itm) => itm.ref == uuid)?.name : "";
    };

    // const convertPartner = (uuid: string): string => {
    //   return handbookRedux.partners.length ? handbookRedux.partners.find((itm) => itm.ref == uuid)?.name : "";
    // };

    const convertDate = (date: string): string => {
        return `${date.slice(-2)}.${date.slice(-5, -3)}.${date.slice(0, 4)}`;
    };

    const convertGoodName = (product: string): string => {
        return handbookRedux.products.length && handbookRedux.products.find((itm) => itm.ref == product) ? handbookRedux.products.find((itm) => itm.ref == product).name : "";
    };
    const calculateOrderTitle = (fullData: any) => {
        if (fullData.synced == "0" && fullData.posted === 1)
            return (
                <>
                    <div className="order__date">Идет синхронизация</div>
                    <div className="order__subtitle">{fullData.number}</div>
                </>
            );
        if (fullData.deleted === true || fullData.deleted === 1)
            return (
                <>
                    <div className="order__date">{convertDate(fullData.date)} </div>
                    <div className="order__subtitle">Отменён</div>
                </>
            );
        if (fullData.posted === false || fullData.posted === 0)
            return (
                <>
                    <div className="order__date">{convertDate(fullData.date)} </div>
                    <div className="order__subtitle">Черновик</div>
                </>
            );
        if (fullData.number !== null && fullData.number !== "")
            return (
                <>
                    <div className="order__date">{convertDate(fullData.date)} </div>
                    <div className="order__subtitle">{fullData.number}</div>
                    {(handbookRedux.d_isManager || handbookRedux.d_isAdminManager) && (
                        <div className="order__sales_type">{fullData.sales_type}</div>
                    )}
                    <div className="order__stage_shipment">{fullData.stage_shipment}</div>
                </>
            );
        return <div className="order__date">Формируется счёт...</div>;
    };

    const handleFilterSearch = (search: string) => {
        fetch(`${SiteUrlDealer}/organization_with_filters?limit=50&page=1&order_type=DESC`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""}`,
            },
            // prettier-ignore
            body: JSON.stringify({
                filters: [{
                    field: "name",
                    comparison: "like",
                    value: search,
                }]
            }),
            method: "POST",
        })
            .then((res) => res.json())
            .then((json) => setOrgsForFilter(json.partners));
    };

    const handleCancelOrders = () => {
        // console.log(selectedOrders);
        const toSend = selectedOrders.map((itm) => ({
            number: orders.find((f) => f.id === itm).number,
            id: itm,
            posted: orders.find((f) => f.id === itm).posted,
        }));
        console.log(toSend);

        fetch(`${SiteUrlDealer}/delete_orders`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""}`,
            },
            body: JSON.stringify({ content: toSend }),
            method: "POST",
        })
            .then((res) => res.json())
            .then((json) => {
                console.log("del", json);
                message.success("Заказ отменён");
                setSearchTrigger(!searchTrigger);
                setSelectedOrders([]);
            })
            .catch((err) => {
                message.error("Что-то пошло не так");
                console.error("err", err);
            });
    };

    useEffect(() => {
        // console.log(searchData);
        setIsLoading(true);

        const timeout = setTimeout(() => {
            setIsLoading(true);

            const body = Filter(radioFilter, orgFromFilter, searchData);

            fetch(
                `${SiteUrlDealer}/order_with_filters?limit=${pageSize ? pageSize : "50"}&page=${page ? page : 1
                }&order_by=date&order_type=DESC`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""
                            }`,
                    },
                    body: JSON.stringify(body),
                    method: "POST",
                }
            )
                .then((res) => res.json())
                .then((json) => {
                    console.log("with_filters", json);
                    const filtered = json.orders
                        .filter((f: any) => f.items !== undefined && f.items.length > 0)
                        .map((itm: any) => ({
                            ...itm,
                            selected: false,
                        }));
                    const deptSum = filtered.reduce(
                        (n: any, { payments_amount, sales_amount }: any) =>
                            // @ts-ignore
                            n + (sales_amount - payments_amount),
                        0
                    );
                    setDeptSum(deptSum);
                    console.log('debt', deptSum)
                    dispatch(setOrders(filtered));

                    setOrdersFullCount(json.count);
                    setIsLoading(false);
                });
        }, 1000);
        return () => {
            clearTimeout(timeout);
            setIsLoading(false);
        };
    }, [searchTrigger, page, pageSize, orgFromFilter, radioFilter]);
    // organization: fetchedOrgs.find((f) => f.ref === data).main_contract_organization,
    // partner_data: fetchedOrgs.find((f) => f.ref === data),
    return (
        <div className="orders-list">
            <Space wrap>
                <Button
                    type="text"
                    icon={<PlusOutlined />}
                    className="orders-list__create-button"
                    size="large"
                    onClick={() => history.push("create-order/new", { mode: "create", data: false, orgFilter: orgFilter })}
                >
                    Новый заказ
                </Button>
                {/* <Button
          type="text"
          icon={<UnorderedListOutlined />}
          className="orders-list__create-button"
          size="large"
          onClick={() => history.push("create-order/prefill", { mode: "create", data: false })}
        >
          Популярные товары
        </Button> */}
            </Space>

            <Card style={{ marginBottom: 20 }} bordered={false} className="card">
                <div className="filter-field filter-field--no-margin">
                    <div className="filter-field__item">
                        <Input
                            placeholder="Поиск по заказам"
                            size="large"
                            // style={{ width: window.screen.width > 450 ? 240 : "100%" }}
                            onChange={(e) => {
                                // setNameFilter(e.target.value);
                                setSearchData(e.target.value);
                                setPage(1);
                                setSearchTrigger(!searchTrigger);
                            }}
                        />
                    </div>

                    <div className="filter-field__item">
                        <Select
                            style={{ width: "100%" }}
                            placeholder="Поиск по организации"
                            showSearch
                            disabled={orgFilter != undefined}
                            allowClear
                            size="large"
                            filterOption={(input, option) =>
                                //@ts-ignore
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            onSearch={handleFilterSearch}
                            onClear={() => {
                                setOrgFromFilter("");
                            }}
                            // notFoundContent="Начните вводить название"

                            onChange={(data, full) => {
                                setOrgFromFilter(data);
                                setPage(1);
                            }}
                        >
                            {orgsForFilter &&
                                orgsForFilter.map((itm, ind) => (
                                    <Select.Option
                                        key={ind}
                                        // value={itm.main_organization}
                                        value={itm.ref}
                                    // disabled={itm.main_organization === null}
                                    >
                                        {itm.name}
                                    </Select.Option>
                                ))}
                        </Select>
                    </div>
                    <div className="filter-field__segmented">
                        <Radio.Group
                            size="large"
                            options={radioFilterOption}
                            onChange={(e: any) => setRadioFilter(e.target.value)}
                            optionType="button"
                            defaultValue={"all"}
                            buttonStyle="solid"
                        />
                    </div>
                </div>
            </Card>
            {isLoading ? (
                <div className={`orders-list${orgFilter ? "__spin__no-padding" : "__spin"}`}>
                    <Spin size="large" />
                </div>
            ) : (
                <>
                    {orders.length ? (
                        <div>
                            {radioFilter && radioFilter == 'waitingPayment' && deptSum ? (
                                <div className="debt">
                                    <div className="debt__amount">
                                        {`Дебиторская задолженность: ${formatMoney(deptSum)} ₽`}
                                    </div>
                                </div>
                            ) : (<></>)
                            }
                            {orders.map((itm: any) => {
                                return (
                                    <Badge.Ribbon
                                        key={itm.id}
                                        style={{
                                            display: itm.number == null || itm.number == "" ? "block" : "none",
                                            marginLeft: window.screen.width < 450 && 7,
                                        }}
                                        text="Новый"
                                        placement="start"
                                        color="cyan"
                                    >
                                        <Card
                                            style={{ marginBottom: 20 }}
                                            bordered={false}
                                            className="card"
                                            onClick={(e) => history.push(`/order/${itm.id}`, { data: itm })}
                                        >
                                            <div className="order">
                                                <div className="order__left">
                                                    <div className="order__checkbox" onClick={(e) => e.stopPropagation()}>
                                                        <Checkbox
                                                            checked={itm.selected}
                                                            disabled={(itm.payments_amount > 0 || itm.sales_amount > 0 || itm.presales_amount > 0 || itm.synced == 0) && itm.number.length > 0}
                                                            onChange={(e) => {
                                                                if (e.target.checked === true) {
                                                                    setSelectedOrders([...selectedOrders, itm.id]);
                                                                } else {
                                                                    const qqq = selectedOrders.filter((f) => f !== itm.id);
                                                                    setSelectedOrders(qqq);
                                                                }
                                                                const editData = orders.map((ordItm) => {
                                                                    if (ordItm.id === itm.id) {
                                                                        return {
                                                                            ...ordItm,
                                                                            selected: e.target.checked,
                                                                        };
                                                                    } else {
                                                                        return ordItm;
                                                                    }
                                                                });
                                                                dispatch(setOrders(editData));

                                                            }}
                                                        />
                                                    </div>
                                                    <div className="order__left-content">
                                                        <Title level={3}>{calculateOrderTitle(itm)}</Title>

                                                        {(window.screen.width < 450 || itm.items.length) && (
                                                            <Tooltip
                                                                placement="top"
                                                                overlayStyle={{ maxWidth: window.screen.width }}
                                                                title={
                                                                    <div>
                                                                        {itm.items.map((product: any, indGood: any) => (
                                                                            <div className="order__item product" key={indGood}>
                                                                                <div className="product__name product__name--full">
                                                                                    {convertGoodName(product.item)}
                                                                                </div>
                                                                                <div className="product__amount">&nbsp;x&nbsp;{product.amount}</div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                }
                                                                trigger="click"
                                                            >
                                                                <Link href="#" onClick={(e) => e.stopPropagation()}>
                                                                    {/* {window.screen.width > 450 ? "Подробнее..." : "Показать товары"} */}
                                                                    Показать товары
                                                                </Link>
                                                            </Tooltip>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="order__center">
                                                    {/* {window.screen.width > 450 && (
                          <>
                            {convertGoodAmount(itm.items).map((product: any, indGood: any) => (
                              <div className="order__item product" key={indGood}>
                                <div className="product__name">{convertGoodName(product.item)}</div>
                                <div className="product__amount">&nbsp;x&nbsp;{product.amount}</div>
                              </div>
                            ))}
                          </>
                        )} */}
                                                    <div className="order__orgs">
                                                        <div className="order__org-title">Поставщик</div>
                                                        <div className="order__our-org-name">{convertOrg(itm.organization)}</div>
                                                        <div className="order__org-title">Покупатель</div>
                                                        <div className="order__org-name">
                                                            {/* {convertPartner(itm.partner)} */}
                                                            {itm.partner_data && itm.partner_data.name ? itm.partner_data.name : ''}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="order__right">
                                                    <div className="order__amount">
                                                        {formatMoney(itm.items_amount)} ₽
                                                    </div>
                                                    <Divider style={{ marginBottom: 10, marginTop: 10, borderTop: "#fff" }} />
                                                    <div className="order__stage">
                                                        {
                                                            itm.payments_amount < itm.sales_amount
                                                                ? "Задолженность"
                                                                : itm.presales_amount > 0
                                                                    ? "На отгрузке"
                                                                    : itm.payments_amount > 0 && itm.payments_amount == itm.sales_amount
                                                                        ? "Успешная"
                                                                        : "В работе"
                                                        }
                                                    </div>
                                                    <Divider style={{ marginBottom: 10, marginTop: 10, borderTop: "#fff" }} />
                                                    <div className="order__debt">
                                                        {itm.payments_amount < itm.sales_amount ? `${formatMoney(itm.sales_amount - itm.payments_amount)} ₽` : ``}
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <Divider /> */}
                                        </Card>
                                    </Badge.Ribbon>
                                );
                            })}
                            <div className="orders-list__pagination-container">
                                <Pagination
                                    defaultCurrent={1}
                                    total={ordersFullCount}
                                    showSizeChanger
                                    pageSizeOptions={["3", "5", "10", "15", "20", "25", "50", "100"]}
                                    pageSize={pageSize}
                                    onShowSizeChange={(page: any, size: any) => {
                                        // console.log("ps", size);
                                        setPageSize(size);
                                    }}
                                    current={page}
                                    onChange={(p: any) => {
                                        // console.log("page", p);
                                        setPage(p);
                                    }}
                                />
                            </div>
                        </div>
                    ) : (
                        <Empty
                            description="Нет заказов"
                            style={orgFilter ? {} : {
                                paddingLeft: window.screen.width > 450 ? "22%" : "0",
                                paddingTop: window.screen.width > 450 ? 200 : 100,
                            }}
                        />
                    )}
                </>
            )}

            {selectedOrders.length !== 0 && (
                <div className="orders-list__actions">
                    <Popconfirm
                        placement="top"
                        title="Вы действительно хотите отменить заказ?"
                        onConfirm={(e) => {
                            e.stopPropagation();
                            handleCancelOrders();
                        }}
                        onCancel={(e) => e.stopPropagation()}
                        okText="Отменить"
                        cancelText="Не отменять"
                    >
                        <Button type="primary" danger>
                            Отменить
                        </Button>
                    </Popconfirm>
                </div>
            )}
        </div>
    );
};

export default OrdersWidget;