import React, { useState, useEffect } from "react";
import { useTypedDispatch, useTypedSelector } from "../../redux/hooks";

import { setHeaderTitle } from "../../redux/slices/layoutSlice";

import "./Help.scss";
import "../../styles/Card.scss";

type Props = {};

export default function Help({ }: Props) {
  const dispatch = useTypedDispatch()

  // console.log(promotions);
  useEffect(() => {

    dispatch(setHeaderTitle("Помощь"));

  }, []);
  return (
    <div className="help">
      <div className="help__video">
        <iframe

          src={`https://www.youtube.com/embed/7QQ9-w0RiPg`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        >
        </iframe>
      </div>
    </div>
  );
}
