import React, { useState, useEffect, useMemo } from "react";
import { useTypedDispatch, useTypedSelector } from "../../redux/hooks";
import { Card, Typography, message, Button, Dropdown, Menu } from "antd";
import { PlusOutlined, SaveOutlined, LoadingOutlined } from "@ant-design/icons";
import { useLocation, useParams } from "react-router-dom";
import { setHeaderTitle } from "../../redux/slices/layoutSlice";
import PromotionDetails from "../PromotionDetails";
import { IPromotions, conditionItem, IGeneral } from "./types";

import PromotionGeneralData from "./PromotionGeneralData";
import AddDropdown from "./AddDropdown";

import "./PromotionSettings.scss";

type Props = {};

export default function PromotionSettings({ }: Props) {
  const dispatch = useTypedDispatch();
  const location = useLocation();
  // @ts-ignore
  // const mainPromotionData: IPromotions = location.state;
  const [mainPromotionData, setMainPromotionData] = useState<IPromotions>(location.state);
  let urlParams: { id: string } = useParams();
  const [generalData, setGeneralData] = useState<IGeneral>({
    image: "",
    title: "",
    type: "",
    // region: {
    //   id: 0,
    //   type: "city",
    // },
    region: "",
    description: "",
    expire: "",
    summable: "yes",
    enable: true,
  });
  const [loadingButton, setLoadingButton] = useState<boolean>(false);
  const [groups, setGroups] = useState<conditionItem[]>([]);
  const [lastIndex, setLastIndex] = useState<number>(1);

  useMemo(() => {
    setLastIndex(lastIndex + 1);
  }, [groups.length]);

  useEffect(() => {
    let isCancelled = false;
    dispatch(setHeaderTitle("Акция"));
    // console.log(urlParams?.id);
    // сделал чтобы всегда шёл запрос, потому что, если брать из пропсов, если они есть,
    // не отображается только что добавленное условие после перезагрузки страницы, т.к. пропсы старые
    if (urlParams?.id != "new") {
      // if (!mainPromotionData) {
      fetch(`${SiteUrlDealer}/promotion/${urlParams.id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""
            }`,
        },
        // signal: controller.signal,
        method: "GET",
      })
        .then((res) => res.json())
        .then((json) => {
          if (!isCancelled) {
            console.log(json);
            setMainPromotionData(json);
          }
        })
        .catch(() => message.error("Ошибка при загрузке акции"));
      // }
    }

    return () => {
      isCancelled = true;
    };
  }, []);

  useEffect(() => {
    console.log(mainPromotionData);

    if (mainPromotionData) {
      setPromotionData();
    }
  }, [mainPromotionData]);

  function setPromotionData() {
    if (mainPromotionData.condition) {
      setGroups(mainPromotionData.condition);
      // console.log(mainPromotionData);
    }

    setGeneralData({
      image: mainPromotionData.image,
      title: mainPromotionData.title,
      type: mainPromotionData.type,
      region: mainPromotionData.region,
      description: mainPromotionData.description,
      expire: mainPromotionData.expire.split(" ")[0],
      summable: mainPromotionData.summable,
      enable: mainPromotionData.enable === "1",
    });
  }

  function addGroup(newGroup: any) {
    const copy = groups.slice();
    copy.push(newGroup);
    console.log("empty");

    setGroups(copy);
  }

  function validateGeneralData() {
    if (generalData.title == "") {
      message.error("Придумайте название акции");
    } else if (generalData.type == "") {
      message.error("Выберите тип акции");
      // } else if (generalData.region.id === 0) {
    } else if (generalData.region === "") {
      message.error("Выберите регион, в котором будет работать акция");
    } else if (generalData.description == "") {
      message.error("Придумайте описание");
    } else if (generalData.expire == "") {
      message.error("Выберите дату истечения акции");
    } else {
      //fetch
      // конвертировать summable active
      if (typeof mainPromotionData?.id == "number") {
        console.log("edit");
        editQuery();
      } else {
        console.log("create");
        createQuery();
      }
    }
  }

  function createQuery() {
    setLoadingButton(true);
    const toSend = {
      ...generalData,
      enable: generalData.enable === true ? "1" : "0",
      for_type: "ref",
      for: " ",
      count: "0",
      condition_id: groups.map((i) => i.id).filter((f) => Math.floor(f) !== 0),
    };
    console.log("create promo", toSend);

    fetch(`${SiteUrlDealer}/promotion`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""}`,
      },
      body: JSON.stringify(toSend),
      method: "POST",
    })
      .then((res) => res.json())
      .then((json) => {
        // setPromotions(json);
        console.log("create promo", json);
        message.success("Акция успешно создана");
      })
      .catch(() => {
        message.error("Что-то пошло не так");
      })
      .finally(() => {
        setLoadingButton(false);
      });
  }

  function editQuery() {
    setLoadingButton(true);
    const toSend = {
      ...generalData,
      enable: generalData.enable === true ? "1" : "0",
      for_type: "ref",
      for: " ",
      count: "0",
      condition_id: groups.map((i) => i.id).filter((f) => Math.floor(f) !== 0),
    };
    console.log("edit promo", toSend);

    fetch(`${SiteUrlDealer}/promotion/${mainPromotionData.id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""}`,
      },
      body: JSON.stringify(toSend),
      method: "PUT",
    })
      .then((res) => res.json())
      .then((json) => {
        // setPromotions(json);
        console.log("edit promo", json);
        message.success("Акция успешно создана");
      })
      .catch((err) => {
        console.error(err);
        message.error("Что-то пошло не так");
      })
      .finally(() => {
        setLoadingButton(false);
      });
  }

  function deleteUnsaved(id: number) {
    // console.log("del", id);
    setGroups(groups.filter((f) => f.id != id));
  }

  return (
    <div className="promotion-settings">
      <Card
        bordered={false}
        title={<Typography.Title level={ResponsiveTitleLevel}>Общее</Typography.Title>}
        extra={
          <Button
            type="primary"
            icon={<SaveOutlined />}
            loading={loadingButton}
            onClick={validateGeneralData}
          >
            Сохранить
          </Button>
        }
        className="card"
      >
        <PromotionGeneralData
          generalData={generalData}
          setGeneralData={setGeneralData}
          mainPromotionData={mainPromotionData}
        />
      </Card>

      <Typography.Title level={ResponsiveTitleLevel} style={{ marginTop: 20 }}>
        Условия акции
      </Typography.Title>

      {groups.length > 0 && <AddDropdown addGroup={addGroup} />}

      {groups.length > 0 ? (
        groups
          .sort((a, b) => {
            if (Math.floor(+a.id) == 0) {
              return -1;
            } else {
              return a.id > b.id ? 1 : a.id == b.id ? 0 : a.id < b.id ? -1 : 0;
            }
          })
          .map((itm) => (
            <div key={itm.id}>
              <PromotionDetails
                group={itm}
                fullGroups={groups}
                setGroups={setGroups}
                type="inPromo"
                deleteUnsaved={deleteUnsaved}
                promotionId={mainPromotionData?.id}
              />
            </div>
          ))
      ) : (
        <div className="promotion-settings__empty">Нет условий</div>
      )}

      <div style={{ marginTop: groups.length > 0 ? 20 : 0, paddingBottom: 20 }}>
        <AddDropdown addGroup={addGroup} />
      </div>
    </div>
  );
}
