import React, { useState, useEffect } from "react";
import "normalize.css";
import { BrowserRouter as Router, Switch, Route, Redirect, useHistory } from "react-router-dom";
import { Layout, Spin, Result, message, notification } from "antd";
import { useTypedDispatch, useTypedSelector } from "./redux/hooks";
import { setStores, fetchJur, fetchProducts, setRole, setLegals, setOrders } from "./redux/slices/handbookSlice";
import { addCartItem } from "./redux/slices/cartSlice";


import Sidebar from "./components/Sidebar";
import Header from "./components/Header";
import 'antd/dist/antd.min.css'
import SignIn from "./components/SignIn";
import User from "./components/User";
import Order from "./components/Orders";
import OrderDetails from "./components/OrderDetails";
import CreateOrder from "./components/CreateOrder";
import Catalog from "./components/Catalog";
import PersonalDiscounts from "./components/PersonalDiscounts";
import MyCompanies from "./components/MyCompanies";
import Settings from "./components/Settings";
import Promotions from "./components/Promotions";
import Conditions from "./components/Conditions";
import PromotionSettings from "./components/PromotionSettings";
import Help from "./components/Help";
import Cookies from 'universal-cookie';
import { setAuthorizeted, setChecked, signOut } from "./redux/slices/authSlice";
import Remains from "./components/Remains/Remains";
import Pricelist from "./components/Pricelist/Pricelist";
import { onMessageListener, requestForToken } from './firebase'
import Projects from "./components/Projects";
import ProjectsPage from "./components/Projects/ProjectsPage";
import Stores from "./components/Stores/Stores";
import ConstructorPage from "./components/ConstructorPage/ConstructorPage";
import EditPatternPage from "./components/EditPatternPage/EditPatternPage";
import CommercialOfferPage from "./components/CommercialOfferPage/CommercialOfferPage";
import EditBlockPage from "./components/EditBlockPage/EditBlockPage";

const cookies = new Cookies();

const AppRoutes = () => {
    const dispatch = useTypedDispatch();
    const authRedux = useTypedSelector((state) => state.auth);
    const [collapsed, setCollapsed] = useState(window.screen.width > 450 ? false : true);
    const handbookRedux = useTypedSelector((state) => state.handbook);
    const legals = useTypedSelector((state) => state.handbook.legals);
    const orders = useTypedSelector((state) => state.handbook.orders);
    const cartRedux = useTypedSelector((state) => state.cart);

    const history = useHistory();
    const messageHandler = payload => {
        const data = payload.data;
        if (data.type === "sync_partner") {
            const company = JSON.parse(data.company);
            console.log("Sync partner", company);
            dispatch(setLegals([company, ...legals.filter(item => item.inn !== company.inn)]))
            notification.open({
                type: "success",
                message: data.title,
                description:
                    data.body,
            });
        }
        if (data.type === "sync_order") {
            const order = JSON.parse(data.order);
            console.log("Sync order", order);
            dispatch(setOrders(orders.map(item => {
                if (item.id === order.id) {
                    return { ...item, ...order };
                }
                return item;
            })))
            notification.open({
                type: "success",
                message: data.title,
                description:
                    data.body,
            });
        }
    }


    onMessageListener(messageHandler)
    // useEffect(() => {
    //   if (authorized) {
    //     onMessageListener(messageHandler)
    //   }
    // }, [authorized])
    // .then((payload) => {
    //   console.log("get message", payload);

    // })
    // .catch((err) => console.log('failed: ', err));

    useEffect(() => {
        const pathName = window.location.pathname;
        const userCookie = cookies.get("user");
        console.log("userCookie", userCookie);
        if (userCookie) {
            localStorage.setItem("user", JSON.stringify(userCookie))
            requestForToken()
                .then((firebaseToken) => {
                    // eslint-disable-next-line no-console
                    console.log("GET TOKEN FIREBASE", firebaseToken);
                    if (firebaseToken) {
                        fetch(SiteUrlDealer + "/firebase_token", {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""
                                    }`,
                            },
                            method: "POST",
                            body: JSON.stringify({
                                user_id: userCookie.id,
                                token: firebaseToken
                            })
                        })
                    }
                })
                .catch((err) => {
                    console.log("Error auth firebase", err);
                });
        }
        const accessTokenCookie = cookies.get("accessToken");
        if (accessTokenCookie) {
            console.log("accessTokenCookie", accessTokenCookie);
            localStorage.setItem("accessToken", accessTokenCookie)
        }

        if (pathName != "/" && pathName != "/login" && accessTokenCookie) {
            fetch(
                `${SiteUrlDealer}/dealers-stores`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""
                            }`,
                    }
                }
            ).then((res) => res.json())
                .then((res) => {

                    dispatch(setStores(res));
                    return dispatch(fetchJur());
                })
                .then(() => {
                    console.log("jur req done", cookies.get("user"));
                    dispatch(setAuthorizeted(true));
                    dispatch(setChecked(true));

                    dispatch(setRole(cookies.get("user").capabilities));
                    // @ts-ignore
                    return dispatch(fetchProducts());
                })
                .then(() => {

                    //dispatch(fetchPromotions());
                })
                .then(() => {
                    console.log("handbookRedux.error", handbookRedux.error);

                    if (!handbookRedux.error) {

                        // console.log("prods req done");
                    } else {

                        // сделать вывод ошибки
                        // localStorage.removeItem("user");
                        // localStorage.removeItem("access_token");
                        // localStorage.removeItem("accessToken");
                        // dispatch(signOut())
                        // history.push("/login")
                    }
                });
        } else {

        }
    }, []);

    useEffect(() => {
        if (handbookRedux.error) {
            dispatch(signOut())
        }
    }, [handbookRedux.error])





    useEffect(() => {
        const localCart = localStorage.getItem("cart");
        if (cartRedux.cartItems.length === 0 && localCart !== null) {
            dispatch(addCartItem(JSON.parse(localCart)));
        }
    }, []);
    // useEffect(() => {
    //     console.log(location.pathname, "---------------------");
    //     if (!authRedux.authorized && (location.pathname != "/" && location.pathname != "/login")) {
    //         console.log(" ============================ REDIRECT!!!!!!!!!!!!!!!!", authRedux.authorized, location.pathname);
    //         history.push("/")
    //     }
    // }, [location.pathname])
    useEffect(() => {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const redirect_flag = params.get('redirect');
        console.log("There redirect ", redirect_flag);
        if (!redirect_flag && (!authRedux.authorized && (location.pathname != "/" && location.pathname != "/login"))) {
            console.log(" ============================ REDIRECT!!!!!!!!!!!!!!!!");
            //history.push("/login")
        }

    }, [location.pathname])

    useEffect(() => {
        const user = localStorage.getItem("user");
        if (user) {
            try {
                setRole(JSON.parse(user).capabilities);
            } catch (error) {

            }
        }

        checkAuth();
    }, []);



    const checkAuth = async () => {
        try {
            console.log("localStorage.getItem(accessToken)", localStorage.getItem("accessToken"));

            if (localStorage.getItem("accessToken") == undefined) {
                dispatch(setChecked(true));

                return;
            }

            fetch(SiteUrlAuth + "/api/user/get", {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""
                        }`,
                },
                method: "GET",
            })
                .then((res) => res.json())
                .then((json) => {
                    console.log("aut data", json);
                    requestForToken()
                        .then((firebaseToken) => {
                            // eslint-disable-next-line no-console
                            console.log("GET TOKEN FIREBASE", firebaseToken);
                            if (firebaseToken) {
                                fetch(SiteUrlDealer + "/firebase_token", {
                                    headers: {
                                        "Content-Type": "application/json",
                                        Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""
                                            }`,
                                    },
                                    method: "POST",
                                    body: JSON.stringify({
                                        user_id: json.user.id,
                                        token: firebaseToken
                                    })
                                })
                            }
                        })
                        .catch((err) => {
                            console.log("Error auth firebase", err);
                        });
                    localStorage.setItem("user", JSON.stringify(json.user));
                    // cookies.set("accessToken", localStorage.getItem("accessToken"))
                    // cookies.set("user", json.user)
                    cookies.set("accessToken", localStorage.getItem("accessToken"), { domain: '.vakio.ru' })
                    cookies.set("user", json.user, { domain: '.vakio.ru' })
                    dispatch(setRole(json.user.capabilities));
                    dispatch(setAuthorizeted(true));
                    dispatch(setChecked(true));

                    // requestOrgs();
                    fetch(
                        `${SiteUrlDealer}/dealers-stores`
                        ,
                        {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""
                                    }`,
                            }
                        }
                    ).then((res) => res.json())
                        .then((res) => {
                            console.log("stores req done");
                            dispatch(setStores(res));
                            return dispatch(fetchJur());
                        })
                        .then(() => {
                            console.log("jur req done 22");
                            return dispatch(fetchProducts());
                        })
                        .then(() => {
                            if (!handbookRedux.error) {
                                console.log("prods req done", json.user);
                                // history.push("/user");

                            } else {
                                // сделать вывод ошибки
                                history.push("/login");
                            }
                        });
                })
                .catch((err) => {
                    console.log(err);
                    history.push("/login");
                    localStorage.removeItem("user");
                    cookies.remove("user")
                    dispatch(signOut());
                }).finally(() => {
                    dispatch(setChecked(true));

                });
        } catch (error) {
            history.push("/login");
            dispatch(signOut());
        }
    };


    return (
        <>
            {!authRedux.authorized && <Switch>

                <Route path="/login" component={SignIn} />
                <Route path="/commercial-offer/:offer" component={CommercialOfferPage} exact />
                {authRedux.checked && <Route path="*" component={SignIn} />}
            </Switch>}
            <>
                {
                    // handbookRedux.loading ? (
                    //     <div style={{ width: "100vw", height: "100vh" }}>
                    //         <Spin
                    //             size="large"
                    //             style={{
                    //                 position: "absolute",
                    //                 top: "50%",
                    //                 left: "50%",
                    //                 transform: "translate(-50%, -50%)",
                    //             }}
                    //         />
                    //     </div>
                    // ) : 
                    handbookRedux.error ? (
                        <div style={{ width: "100vw", height: "100vh" }}>
                            <Result
                                status="500"
                                title="Ошибка"
                                subTitle="Что-то пошло не так"
                                style={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                }}
                            // extra={<Button type="primary">Back Home</Button>}
                            />
                        </div>

                    ) : (
                        <>
                            {(authRedux.authorized) && <Layout style={{ height: "100vh" }}>
                                {<Sidebar collapsed={collapsed} setCollapsed={setCollapsed} />}
                                <Layout className="site-layout">
                                    <Header state={collapsed} setState={setCollapsed} />
                                    <Layout.Content
                                        style={{
                                            padding: "16px",
                                            paddingLeft: window.screen.width > 450 ? "16px" : 0,
                                            paddingRight: window.screen.width > 450 ? "16px" : 0,
                                            overflowY: "auto",
                                        }}
                                    >
                                        {(handbookRedux.d_isUser || handbookRedux.d_isManager || handbookRedux.d_isAdminManager) && <>
                                            <Route path="/user" component={User} />
                                            <Route path="/" component={User} exact />
                                            <Route path="/form-co" component={ConstructorPage} exact />
                                            <Route path="/edit-block" component={EditBlockPage} exact />
                                            <Route path="/edit-pattern" component={EditPatternPage} exact />
                                            <Route path="/edit-pattern/:id" component={EditPatternPage} exact />
                                            <Route path="/commercial-offer/:offer" component={CommercialOfferPage} exact />
                                            <Route path="/commercial-offer/edit/:offer" component={CommercialOfferPage} exact />
                                            <Route path="/dealer-projects" component={Projects} />
                                            <Route path="/orders" component={Order} />
                                            <Route path="/stores" component={Stores} />
                                            <Route path="/order/:id" component={OrderDetails} />
                                            <Route path="/create-order/:id" component={CreateOrder} />
                                            <Route path="/catalog" component={Catalog} />
                                            <Route path="/discounts" component={PersonalDiscounts} />
                                            <Route path="/my-companies" component={MyCompanies} />
                                            {(handbookRedux.d_isAdminManager) && <Route path="/settings" component={Settings} />}
                                            <Route path="/promotions" exact component={Promotions} />
                                            <Route path="/promotions/:id" exact component={PromotionSettings} />
                                            <Route path="/conditions" component={Conditions} />
                                            <Route path="/help" component={Help} />
                                            {/* <Route path="/" component={User} exact /> */}
                                            {(handbookRedux.d_isManager || handbookRedux.d_isAdminManager) && <Route path="/remains" component={Remains} />}
                                            {(handbookRedux.d_isManager || handbookRedux.d_isAdminManager) && <Route path="/pricelist" component={Pricelist} />}
                                        </>}



                                        {/* {!authRedux.authorized && <Redirect from="*" to="/" />} */}
                                    </Layout.Content>
                                </Layout>
                            </Layout>
                            }
                        </>
                    )}
            </>
            {/* {authRedux.checked && <Redirect from='*' to='/' />} */}
        </>
    );
};

export default AppRoutes;