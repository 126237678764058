import React, { useState, useEffect } from "react";
import { useTypedDispatch, useTypedSelector } from "../../redux/hooks";
import { Button, Spin, message } from "antd";
import { PlusOutlined, PictureTwoTone } from "@ant-design/icons";
import { useRouteMatch, Link, useHistory } from "react-router-dom";
import { setHeaderTitle } from "../../redux/slices/layoutSlice";

import PromotionDetails from "../PromotionDetails";

import "./Conditions.scss";
import "../../styles/Card.scss";

type Props = {};

export default function Promotions({ }: Props) {
  const dispatch = useTypedDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  type inputData = {
    input_type: string[];
    input_comparison: string;
    input_value: string;
  };

  type outputData = {
    output_type: string[] | string;
    output_comparison: string;
    output_value: string;
  };

  interface IPromotions {
    id?: number | string;
    title: string;
    // expired: string;
    input_data: inputData[];
    output_data: outputData[];
  }

  const [promotions, setPromotions] = useState<IPromotions[]>([]);
  let controller = new AbortController();

  useEffect(() => {
    dispatch(setHeaderTitle("Условия акций"));

    setIsLoading(true);
    fetch(`${SiteUrlDealer}/promotion_condition`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""}`,
      },
      signal: controller.signal,
      method: "GET",
    })
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        setPromotions(json.content);
        setIsLoading(false);
      })
      .catch(() => message.error("Ошибка при загрузке условий"));

    return () => {
      // controller.abort();
    };
  }, []);

  function handleAddGroup() {
    const emptyGroup = {
      title: "Новое условие",
      // id: "",
      id: Math.random(),
      // parent_id: 2,
      // group_id: "0",
      input_data: [
        {
          input_type: [""],
          input_comparison: "=",
          input_value: "1",
        },
      ],
      output_data: [
        {
          output_type: [""],
          output_comparison: "=",
          output_value: "1",
        },
      ],
    };

    const copy = promotions.slice();
    copy.push(emptyGroup);

    setPromotions(copy);
  }

  function deleteUnsaved(id: number) {
    // console.log("del", id);
    setPromotions(promotions.filter((f) => f.id != id));
  }

  // console.log(promotions);

  return (
    <div className="conditions">
      {isLoading ? (
        <div className="conditions__spin-container">
          <Spin size="large" />
        </div>
      ) : (
        <>
          <Button type="primary" icon={<PlusOutlined />} onClick={handleAddGroup}>
            Новое условие
          </Button>
          <div className="conditions__items-container">
            {promotions
              .sort((a, b) => {
                if (Math.floor(+a.id) == 0) {
                  return -1;
                } else {
                  return a.id > b.id ? -1 : a.id == b.id ? 0 : a.id < b.id ? 1 : 0;
                }
              })
              .map((itm) => {
                return (
                  <div key={itm.id}>
                    <PromotionDetails
                      group={itm}
                      // setGroups={setPromotions}
                      type="standalone"
                      deleteUnsaved={deleteUnsaved}
                      promotionId={false}
                    />
                  </div>
                );
              })}
          </div>
        </>
      )}
    </div>
  );
}
