import React from 'react';
import "./MainBlock.scss"
const ProductResultSum = ({ products, label = undefined }) => {

    const calcDefaultPrice = () => {
        const result = products.reduce((prev, product) => {
            return prev + product.default_price* product.amount;
        }, 0)
        return result;
    }

    const calcRealPrice = () => {
        const result = products.reduce((prev, product) => {
            return prev + Number(product.price ?? 0) * product.amount ;
        }, 0)
        return result;
    }
    console.log("products",products);
    return (
        <div className='room-result'>
            <div className='room-result__label'>
                {`${label ? label : "Итого:"}`}
            </div>
            <div
                className='room-result__default'
                style={calcRealPrice() < calcDefaultPrice() ? { textDecoration: "line-through", color: "#afafaf", } : {}}
            >
                {formatMoney(calcDefaultPrice())}&nbsp;₽
            </div>
            {calcRealPrice() < calcDefaultPrice() && <div className='room-result__price'>
                {formatMoney(calcRealPrice())}&nbsp;₽
            </div>}
        </div>
    );
};

export default ProductResultSum;