import React, { useState, useEffect, useMemo } from "react";
import { Card, Typography, Button, Divider, Spin, message, Input, Modal, Select } from "antd";
import { PlusOutlined, DeleteOutlined, SendOutlined, EditOutlined } from "@ant-design/icons";
import { useTypedDispatch, useTypedSelector } from "../../redux/hooks";
import { setHeaderTitle } from "../../redux/slices/layoutSlice";
import { useHistory, useLocation, useParams } from "react-router-dom";

import ItemCreateOrder from "./CreateOrderListItem";

import LegalAndDelivery from "../LegalAndDelivery";
import { routePropsTypes, toSendType, productsType, promoType } from "./types";

import "../../styles/Card.scss";
import "./CreateOrder.scss";

import productsSets from "../../json/productsSets.json";
import OrderTable from "./OrderTable";
import DeliveryModal from "./DeliveryModal";
import ActivePromotions from "./ActivePromotions";
import Popover from "antd/es/popover";
import LoadPrice from "./LoadPrice";
import MissingProductsModal from "./MissingProductsModal";
import AgreementModal from "./AgreementModal";

export interface ICreateOrderProps { }

export default function CreateOrder(props: ICreateOrderProps) {
  const d = new Date();
  const date =
    // prettier-ignore
    d.getFullYear() + '-' +
    ('0' + (d.getMonth() + 1)).slice(-2) + '-' +
    ('0' + d.getDate()).slice(-2);

  const history = useHistory();
  const dispatch = useTypedDispatch();
  const handbookRedux = useTypedSelector((state) => state.handbook);
  const state = useLocation().state as (routePropsTypes | any);
  const [subtotal, setSubtotal] = useState<number | null>(null);
  // const [totalDiscount, setTotalDiscount] = useState<number>(0);
  const [total, setTotal] = useState<number | null>(null);
  const defaultData = {
    ref_item: `temporary${Math.random().toString()[2]}`,
    name: "",
    qty: 1,
    sum: null as null,
    price: null as null,
    // defaultPrice: null as null,
    id: 0,
    custom_price: false,
    custom_sum: false,
  };
  const [products, setProducts] = useState<any[]>([defaultData]);
  const [tableData, setTableData] = useState<any[]>([defaultData]);
  const [filterCat, setFilterCat] = useState([])
  const [toSend, setToSend] = useState<toSendType>({
    type: "order",
    ref: "",
    invoice_type: "",
    bitrix_id: null,
    deleted: false,
    posted: true,
    date: date,
    number: "",
    organization: "",
    partner: "",
    store: "3cf46df0-c735-11e6-8731-001e6730f61b",
    // !!!!!!!!!!!!!!!!!!!
    user: "",
    // !!!!!!!!!!!!!!!!!!!
    comment: "",
    explicit_prices: false,
    items: [],
    // payments: [],
    // sales: [],
    delivery_method: null,
    transport_company: null,
    comment_for_store: "",
    partner_data: {},
    invoice_email: [],
    synced: "0",
    track_phone: "",
    avaliable_shipment: undefined
  });
  const [modalVis, setModalVis] = useState<boolean>(false);
  const [agreeModalVisible, setAgreeModalVisible] = useState<boolean>(false);
  const [bizProcId, setBizProcId] = useState<any>(null);
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const [lastIndex, setLastIndex] = useState<number>(0);
  // const [layout, setLayout] = useState<string>("table");
  const [layout, setLayout] = useState<string>("list");
  const [addTrigger, setAddTrigger] = useState<boolean>(false);
  const [btnDraftLoading, setBtnDraftLoading] = useState<boolean>(false);
  const [role, setRole] = useState<string>("");
  const [comment, setComment] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [promo, setPromo] = useState<promoType[]>([]);
  const [productsMode, setProductsMode] = useState<"classic" | "prefill">("classic");
  const [fullPartnerData, setFullPartnerData] = useState<any>({});
  const routerParams: any = useParams();
  const [modifedProduct, setModifedProduct] = useState([]);
  const [missingProductsVisible, setMissingProductsVisible] = useState<boolean>(false);
  const [missingProducts, setMissingProducts] = useState<any>({});


  let controller = new AbortController();

  useEffect(() => {
    let isCancelled = false;
    dispatch(setHeaderTitle("Новый заказ"));
    (async () => localStorage.getItem("defaultPartner"))().then((p) => {
      if (p !== null) {
        fetch(`${SiteUrlDealer}/organization_with_filters`, {
          signal: controller.signal,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""
              }`,
          },
          body: JSON.stringify({
            filters: [
              {
                field: "ref",
                comparison: "like",
                value: p,
              },
            ],
          }),
          method: "POST",
        })
          .then((res) => res.json())
          .then((json) => {
            if (!isCancelled) {
              setFullPartnerData(json.partners[0]);
              setToSend(toSend => {
                return {
                  ...toSend,
                  partner: p,
                  partner_data: json.partners[0],
                }
              });
            }
          });

        if (Number.isInteger(parseInt(routerParams.id))) {
          fetch(`${SiteUrlDealer}/order/${routerParams.id}`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""
                }`,
            },
            method: "GET",
          })
            .then((res) => res.json())
            .then((json) => {
              if (!isCancelled) {
                json[0].invoice_email = [];
                setToSend({ ...json[0] });
                dispatch(setHeaderTitle(`Редактирование ${json[0].number}`));
                state.mode = "edit";
              }
            });
        }
      }
    });


    if (document.location.href.split("create-order/")[1] === "prefill") {
      handlePrefill(['all']);
    }

    return () => {
      isCancelled = true;
    };
  }, []);

  // useEffect(() => {
  //   setToSend({
  //     ...toSend,
  //     partner: fullPartnerData.ref,
  //     partner_data: fullPartnerData,
  //   });
  // }, [fullPartnerData]);

  // console.log("tosend", toSend);

  useEffect(() => {
    setAddTrigger(!addTrigger);
  }, [toSend.partner]);

  useEffect(() => {
    const defaultProducts = products
      .filter((f) => !f.ref_item.includes("temporary"))
      .filter((f) => f.type === "default")
      .map((itm, ind) => ({
        item: itm.ref_item,
        manager_discount: itm.discount,
        amount: itm.qty,
        sum: itm.sum,
        type: itm.type,
      }));
    const promoProducts = promo
      .filter((f) => f.type === "promo")
      .map((itm, ind) => ({
        item: itm.ref_item,
        manager_discount: itm.discount,
        amount: itm.qty,
        sum: itm.sum,
        type: itm.type,
      }));

    setToSend(toSend => {
      return {
        ...toSend,
        items: defaultProducts.concat(promoProducts),
      }
    });
  }, [products, promo]);

  useEffect(() => {
    const lastProduct = products[products.length - 1];
    if (state) {
      // && state.mode !== "cart" ДОРАБОТАТЬ в корзине тоже нужно добавить позиции
      if (
        productsMode === "classic" &&
        !lastProduct.ref_item.includes("temporary")
      ) {
        addEmptyField();
      }
    }
  }, [products]);
  useEffect(() => {
    if (state.mode === "edit") {


      setToSend(toSend => {

        return {
          ...toSend,
          organization: toSend.organization,
        }
      });
    } else if (state?.mode === "co") {

      // setToSend(toSend => {
      //   return {
      //     ...toSend

      //   }
      // });
    } if (state && (state.mode === "create" || state.mode === "cart")) {
      if (toSend.partner_data.length !== 0) {
        setToSend(toSend => {
          return {
            ...toSend,
            organization: toSend.partner_data.main_contract_organization,
          }
        });
      }
    }
    // else if (state && state.mode === "edit") {
    //   setToSend({
    //     ...toSend,
    //     organization: toSend.organization,
    //   });
    // }
  }, [toSend.partner_data]);

  useMemo(() => {
    setLastIndex(lastIndex + 1);
    defaultData.id = products.length;
    setProducts([...products.filter((f) => !f.ref_item.includes("temporary")), defaultData])

  }, [products.length]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (products.length === 0) return;
      if (products[0].ref_item.includes("temporary")) return;



      const body = {
        ref_store: toSend.store,
        ref_org: toSend.partner,
        content: products
          .filter((f) => !f.ref_item.includes("temporary"))
          .map((prod, ind) => ({
            item: prod.ref_item,
            qty: prod.qty,
            sum: prod.sum,
            price: prod.price,
            custom_sum: prod.custom_sum,
            custom_price: prod.custom_price,
            type: prod.type ? prod.type : "default",
          })),
      };
      if (body.content.length === 0)
        return;
      console.log("modifedProduct", modifedProduct);
      setIsLoading(true);
      fetch(`${SiteUrlDealer}/check_discount`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""}`,
        },
        body: JSON.stringify(body),
        method: "POST",
      })
        .then((res) => res.json())
        .then((json) => {
          const products = json.products;
          if (json.missing_on_main || json.missing_on_transit) {
            setMissingProductsVisible(true);
          }
          setMissingProducts({
            missing_on_main: json.missing_on_main,
            missing_on_transit: json.missing_on_transit,
          });
          const withIds = products.map((itm: any, ind: any) => ({
            ...itm,
            id: ind,
          })).filter((itm: any) => (modifedProduct.includes(itm.ref_item) || itm.qty > 0));
          const filteredDefault = products.filter((f: any) => f.type === "default" || f.type === "discount");

          if (productsMode === "classic") {
            console.log("filteredDefault", filteredDefault);
            setProducts(filteredDefault);
            setTableData(withIds);
          } else {
            const edited = products.map((prodItm: any) => {
              const found = filteredDefault.find((f: any) => f.ref_item === prodItm.ref_item);
              return found ? { ...found, id: prodItm.id } : prodItm;
            });

            setProducts(edited);
            setTableData(withIds);
          }
          const filteredPromos = withIds.filter((f: any) => f.type === "promo");
          setPromo(filteredPromos);

          setIsLoading(false);
        });
    }, 500)
    return () => clearTimeout(timeout);
  }, [addTrigger]);

  // подставляшки
  useEffect(() => {

    if (state && state.mode === "edit" && handbookRedux.products.length > 0) {
      console.log(state);
      (async () => {
        // @ts-ignore
        setToSend(state.data);
        setComment(state.data.comment !== null ? state.data.comment : "");
        console.log("вошел");
        // console.log(state.data);
      })()
        .then(() =>
          state.data.items.map((itm, ind) => ({
            qty: itm.amount,
            ref_item: itm.item,
            sum: itm.sum,
            id: ind,
            name: handbookRedux.products.find((find) => find.ref == itm.item).name,
            discount: itm.manager_discount,
            custom_price: false,
            custom_sum: false,
            parent: itm.parent,
            default_price: itm.default_price,
            type: itm.type,
          }))
        )
        .then((prods) => {
          setProducts(prods);
          setModifedProduct(prods.map(prod => prod.ref_item))
        })
        .then(() => {
          setAddTrigger(!addTrigger);
        });
    }

    if (state && (state.mode === "cart" || state.mode === "co") && handbookRedux.products.length > 0) {
      (async () =>
        state.data.items.map((itm, ind) => ({
          qty: itm.amount,
          ref_item: itm.item,
          sum: itm.sum,
          id: ind,
          name: handbookRedux.products.find((find) => find.ref == itm.item).name,
          discount: itm.manager_discount,
          custom_price: false,
          custom_sum: false,
          parent: itm.parent,
          default_price: itm.default_price,
        })))().then((prods) => {
          setProducts(prods);
        });
    }
  }, [state]);

  // console.log(products);

  useEffect(() => {
    const filteredSubtotal = products.filter((f) => !f.ref_item.includes("temporary"));
    const calculatedSubtotal = filteredSubtotal.reduce((n, { default_price, qty, discount }) => {
      // console.log(default_price);

      return n + default_price * qty;
    }, 0);
    // const promoSubtotal = promo.reduce((n, { default_price, qty, discount }) => n + default_price * qty, 0);

    setSubtotal(calculatedSubtotal);

    // const calculatedFullDiscount = filteredSubtotal.reduce(
    //   (n, { price, qty, sum }: any) =>
    //     // @ts-ignore
    //     n + (price - sum / qty) * qty,
    //   0
    // );
    // setTotalDiscount(calculatedFullDiscount);
    setTotal(products.reduce((n, { sum }) => n + sum, 0));
  }, [products]);

  function sendOrder(type?: string) {
    // if (type == "draft") {
    //   toSend.posted = 0;
    // } else {
    //   toSend.posted = 1;
    // }
    toSend.posted = 0;
    toSend.items = toSend.items.filter((f) => f.amount !== 0);
    console.log("tosend", toSend.items);

    if (state && state.mode == "edit") {
      setBtnLoading(false);
      setBtnDraftLoading(false);
      setModalVis(true);
      return;
    }

    fetch(
      `${SiteUrlDealer}/order${state && state.mode == "edit" && handbookRedux.products.length ? `/${toSend.id}` : ""
      }`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""
            }`,
        },
        // body: JSON.stringify(state.mode == "edit" ? toSend : { content: [toSend] }),
        body: state.mode == "edit" ? JSON.stringify({ ...toSend, avaliable_shipment: undefined }) : JSON.stringify({ content: [{ ...toSend, avaliable_shipment: undefined }] }),
        method: state.mode == "edit" ? "PUT" : "POST",
      }
    )
      .then((res) => res.json())
      .then((json) => {
        if (state && state.mode == "create") {
          setToSend({
            ...toSend,
            id: json.content[0],
            avaliable_shipment: json.avaliable_shipment
          });
          state.mode = "edit";
        }

        console.log(json);
        setBtnLoading(false);
        setBtnDraftLoading(false);
        setModalVis(true);

        // if (type === "draft") {
        //   message.success("Черновик сохранён");
        //   history.push("/orders");
        // } else {
        //   setModalVis(true);
        //   // message.success("Заказ отправлен! Счёт будет доступен в течение 2-х минут на странице заказа", 30);
        // }
      })
      .catch(console.error);
  }

  function handlePrefill(parent: any[]) {

    // setProductsMode("prefill");

    // const filteredProducts = productsSets.default;
    const filteredProducts = handbookRedux.products
      .filter((itm, ind) => itm.enable == 1).filter(itm => parent.includes("all") || parent.includes(itm.parent))

    console.log("parent", parent);

    const convertedProducts = filteredProducts.map(
      (itm, ind) =>
      ({
        discount: 0,
        id: ind,
        qty: 0,
        ref_item: itm.ref,
        ref_org: toSend.partner,
        sum: 0,
        price: 0,
        parent: itm.parent,
        default_price: itm.price,
      } as any)
    );
    console.log("products filterCat", [...products.filter(product => modifedProduct.includes(product.ref_item)), ...convertedProducts.filter((product) => {
      if (modifedProduct.includes(product.ref_item)) return false;
      else return true;
    })]);
    setFilterCat(parent);
    setAddTrigger(!addTrigger);
    setProducts([...products.filter(product => modifedProduct.includes(product.ref_item)), ...convertedProducts.filter((product) => {
      if (modifedProduct.includes(product.ref_item)) return false;
      else return true;
    })]);
  }

  function handleAddPromo(promo: any) {
    // setProductsMode("prefill");
    console.log(promo);
    const converted = promo.map(
      (itm: any, ind: any) =>
      ({
        discount: 0,
        id: ind,
        qty: itm.qty,
        // qty: 0,
        ref_item: itm.ref,
        ref_org: toSend.partner,
        sum: 0,
        price: 0,
        default_price: 0,
      } as any)
    );
    setTimeout(() => {
      setProducts(products.concat(converted));
      // setAddTrigger(!addTrigger);
    });
  }

  function addEmptyField() {
    defaultData.id = lastIndex;

    setProducts([...products, defaultData]);
  }



  return (
    <div className="create-order">
      <div className="create-order__layout-main">
        <ActivePromotions addPromos={handleAddPromo} />
        <div style={{ width: "100%" }}>
          <LegalAndDelivery mode="edit" editData={state} toSend={toSend} setToSend={setToSend} setAddTrigger={setAddTrigger} />
        </div>

        <Card
          title={<Typography.Title level={ResponsiveTitleLevel}>Товары</Typography.Title>}
          extra={
            <Popover
              placement="bottom"
              content={
                <LoadPrice
                  handlePrefill={handlePrefill} />
              } title="Прайс" trigger="click">
              <Button
                type="default"
                // disabled={productsMode === "prefill"}
                onClick={() => {

                }}
              >
                {/* Добавить популярное */}
                Подгрузить прайс
              </Button>
            </Popover>
          }
          style={{ width: "100%" }}
          bordered={false}
          className="card create-order__products"
        >
          {isLoading && (
            <div className="create-order__load-container">
              <div className="create-order__load">
                <Spin />
              </div>
            </div>
          )}

          {products.length > 0 &&
            products
              .sort((a, b) => {
                return a.id > b.id ? 1 : a.id == b.id ? 0 : a.id < b.id ? -1 : 0;
              })
              .filter((f) => {
                if (productsMode === "classic") {
                  return f.ref_item.includes("temporary") || f.type === "default";
                } else {
                  return f;
                }
              })
              .map((itm, ind) => {
                return (
                  <div key={ind}>
                    <ItemCreateOrder
                      setTableData={setTableData}
                      tableData={tableData}
                      setModifedProduct={setModifedProduct}
                      modifedProduct={modifedProduct}
                      itm={itm}
                      ind={ind}
                      fullData={products}
                      setParentData={(data: any[]) => setProducts(data)}
                      disabled={toSend.partner == ""}
                      // setLastIndex={setLastIndex}
                      addTrigger={addTrigger}
                      setAddTrigger={setAddTrigger}
                      productsMode={productsMode}
                    />
                  </div>
                );
              })}

          <Divider />
          <div className="create-order__comment">
            <Input.TextArea
              rows={4}
              placeholder="Комментарий к заказу"
              maxLength={300}
              value={comment}
              style={{ resize: "none" }}
              onChange={(e) => {
                setComment(e.target.value);
                setToSend({
                  ...toSend,
                  comment: e.target.value,
                });
              }}
            />
          </div>
        </Card>
        <Card
          title={<Typography.Title level={ResponsiveTitleLevel}>Итог</Typography.Title>}
          bordered={false}
          className="card create-order__table"
          style={{ marginTop: 16, width: "100%" }}
        >
          <OrderTable
            setModifedProduct={setModifedProduct}
            modifedProduct={modifedProduct}
            products={tableData}
            total={total}
            disabled={toSend.partner == ""}
            productsMode={productsMode}
            fullData={products}
            setParentData={(data: any[]) => setProducts(data)}
            // setLastIndex={setLastIndex}
            addTrigger={addTrigger}
            setAddTrigger={setAddTrigger}
          />
        </Card>
      </div>

      <Card
        title={<Typography.Title level={ResponsiveTitleLevel}>Итог</Typography.Title>}
        bordered={false}
        className="card create-order__layout-side"
      >
        <div className="create-order__sum-item">
          {/* <div className="create-order__sum-left">Подытог</div> */}
          <div className="create-order__sum-left">Без скидки</div>
          <div className="create-order__sum-right">{formatMoney(subtotal ? subtotal : 0)}&nbsp;₽</div>
        </div>

        <Divider style={{ marginTop: 5, marginBottom: 5, width: "70%" }} />

        <div className="create-order__sum-item create-order__sum-item--big">
          <div className="create-order__sum-left">Итог</div>
          <div className="create-order__sum-right">{formatMoney(total ? total : 0)}&nbsp;₽</div>
        </div>
        <Divider style={{ marginTop: 5, marginBottom: 5, width: "70%" }} />


        <div
          style={{
            width: "100%",
            textAlign: "right",
            marginTop: 32,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Button
            type="primary"
            size="large"
            loading={btnLoading}
            icon={<SendOutlined />}
            style={{ whiteSpace: "normal", height: "auto" }}
            disabled={toSend.items.filter((f) => f.amount !== 0)?.length < 1 || (toSend && toSend.synced == "0" && toSend.posted === 1 ? true : false)}
            // style={{ marginTop: 16 }}
            onClick={() => {
              if (products.length > 0) {
                console.log("TO SEND", toSend);

                if (toSend.partner === "" || toSend.organization === "") {
                  message.error("Данные счёта заполнены некорректно!");
                } else if (products.length === 1 && products[0].name === "") {
                  message.error("Товары не выбраны!");
                } else {
                  setBtnLoading(true);
                  // sendOrder("draft");
                  sendOrder("send");
                }
              }
            }}
          >
            {state && state.mode === "edit" && toSend.synced == "0" && toSend.posted === 1
              ? "Идет синхронизация"
              : state && state.mode === "edit" && toSend.synced == "1" && toSend.posted === 1
                ? "Продолжить оформление"
                : "Продолжить оформление"}
          </Button>
        </div>
      </Card>
      <MissingProductsModal
        visible={missingProductsVisible}
        missingProducts={missingProducts}
        setToSend={setToSend}
        setVisible={setMissingProductsVisible}
      />
      <DeliveryModal
        toSend={toSend}
        setToSend={setToSend}
        modalVis={modalVis}
        setModalVis={setModalVis}
        state={state}
        setBizProcId={setBizProcId}
        setAgreeModalVisible={setAgreeModalVisible}
        agreeModalVisible={agreeModalVisible}

      />
      <AgreementModal
        setBizProcId={setBizProcId}
        bizprocId={bizProcId}
        setVisible={setAgreeModalVisible}
        visible={agreeModalVisible}
      />
    </div>
  );
}
