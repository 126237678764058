// import * as React from "react";
import React, { useState, useEffect } from "react";
import { Card, Typography, Divider, Button, Spin, TypographyProps, message, Tooltip } from "antd";
import {
  PictureTwoTone,
  InfoCircleOutlined,
  CommentOutlined,
  SendOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { ResponsivePie } from "@nivo/pie";
import { useHistory, useLocation, useParams } from "react-router-dom";
// import { useSelector, useDispatch } from "react-redux";
import { useTypedDispatch, useTypedSelector } from "../../redux/hooks";
import { routePropsTypes, toSendType, productsType, promoType } from "../CreateOrder/types";
import DeliveryModal from "../CreateOrder/DeliveryModal";
import {
  setHeaderTitle,
  setHeaderSubtitle,
  hideHeaderSubtitle,
  setHeaderButtonData,
} from "../../redux/slices/layoutSlice";
import { fetchProducts } from "../../redux/slices/handbookSlice";
import LegalAndDelivery from "../LegalAndDelivery";
import "./OrderDetails.scss";
import deliveryJson from "../../json/delivery.json";
import AgreementModal from "../CreateOrder/AgreementModal";

const { Text, Link, Title } = Typography;

export interface IOrderDetailsProps { }

interface IRouteProps {
  mode: string;
  data: {
    ref: string;
    number: string;
    date: string;
    items: any[];
    items_amount: number | string;
    payments_amount: number | string;
    sales_amount: number | string;
    comment: string;
    organization: string;
    partner: string;
    store: string;
  };
}

export default function OrderDetails(props: IOrderDetailsProps) {
  const d = new Date();
  const date =
    // prettier-ignore
    d.getFullYear() + '-' +
    ('0' + (d.getMonth() + 1)).slice(-2) + '-' +
    ('0' + d.getDate()).slice(-2);
  const location = useLocation();
  const history = useHistory();
  const dispatch = useTypedDispatch();
  // var data = state.data ? state.data : undefined;
  const handbookRedux = useTypedSelector((state) => state.handbook);
  const layoutRedux = useTypedSelector((state) => state.layout);
  const state = location.state
    ? (location.state as IRouteProps)
    : ({ mode: "view", data: undefined } as IRouteProps);
  const [agreeModalVisible, setAgreeModalVisible] = useState<boolean>(false);
  const [bizProcId, setBizProcId] = useState<any>(null);
  // console.log('location',location);
  const [data, setData] = useState<any>(state ? state.data : undefined);
  const [pieData, setPieData] = useState<any>(undefined);
  const [toSend, setToSend] = useState<toSendType>({
    type: "order",
    ref: "",
    invoice_type: "",
    bitrix_id: null,
    deleted: false,
    posted: true,
    date: date,
    number: "",
    organization: "",
    partner: "",
    store: "3cf46df0-c735-11e6-8731-001e6730f61b",
    // !!!!!!!!!!!!!!!!!!!
    user: "",
    // !!!!!!!!!!!!!!!!!!!
    comment: "",
    explicit_prices: false,
    items: [],
    // payments: [],
    // sales: [],
    delivery_method: null,
    transport_company: null,
    comment_for_store: "",
    partner_data: {},
    invoice_email: [],
    synced: "0",
    track_phone: "",
    avaliable_shipment: undefined
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const [products, setProducts] = useState<{}[]>([]);
  const [discount, setDiscount] = useState<number>(0);
  const [subtotal, setSubtotal] = useState<number>(0);
  const [discSum, setDiscSum] = useState<number | string>(0);
  const [total, setTotal] = useState<number>(0);
  const [paymentsPerc, setPaymentsPerc] = useState<number>(0);
  const [salesPerc, setSalesPerc] = useState<number>(0);
  const [organization, setOrganization] = useState<string>("");
  const [partner, setPartner] = useState<string>("");
  const [store, setStore] = useState<string>("");
  const [invoiceLoading, setInvoiceLoading] = useState<boolean>(false);
  const delivery = deliveryJson;
  const routerParams: any = useParams();
  const [modalVis, setModalVis] = useState<boolean>(false);
  const [btnDraftLoading, setBtnDraftLoading] = useState<boolean>(false);
  console.log("state data", state);
  useEffect(() => {
    // (async () => {
    if (state && state.data) {
      setData(state.data);
    } else {
      // getOrderDataFromUrl();
      // history.push("/orders");
    }
    // })().then(() => {
    //   // calculateMiscData();
    // });

    // console.log(data);

    return () => {
      dispatch(hideHeaderSubtitle());

      dispatch(hideHeaderSubtitle());
      dispatch(
        setHeaderButtonData({
          action: "",
          data: {},
        })
      );
    };
  }, []);

  useEffect(() => {
    // setSubtotal(+data?.items_amount + (+data?.items_amount / 100) * (100 - +data?.manager_discount));
    // setDiscSum((+data?.items_amount / 100) * (100 - +data?.manager_discount));

    if (products.length !== 0) {
      const filteredSubtotal = products.filter((f: any) => f.price !== 0);
      const calculatedSubtotal = filteredSubtotal.reduce(
        (n, { price, amount }: any) =>
          // @ts-ignore
          n + price * amount,
        0
      );
      setSubtotal(calculatedSubtotal);

      const calculatedFullDiscount = filteredSubtotal.reduce(
        (n, { price, amount, rawSum }: any) =>
          // @ts-ignore
          n + (price - rawSum / amount) * amount,
        0
      );
      setDiscSum(calculatedFullDiscount);
    }
  }, [products, discount]);

  useEffect(() => {
    if (handbookRedux.orgs.length > 0 && data) {
      // console.log(data?.organization);

      setOrganization(handbookRedux.orgs.find((itm) => itm.ref == data?.organization)?.name);
    }
  }, [handbookRedux.orgs, products]);

  useEffect(() => {
    // if (handbookRedux.partners.length > 0 && data) {
    //   setPartner(handbookRedux.partners.find((itm) => itm.ref == data?.partner).full_name);
    // }
    setPartner(data?.partner_data.name);
  }, [handbookRedux.partners, products]);

  useEffect(() => {
    if (handbookRedux.stores.length > 0 && data) {
      setStore(handbookRedux.stores.find((itm) => itm.ref == data?.store).name);
    }
  }, [handbookRedux.stores, products]);

  useEffect(() => {
    // мб вообще убрать редакс?
    if (handbookRedux.dealerDiscount !== 0) {
      setDiscount(handbookRedux.dealerDiscount);
    } else {
      setDiscount(+localStorage.getItem("discount"));
    }
  }, [handbookRedux.dealerDiscount, products]);

  useEffect(() => {
    // здесь пустой handbook служим маркером перезагрузки страницы/ открытия по прямой ссылке
    // + сделать loading, если будет нужно
    // if (handbookRedux.products.length > 0 && data) {
    //   // если handbook существует, берём данные оттуда
    //   const output = data?.items.map((itm: any) => ({
    //     ...handbookRedux.products.find((prod) => prod.ref == itm.item),
    //     amount: itm.amount,
    //     sum: formatMoney(itm.sum),
    //     rawSum: itm.sum,
    //     manager_discount: itm.manager_discount,
    //   }));

    //   setProducts(output);
    //   console.log('use')
    // } else {
    // если нет, то запрашиваем с сервера, подождав, когда handbook загрузится
    getOrderDataFromUrl();
    //}
  }, [handbookRedux.products]);

  useEffect(() => {
    calculateMiscData();
  }, [data, pieData]);

  function openModal(type?: string) {
    console.log(toSend);
    // setToSend({
    //   ...toSend,
    //   posted: 1
    // });
    setBtnLoading(false);
    setBtnDraftLoading(false);
    setModalVis(true);
  }

  function calculateDiscountPrice(fullPrice: number, amount: number, discount: number): number {
    // const discountInt = (fullPrice / 100) * discount;
    // return (fullPrice - discountInt) / amount;
    return (fullPrice / 100) * discount;
  }

  function calculateMiscData() {
    //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    setTotal(+data?.items_amount);

    setPaymentsPerc(Math.round((+data?.payments_amount / +data?.items_amount) * 100));
    setSalesPerc(Math.round((+data?.sales_amount / +data?.items_amount) * 100));
    if (data?.synced == 0 && data?.posted === 1) {
      dispatch(setHeaderTitle(`Идет синхронизация ${data?.number}`));
    } else if (data?.deleted === 1) {
      dispatch(setHeaderTitle(`Отменён`));
    } else if (data?.number) {
      dispatch(setHeaderTitle(`Заказ ${data?.number}`));
    } else if (data?.posted === 1) {
      dispatch(setHeaderTitle(`Формируется счёт...`));
    } else {
      dispatch(setHeaderTitle(`Черновик`));
    }

    // if (data?.number) {
    //   dispatch(setHeaderTitle(`Заказ ${data?.number}`));
    // } else {
    //   if (data?.posted === 1) {
    //     dispatch(setHeaderTitle(`Формируется счёт...`));
    //   } else {
    //     dispatch(setHeaderTitle(`Черновик`));
    //   }
    // }

    dispatch(
      setHeaderSubtitle(`от ${data?.date.slice(-2)}.${data?.date.slice(-5, -3)}.${data?.date.slice(0, 4)}`)
    );

    const hiddenProductsChecker = data?.items.map((itm: any) => handbookRedux.products.includes(itm));

    // console.log(data?.sales_amount, data?.payments_amount, hiddenProductsChecker);

    if (
      (data?.sales_amount == 0 && data?.presales_amount == 0) || // && !hiddenProductsChecker.includes(false) Проверку ДОРАБОТАТЬ не понял для чего, иногда не дает редактировать
      data?.posted === 0
    ) {
      dispatch(
        setHeaderButtonData({
          action: "editOrder",
          data: data,
        })
      );
    } else {
      dispatch(
        setHeaderButtonData({
          action: "editOrderReject",
          data: data,
        })
      );
    }
  }

  function getOrderDataFromUrl() {
    setIsLoading(true);
    (async () => {
      if (handbookRedux.products.length === 0) dispatch(fetchProducts());
    })().then(() => {
      // console.log(`${SiteUrlDealer}/order/${routerParams.id}`);

      fetch(`${SiteUrlDealer}/order/${routerParams.id}`, {
        headers: {
          "Content-Type": "application/json",

          Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""
            }`,
        },
        method: "GET",
      })
        .then((res) => res.json())
        .then((json) => {
          console.log("refresh json", json);
          json[0].invoice_email = [];
          setData(json[0]);
          setToSend(json[0]);
          if (json[0].partner_data.main_organization && json[0].partner_data.main_organization != "") {
            getOrderPartnerStatistic(json[0].partner_data.main_organization);
          }

          state.mode = "view";

          const output = json[0].items.map((itm: any) => {
            // console.log(itm.manager_discount);

            return {
              ...handbookRedux.products.find((prod) => prod.ref == itm.item),
              amount: itm.amount,
              sum: formatMoney(itm.sum),
              rawSum: itm.sum,
              manager_discount: itm.manager_discount,
              // price: itm.price,
            };
          });

          // console.log("out", output.manager_discount);
          setProducts(output);
          setIsLoading(false);
        });
    });
  }

  function getOrderPartnerStatistic(head: any) {
    const firstDay = new Date(new Date().setDate(1));
    const lastDay = new Date();
    const firstDate = "2022-01-01";
    // // prettier-ignore
    //   firstDay.getFullYear() + '-' +
    // ('0' + (firstDay.getMonth() + 1)).slice(-2) + '-' +
    // ('0' + firstDay.getDate()).slice(-2);

    const lastDate =
      // prettier-ignore
      lastDay.getFullYear() + '-' +
      ('0' + (lastDay.getMonth() + 1)).slice(-2) + '-' +
      ('0' + lastDay.getDate()).slice(-2);

    fetch(
      `${SiteUrlPortal}/data/headOrgStats?period=${firstDate},${lastDate}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""}`,
        },
        method: "POST",
        body: JSON.stringify({
          //type: "exec",
          "filters": [
            {
              field: "head",
              comparison: "=",
              value: head,
              condition: "and",
            },
          ]
        }),
      }
    )
      .then((res) => res.json())
      .then((json) => {
        console.log("pie data  json", json);
        setPieData(json);

      });
  }



  function convertDelivery(type: "method" | "company", value: string) {
    const methods = [
      {
        value: "5685",
        label: "Самовывоз",
      },
      {
        value: "5687",
        label: "До терминала транспортной компании",
      },
      {
        value: "5689",
        label: "Доставка транспортной компанией",
      },
      {
        value: "5691",
        label: "Доставка Вакио по Новосибирску",
      },
    ];

    if (type === "method") {
      return methods.find((f) => f.value == value).label;
    } else if (type === "company") {
      return delivery.find((f) => f.value == value).label;
    }
  }

  if (data === undefined) {
    return <Spin size="large" style={{ position: "absolute", top: "50%", left: "50%" }} />;
  } else {
    return (
      <>
        <div className="order-details">
          <div className="order-details__layout-main">
            <div className="order-details__legal-delivery">
              <Card
                //prettier-ignore
                className="card order-details__contacts order-contacts"
                title={<Typography.Title level={ResponsiveTitleLevel}>Контрагенты</Typography.Title>}
                bordered={false}
              >
                <div className="order-contacts__container">
                  <div className="order-contacts__accent">Поставщик:</div> {organization}
                </div>
                <div className="order-contacts__container">
                  <div className="order-contacts__accent">Покупатель:</div> {partner}
                </div>
              </Card>

              <Card
                //prettier-ignore
                className="card order-details__contacts order-contacts"
                title={<Typography.Title level={ResponsiveTitleLevel}>Данные отгрузки</Typography.Title>}
                bordered={false}
              >
                <div className="order-contacts__container">
                  <div className="order-contacts__accent">Склад отгрузки:</div>
                  <div className="order-contacts__data">{store}</div>
                </div>
                <div className="order-contacts__container">
                  <div className="order-contacts__accent">Доставка:</div>
                  <div className="order-contacts__data">
                    {data?.delivery_method ? convertDelivery("method", data?.delivery_method) : "Не указано"}
                  </div>
                </div>
                <div className="order-contacts__container">
                  <div className="order-contacts__accent">Транспортная компания:</div>
                  <div className="order-contacts__data">
                    {data?.transport_company
                      ? convertDelivery("company", data?.transport_company)
                      : "Не указано"}
                  </div>
                </div>
                <div className="order-contacts__container">
                  <div className="order-contacts__accent">Комментарий для логиста:</div>
                  {/* {data?.comment_for_store ? data?.comment_for_store : "Не указано"} */}
                  <div className="order-contacts__data">
                    <Typography.Paragraph
                      ellipsis={{
                        rows: 2,
                        expandable: true,
                      }}
                      style={{ width: "100%", whiteSpace: "break-spaces" }}
                    >
                      {data?.comment_for_store ? data?.comment_for_store : "Не указано"}
                    </Typography.Paragraph>
                  </div>
                </div>
                {data?.sales.length > 0 && (
                  <div className="order-contacts__container">
                    <div className="order-contacts__accent">Отгрузка:</div>
                    {data.sales.map((itm: any, ind: any) => (
                      <div className="order-contacts__data" key={ind}>
                        {itm?.date ?? ""} - {itm?.posted == 0 ? "готовится к отгрузке" : "отгружено"}
                      </div>
                    ))}
                  </div>
                )}
              </Card>
            </div>

            <Card
              //prettier-ignore
              className="card order-details__products order-products"
              style={{ marginTop: 16 }}
              title={<Typography.Title level={ResponsiveTitleLevel}>Товары</Typography.Title>}
              bordered={false}
            >
              {products
                ? products.map((itm: any) => {
                  return (
                    <div className="order-products__item" key={itm.ref}>
                      <div className="order-products__half">
                        <div className="order-products__image">
                          <PictureTwoTone
                            style={{
                              fontSize: 50,
                              color: "#1890FF",
                              border: "1px solid #EAEAEC",
                              padding: 5,
                              borderRadius: 8,
                            }}
                          />
                        </div>

                        <Typography.Title className="order-products__name" level={5}>
                          {itm.full_name}
                          {/* Прибор вентиляционный VAKIO LUMI (без наружнего козырька) */}
                        </Typography.Title>
                      </div>

                      <div className="order-products__half">
                        <div className="order-products__price">
                          <div className="order-products__price-single">
                            {formatMoney(itm.price)} ₽ x {itm.amount} {itm.unit}
                          </div>
                          <div className="order-products__discount">
                            - {itm.manager_discount} % /{" "}
                            {formatMoney(
                              calculateDiscountPrice(itm.price, itm.amount, itm.manager_discount)
                            )}{" "}
                            ₽
                          </div>
                        </div>

                        <div className="order-products__total">
                          {/* 322 228 ₽ */}
                          {itm.sum} ₽
                        </div>
                      </div>
                    </div>
                  );
                })
                : "load"}
              <Divider />

              <div className="order-products__bottom">
                <div className="order-products__comment order-products__half">
                  <div className="order-products__comment-title">
                    <CommentOutlined />
                  </div>
                  {/* <div className="order-products__comment-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid quibusdam deserunt.</div> */}
                  <div className="order-products__comment-text">
                    {data.comment ? data.comment : "Нет комментария"}
                  </div>
                </div>
                <div className="order-products__sum order-products__half">
                  <div className="order-products__sum-item">
                    <div className="order-products__sum-left">Подытог</div>
                    <div className="order-products__sum-right">{formatMoney(subtotal)} ₽</div>
                  </div>
                  {/* <div className="order-products__sum-item">
                  <div className="order-products__sum-left">Скидка&nbsp;(%)</div>
                  <div className="order-products__sum-right">{discount}%</div>
                </div> */}

                  <div className="order-products__sum-item">
                    <div className="order-products__sum-left">Скидка&nbsp;(₽)</div>
                    <div className="order-products__sum-right">{formatMoney(discSum)} ₽</div>
                  </div>

                  <Divider style={{ marginTop: 5, marginBottom: 5, width: "70%" }} />

                  <div className="order-products__sum-item order-products__sum-item--big">
                    <div className="order-products__sum-left">Итог</div>
                    <div className="order-products__sum-right">{formatMoney(total)} ₽</div>
                  </div>
                </div>
              </div>
            </Card>
          </div>
          <div className="order-details__layout-side">
            <Card
              title={<Typography.Title level={ResponsiveTitleLevel}>Итого</Typography.Title>}
              bordered={false}
              className="card"
              style={{ marginBottom: 16 }}
            >
              <div style={{ textAlign: "center", width: "100%" }}>
                <div className="create-order__sum-item">
                  {/* <div className="create-order__sum-left">Подытог</div> */}
                  <div className="order-details__sum-left">Без скидки</div>
                  <div className="order-details__sum-right">
                    {formatMoney(subtotal ? subtotal : 0)}&nbsp;₽
                  </div>
                </div>

                <Divider style={{ marginTop: 5, marginBottom: 5, width: "70%" }} />

                <div className="create-order__sum-item create-order__sum-item--big">
                  <div className="order-details__sum-left">Итог</div>
                  <div className="order-details__sum-right">{formatMoney(total ? total : 0)}&nbsp;₽</div>
                </div>

                <div
                  style={{
                    width: "100%",
                    textAlign: "right",
                    marginTop: 32,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Button
                    type="primary"
                    size="large"
                    loading={btnLoading}
                    disabled={
                      toSend &&
                        (toSend.synced == "0" ||
                          toSend.posted === 0 ||
                          toSend.presales_amount > 0 ||
                          toSend.sales_amount > 0)
                        ? true
                        : false
                    }
                    icon={<SendOutlined />}
                    style={{ marginBottom: 16 }}
                    onClick={() => {
                      if (products.length > 0) {
                        // if (toSend.partner === "" || toSend.organization === "") {
                        //   message.error("Данные счёта заполнены некорректно!");
                        // } else if (products.length === 1 && products[0].name === "") {
                        //   message.error("Товары не выбраны!");
                        // } else {
                        if (layoutRedux.headerButtonData.action == "editOrder") {
                          setBtnLoading(true);
                          openModal("send");
                        }
                        if (layoutRedux.headerButtonData.action == "editOrderReject") {
                          message.error("Редактирование заказа недоступно");
                        }

                        // }
                      }
                    }}
                  >
                    {toSend && toSend.synced == "0" && toSend.posted === 1
                      ? "Идет синхронизация"
                      : "Действия с заказом"}
                    {/* Выставить счёт */}
                  </Button>
                  <Button
                    type="default"
                    size="large"
                    icon={<EditOutlined />}
                    style={{ marginBottom: 16 }}
                    disabled={
                      (toSend && toSend.synced == "0" && toSend.posted === 1) ||
                        toSend.presales_amount > 0 ||
                        toSend.sales_amount > 0
                        ? true
                        : false
                    }
                    onClick={() => {
                      if (layoutRedux.headerButtonData.action == "editOrder") {
                        history.push(`/create-order/${layoutRedux.headerButtonData.data.id}`, {
                          mode: "edit",
                          data: layoutRedux.headerButtonData.data,
                        });
                      }
                      if (layoutRedux.headerButtonData.action == "editOrderReject") {
                        message.error("Редактирование заказа недоступно");
                      }
                    }}
                  >
                    Редактировать
                  </Button>
                  <Button
                    type="dashed"
                    size="large"
                    loading={invoiceLoading}
                    disabled={
                      data.number === null ||
                      data.number === "" ||
                      data.bitrix_id === "" ||
                      data.bitrix_id === null ||
                      data.synced == "0"
                    }
                    onClick={() => {
                      window.open("https://dealers.vakio.ru/generate_account?type=account&ref=" + data?.ref);
                      // if (
                      //   (data.payments_amount != "0" || data.sales_amount != "0")
                      //   // && data.invoice_link !== "" &&
                      //   // data.invoice_link !== null
                      // ) {
                      //   window.open("https://dealers.vakio.ru/generate_account?type=account&ref=" + data?.ref);
                      // } else {
                      //   setInvoiceLoading(true);
                      //   // message.error("Невозможно получить счёт, свяжитесь с менеджером");
                      //   fetch(`${SiteUrlDealer}/request_invoice`, {
                      //     headers: {
                      //       "Content-Type": "application/json",
                      //       Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""
                      //         }`,
                      //     },
                      //     body: JSON.stringify({
                      //       bitrix_id: data?.bitrix_id,
                      //     }),
                      //     method: "POST",
                      //   })
                      //     .then((res) => res.json())
                      //     .then((json) => {
                      //       console.log("invoice", json);
                      //       setInvoiceLoading(false);
                      //       window.open("https://dealers.vakio.ru/generate_account?type=account&ref=" + data?.ref);
                      //       // setCompanies(json.partners);
                      //     });
                      // }
                    }}
                  >
                    {data.bitrix_id === "" || data.bitrix_id === null
                      ? "Требуется синхронизация"
                      : data.number === null || data.number === "" || data.synced == "0"
                        ? "Счёт ещё формируется"
                        : "Скачать счёт"}
                  </Button>
                  <Tooltip
                    placement="bottom"
                    overlayStyle={{ maxWidth: window.screen.width }}
                    title={
                      data?.docs_shipment?.split(",").length > 0 ? (
                        <div>
                          {data?.docs_shipment?.split(",").map((i: any, ind: any) => (
                            <div className="order__item product" key={i}>
                              <div className="order-details__docs-shipment-link">
                                {/* <a href={i} target="_blanc">{i.split('/').pop()}</a> */}
                                <a href={i} target="_blanc">
                                  Приложение №{ind + 1}
                                </a>
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <></>
                      )
                    }
                    trigger="click"
                  >
                    <Button
                      type="dashed"
                      size="large"
                      style={{ marginTop: 20, width: "100%" }}
                      disabled={
                        !data.docs_shipment || data.docs_shipment === null || data.docs_shipment === ""
                      }
                      onClick={(e) => e.stopPropagation()}
                    >
                      Документы
                    </Button>
                  </Tooltip>
                </div>
              </div>
            </Card>
            {data && data.payments_amount > 0 && (
              <Card
                //prettier-ignore
                className="card order-details__payments"
                title={
                  <Typography.Title level={ResponsiveTitleLevel}>
                    Оплачено <span className="order-charts__percent">{paymentsPerc}%</span>
                  </Typography.Title>
                }
                bordered={false}
              >
                <div style={{ width: "100%", height: 200 }}>
                  <ResponsivePie
                    data={[
                      {
                        id: "Оплачено",
                        type: "Оплачено",
                        value: data.payments_amount,
                      },
                      {
                        id: "Не оплачено",
                        type: "Не оплачено",
                        value: +data.items_amount - +data.payments_amount,
                      },
                    ]}
                    valueFormat={(v) => `${formatMoney(v)} ₽`}
                    margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
                    padAngle={0.7}
                    fit={true}
                    activeOuterRadiusOffset={8}
                    enableArcLabels={false}
                    borderWidth={1}
                    borderColor={"white"}
                    enableArcLinkLabels={false}
                    // colors={["#1890FF", "#001529"]}
                    colors={["#69c0ff", "#d9d9d9"]}
                  />
                </div>
              </Card>
            )}
            {data && data.sales_amount > 0 && (
              <Card
                //prettier-ignore
                className="card order-details__sales order-charts"
                title={
                  <Typography.Title level={ResponsiveTitleLevel}>
                    Отгружено <span className="order-charts__percent">{salesPerc}%</span>
                  </Typography.Title>
                }
                bordered={false}
              >
                <div style={{ width: "100%", height: 200 }}>
                  <ResponsivePie
                    data={[
                      {
                        id: "Отгружено",
                        type: "Отгружено",
                        value: data.sales_amount,
                      },
                      {
                        id: "Не отгружено",
                        type: "Не отгружено",
                        value: +data.items_amount - +data.sales_amount,
                      },
                    ]}
                    valueFormat={(v) => `${formatMoney(v)} ₽`}
                    margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
                    padAngle={0.7}
                    fit={true}
                    activeOuterRadiusOffset={8}
                    enableArcLabels={false}
                    borderWidth={1}
                    borderColor={"white"}
                    enableArcLinkLabels={false}
                    // colors={["#1890FF", "#001529"]}
                    colors={["#69c0ff", "#d9d9d9"]}
                  />
                </div>
              </Card>
            )}
            {/* {pieData && pieData[0]["Сумма"] > 1000000000000 && (
              <Card
                //prettier-ignore
                className="card order-details__sales order-charts"
                title={<Typography.Title level={ResponsiveTitleLevel}>Отгружено за 2022г.</Typography.Title>}
                bordered={false}
              >
                <div style={{ width: "100%", height: 50 }}>
                  <div className="order-charts__sales-amount">{formatMoney(pieData[0]["Сумма"])} ₽</div>
                </div>
              </Card>
            )} */}
            <DeliveryModal
              toSend={toSend}
              setToSend={setToSend}
              modalVis={modalVis}
              setModalVis={setModalVis}
              state={state}
              setBizProcId={setBizProcId}
              setAgreeModalVisible={setAgreeModalVisible}
              agreeModalVisible={agreeModalVisible}

            />
            <AgreementModal
              setBizProcId={setBizProcId}
              bizprocId={"6466f18ae80a86.73272559"}
              setVisible={setAgreeModalVisible}
              visible={agreeModalVisible}
            />
          </div>
        </div>
      </>
    );
  }
}
