import React from "react";
import "normalize.css";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import 'antd/dist/antd.min.css'
import Cookies from 'universal-cookie';
const cookies = new Cookies();
import AppRoutes from "./AppRoutes"
import "./App.scss"
export function App() {

  // useEffect(() => {
  //   if (authorized) {
  //     onMessageListener(messageHandler)
  //   }
  // }, [authorized])
  // .then((payload) => {
  //   console.log("get message", payload);

  // })
  // .catch((err) => console.log('failed: ', err));

  return (
    <div className="App" style={{ height: "100%" }}>
      <Router>
        <Switch>
          <AppRoutes />
        </Switch>
      </Router>
    </div >
  );
}
