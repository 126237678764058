import React, { useEffect, useState } from 'react';
import { Button } from "antd"
import COFormModal from './COFormModal';
import OfferItem from './OfferItem';
import { useDispatch } from 'react-redux';
import { setHeaderTitle } from '../../redux/slices/layoutSlice';

const ConstructorPage = () => {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [commercialOffers, setCommercialOffers] = useState([]);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setHeaderTitle("Коммерческое предложение"))
    }, [])

    const fetchCommercialOffers = () => {
        setLoading(true);
        fetch(SiteUrlDealer + "/commercial_offer", {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""
                    }`,
            },
            method: "GET"
        }).then((res) => res.json()).then((response) => {
            setCommercialOffers(response);
        }).catch(e => {
            console.log(e);
        }).finally(() => {
            setLoading(false);
        })
    }
    const deleteCommercialOffers = (id) => {
        setLoading(true);
        fetch(SiteUrlDealer + "/commercial_offer/" + id, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""
                    }`,
            },
            method: "DELETE"
        }).then((response) => {
            setCommercialOffers(commercialOffers.filter(item => item.id != id));
        }).catch(e => {
            console.log(e);
        }).finally(() => {
            setLoading(false);
        })
    }

    useEffect(() => {
        fetchCommercialOffers();
    }, [])
    const openCreateModal = () => {
        setVisible(true);
    }
    return (
        <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column" }}>
            <Button
                style={{
                    width: "100%",
                    maxWidth: 150
                }}
                onClick={openCreateModal}
            >
                Создать КП
            </Button>
            <COFormModal
                visible={visible}
                setVisible={setVisible}
            />
            {commercialOffers.map(offer => {
                return <OfferItem key={offer.id} offer={offer} deleteItem={deleteCommercialOffers} />
            })}
        </div>
    );
};
export default ConstructorPage;