import React, { useState, useEffect, useMemo } from "react";
import { useTypedDispatch, useTypedSelector } from "../../redux/hooks";
import { TreeSelect } from "antd";
// import { IRegion } from "./types";

type Props = { region: string; setRegion: any };

const { TreeNode } = TreeSelect;

interface IIterables {
  id: number;
}

interface ICity extends IIterables {
  city: string;
}

interface IRegs extends IIterables {
  region: string;
}

interface IDist extends IIterables {
  district: string;
}

interface ICount extends IIterables {
  country: string;
}

interface IPlaceholders {
  cities: boolean;
  regions: boolean;
  districts: boolean;
  countries: boolean;
}

export default function RegionSelect({ region, setRegion }: Props) {
  const [value, setValue] = useState<string | undefined | { label: string; value: string }>(undefined);
  const [expanded, setExpanded] = useState<string[]>([]);
  const [showPlaceholders, setShowPlaceholders] = useState<IPlaceholders>({
    cities: true,
    regions: true,
    districts: true,
    countries: true,
  });
  const [cities, setCities] = useState<ICity[]>([]);
  const [regions, setRegions] = useState<IRegs[]>([]);
  const [districts, setDistricts] = useState<IDist[]>([]);
  const [countries, setCountries] = useState<ICount[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  useEffect(() => {
    if (region !== "") {
      // setValue(region);
      setValue({ label: region, value: region });
    }
  }, [region]);

  const onChange = (newValue: any) => {
    console.log(newValue);
    // тут есть возможность выводить результат выбора в виде объекта, а не строки
    setValue(newValue);
    // const converted = {
    //   id: +newValue.split(".")[0],
    //   type: newValue.split(".")[1],
    // };
    // setRegion(converted);
    setRegion(newValue.label);
  };

  function onSearch(search: string) {
    // console.log(search);
    setShowPlaceholders({
      cities: false,
      regions: false,
      districts: false,
      countries: false,
    });
    const timeout = setTimeout(() => {
      onExpand(["all"], search);
    });

    if (isFetching) {
      clearTimeout(timeout);
    }
  }

  // console.log(showPlaceholders);

  function onExpand(allTypes: string[], search?: any) {
    // console.log(allTypes);
    setExpanded(allTypes[0] == "all" ? ["city", "region", "district", "country"] : allTypes);
    const type = allTypes[allTypes.length - 1];
    // console.log(type);
    const uri = `${SiteUrlDealer}/location_with_filter?type=${type}${search && search !== "" ? "&value=" + search : ""
      }`;
    // console.log(uri);
    setIsFetching(true);
    if (type !== undefined) {
      fetch(uri, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""
            }`,
        },
        method: "GET",
      })
        .then((res) => res.json())
        .then((json) => {
          setIsFetching(false);
          // console.log("json", json);
          const citiesJson = json.filter((f: ICity) => f.city);
          const regionsJson = json.filter((f: IRegs) => f.region);
          const districtsJson = json.filter((f: IDist) => f.district);
          const countriesJson = json.filter((f: ICount) => f.country);

          if (type === "all")
            setShowPlaceholders({
              cities: false,
              regions: false,
              districts: false,
              countries: false,
            });

          if (type === "city" || type === "all") {
            setCities(
              citiesJson.map((i: ICity) => ({
                id: `${i.id}.city`,
                city: i.city,
              }))
            );
          }

          if (type === "region" || type === "all") {
            setRegions(
              regionsJson.map((i: IRegs) => ({
                id: `${i.id}.region`,
                region: i.region,
              }))
            );
          }

          if (type === "district" || type === "all") {
            setDistricts(
              districtsJson.map((i: IDist) => ({
                id: `${i.id}.district`,
                district: i.district,
              }))
            );
          }

          if (type === "country" || type === "all") {
            setCountries(
              countriesJson.map((i: ICount) => ({
                id: `${i.id}.country`,
                country: i.country,
              }))
            );
          }
        });
    }
  }

  return (
    <div>
      <TreeSelect
        showSearch
        onSearch={onSearch}
        style={{ width: "100%" }}
        value={value}
        dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
        placeholder="Регион"
        allowClear
        treeExpandedKeys={expanded}
        onChange={onChange}
        onTreeExpand={onExpand}
        labelInValue
        getPopupContainer={(trigger) => trigger.parentNode}
        filterTreeNode={(search, item) => {
          // @ts-ignore
          return item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0;
        }}
      >
        <TreeNode value="city" title="Города" selectable={false}>
          {showPlaceholders.cities && <TreeNode value="1" title="Загрузка..." selectable={false} />}
          {cities.map((itm) => (
            <TreeNode value={itm.id} key={itm.id} title={itm.city} />
          ))}
        </TreeNode>
        <TreeNode value="region" title="Области" selectable={false}>
          {showPlaceholders.regions && <TreeNode value="2" title="Загрузка..." selectable={false} />}
          {regions.map((itm) => (
            <TreeNode value={itm.id} key={itm.id} title={itm.region} />
          ))}
        </TreeNode>
        <TreeNode value="district" title="Федеральные округа" selectable={false}>
          {showPlaceholders.districts && <TreeNode value="3" title="Загрузка..." selectable={false} />}
          {districts.map((itm) => (
            <TreeNode value={itm.id} key={itm.id} title={itm.district} />
          ))}
        </TreeNode>
        <TreeNode value="country" title="Страны" selectable={false}>
          {showPlaceholders.countries && <TreeNode value="4" title="Загрузка..." selectable={false} />}
          {countries.map((itm) => (
            <TreeNode value={itm.id} key={itm.id} title={itm.country} />
          ))}
        </TreeNode>
      </TreeSelect>
    </div>
  );
}
