import React, { useState } from 'react';

const useProductInfoService = () => {
    const [loading, setLoading] = useState()

    const updateProductInfo = (productInfo) => {
        setLoading(true);
        return fetch(SiteUrlDealer + "/product_info", {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""
                    }`,
            },
            body: JSON.stringify(productInfo),
            method: "POST"
        }).then((res) => res.json()).then((response) => {
            return response;

        }).catch(e => {
            console.log(e);
        }).finally(() => {
            setLoading(false);
        })
    }

    return {
        loading,
        updateProductInfo,

    }
};

export default useProductInfoService;