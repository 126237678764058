import React from 'react';

export const getFormatedValue = (value) => {
    if (!value) return undefined;
    switch (value.type) {
        case "sub":
            return <>{value.value} <sub>{value.sub}</sub></>
        case "sup":
            return <>{value.value} <sup>{value.sup}</sup></>
        default:
            return <> {value.value}</>;
    }
}


// export const validateValue = (value, prevValue, allValues) => {
//     console.log(value, "test");
//     return value.toUpperCase();
//     // const reg = /^\d*(\.\d*)?$/;
//     // if (!reg.test(value)) return prevValue;
//     // return prevValue;
// }