import React from 'react';
import Editor from '../../Editor/Editor';

const PatternEntity = ({
    setRefreshId,
    refreshId,
    patternEntity,
    holder,
    trigger,
    setPatternEntity,
    readOnly }) => {
    return (
        <div style={{ width: "fit-content", height: "fit-content", background: "white", padding: 2, borderRadius: 10 }}>
            <Editor
                setRefreshId={setRefreshId}
                refreshId={refreshId}
                trigger={trigger}
                entityId={patternEntity?.id}
                entity={patternEntity}
                data={patternEntity?.body}
                holder={"entity" + holder + patternEntity?.id}
                setPatternEntity={setPatternEntity}
                readOnly={readOnly} />
        </div>
    );
};

export default PatternEntity;