import React, { useEffect, useState } from 'react';
import './EditPattern.scss';
import PatternEntity from '../PatternEntity/PatternEntity';
import AddEntity from './AddEntity';
import PatternEntityModal from '../PatternEntity/PatternEntityModal';
import { Button, Popconfirm, Statistic } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import MainInfoBlock from '../MainInfoBlock/MainInfoBlock';
import useSaveCOForm from '../../ConstructorPage/useSaveCOForm';
const EditPattern = ({ addToTop, addToBottom, deleteEntity, updateEntity, updateMainEntity, pattern, setPattern, mainInfo = null, readOnly = false }) => {
	const [addEntityModal, setAddEntityModal] = useState(false);
	const [position, setPosition] = useState('top');
	const [entity, setEntity] = useState({});
	const [refreshId, setRefreshId] = useState(0);
	const [trigger, setTrigger] = useState(false);

	const { formItems, loading } = useSaveCOForm({ withLoad: true });
	const [preparedFormItems, setPreparedFormItems] = useState([]);
	useEffect(() => {
		if (!loading) {
			let items = [];
			formItems.forEach((formItem) => {
				items = [...items, ...formItem.content];
			});
			setPreparedFormItems(items);
		}
	}, [loading]);

	const openModal = () => {
		setEntity({});
		setAddEntityModal(true);
	};



	const updateEntityModal = (entity) => {
		console.log("UPDATE ENTITY", entity);
		updateEntity(entity);
		console.log("set trigger", trigger);
		setTrigger(prev => !prev)
		setRefreshId(entity.id);
	};

	const addEntity = (entity, settedPosition = undefined) => {
		const currentPosition = settedPosition ? settedPosition : position;
		if (currentPosition === 'top') addToTop(entity);
		if (currentPosition === 'bottom') addToBottom(entity);
		if (currentPosition === 'update') updateEntityModal(entity);
	};

	const onEdit = (entity) => {
		setEntity(entity);
		setPosition('update');
		setAddEntityModal(true);
	};

	return (
		<div
			className="pattern"
			id="pattern"
			style={{
				marginTop: readOnly ? undefined : 10,
			}}
		>
			{pattern?.pattern?.map((item) => {
				return (
					<div key={item.id} className="pattern__item">
						{item?.type == 'main' ? (
							<>
								{!readOnly && (
									<AddEntity
										openModal={openModal}
										setPosition={() => setPosition('top')}
									/>
								)}
								{mainInfo ? (
									<MainInfoBlock
										trigger={trigger}
										isReadOnly={readOnly}
										updateMainEntity={updateMainEntity}
										mainInfo={mainInfo}
										entity={item}
										setEntity={updateEntityModal}
										updateEntity={updateEntity}
										updateEntityModal={updateEntityModal}
										setPattern={setPattern}
									/>
								) : (
									<>
										<div className="pattern__item__main">
											<div
												style={{
													width: '100%',
													display: 'flex',
													justifyContent: 'center',
													marginTop: 10,
													marginBottom: 10,
												}}
											>
												Блок информации о предложении (Заполняется
												через раздел создания коммерческого
												предложения)
											</div>

											<div className="main__columns">
												<div className="main__columns__column">
													{preparedFormItems
														.flat()
														.filter(
															(item, index) =>
																!(index % 2) &&
																item.type != 'room_type'
														)
														.map((item, index) => {
															return (
																<div
																	className="preview-item"
																	key={index}
																>
																	<Statistic
																		title={item.label}
																		value={''}
																		loading
																	/>
																</div>
															);
														})}
												</div>
												<div className="main__columns__column">
													{preparedFormItems
														.flat()
														.filter(
															(item, index) =>
																index % 2 &&
																item.type != 'room_type'
														)
														.map((item, index) => {
															return (
																<div
																	className="preview-item"
																	key={index}
																>
																	<Statistic
																		title={item.label}
																		value={''}
																		loading
																	/>
																</div>
															);
														})}
												</div>
											</div>
										</div>
									</>
								)}
							</>
						) : (
							<div className="pattern__item__pattern">
								<>
									{!readOnly && (
										<div className="pattern__item__pattern__edit">
											<Button
												onClick={() => onEdit(item)}
												type="link"
												size="large"
												icon={<EditOutlined />}
											/>
											<Popconfirm
												placement="top"
												title="Удалить блок?"
												onConfirm={(e) => {
													e.stopPropagation();
													deleteEntity(item);
													setRefreshId(item.id)
												}}
												onCancel={(e) => e.stopPropagation()}
												okText="Удалить"
												cancelText="Отмена"
											>
												<Button
													type="link"
													danger
													size="large"
													icon={<DeleteOutlined />}
												/>
											</Popconfirm>
										</div>
									)}
								</>
								<PatternEntity
									holder={'edit'}
									setRefreshId={setRefreshId}
									trigger={trigger}
									refreshId={refreshId}
									setPatternEntity={updateEntity}
									patternEntity={item}
									readOnly={readOnly}
								/>
							</div>
						)}
					</div>
				);
			})}
			{!readOnly && (
				<AddEntity
					openModal={openModal}
					setPosition={() => setPosition('bottom')}
				/>
			)}
			{!readOnly && (
				<PatternEntityModal
					entity={entity}
					local={mainInfo ? true : false}
					setEntity={setEntity}
					visible={addEntityModal}
					setVisible={setAddEntityModal}
					addEntity={addEntity}
				/>
			)}
		</div>
	);
};

export default EditPattern;
