import { Input, message, Segmented, Select, Button } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import "./Pricelist.scss"
import { ExclamationOutlined } from "@ant-design/icons";
const Pricelist = () => {
    const [categories, setCategories] = useState([]);
    const [products, setProducts] = useState([]);
    const [customDiscount, setCustomDiscount] = useState("")
    const [discountType, setDiscountType] = useState("Категория")
    const [loadCategories, setLoadCategories] = useState(false)
    const [loadProducts, setLoadProducts] = useState(false)
    const [generationLoad, setGenerationLoad] = useState(false)
    const [product, setProduct] = useState([])
    const [category, setCategory] = useState([])
    const [currency, setCurrency] = useState("RUB")
    const [rate, setRate] = useState("")
    const [open, setOpen] = useState(false);
    const [rateError, setRateError] = useState(false)
    const inputRef = useRef()
    const selectRef = useRef()

    useEffect(() => {
        const storageCurrency = localStorage.getItem("currency");
        if (storageCurrency) {
            setCurrency(storageCurrency)
        }
    }, [])
    useEffect(() => {
        setLoadCategories(true);
        fetch(`${SiteUrlDealer}/dealer_categories`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""
                    }`,
            },
            method: "POST",
            body: JSON.stringify({
                "filters": [
                    {
                        "field": "pricelist_enable",
                        "comparison": "=",
                        "value": 1
                    }

                ]
            })
        }).then((res) => res.json()).then(response => {
            console.log("Dealer categories", response);
            setCategories(response);
            setCategory(response.map(item => item.id))
        }).catch(err => {
            console.log("Dealer categories error", err);
            message.error("Не удалось загрузить категории дилеров")
        }).finally(() => {
            setLoadCategories(false);
        })

        setLoadProducts(true);
        fetch(`${SiteUrlDealer}/product_categies`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""}`,
            },
            method: "POST",
            body: JSON.stringify({
                "filters": [
                    {
                        "field": "pricelist_enable",
                        "comparison": "=",
                        "value": 1
                    }

                ]
            })
        })
            .then((res) => res.json())
            .then((json) => {
                setProducts(json)
                setProduct(json.map(item => item.value))
            }).catch(err => {
                message.error("Не удалось загрузить категории продуктов")
            }).finally(() => {
                setLoadProducts(false);
            })

        return () => {
            setLoadCategories(false);
            setLoadProducts(false);
        }
    }, [])


    const changeDiscountType = (type) => {
        setDiscountType(type)

    }

    const changeCustomDiscount = (event) => {
        const value = event.target.value;
        const reg = /^\d*(\.\d*)?$/;
        if (reg.test(value)) {
            setCustomDiscount(value);
        }
    }

    const generatePdf = () => {
        if (currency !== "RUB" && rate === "") {
            setRateError(true);
            return;
        }
        let params = ""
        if (discountType === "Категория") {
            params = `&dealer_cats=${category.join(",")}&product_cats=${product.join(",")}`
        } else {
            params = `&dealer_cats=custom_discount&product_cats=${product.join(",")}&custom_discount=${customDiscount}`

        }
        setGenerationLoad(true);
        fetch(`${SiteUrlDealer}/generate_pdf?type=pricelist${params}${currency !== "RUB" ? `&currency=${currency}&rate=${rate}` : ""}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""}`,
            },
            method: "GET",

        })
            .then((res) => res.json())
            .then((response) => {
                const link = document.createElement('a');
                console.log(response.link);
                link.href = response.link;
                link.setAttribute(
                    'download',
                    `FileName.pdf`,
                );
                // Append to html link element page
                document.body.appendChild(link);
                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            }).catch(err => {
                message.error("Не удалось сформировать прайс")
            }).finally(() => {
                setGenerationLoad(false);
            })
    }
    const onChangeСurrency = (value) => {
        console.log("Set value", value);
        localStorage.setItem("currency", value);
        setCurrency(value);
        setRate("");
    }

    const onChangeRate = (e) => {
        const value = e.target.value
        if (value.match(/^([0-9]{1,})?(\.)?([0-9]{1,})?$/)) {
            setRate(value);
            setRateError(false);
        }
    }
    return (
        <div className='pricelist'>
            <div className='pricelist__header'>
                Формирование прайса
            </div>
            <div style={{ marginBottom: 10 }} className="create-order__sum-item create-order__sum-item--big">
                <div style={{ fontSize: 14 }} className="create-order__sum-left">Валюта</div>
                <Select
                    value={currency}
                    style={{ width: "50%" }}
                    onChange={onChangeСurrency}
                    options={[
                        { value: 'RUB', label: 'Рубли' },
                        { value: 'KZT', label: 'Тенге' },

                    ]}

                />
            </div>

            <div style={{ marginBottom: 10 }} className="create-order__sum-item create-order__sum-item--big">
                <div style={{ fontSize: 14 }} className="create-order__sum-left">Курс</div>
                <Input
                    addonBefore="1 Рубль ="
                    placeholder={rateError ? "Заполните курс!" : null}
                    status={rateError ? "error" : null}
                    disabled={currency === "RUB"}
                    onChange={onChangeRate} value={rate} style={{ width: "50%" }}
                />
            </div>

            <div className='pricelist__dealer'>
                <div className='pricelist__dealer__title'>Категория дилера</div>
                <div className='pricelist__dealer__segmented'>
                    <Segmented options={['Категория', 'Скидка']} onChange={changeDiscountType} />
                </div>
                <div className='pricelist__dealer__input'>
                    {discountType === "Категория" ?
                        <Select
                            loading={loadCategories}
                            showSearch
                            mode="multiple"
                            value={category}
                            onChange={(value) => {
                                setCategory(value)
                                setOpen(false)
                            }}
                            style={{ width: "100%" }}
                            options={categories.map(item => {
                                return { label: item.name, value: item.id }
                            })}
                        />
                        :
                        <Input suffix="%" autoFocus ref={inputRef} value={customDiscount} onChange={changeCustomDiscount} />
                    }
                </div>
            </div>
            <div className='pricelist__dealer'>
                <div className='pricelist__dealer__title'>Категория продукции</div>

                <div className='pricelist__dealer__input'>

                    <Select
                        mode="multiple"
                        showSearch
                        autoFocus
                        value={product}
                        onChange={setProduct}
                        style={{ width: "100%" }}
                        options={products}
                    />

                </div>
            </div>
            <div className='pricelist__submit'>
                <Button type="primary"
                    loading={generationLoad}
                    onClick={generatePdf}
                    disabled={product?.length === 0 || (discountType === "Категория" && category === null) || (discountType !== "Категория" && customDiscount === "")}>
                    Сформировать
                </Button >
            </div>
        </div>

    );
};

export default Pricelist;