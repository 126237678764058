import React, { useState } from 'react';
import { Button, Input } from 'antd';
import { PlusOutlined, FontSizeOutlined, MinusOutlined } from '@ant-design/icons';
import './DivInput.scss';
import { useRef } from 'react';
import { useEffect } from 'react';

const DivInput = ({
	fontSize = 15,
	textStyle = undefined,
	containerStyle,
	isReadOnly,
	value,
	onInput,
	onChangeSize = undefined,
	extraNode = <></>,
	html = true,
	defaultOnPaste = false,
}) => {
	const [fontSizeState, setFontSizeState] = useState(fontSize);
	const updateFontSize = (updatedSize) => {
		let newSize = updatedSize;
		if (newSize <= 0) {
			newSize = 1;
		}
		if (newSize > 30) {
			newSize = 30;
		}
		setFontSizeState(newSize);
		onChangeSize(newSize);
	};
	const onChange = (e) => {
		const value = e.target.innerHTML;
		onInput(value);
	};
	const onChangeText = (e) => {
		const value = e.target.innerText;
		onInput(value);
		//ref.current.innerText = value;
	};
	function htmlDecode(content) {
		let e = document.createElement('div');
		e.innerText = content;
		return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
	}
	const ref = useRef(null);
	useEffect(() => {
		if (ref && ref.current) {
			ref.current.innerText = value;
		}
	}, [isReadOnly]);
	const onPaste = (event) => {
		event.preventDefault();
		event.stopPropagation();
		const text = event?.clipboardData?.getData('Text') ?? '';
		onInput(ref.current.innerText + text);
		ref.current.innerText = ref.current.innerText + text;
	};
	return (
		<div style={{ position: 'relative', ...containerStyle }}>
			{isReadOnly ? (
				<div
					className={`div-block ${isReadOnly ? '' : 'div-input'}`}
					style={{ fontSize: fontSizeState ?? undefined, ...(textStyle ?? {}) }}
					// inputMode="text"
					// suppressContentEditableWarning={true}
					dangerouslySetInnerHTML={{
						__html: `${
							value
								? value.replaceAll('&lt;', '<').replaceAll('&gt;', '>')
								: ''
						}`,
					}}
				/>
			) : (
				<div
					ref={ref}
					onPaste={defaultOnPaste ? undefined : onPaste}
					className={`div-block ${isReadOnly ? '' : 'div-input'}`}
					style={{ fontSize: fontSizeState ?? 14, ...(textStyle ?? {}) }}
					contentEditable={true}
					// inputMode="text"
					onInput={onChangeText}
					// dangerouslySetInnerHTML={isReadOnly ? { __html: `${value ? value.replaceAll("&lt;", "<").replaceAll("&gt;", ">") : ""}` } : { __html: `${value ? value : ""}` }}
				/>
			)}

			{!isReadOnly && onChangeSize && (
				<div className="font-buttons">
					<FontSizeOutlined />
					<Button
						type="link"
						icon={<PlusOutlined />}
						onClick={() => updateFontSize(fontSizeState + 1)}
					/>
					<Button
						type="link"
						icon={<MinusOutlined />}
						onClick={() => updateFontSize(fontSizeState - 1)}
					/>
					{extraNode && extraNode}
				</div>
			)}
		</div>
	);
};

export default DivInput;
