import React, { useState, useEffect, useReducer, useContext, useMemo } from "react";
import { Card, Typography, Divider, Button, Input, message, Switch } from "antd";
import { PictureTwoTone, MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { useTypedDispatch, useTypedSelector } from "../../redux/hooks";
import { useHistory } from "react-router-dom";
import { shallowEqual } from "react-redux";
import { setHeaderTitle } from "../../redux/slices/layoutSlice";
import { addCartItem } from "../../redux/slices/cartSlice";

import CustomInputNumber from "../CustomInputNumber";

import "../../styles/Card.scss";
import "./Catalog.scss";
import { IProductItem } from "../PromotionDetails/types";
import UploadProductImage from "./UploadProductImage";
import DivInput from "../Editor/components/DivInput/DivInput";

type PropsProduct = {
  setVisible: (visible: boolean) => void,
  setProductRoomDesc: (itm: any) => void;
  productRoomDesc: any;
  itm: any;
  role: any;
  listView: any,
  filtered: any,
  updateProducts: (product: any) => void, updateProductInfo: any
};

export default function Product({ itm, role, setVisible, setProductRoomDesc, productRoomDesc, listView, updateProducts, filtered, updateProductInfo }: PropsProduct) {
  const dispatch = useTypedDispatch();
  // const handbookRedux = useTypedSelector((state) => state.handbook);
  const cartRedux = useTypedSelector((state) => state.cart, shallowEqual);
  const handbook = useTypedSelector((state) => state.handbook, shallowEqual);
  const [enabled, setEnabled] = useState<boolean>(false);
  const [enabledPrice, setEnabledPrice] = useState<boolean>(false);
  const [productInfo, setProductInfo_] = useState(itm?.info ?? {})
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingPrice, setLoadingPrice] = useState<boolean>(false);
  //const [productData, setProductData] = useState<any[]>([]);
  const [num, setNum] = useState<any>("init");
  const [numTrigger, setNumTrigger] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState(window.screen.width <= 450)

  const setProductInfo = (info) => {
    console.log("SET", info);

    setProductInfo_(info);
    updateProducts(info)
  }
  useEffect(() => {
    if (itm.enable === 1) {
      setEnabled(true);
    }
    if (itm.enable === 0) {
      setEnabled(false);
    }
    if (itm.pricelist_enable === 1) {
      setEnabledPrice(true);
    }
    if (itm.pricelist_enable === 0) {
      setEnabledPrice(false);
    }
    setNum(0);
  }, [itm]);

  useEffect(() => {
    if (num === "init") return;
    console.log("num trig");
    const kek = setTimeout(() => {
      // dispatch(addCartItem({ ...itm, qty: num }));
      const cartMode = cartRedux.cartItems.find((f: any) => f.ref === itm.ref) ? "edit" : "add";
      if (cartMode === "edit") {
        const editedItem = cartRedux.cartItems.map((i: any) => {
          if (i.ref === itm.ref) {
            return {
              ...i,
              amount: num,
            };
          } else {
            return i;
          }
        });
        const res = editedItem.slice();
        const copy = res.filter((f: any) => f.amount > 0);
        dispatch(addCartItem(copy));
        localStorage.setItem("cart", JSON.stringify(copy));
      } else {
        const copy = cartRedux.cartItems.slice();
        copy.push({
          ...itm,
          amount: num,
        });
        dispatch(addCartItem(copy));
        localStorage.setItem("cart", JSON.stringify(copy));
      }
    }, 2000);

    return () => {
      clearTimeout(kek);
    };
  }, [numTrigger]);

  function handleEditQty(e: number) {
    setNum(e);
    setNumTrigger(!numTrigger);
  }

  function handleHideProductPrice(state: boolean, prod: any) {
    setLoadingPrice(true);
    fetch(`${SiteUrlDealer}/product/${prod.id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""}`,
      },
      // prettier-ignore
      body: JSON.stringify({ pricelist_enable: state ? 1 : 0 }),
      method: "PUT",
    })
      // .then((res) => res.json())
      .then(() => {
        setEnabledPrice(!enabledPrice);
      })
      .catch(() => message.error("Ошибка!"))
      .finally(() => {
        setLoadingPrice(false);
      });
  }
  function handleHideProductPortal(state: boolean, prod: any) {
    setLoading(true);
    fetch(`${SiteUrlDealer}/product/${prod.id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""}`,
      },
      // prettier-ignore
      body: JSON.stringify({ enable: state ? 1 : 0 }),
      method: "PUT",
    })
      // .then((res) => res.json())
      .then(() => {
        setEnabled(!enabled);
      })
      .catch(() => message.error("Ошибка!"))
      .finally(() => {
        setLoading(false);
      });
  }



  const onChangeDescription = (description) => {
    setProductInfo_({
      ...productInfo,
      description: description
    })


  }

  const [mounted, setMounted] = useState(false)
  useEffect(() => {
    if (!mounted) {
      setMounted(true)
      return;
    }
    const timeout = setTimeout(() => {
      updateProductInfo({
        product_id: itm.id,
        ...(productInfo ? productInfo : {}),
      }).then(response => {
        setProductInfo(response);
        updateProducts(response)
      })
    }, 1500)
    return () => clearTimeout(timeout);
  }, [productInfo?.description])

  function htmlDecode(input) {
    var e = document.createElement('div');
    e.innerHTML = input;
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
  }
  const openRoomDescModal = () => {
    console.log("itm", itm);

    setProductRoomDesc(itm);
    setVisible(true)
  }
  if (!listView) {
    return (
      <Card bordered={false} className="card catalog__product">
        <div className="catalog__product-top">
          <PictureTwoTone
            style={{
              fontSize: 50,
              color: "#1890FF",
              border: "1px solid #EAEAEC",
              padding: 5,
              borderRadius: 8,
            }}
          />
          <div className="catalog__product-name">{itm.full_name}</div>
        </div>
        <div className="catalog__price-container">
          <div className="catalog__price">{formatMoney(itm.price)} ₽</div>
          {handbook.d_isAdminManager && (
            <Switch checked={enabledPrice} style={{ marginRight: !isMobile ? 20 : 0 }} loading={loadingPrice} onChange={(e) => handleHideProductPrice(e, itm)} />
          )}
          {handbook.d_isAdminManager && (
            <Switch checked={enabled} loading={loading} onChange={(e) => handleHideProductPortal(e, itm)} />
          )}

        </div>
      </Card>
    );
  } else {
    return (
      <Card
        key={itm.id}
        style={{ marginBottom: 20 }}
        bordered={false}
        className="card catalog__product"
      // onClick={(e) => history.push(`/order-${itm.id}`, { data: itm })}
      >
        <div className="catalog__list-left">
          <div className="catalog__list-product-top">
            <div style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}>

              {productInfo?.link ?

                <img src={productInfo.link} style={{
                  borderRadius: 8,
                  width: 80,
                  height: 80,
                  objectFit: "cover"
                }} />
                :
                <PictureTwoTone
                  style={{
                    fontSize: 70,
                    color: "#1890FF",
                    border: "1px solid #EAEAEC",
                    padding: 5,
                    borderRadius: 8,

                  }}
                />}
              {handbook.d_isAdminManager && <UploadProductImage setProductInfo={setProductInfo} product_id={itm.id} />}
            </div>
            <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>


              <div className="catalog__product-name">
                <Typography.Title level={5}>{itm.full_name}</Typography.Title>

                {/* <Input.TextArea 
                placeholder="Описание не указано"
                value={itm?.info?.description}
                onChange={(e) => onChangeDescription(e.target.value)}
                  style={{ marginRight: 10, fontSize: 14, color: "#bbbbbb" }}
                /> */}

                {/* <Input.TextArea
                value={productInfo?.description}
                onChange={(e) => onChangeDescription(e.target.value)}
                style={{
                  margin: 4
                }}
              /> */}


              </div>
              <DivInput
                html={true}
                defaultOnPaste={true}
                textStyle={{ fontSize: 14, color: "#bbbbbb" }}
                containerStyle={{ marginRight: 10 }}
                onInput={(e) => onChangeDescription(e)}
                isReadOnly={!handbook.d_isAdminManager}
                value={itm?.info?.description == undefined || itm?.info?.description == "" ? !handbook.d_isAdminManager ? "Описание не указано" : "" : itm?.info?.description}
              />
            </div>
          </div>
          <div className="catalog__list-price-container" >
            <div className="catalog__items-number">
              <CustomInputNumber onChange={handleEditQty} minValue={0} value={itm.amount} />
            </div>
            <div className="catalog__price">{formatMoney(itm.price)}₽</div>
            {!isMobile && <div className="catalog__switch">

              <div style={{
                display: "flex",
                justifyContent: "flex-end"
              }}>
                {handbook.d_isAdminManager && (
                  <Switch checked={enabledPrice} style={{ marginRight: !isMobile ? 20 : 0 }} loading={loadingPrice} onChange={(e) => handleHideProductPrice(e, itm)} />
                )}
                {handbook.d_isAdminManager && (
                  <Switch checked={enabled} loading={loading} onChange={(e) => handleHideProductPortal(e, itm)} />
                )}
              </div>
              <Button
                type="link"
                onClick={openRoomDescModal}
              >
                Описание для комнат
              </Button>

            </div>}
          </div>
          {isMobile && <div style={{
            display: "flex",
            width: "100%",
            justifyContent: "flex-end"
          }}>
            <div style={{
              display: "flex",
              flexDirection: "column",
              fontVariant: "tabular-nums",
              lineHeight: 1.5715,
              listStyle: "none",
              fontFeatureSettings: 'tnum'
            }}>
              {handbook.d_isAdminManager && (
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <div style={{ marginRight: 10 }}>Прайс</div>
                  <Switch checked={enabledPrice} style={{}} loading={loadingPrice} onChange={(e) => handleHideProductPrice(e, itm)} />
                </div>
              )}
              {handbook.d_isAdminManager && (
                <div style={{ display: "flex", marginTop: 10, justifyContent: "flex-end" }}>
                  <div style={{ marginRight: 10 }}>Портал</div>
                  <Switch checked={enabled} style={{}} loading={loading} onChange={(e) => handleHideProductPortal(e, itm)} />
                </div>
              )}
            </div>
          </div>}
        </div>
      </Card>
    );
  }
}
