import React, { useState, useEffect } from "react";
import { useTypedDispatch, useTypedSelector } from "../../redux/hooks";
import { Card, Typography, Table, Modal, Button, Input, message, Select, Form } from "antd";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";
import { MaskedInput } from "antd-mask-input";
import { setHeaderTitle } from "../../redux/slices/layoutSlice";

import LegalTable from "./SettingsLegalTable";

import "../../styles/Card.scss";
import CitySelect from "../MyCompanies/CitySelect";
import { IRegion } from "../PromotionSettings/types";

type Props = {};

export default function Settings({ }: Props) {
  const dispatch = useTypedDispatch();
  const [activeUser, setActiveUser] = useState<any>({});
  const [reloadPage, setReloadPage] = useState<any>(true);
  const [editUser, setEditUser] = useState<any>(undefined);
  const [modalVis, setModalVis] = useState<boolean>(false);
  const [users, setUsers] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [saveTrigger, setSaveTrigger] = useState<boolean>(false);
  const [addModalVis, setAddModalVis] = useState<boolean>(false);
  const [createNew, setCreateNew] = useState<boolean>(true);
  const [phoneError, setPhoneError] = useState<boolean>(true);
  const [region, setRegion] = useState<IRegion>("");
  const [filterName, setFilterName] = useState<string>("");
  const [filterEmail, setFilterEmail] = useState<string>("");
  const [filterTitle, setFilterTitle] = useState<string>("");
  interface ICreate {
    email: string;
    name: string;
    pass: string;
    role: any;
  }
  const [createData, setCreateData] = useState<ICreate>({
    email: "",
    name: "",
    pass: "",
    role: "d_user",
  });
  const [form] = Form.useForm();
  const [pass, setPass] = useState<string | null>(null);
  const [updatedIndex, setUpdatedIndex] = useState<number>(0);
  const roles = {
    "d_manager": "Менеджер",
    "d_user": "Дилер",

  }
  const DynamicPhone = (props: any) => {
    const cellphoneMask = "(00) 0 0000-0000";
    const phoneMask = "+7 (000) 000-00-00";

    // always memoize dynamic masks
    const mask = React.useMemo(
      () => [
        {
          mask: cellphoneMask,
          lazy: false,
        },
        {
          mask: phoneMask,
          lazy: false,
        },
      ],
      []
    );

    return (
      <MaskedInput
        {...props}
        mask={mask}
        maskOptions={{
          dispatch: function (appended, dynamicMasked) {
            const isCellPhone = dynamicMasked.unmaskedValue[2] === "9" && dynamicMasked.unmaskedValue[0] != "9";
            return dynamicMasked.compiledMasks[isCellPhone ? 0 : 1];
          },
        }}
      />
    );
  };

  useEffect(() => {

    // if (Object.keys(editUser).length === 0) return;
    if (editUser === undefined) return;
    if (editUser.email == "") {
      form.setFieldsValue(editUser);
      setCreateNew(true);
    } else {

      form.setFieldsValue(editUser);
      setCreateNew(false);
    }

    //setCreateNew(false);
  }, [editUser]);

  useEffect(() => {
    dispatch(setHeaderTitle("Настройки"));
    setPhoneError(false);
    setIsLoading(true);
    // такая еботня нужна, чтобы убрать странную ошибку cant update unmounted component
    const timeout = setTimeout(() => {
      let filters = []

      if (filterTitle != "") {
        filters.push({
          "comparison": "like",
          "field": "title",
          "value": filterTitle
        })
      }
      if (filterEmail != "") {
        filters.push({
          "comparison": "like",
          "field": "email",
          "value": filterEmail
        })
      }
      if (filterName != "") {
        filters.push({
          "comparison": "like",
          "field": "name",
          "value": filterName
        })
      }
      // fetch(SiteUrlDealer + "/user_org_index", {
      fetch(SiteUrlDealer + "/user_portal_dealer_new?order_by=title&order_type=ASC", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""
            }`,
        },
        body: JSON.stringify({
          "filters": filters
        }),
        method: "POST",
      })
        .then((res) => res.json())
        .then((json) => {
          console.log(json);
          const users = json;
          const d_managers = users.d_managers.map(admin => { return { ...admin, capabilities: "d_manager" } })
          const d_admin_managers = users.d_admin_managers.map(admin => { return { ...admin, capabilities: "d_admin_manager" } })

          const d_users = users.d_users.map(admin => { return { ...admin, capabilities: "d_user" } })

          const tableUsers_ = [...d_managers, ...d_users, ...d_admin_managers];
          const filteredUsers = tableUsers_.reduce((result, obj) => {
            if (result.findIndex(user => user.id === obj.id) === -1) {
              obj.capabilities = [obj.capabilities]
              obj.view = roles[obj.capabilities]
              result.push(obj);
            } else {
              result.map(user => {
                if (user.id === obj.id) {
                  user.capabilities.push(obj.capabilities);
                  user.view += ", " + roles[obj.capabilities]

                  console.log(user.capabilities);
                }
                return user
              })
            }
            return result
          }, []);
          const editedUsers = filteredUsers.map((itm: any, ind: number) => ({
            key: ind.toString(),
            id: itm.id,
            title: itm.title,
            name: itm.name,
            email: itm.email,
            phone: itm.phone,
            capabilities: itm.capabilities,
            city_name: itm.city_name,
            location: itm.location,
            orgs_length: itm.orgs?.length
          }));
          setUsers(editedUsers);
          setIsLoading(false);
        });
      // .catch((err) => console.log(err));
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [reloadPage, filterTitle, filterEmail, filterName]);

  function generatePassword() {
    var length = 8,
      charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }

    form.setFieldsValue({
      password: retVal,
    });
  }

  function sendForm() {
    form
      .validateFields()
      .then((values) => {
        const copy = Object.assign({}, { ...values, city_name: region.label, location: region.value });
        // prettier-ignore
        copy.phone = "+" + copy.phone
          .replace(/[^a-zA-Z0-9 ]/g, "")
          .split(" ")
          .join("");
        console.log(copy);
        if (copy.phone === "+7") {
          setPhoneError(true);
          return;
        }

        fetch(SiteUrlDealer + `/user/${createNew ? "" : editUser.id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""
              }`,
          },
          body: JSON.stringify(copy),
          method: createNew ? "POST" : "PUT",
        })
          .then((res) => res.json())
          .then((json) => {
            console.log(json);
            form.resetFields();
            setAddModalVis(false);
            message.success(createNew ? "Пользователь добавлен" : "Пользователь обновлен");
            setReloadPage(!reloadPage);
            setUpdatedIndex(editUser.id)
            setTimeout(() => {
              setUpdatedIndex(0)
            }, 1000)
          });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  }
  useEffect(() => {
    setRegion({ label: editUser?.city_name || null, value: editUser?.location || null });
  }, [editUser?.id])

  return (
    <div>
      <Card
        title={<Typography.Title level={ResponsiveTitleLevel}>Аккаунты</Typography.Title>}
        style={{ marginBottom: 20 }}
        extra={
          <Button
            type="default"
            // shape="circle"
            icon={<PlusOutlined />}
            onClick={() => {

              setEditUser({
                capabilities: "d_user",
                email: "",
                name: "",
                phone: "",
                title: "",
                city_name: null,
                location: null,
              });
              setAddModalVis(true);
            }}
          >
            {/* {window.screen.width > 450 && "Добавить пользователя"} */}
            Добавить
          </Button>
        }
        bordered={false}
        className="card"
      >

        <div className="settings_filters">
          <div className="settings_filters__input">
            <Input placeholder="Название аккаунта" value={filterTitle} onChange={(e) => setFilterTitle(e.target.value)} />
          </div>
          <div className="settings_filters__input">
            <Input placeholder="Имя" value={filterName} onChange={(e) => setFilterName(e.target.value)} />
          </div>
          <div className="settings_filters__input">
            <Input placeholder="E-mail" value={filterEmail} onChange={(e) => setFilterEmail(e.target.value)} />
          </div>
        </div>
        <Table
          //   pagination={true}
          pagination={{
            defaultCurrent: 1,
            total: 500,
            pageSize: 500,
          }}
          rowClassName={(record, index) => record.id === updatedIndex ? 'table-row start-animate ' : 'table-row'}
          bordered={true}
          loading={isLoading}
          columns={[
            {
              title: "Название аккаунта",
              dataIndex: "title",
              key: "title",
            },
            {
              title: "Имя",
              dataIndex: "name",
              key: "name",
            },
            {
              title: "E-mail",
              dataIndex: "email",
              key: "email",
            },
            {
              title: "Город",
              dataIndex: "city_name",
              key: "city_name",
            },
            {
              title: "Компании",
              dataIndex: "orgs_length",
              key: "orgs_length",
              render: (orgs_length, full) => (
                <div>
                  {orgs_length}
                </div>
              ),
            },
            {
              title: "",
              dataIndex: "action",
              key: "action",
              render: (_, full) => (
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setModalVis(true);
                    setActiveUser(full);
                  }}
                >
                  Привязка
                </a>
              ),
            },
            {
              title: "",
              dataIndex: "action",
              key: "action",
              render: (_, full) => (
                <a
                  href="#"
                  onClick={(e) => {

                    e.preventDefault();
                    setEditUser(full);
                    setAddModalVis(true);
                  }}
                >
                  <EditOutlined />
                </a>
              ),
            },
          ]}
          dataSource={users}
          scroll={{ x: 300 }}
        />
      </Card>

      <Modal
        title={<Typography.Title level={4}>Юридические лица дилера "{activeUser.name}"</Typography.Title>}
        centered
        visible={modalVis}
        footer={false}
        // onOk={() => {
        //   setModalVis(false);
        //   setSaveTrigger(!saveTrigger);
        // }}
        // okText="Сохранить"
        // onCancel={() => setModalVis(false)}
        closable={false}
        destroyOnClose
        width={window.screen.width < 450 ? "100%" : "80%"}
        // zIndex={10000}
        bodyStyle={{ paddingBottom: 16 }}
      >
        <LegalTable userData={activeUser} visible={modalVis} setVisible={setModalVis} />
      </Modal>

      <Modal
        title={
          <Typography.Title level={4}>{createNew ? "Добавить" : "Обновить"} пользователя</Typography.Title>
        }
        centered
        visible={addModalVis}
        onOk={sendForm}
        okText={createNew ? "Добавить" : "Обновить"}
        onCancel={() => setAddModalVis(false)}
        closable={false}
        destroyOnClose
        width={window.screen.width < 450 ? "100%" : 450}
        // zIndex={10000}
        bodyStyle={{ paddingBottom: 16 }}
      >
        <Form
          name="wrap"
          // labelCol={{ flex: "110px" }}
          labelAlign="left"
          labelWrap
          wrapperCol={{ flex: 1 }}
          colon={false}
          form={form}
          initialValues={editUser}
          layout="vertical"
        >
          <Form.Item
            label="Название аккаунта"
            required
            name="title"
            rules={[
              {
                required: true,
                message: "Поле обязательно к заполнению",
              },
            ]}
          >
            <Input placeholder="Введите название аккаунта" className="settings__input" />
          </Form.Item>

          <Form.Item
            label="Имя пользователя"
            required
            name="name"
            rules={[
              {
                required: true,
                message: "Поле обязательно к заполнению",
              },
            ]}
          >
            <Input placeholder="Введите имя" className="settings__input" />
          </Form.Item>

          <Form.Item
            label="E-mail"
            required
            name="email"
            rules={[
              {
                required: true,
                message: "Некорректный E-mail",
                type: "email",
              },
            ]}
          >
            <Input placeholder="Введите e-mail" className="settings__input" />
          </Form.Item>

          <Form.Item
            label="Телефон"
            required
            name="phone"
            validateStatus={phoneError ? "error" : undefined}
            help={phoneError ? "Заполните номер телефона!" : undefined}
            rules={[
              {
                required: true,
                message: "Некорректный телефон",
                type: "string",
              },
            ]}
          >
            {/* <Input placeholder="Введите телефон" className="settings__input" /> */}
            <DynamicPhone />
          </Form.Item>

          <Form.Item
            label="Пароль"
            required
            name="password"
            rules={[
              {
                required: false,
                message: "Минимальная длина пароля: 8 символов",
              },
            ]}
          >
            <Input
              placeholder="Введите пароль"
              suffix={
                <Button type="default" size="small" onClick={generatePassword}>
                  Сгенерировать
                </Button>
              }
              className="settings__input"
            />
          </Form.Item>

          <Form.Item label="Город"
            name="city_name"

          >
            <CitySelect region={region} setRegion={setRegion} />
          </Form.Item>
          <Form.Item label="Доступ" required name="capabilities">
            <Select className="settings__input" mode="multiple" onChange={() => console.log(1)}>
              <Select.Option value="d_user">Дилер</Select.Option>
              <Select.Option value="d_manager">Менеджер</Select.Option>
              <Select.Option value="d_admin_manager">Руководитель менеджеров</Select.Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
