import React, { useEffect, useState } from 'react';
import useLoadPatternEntity from '../EditPatternPage/PatternEntity/useLoadPatternEntity';
import { useDispatch } from 'react-redux';
import { setHeaderTitle } from '../../redux/slices/layoutSlice';
import { Button, Popconfirm } from "antd"
import { DeleteOutlined, EditOutlined } from "@ant-design/icons"
import "../ConstructorPage/OfferItem.scss"
import PatternEntityModal from '../EditPatternPage/PatternEntity/PatternEntityModal';
import TypeEntitySelect from '../EditPatternPage/TypeEntitySelect/TypeEntitySelect';

const EditBlockPage = () => {
    const [addEntityModal, setAddEntityModal] = useState(false);
    const [action, setAction] = useState("new")
    const [groupId, setGroupId] = useState(null)
    const [entity, setEntity] = useState({})
    const { loading, patternEntities, addEntity: addNewEntity, updateEntity, createPatternEntity, deletePatternEntity } = useLoadPatternEntity({ withLoad: true })
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setHeaderTitle("Редактор блоков"))
    }, [])
    const openModal = (item) => {
        setEntity(item)
        setAddEntityModal(true);
    }
    const updateEntityItem = (entityBody) => {

        setEntity(entity => {

            return {
                ...entity,
                ...entityBody,
            }
        })
    }

    const addEntity = (entity) => {
        console.log("addEntity", entity);
        if (action == "update")
            updateEntity(entity);
        if (action == "new")
            addNewEntity(entity);
    }
    const onChangeGroupId = (group_id) => {
        setGroupId(group_id);
        console.log("Set group id", group_id);
    }
    return (
        <div className='edit-block'>
            <Button
                style={{
                    width: "100%",
                    maxWidth: 150,
                    marginBottom: 15
                }}
                onClick={() => {
                    setAction("new")
                    openModal({})
                }}
            >
                Создать блок
            </Button>
            <TypeEntitySelect onChangeGroupId={onChangeGroupId} groupId={groupId} create={false} allowClear />
            {(groupId ? patternEntities.filter(item => item.group_id == String(groupId)) : patternEntities).map(item => {

                return <div key={item.id} className='offer'
                    onClick={() => {
                        setAction("update")
                        openModal(item)
                    }}
                >
                    <div className='offer__name'>
                        {item.name}
                    </div>
                    <div className='offer__actions'
                        onClick={e => {
                            e.stopPropagation();
                        }}
                    >
                        <Button
                            type="link"
                            style={{
                                marginRight: 10
                            }}
                            onClick={() => {
                                setAction("update")
                                openModal(item)
                            }}
                            icon={<EditOutlined />}
                        />
                        <Popconfirm

                            placement="top"
                            title="Удалить блок?"
                            onConfirm={(e) => {
                                e.stopPropagation();
                                deletePatternEntity(item.id)
                                // deleteItem(item.id);
                            }}
                            onCancel={(e) => e.stopPropagation()}
                            okText="Удалить"
                            cancelText="Отмена"
                        >
                            <Button
                                type="link"
                                danger
                                icon={<DeleteOutlined />}
                            />
                        </Popconfirm>
                    </div>
                </div>
            })}
            <PatternEntityModal
                entity={entity}
                local={false}
                setEntity={updateEntityItem}
                editing={true}
                parentAction={action}
                visible={addEntityModal}
                setVisible={setAddEntityModal}
                addEntity={addEntity} />
        </div>
    );
};

export default EditBlockPage;