import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { getAuth } from "firebase/auth";



const firebaseConfig = {
    apiKey: "AIzaSyCG-ex9XsAwFl06X8mTaxXAF3EadtKcYlM",
    authDomain: "portal-vakio.firebaseapp.com",
    projectId: "portal-vakio",
    storageBucket: "portal-vakio.appspot.com",
    messagingSenderId: "87751130057",
    appId: "1:87751130057:web:b9cfb22fb898d43e3f0618"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const messaging = getMessaging(app);
export const onMessageListener = (handler) =>
    onMessage(messaging, (payload) => {
        handler(payload)

    });

export const requestForToken = () => {
    return getToken(messaging, { vapidKey: "BPATNWXFvznKFOXsD7V11meAuexR_y6bk14QG2p8t-uifUWmMn2adViifxbOelP-YG_3izyAGSpb0aCKHU87ytw" })
        .then((currentToken) => {
            console.log('current token for client: ', currentToken);
            return currentToken;
        })
        .catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
            throw err;
        });
};
