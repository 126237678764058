import { Button, Popconfirm, Select, Input } from 'antd';
import React, { useEffect } from 'react';
import "./SelectDevice.scss"
import { PlusOutlined, MinusOutlined, DeleteOutlined } from "@ant-design/icons";
import styled from "styled-components";

const StyledInput = styled(Input)`
.ant-input {
    text-align: center;
  }`
const SelectDevice = ({
    setProjectDevices,
    projectDevices,
    canUpdate,
    selectDevices,
    setTriggerSend,
    setDeletedDevices,
    setSelectDevices,
    devices }) => {

    const onChange = (values, option) => {
        console.log("select devices", values);
        if (setDeletedDevices) {

            if (selectDevices.length > values.length) {

                setDeletedDevices(prev => {
                    return [...prev, ...selectDevices.filter(n => values.indexOf(n) === -1)]
                })
            }
        }
        setSelectDevices(values);
    }

    const onChangeCount = (event, project_device_id) => {
        const value = event.target.value;

        const reg = /^\d*(\.\d*)?$/;
        if (reg.test(value)) {
            setProjectDevices(projectDevices.map(device => {
                if (device.project_device_id === project_device_id) {
                    return { ...device, count: value }
                } else {
                    return device;
                }
            }))

            if (setTriggerSend) {
                setTriggerSend(prev => {
                    return !prev;
                })
            }
        }
    }

    const addCount = (project_device_id) => {
        setProjectDevices(projectDevices.map(device => {
            if (device.project_device_id === project_device_id) {
                return { ...device, count: device.count + 1 }
            } else {
                return device;
            }
        }))

        if (setTriggerSend) {
            setTriggerSend(prev => {
                return !prev;
            })
        }
    }
    const minusCount = (project_device_id) => {
        setProjectDevices(projectDevices.map(device => {
            if (device.project_device_id === project_device_id) {
                return { ...device, count: device.count - 1 > 0 ? device.count - 1 : device.count }
            } else {
                return device;
            }
        }))

        if (setTriggerSend) {
            setTriggerSend(prev => {
                return !prev;
            })
        }
    }

    const deleteDeviceItem = (e, project_device_id) => {
        e.stopPropagation();
        if (setDeletedDevices) {


            setDeletedDevices(prev => {
                return [...prev, project_device_id]
            })

        }
        if (setTriggerSend) {
            setTriggerSend(prev => {
                return !prev;
            })
        }
        setProjectDevices(projectDevices.filter(device => device.project_device_id !== project_device_id));
        setSelectDevices(selectDevices.filter(item => item !== project_device_id))
    }
    return (
        <>
            <div>

                {canUpdate && <Select
                    style={{ width: "100%" }}
                    placeholder="Выберите приборы"
                    onChange={onChange}
                    mode="multiple"
                    value={selectDevices}
                    // filterOption={(input, option) =>
                    //     (option.children ).toLowerCase().includes(input.toLowerCase())
                    //   }

                    options={devices?.map((device) => {
                        return { label: device?.name, value: device?.id }
                    })} />}
            </div>
            <div>
                {projectDevices?.map(device => {
                    return <div className={canUpdate ? 'project-device' : 'project-device-no'} key={device.project_device_id}>
                        <div className='project-device__name' style={canUpdate ? {} : { marginRight: 20, fontSize: 16, justifyContent: "space-between" }}>
                            {devices.find(item => item.id === device.project_device_id)?.name}
                        </div>
                        {canUpdate ? <div className='project-device__actions'>



                            <StyledInput
                                className='project-device__input'
                                size="middle"
                                value={device.count}
                                style={{ width: 120, textAlign: "center" }}
                                onChange={(event) => onChangeCount(event, device.project_device_id)}
                                prefix={
                                    <Button
                                        onClick={() => minusCount(device.project_device_id)}
                                        size="small"
                                        type='text'
                                        shape="circle"
                                        icon={<MinusOutlined />}
                                    />}
                                suffix={
                                    <Button
                                        onClick={() => addCount(device.project_device_id)}
                                        shape="circle"
                                        type='text'
                                        size="small"
                                        icon={<PlusOutlined />} />}

                            />

                            <Popconfirm
                                placement="topRight"
                                title={`Удалить прибор ${devices.find(item => item.id === device.project_device_id)?.name} из списка?`}
                                onConfirm={(e) => deleteDeviceItem(e, device.project_device_id)}
                                onCancel={(e) => e.stopPropagation()}
                                okText="Удалить"
                                cancelText="Отмена"
                            >

                                <Button style={{ marginLeft: window.screen.width > 450 ? 30 : 20 }} danger icon={<DeleteOutlined />} />
                            </Popconfirm>
                        </div>
                            :
                            <div>{device.count}{" шт."}</div>
                        }
                    </div>
                })}
            </div>
        </>
    );
};

export default SelectDevice;