import React from 'react';
import { Button, Modal } from 'antd';

interface MissingProductsModalProps {
    visible: boolean,
    setToSend: (toSend: any) => void,
    setVisible: (visible: boolean) => void,
    missingProducts: any
}
const MissingProductsModal = ({ visible, setVisible, setToSend, missingProducts }: MissingProductsModalProps) => {

    const handleOk = () => {
        if (missingProducts.missing_on_main && missingProducts.missing_on_transit) {
            setToSend(prev => {
                return {
                    ...prev,
                    store: "3cf46df0-c735-11e6-8731-001e6730f61b"
                }
            })
        } else if (!missingProducts.missing_on_main && missingProducts.missing_on_transit) {

        } else {
            setToSend(prev => {
                return {
                    ...prev,
                    store: "3cf46df0-c735-11e6-8731-001e6730f61b"
                }
            })
        }
        setVisible(false);
    }
    const handleCancel = () => {
        setVisible(false);
    }
    const getText = () => {
        if (missingProducts?.missing_on_main && missingProducts?.missing_on_transit) {
            return <>
                <p>Данная позиция на складе отсутствует, срок доставки будет увеличен.</p>
                <p>Cменить склад на Новосибирск?</p>
            </>
        } else if (!missingProducts?.missing_on_main && missingProducts?.missing_on_transit) {
            return <>
                <p>Данная позиция на складе отсутствует или отсутствует частично, но товар находится в пути.</p>
                <p>Уточните срок доставки у менеджера.</p>
            </>
        } else {
            return <>
                <p>Данная позиция на складе отсутствует, срок доставки будет увеличен.</p>
                <p>Cменить склад на Новосибирск?</p>
            </>
        }

    }
    return (
        <>
            <Modal
                okText="Изменить склад на Новосибирск"
                cancelText="Оставить как есть"
                title="Товар отсутсвует на складе"
                visible={visible}
                onOk={handleOk}
                onCancel={handleCancel}>
                <div style={{ fontSize: 15 }}>
                    {getText()}
                </div>
            </Modal>
        </>
    );
};

export default MissingProductsModal;