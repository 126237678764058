import React from 'react';
import { Form } from "antd"
import FormInput from '../FormInput';
const RoomSizeInput = ({ item, form, formValues, setFormValues }) => {


    return (
        <div style={{ display: "flex" }}>
            {item.fields.filter(item => item.hide != true).map((innitem, index, fullArr) => {
                return <FormInput
                    setFormValues={setFormValues}
                    formValues={formValues}
                    key={innitem.key + "_"}
                    form={form}
                    style={{
                        marginBottom: 12,
                        width: 100 / fullArr.length + "%",
                        marginRight: index != fullArr.length - 1 ? 10 : 0,
                        display: "flex",
                        flexDirection: "column"
                    }}
                    item={innitem} />
            })}
        </div>

    );
};

export default RoomSizeInput;