import React, { useEffect, useState } from 'react';
import CitySelect from '../../MyCompanies/CitySelect';

const FormCitySelect = ({ form, item, formValues, setFormValues }) => {
    const [region, setRegion] = useState({})

    useEffect(() => {
        setRegion(form.getFieldValue(item.key));
    }, [])

    const updateRegion = (region) => {

        form.setFieldsValue({ [item.key]: region });
        setFormValues({ ...formValues, [item.key]: region });
        setRegion(region)

    }

    return (
        <CitySelect region={region} setRegion={updateRegion} />
    );
};

export default FormCitySelect;