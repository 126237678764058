import React, { useState, useEffect } from "react";
import { Card, Typography, Select, message, Input, Checkbox, Alert } from "antd";
import { routePropsTypes, toSendType, productsType, promoType } from "./types";
import { useTypedDispatch, useTypedSelector } from "../../redux/hooks";

import deliveryJson from "../../json/delivery.json";

import "./CreateOrderModal.scss";
import CitySelect from "../MyCompanies/CitySelect";

type Props = {
  toSend: toSendType;
  setToSend: any;
  deliveryType: any;
};

export default function ModalForm({ toSend, setToSend, deliveryType }: Props) {
  const handbookRedux = useTypedSelector((state) => state.handbook);
  const [stores, setStores] = useState<any[]>([]);
  const [comment, setComment] = useState<string>("");
  const [trackContacts, setTrackContacts] = useState<any[]>([]);
  const bitrixData = useTypedSelector((state) => state.handbook.bitrixData);
  const [region, setRegion] = useState<any>(null)

  useEffect(() => {
    if (region) {
      setToSend({
        ...toSend,
        location: region?.value
      })
    }
  }, [region])

  useEffect(() => {
    const location = toSend.partner_data?.location?.[0];
    if (location) {
      setRegion({
        value: location.id,
        label: location.city
      })
    }
  }, [])
  const delivery = deliveryJson;

  useEffect(() => {
    // сделать шобы для менеджера были видны все
    // if (handbookRedux.stores.length !== 0) {
    //   if (handbookRedux.d_isUser || (handbookRedux.d_isManager || handbookRedux.d_isAdminManager)) {
    //     const allowedStores = handbookRedux.stores.filter((f) => f.visibility);
    //     const arrOfStoresRefs = allowedStores.map((itm) => itm.ref);

    //     if (!arrOfStoresRefs.includes(toSend.store)) {
    //       const thisStore = handbookRedux.stores.find((f) => f.ref === toSend.store);
    //       setStores(allowedStores.concat(thisStore));
    //     } else {
    //       setStores(allowedStores);
    //     }
    //   }

    //   // else if (handbookRedux.d_isManager) { // Пока убрал, сказали не надо
    //   //   setStores(handbookRedux.stores);
    //   // }
    // }
    setStores(handbookRedux.stores);


  }, [handbookRedux.stores]);

  useEffect(() => {
    if (!bitrixData && bitrixData?.contacts.length === 0) return;

    const contacts = bitrixData?.contacts?.map((itm: any, ind: any) => {
      const p = itm?.phones
        .map((i: any) => ({
          val: i,
          label: itm.name + ' ' + i
        }));
      return p;
    })
    const result = [].concat.apply([], contacts);
    console.log('phones', contacts);
    setTrackContacts(result);
  }, [bitrixData.contacts]);

  return (
    <div>
      <div className="delivery__select-container">
        <div className="delivery__notification"><Alert message={deliveryType == 'on_demand' ? 'Информацию Вы можете заполнить позже' : 'Поля обязательны для заполнения'} type={deliveryType == 'on_demand' ? 'success' : 'warning'} /></div>
        <div className="delivery__select-text">Склад {deliveryType == 'delivery_now' ? '*' : ''}</div>
        <div className="delivery__select">
          <Select
            disabled
            placeholder="Не выбран"
            style={{ width: "100%" }}
            // defaultValue="3cf46df0-c735-11e6-8731-001e6730f61b"
            value={toSend.store !== null && toSend.store !== "" ? toSend.store : undefined}
            onChange={(data) => {
              setToSend({
                ...toSend,
                store: data,
              });
            }}
          >
            {stores &&
              stores.map((itm, ind) => {
                return (
                  <Select.Option key={ind} value={itm.ref}>
                    {itm.name}
                  </Select.Option>
                );
              })}
          </Select>
        </div>
      </div>

      {
        <div className="delivery__select-container delivery__select-container--margin">
          <div className="delivery__select-text">Город назначения {deliveryType == 'delivery_now' ? '*' : ''}</div>
          <div className="delivery__select">
            <CitySelect region={region} setRegion={setRegion} />
          </div>
        </div>
      }
      <div className="delivery__select-container delivery__select-container--margin">
        <div className="delivery__select-text">Доставка {deliveryType == 'delivery_now' ? '*' : ''}</div>
        <div className="delivery__select">
          <Select
            placeholder="Не выбрана"
            style={{ width: "100%" }}
            value={
              toSend.delivery_method !== null && toSend.delivery_method !== ""
                ? toSend.delivery_method.toString()
                : undefined
            }
            onChange={(data) => {
              console.log(data);
              setToSend({
                ...toSend,
                delivery_method: data,
                transport_company: "",
              });
            }}
          >
            <Select.Option value="5685">Самовывоз</Select.Option>
            <Select.Option value="5687">До терминала транспортной компании</Select.Option>
            <Select.Option value="5689">Доставка транспортной компанией</Select.Option>
            <Select.Option value="5691">Доставка Вакио по Новосибирску</Select.Option>
          </Select>
        </div>
      </div>

      <div className="delivery__select-container" style={{ marginTop: 7.5 }}>
        <div className="delivery__select-text">Транспортная компания</div>
        <div className="delivery__select">
          <Select
            placeholder="Не выбрана"
            style={{ width: "100%" }}
            value={
              toSend.transport_company !== null && toSend.transport_company !== ""
                ? toSend.transport_company.toString()
                : undefined
            }
            disabled={toSend.delivery_method === "5685" || toSend.delivery_method === "5691"}
            onChange={(data) => {
              console.log(data);
              setToSend({
                ...toSend,
                transport_company: data,
              });
            }}
          >
            {delivery.map((itm, ind) => (
              <Select.Option value={itm.value} key={ind}>
                {itm.label}
              </Select.Option>
            ))}
          </Select>
        </div>
      </div>

      {trackContacts.length > 0 ? (
        <div className="delivery__select-container" style={{ marginTop: 7.5 }}>
          <div className="delivery__select-text">Телефон получения трек-номера {deliveryType == 'delivery_now' ? '*' : ''}</div>
          <div className="delivery__select">
            <Select
              placeholder="Не выбрана"
              style={{ width: "100%" }}
              value={
                toSend.track_phone !== null && toSend.track_phone !== ""
                  ? toSend.track_phone
                  : undefined
              }
              onChange={(data) => {
                console.log(data);
                setToSend({
                  ...toSend,
                  track_phone: data,
                });
              }}
            >
              {trackContacts?.map((itm: any, ind: any) => (
                <Select.Option value={itm.val} key={ind}>
                  {itm.label}
                </Select.Option>
              ))}
            </Select>
          </div>
        </div>
      ) : (
        <div className="delivery__select-container" style={{ marginTop: 7.5, alignItems: "flex-start" }}>
          <div className="delivery__select-text">Телефон получения трек-номера {deliveryType == 'delivery_now' ? '*' : ''}</div>
          <div className="delivery__select">
            <Input
              placeholder="Введите номер телефона"
              maxLength={300}
              value={
                toSend.track_phone !== null && toSend.track_phone !== ""
                  ? toSend.track_phone
                  : undefined
              }
              style={{ resize: "none" }}
              onChange={(e) => {
                setComment(e.target.value);
                setToSend({
                  ...toSend,
                  track_phone: e.target.value,
                });
              }}
            />
          </div>
        </div>
      )}

      <div className="delivery__select-container" style={{ marginTop: 7.5, alignItems: "flex-start" }}>
        <div className="delivery__select-text">Комментарий для логиста</div>
        <div className="delivery__select">
          <Input.TextArea
            rows={4}
            placeholder="Адрес доставки и другие данные"
            maxLength={300}
            value={
              toSend.comment_for_store !== null && toSend.comment_for_store !== ""
                ? toSend.comment_for_store.toString()
                : undefined
            }
            style={{ resize: "none" }}
            onChange={(e) => {
              setComment(e.target.value);
              setToSend({
                ...toSend,
                comment_for_store: e.target.value,
              });
            }}
          />
        </div>
      </div>

    </div>
  );
}
