import React from 'react';
import { Input, Divider, Button } from "antd"
import "./SelectContact.scss"
import ContactsEmailPhone from './ContactsEmailPhone';
import { CloseOutlined } from "@ant-design/icons"
const Contact = ({ contact, updateContact, deleteContact }) => {

    const onChange = (key, value) => {
        updateContact({ ...contact, [key]: value })
    }
    return (<>
        <div className='contact-item'>
            <div className='contact-item__input'>
                <div style={{
                    marginBottom: 8,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center"
                }}>

                    <div className='contact-item__input__label'>Имя</div>
                    <Button icon={<CloseOutlined />} onClick={() => deleteContact(contact.id)} danger />
                </div>
                <Input value={contact.name} onChange={(e) => onChange("name", e.target.value)} />
            </div>
            <ContactsEmailPhone contact={contact} updateContact={updateContact} />
        </div>
        <Divider style={{ margin: "10px 0" }} />
    </>
    );
};

export default Contact;