import React, { useState, useEffect, useMemo } from "react";
import { useTypedDispatch, useTypedSelector } from "../../redux/hooks";
import { Card, Typography, message, Button, Popconfirm } from "antd";
import { PlusOutlined, SaveOutlined, DeleteOutlined } from "@ant-design/icons";
import { useLocation, useHistory, useParams } from "react-router-dom";
import ProductItem from "./ProductItem";
import MoneyItem from "./MoneyItem";
import { IProductItem, ISumItem, IToSend } from "./types";

import "./PromotionDetails.scss";

type Props = {
  group: any;
  fullGroups?: any;
  setGroups?: any;
  type: "inPromo" | "standalone";
  deleteUnsaved: any;
  promotionId: number | false;
};

export default function PromotionDetails({
  group,
  fullGroups,
  setGroups,
  type,
  deleteUnsaved,
  promotionId,
}: Props) {
  const handbookRedux = useTypedSelector((state) => state.handbook);
  let params: { id: string } = useParams();
  const [detailsName, setDetailsName] = useState<string>("Новое условие");
  const itemDefault = {
    id: 0,
    type: [""],
    comparison: "=",
    amount: 1,
  };
  const [terms, setTerms] = useState<IProductItem[] | ISumItem[]>([itemDefault]);
  const [benefits, setBenefits] = useState<IProductItem[] | ISumItem[]>([itemDefault]);
  const [termsTrigger, setTermsTrigger] = useState<boolean>(false);
  const [benefitsTrigger, setBenefitsTrigger] = useState<boolean>(false);
  const [lastTermsIndex, setLastTermsIndex] = useState<number>(0);
  const [lastBenefitsIndex, setLastBenefitsIndex] = useState<number>(0);
  const [toSend, setToSend] = useState<IToSend>({
    title: "",
    input_data: [],
    output_data: [],
  });
  const [sendButtonLoading, setSendButtonLoading] = useState<boolean>(false);
  const [isMounted, setIsMounted] = useState<boolean>(false);

  useMemo(() => {
    setLastTermsIndex(lastTermsIndex + 1);
  }, [terms.length]);

  useMemo(() => {
    setLastBenefitsIndex(lastBenefitsIndex + 1);
  }, [benefits.length]);

  useEffect(() => {
    // подставляшки
    if (type == "inPromo") {
      if (params?.id != "new") {
        fillDataFromProps();
      }
    } else if (type == "standalone") {
      fillDataFromProps();
    } else {
      // сделать какой-то вывод ошибки
    }
    return () => {
      setIsMounted(true);
    };
  }, []);

  useEffect(() => {
    // console.log("input save", terms);
    setToSend({
      ...toSend,
      input_data: terms.map((t) => ({
        input_type: t.type,
        input_comparison: t.comparison,
        input_value: t.amount.toString(),
      })),
    });
  }, [termsTrigger]);

  useEffect(() => {
    // console.log("output save", benefits);
    setToSend({
      ...toSend,
      output_data: benefits.map((b) => ({
        output_type: b.type,
        output_comparison: b.comparison,
        output_value: b.amount.toString(),
      })),
    });
  }, [benefitsTrigger]);

  function fillDataFromProps() {
    setDetailsName(group.title);
    setTimeout(() => {
      setToSend({
        title: group.title,
        id: group.id,
        parent_id: group.parent_id,
        input_data: group.input_data,
        output_data: group.output_data,
      });
    });

    const convrtedTerms = group.input_data.map((itm: any, ind: number) => ({
      id: ind,
      type: itm.input_type,
      comparison: itm.input_comparison,
      amount: itm.input_value,
    }));
    setTerms(convrtedTerms);

    const convrtedBenefits = group.output_data.map((itm: any, ind: number) => ({
      id: ind,
      type: itm.output_type,
      comparison: itm.output_comparison,
      amount: itm.output_value,
    }));
    setBenefits(convrtedBenefits);
  }

  function handleDetailsName(name: string) {
    setDetailsName(name);
    setToSend({
      ...toSend,
      title: name,
    });
  }

  function handleAddTerm() {
    // console.log(1);
    const copy = terms.slice();
    copy.push({
      ...itemDefault,
      id: lastTermsIndex,
    });
    setTerms(copy);
  }

  function handleAddBenefit() {
    // console.log(2);
    const copy = benefits.slice();
    copy.push({
      ...itemDefault,
      id: lastBenefitsIndex,
    });
    setBenefits(copy);
  }

  function validateItem() {
    setSendButtonLoading(true);
    const inputValidate = terms.map((inp) => inp.type.includes(""));
    const outputValidate = benefits.map((inp) => inp.type.includes(""));
    // const isForEachComparison = benefits.map((itm) => itm.comparison == "=*").includes(true);
    // const isMultipleProductTerms =terms.filter((f) => f.type[0] != "percent" || f.type[0] != "money").length > 1;
    if (!toSend.title || toSend.title === "") {
      message.error("Пожалуйста, придумайте название условия");
      setSendButtonLoading(false);
    } else if (inputValidate.includes(true)) {
      message.error("Товары в условии акции не выбраны");
      setSendButtonLoading(false);
    } else if (outputValidate.includes(true)) {
      message.error("Подарочные товары не выбраны");
      setSendButtonLoading(false);

      // }
      // else if (isForEachComparison && isMultipleProductTerms) {
      //   message.error(
      //     "Создание нескольких групп товаров при выборе подарков за каждый товар в корзине недоступно"
      //   );
    } else {
      // message.info("start");
      // console.log(group, toSend);
      // !!!!!!!!!!!!!!!!!!!!!!!
      // if (group.id == "") {
      if (Math.floor(group.id) == 0) {
        createQuery();
      } else {
        editQuery();
      }
    }
  }

  // console.log(group);

  function sendConditionToParent(data: any) {
    const newArr = fullGroups.map((obj: any) => {
      if (obj.id === group.id) {
        return data;
      } else {
        return obj;
      }
    });
    setGroups(newArr);
    // group.id = id;
  }

  async function createQuery() {
    // console.log("create cond");
    console.log("send", toSend);
    return fetch(`${SiteUrlDealer}/promotion_condition`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""}`,
      },
      body: JSON.stringify({
        content: [toSend],
      }),
      method: "POST",
    })
      .then((res) => res.json())
      .then((json) => {
        if (!isMounted) {
          console.log(json);
          message.success("Условие добавлено");
        }

        // setGroups([
        //   ...fullGroups,
        //   group
        // ]);
        // тут надо сделать алгоритм типа setParentData, чтобы передать айдишники на род компонент для отправки  на сервер
        // + надо сделать доступ по прямой ссылке

        sendConditionToParent(json.content[0]);
      })
      .catch(() => {
        message.error("Что-то пошло не так");
      })
      .finally(() => {
        setSendButtonLoading(false);
      });
  }

  async function editQuery() {
    // console.log(toSend);

    return fetch(`${SiteUrlDealer}/promotion_condition/${toSend.id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""}`,
      },
      body: JSON.stringify(toSend),
      method: "PUT",
    })
      .then((res) => res.json())
      .then((json) => {
        if (!isMounted) {
          console.log(json);
          message.success("Условие редактировано");
        }
      })
      .catch(() => {
        message.error("Что-то пошло не так");
      })
      .finally(() => {
        setSendButtonLoading(false);
      });
  }

  function deleteItem() {
    console.log("del");
    if (Math.floor(toSend.id) == 0) {
      deleteUnsaved(toSend.id);
    } else {
      // console.log(toSend.id);

      fetch(`${SiteUrlDealer}/promotion_condition/${toSend.parent_id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""
            }`,
        },
        method: "DELETE",
      })
        .then((res) => console.log(res.status))
        .then(() => {
          if (!isMounted) {
            // console.log(json);
            message.success("Удалено");
            document.location.reload();
          }
        })
        .catch((err) => {
          console.error(err);
          message.error("Что-то пошло не так");
        });
    }
  }

  function removeFromPromo() {
    console.log(1);
    setGroups(fullGroups.filter((f: any) => f.id != group.id));
  }

  return (
    <Card
      bordered={false}
      style={{ marginTop: 20 }}
      // loading={true}
      title={
        <Typography.Title
          level={ResponsiveTitleLevel}
          style={{ width: "fit-content" }}
          editable={{
            onChange: handleDetailsName,
            maxLength: 100,
            // autoSize: { maxRows: 1, minRows: 1 },
          }}
        >
          {detailsName}
        </Typography.Title>
      }
      extra={
        <Button type="primary" icon={<SaveOutlined />} loading={sendButtonLoading} onClick={validateItem}>
          {window.screen.width > 450 && (type === "inPromo" ? "Добавить к акции" : "Сохранить")}
        </Button>
      }
      className="card"
    >
      <div className="promotion-details">
        <Typography.Title level={4} style={{ width: "fit-content", marginBottom: 26 }}>
          Если
        </Typography.Title>
        <div className="promotion-details__terms-container">
          {terms
            .sort((a, b) => {
              return a.id > b.id ? 1 : a.id == b.id ? 0 : a.id < b.id ? -1 : 0;
            })
            .map((itm, ind) => (
              <div key={itm.id}>
                {itm.type[0] != "percent" ? (
                  <ProductItem
                    itm={itm}
                    ind={ind}
                    fullData={terms}
                    setFullData={setTerms}
                    role="term"
                    trigger={termsTrigger}
                    setTrigger={setTermsTrigger}
                  />
                ) : (
                  <MoneyItem
                    itm={itm}
                    ind={ind}
                    fullData={terms}
                    setFullData={setTerms}
                    role="term"
                    trigger={termsTrigger}
                    setTrigger={setTermsTrigger}
                  />
                )}
              </div>
            ))}

          <div className="promotion-details__add-button">
            <Button
              type="primary"
              icon={<PlusOutlined />}
              disabled={terms.length == 1 && (terms[0].type[0] == "" || terms[0].type[0] == undefined)}
              onClick={handleAddTerm}
            >
              Добавить условие
            </Button>
          </div>
        </div>
        <Typography.Title level={4} style={{ width: "fit-content", marginTop: 26, marginBottom: 26 }}>
          Тогда
        </Typography.Title>
        {/* <Divider /> */}
        <div className="promotion-details__benefits-container">
          {benefits
            .sort((a, b) => {
              return a.id > b.id ? 1 : a.id == b.id ? 0 : a.id < b.id ? -1 : 0;
            })
            .map((itm, ind) => (
              <div key={itm.id}>
                {itm.type[0] == "money" || itm.type[0] == "percent" ? (
                  <MoneyItem
                    itm={itm}
                    ind={ind}
                    fullData={benefits}
                    setFullData={setBenefits}
                    role="benefit"
                    trigger={benefitsTrigger}
                    setTrigger={setBenefitsTrigger}
                  />
                ) : (
                  <ProductItem
                    itm={itm}
                    ind={ind}
                    fullData={benefits}
                    setFullData={setBenefits}
                    role="benefit"
                    trigger={benefitsTrigger}
                    setTrigger={setBenefitsTrigger}
                  />
                )}
              </div>
            ))}

          <div className="promotion-details__add-button">
            <Button
              type="primary"
              icon={<PlusOutlined />}
              disabled={
                benefits.length == 1 && (benefits[0].type[0] == "" || benefits[0].type[0] == undefined)
              }
              onClick={handleAddBenefit}
            >
              Добавить действие
            </Button>
          </div>
        </div>
        <div className="promotion-details__delete-container">
          {type == "inPromo" && (
            <Button type="link" onClick={removeFromPromo}>
              Убрать из акции
            </Button>
          )}
          <Popconfirm
            placement="top"
            title="Вы действительно хотите удалить условие?"
            onConfirm={deleteItem}
            onCancel={(e) => e.stopPropagation()}
            okText="Удалить"
            cancelText="Не удалять"
          >
            <Button type="link" danger>
              Удалить
            </Button>
          </Popconfirm>
        </div>
      </div>
    </Card>
  );
}
