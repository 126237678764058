import React, { useState, useEffect } from "react";
import { Button, Typography, Badge, Popover, Divider, Empty, Modal, Input, message, Select, Form } from "antd";
import { ShoppingCartOutlined, DeleteOutlined } from "@ant-design/icons";
import { useTypedDispatch, useTypedSelector } from "../../redux/hooks";
import { useHistory } from "react-router-dom";
import { addCartItem } from "../../redux/slices/cartSlice";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

import CustomInputNumber from "../CustomInputNumber/CustomInputNumber";

import "./CartPreview.scss";

type Props = {};

export default function CartPreview({ }: Props) {
  const history = useHistory();
  const dispatch = useTypedDispatch();
  const layoutRedux = useTypedSelector((state) => state.layout);
  const cartRedux = useTypedSelector((state) => state.cart);
  const [cartAmount, setCartAmount] = useState<number>(0);
  const [cartTotal, setCartTotal] = useState<number>(0);
  const [cartVisibility, setCartVisibility] = useState<boolean>(false);

  const [addModalVis, setAddModalVis] = useState<boolean>(false);
  const [form] = Form.useForm();

  useEffect(() => {
    const calcedAmount = cartRedux.cartItems.reduce((n: any, { amount }: any) => n + amount, 0);
    setCartAmount(calcedAmount);
    const calcedTotal = cartRedux.cartItems.reduce((n: any, { price, amount }: any) => n + price * amount, 0);
    setCartTotal(calcedTotal);
  }, [cartRedux.cartItems]);

  function handleNavigateToCreateOrder() {
    history.push(`/create-order/new`, {
      mode: "cart",
      data: {
        partner: localStorage.getItem("defaultPartner") ? localStorage.getItem("defaultPartner") : "",
        items: cartRedux.cartItems.map((itm: any) => ({
          amount: itm.amount,
          item: itm.ref,
          sum: 0,
          manager_discount: 0,
          default_price: itm.price,
          type: itm.type ? itm.type : "default",
        })),
      },
    });
    setCartVisibility(false);
  }

  function handleEditQty(e: number, itm: any) {
    const cartMode = cartRedux.cartItems.find((f: any) => f.ref === itm.ref) ? "edit" : "add";
    if (cartMode === "edit") {
      const editedItem = cartRedux.cartItems.map((i: any) => {
        if (i.ref === itm.ref) {
          return {
            ...i,
            amount: e,
          };
        } else {
          return i;
        }
      });
      const res = editedItem.slice();
      const copy = res.filter((f: any) => f.amount > 0);
      dispatch(addCartItem(copy));
      localStorage.setItem("cart", JSON.stringify(copy));
    } else {
      const copy = cartRedux.cartItems.slice();
      copy.push({
        ...itm,
        amount: e,
      });
      dispatch(addCartItem(copy));
      localStorage.setItem("cart", JSON.stringify(copy));
    }
  }

  return (
    <div className="header__cart cart-preview">
      <Popover
        overlayStyle={{ width: window.screen.width > 450 ? "500px" : "100%" }}
        title={
          <div className="cart-preview__header">
            <Typography.Title level={4} style={{ marginBottom: 5 }}>
              Корзина
            </Typography.Title>
            <Button
              type="link"
              danger
              size="small"
              style={{ padding: 0 }}
              onClick={() => dispatch(addCartItem([]))}
            >
              Очистить
            </Button>
          </div>
        }
        placement="bottomRight"
        trigger="click"
        visible={cartVisibility}
        onVisibleChange={() => setCartVisibility(!cartVisibility)}
        content={
          <div
            style={{
              height: window.screen.width < 450 && "100%",
            }}
          >
            {cartRedux.cartItems.length > 0 ? (
              <>
                <PerfectScrollbar style={{ maxHeight: 291, overflowY: "auto" }}>
                  {cartRedux.cartItems.map((itm: any, ind: any, arr: any) => (
                    <div key={itm.ref} className="cart-preview__delete-items-container">
                      <div className="cart-preview__delete-container">
                        <Button
                          type="text"
                          shape="circle"
                          icon={<DeleteOutlined />}
                          style={{ marginRight: 16 }}
                          size="middle"
                          onClick={() => {
                            const edited = cartRedux.cartItems.filter((f: any) => f.ref !== itm.ref);
                            dispatch(addCartItem(edited));
                          }}
                        />
                        <div className="cart-preview__items-container">
                          <div className="cart-preview__items-name">{itm.name}</div>
                          <div className="cart-preview__items-amount">
                            <CustomInputNumber
                              onChange={(e: any) => handleEditQty(e, itm)}
                              minValue={1}
                              value={itm.amount}
                            />
                          </div>
                          <div className="cart-preview__items-price">{formatMoney(itm.price)}&nbsp;₽</div>
                        </div>
                      </div>

                      <Divider />
                    </div>
                  ))}
                </PerfectScrollbar>
                <div className="cart-preview__total">
                  <div className="cart-preview__total-title">Итого:</div>
                  <div className="cart-preview__total-amount">{formatMoney(cartTotal)}&nbsp;₽</div>
                </div>
                {/* <Divider /> */}

                <div className="cart-preview__buttons">
                  <Button
                    type="link"
                    size="small"
                    style={{ padding: 0 }}
                    onClick={() => {
                      setCartVisibility(!cartVisibility);
                      setAddModalVis(true);
                    }}
                  >
                    Отправить предложение
                  </Button>
                  <Button
                    type="primary"
                    style={{ marginLeft: "auto" }}
                    onClick={() => handleNavigateToCreateOrder()}
                  >
                    Оформить сейчас
                  </Button>
                </div>
              </>
            ) : (
              <Empty
                style={{ marginRight: 24 }}
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={<span>Корзина пуста</span>}
              />
            )}
          </div>
        }
      >
        <Button
          type="link"
          icon={
            <Badge count={cartAmount}>
              <ShoppingCartOutlined style={{ color: "#8c8c8c", fontSize: 24 }} />
            </Badge>
          }
        // style={{ fontWeight: 700, fontSize: 15, color: "#141414" }}
        />
      </Popover>
      <Modal
        title={<Typography.Title level={4}>Отправить предложение</Typography.Title>}
        centered
        visible={addModalVis}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              console.log(values);
              values.cart = cartRedux.cartItems;
              console.log(values);
              fetch(SiteUrlDealer + "/create_customer_offer", {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""
                    }`,
                },
                body: JSON.stringify(values),
                method: "POST",
              })
                .then((res) => res.json())
                .then((json) => {
                  console.log(json);
                  form.resetFields();
                  setAddModalVis(false);
                  message.success("Предложение отправлено");
                });
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
        okText="Отправить"
        onCancel={() => setAddModalVis(false)}
        closable={false}
        destroyOnClose
        width={window.screen.width < 450 ? "100%" : 450}
        // zIndex={10000}
        bodyStyle={{ paddingBottom: 16 }}
      >
        <Form
          name="wrap"
          labelCol={{ flex: "110px" }}
          labelAlign="left"
          labelWrap
          wrapperCol={{ flex: 1 }}
          colon={false}
          form={form}
          initialValues={{
            capabilities: "d_user",
          }}
        >


          <Form.Item
            label="Имя клиента"
            required
            name="name"
            rules={[
              {
                required: true,
                message: "Поле обязательно к заполнению",
              },
            ]}
          >
            <Input placeholder="Введите имя" className="settings__input" />
          </Form.Item>

          <Form.Item
            label="E-mail"
            required
            name="email"
            rules={[
              {
                required: false,
                message: "Некорректный E-mail",
                type: "email",
              },
            ]}
          >
            <Input placeholder="Введите e-mail" className="settings__input" />
          </Form.Item>

          <Form.Item
            label="Телефон"
            required
            name="phone"
            rules={[
              {
                required: false,
                message: "Некорректный телефон",
                type: "string",
              },
            ]}
          >
            <Input placeholder="Введите телефон" className="settings__input" />
          </Form.Item>

        </Form>
      </Modal>
    </div>
  );
}
