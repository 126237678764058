import React, { useEffect, useState } from 'react';
import "./SettingsModal.scss"
import { TimePicker, Modal, message } from 'antd';
import { CheckOutlined, StopOutlined } from "@ant-design/icons"
import moment from 'moment';
// 0 - 6
const days = [
    "Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"
]
const SettingsModal = ({ setVisible, visible }) => {
    const [time, setTime] = useState(null)
    const [loading, setLoading] = useState(false);
    const [loadingDay, setLoadingDay] = useState(false);
    const [selectedDays, setSelectedDays] = useState([0, 1, 2, 3, 4]);
    const changeTime = (time) => {
        console.log(time);
    }
    useEffect(() => {
        setSelectedDays([]);
    }, [visible])
    useEffect(() => {
        if (visible) {
            fetch(`https://auth.vakio.ru/api/user_meta`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""}`,
                },
                method: "GET",
            })
                .then((res) => res.json())
                .then((json) => {

                    const userTime = json.find(item => item.meta_key == "notify_time");
                    const userDays = json.find(item => item.meta_key == "notify_days");
                    console.log(userTime, userDays);
                    if (userTime) {
                        setTime(moment("10-10-2022 " + userTime.meta_value));
                    } else {
                        setTime(moment("10-10-2022 " + "18:00"));
                    }
                    if (userDays) {
                        setSelectedDays((userDays.meta_value.split(",")).map(item => Number(item)))
                    } else {
                        setSelectedDays([0, 1, 2, 3, 4])
                    }
                })
                .catch(() => message.error("Ошибка при загрузке информации!")).finally(() => {
                });
        }
        // const date = "10-10-2022 " + "18:00";
        // setTime(moment(date))
    }, [visible])


    const updateDay = (index) => {
        if (selectedDays.includes(index)) {
            setSelectedDays(selectedDays.filter(item => item !== index))
        } else {
            setSelectedDays([...selectedDays, index])

        }
    }
    const onSave = () => {
        setLoading(true);
        fetch(`https://auth.vakio.ru/api/user_meta`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""}`,
            },
            method: "POST",
            body: JSON.stringify(
                {
                    "meta_key": "notify_days",
                    "meta_value": selectedDays.join(",")
                },

            )
        })
            .then((res) => res.json())
            .then((json) => {
                message.success("Изменения успешно сохранены!")
                setVisible(false);
            })
            .catch(() => message.error("Ошибка при сохранении!")).finally(() => {
                setLoading(false);
            }).finally(() => setLoading(false));
        setLoadingDay(true);
        fetch(`https://auth.vakio.ru/api/user_meta`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""}`,
            },
            method: "POST",
            body: JSON.stringify(
                {
                    "meta_key": "notify_time",
                    "meta_value": time.format("HH:mm")
                },
            )
        })
            .then((res) => res.json())
            .then((json) => {
                //  message.error("Изменения успешно сохранены!")
                setVisible(false);
            })
            .catch(() => { }).finally(() => {
                setLoadingDay(false);
            }).finally(() => setLoadingDay(false));
    }

    return (
        <Modal
            visible={visible}
            onCancel={() => setVisible(false)}
            title="Настройка уведомлений"
            closable
            onOk={onSave}
            confirmLoading={loading || loadingDay}
            cancelText={"Закрыть"}
            okText={"Сохранить"}
        >
            <div className='notify'>
                <div className='notify__label'>
                    Дни работы:
                </div>
                <div className='notify__days'>
                    {days.map((day, index) => {
                        return <div
                            key={index}
                            className={`notify__days__day ${selectedDays.includes(index) ? "selected" : ""}`}
                            onClick={() => updateDay(index)}
                        >
                            {day}
                            {selectedDays.includes(index) ? <CheckOutlined style={{ marginLeft: 10 }} /> : <StopOutlined style={{ marginLeft: 10 }} />}
                        </div>
                    })}
                </div>
            </div>
            <div className='time-picker'>

                <div className='time-picker__label'>
                    Время:
                </div>
                <TimePicker

                    showSecond={false}
                    allowClear={false}
                    format={"HH:mm"}

                    hideDisabledOptions={true}
                    value={time}
                    onChange={setTime}
                    onSelect={setTime}
                />

            </div>
        </Modal>
    );
};

export default SettingsModal;