import Checklist from "@editorjs/checklist";
import EditorJS from "@editorjs/editorjs";
import Header from "@editorjs/header";
import List from "@editorjs/list";
import Marker from "@editorjs/marker";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "./Editor.scss";
import HeaderBlock from "./components/HeaderBlock";
import ImageWithDesc from "./components/ImageWithDesc/ImageWithDesc";
import SampleImage from "./components/SampleImage";
import TextBlock from "./components/TextBlock";

const Editor = ({
  refreshId,
  setRefreshId,
  entity,
  holder,
  trigger,
  data,
  setPatternEntity,
  entityId,
  readOnly = false,
}) => {
  const onChange = async () => {
    try {
      let content = await ejInstance?.current.saver.save();
      setPatternEntity({
        ...(entity ?? {}),
        body: content,
      });
    } catch (e) { }
  };
  const ejInstance = useRef();

  const [isReady, setIsReady] = useState(false);
  const initEditor = () => {
    setIsReady(false);
    const editor = new EditorJS({
      logLevel: "ERROR",
      holder: holder,
      onReady: () => {
        setIsReady(true);
        ejInstance.current = editor;
      },
      autofocus: true,
      readOnly: readOnly ?? true,
      data: data,
      onChange: onChange,
      i18n: {
        messages: {
          tools: {
            warning: {
              Title: "Название",
              Message: "Сообщение",
            },
            link: {
              "Add a link": "Вставьте ссылку",
            },
          },
          blockTunes: {
            delete: {
              Delete: "Удалить",
            },
            moveUp: {
              "Move up": "Переместить вверх",
            },
            moveDown: {
              "Move down": "Переместить вниз",
            },
          },
        },
      },
      defaultBlock: "text",
      inlineToolbar: true,
      tools: {
        HeaderBlock: {
          class: HeaderBlock,
        },
        imageDesc: {
          class: ImageWithDesc,
          inlineToolbar: true,
        },
        image: {
          class: SampleImage,
          inlineToolbar: true,
        },
        text: {
          class: TextBlock,
          inlineToolbar: true,
        },
        header: Header,
        checklist: Checklist,
        list: List,
        marker: Marker,
      },
    });
  };

  useEffect(() => {
    if (ejInstance.current === null || ejInstance.current === undefined) {
      initEditor();
    }

    return () => {
      ejInstance?.current?.destroy();
      ejInstance.current = null;
      setIsReady(false);
    };
  }, [entityId]);

  useEffect(() => {
    if (refreshId === entityId && entityId && ejInstance.current) {
      ejInstance?.current.render(data);
      if (ejInstance.current?.configuration?.onChange) {

        ejInstance.current.configuration.onChange = onChange;
      }

      if (setRefreshId) setRefreshId(0);
    }
  }, [refreshId]);
  useEffect(() => {
    if (entityId && ejInstance.current) {
      console.log("Trigger!!!!!!!!!");
      if (ejInstance.current?.configuration?.onChange) {
        ejInstance.current.configuration.onChange = onChange;
      }
      ejInstance.current.render(data);
    }
  }, [trigger]);

  useEffect(() => {
    if (ejInstance.current) {
      ejInstance.current.readOnly.toggle(readOnly);
    }
  }, [readOnly]);
  return (
    <>
      <div id={holder} style={{ width: 990, maxHeight: undefined }}></div>
    </>
  );
};

export default Editor;
