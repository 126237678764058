import React, { useRef, useState } from 'react';
import { EyeOutlined, EyeInvisibleOutlined, PlusOutlined, MinusOutlined } from "@ant-design/icons"
import { Input, Button, Typography, Image, Spin } from 'antd';
import "./ImageWithDesc.scss"
import DivInput from '../DivInput/DivInput';
import UploadFile from '../UploadFile';

const ImageWithDescCard = ({ readOnly, data, onDataChange }) => {
    const [readOnlyEdit, setReadOnlyEdit] = useState(false);
    const [imageState, setImageState] = useState(data?.image)
    const [imageSize, setImageSize] = useState(data?.imageSize)
    // const [values, setValues] = useState(data);
    const values = useRef(data);

    const updateData = (key, value) => {
        const newData = {
            ...values.current,
            [key]: value
        }
        onDataChange(newData);
        values.current = newData;
        //setValues(newData)
    }
    const changeReadOnly = () => {
        setReadOnlyEdit(!readOnlyEdit);
    }
    const isReadOnly = () => {
        return readOnlyEdit || readOnly;
    }

    const setImage = (image) => {
        setImageState(image)
        updateData("image", image);
    }

    const plusImageSize = () => {
        if (values.current.imageSize < 5) {
            updateData("imageSize", values.current.imageSize + 1);
            setImageSize(values.current.imageSize + 1);
        }
    }
    const minusImageSize = () => {
        if (values.current.imageSize > 1) {
            updateData("imageSize", values.current.imageSize - 1);
            setImageSize(values.current.imageSize - 1);
        }
    }


    const getImageSize = () => {
        switch (values.current.imageSize) {
            case 0:
                return 60
            case 1:
                return 100
            case 2:
                return 140
            case 3:
                return 200
            case 4:
                return 250
            case 5:
                return 300
        }
    }



    return (
        <div className='image-desc'>
            {!readOnly && <Button
                onClick={changeReadOnly}
                type="link"
                icon={isReadOnly() ? <EyeInvisibleOutlined /> : <EyeOutlined />}
            />}
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>

                <div className='image-desc__image' style={{
                    width: getImageSize(),
                    height: getImageSize(),
                    borderRadius: imageState?.link ? "1px solid #FFFFFF00" : "1px solid rgb(218, 218, 218, 0.4)"
                }}>
                    {imageState?.status == "uploading" ?
                        <Spin />
                        :
                        <>
                            {imageState?.link && <img src={imageState.link} alt="image" width={"100%"} height={"100%"} />}
                        </>}
                </div>
                {!isReadOnly() && <> <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', flexDirection: "row", marginBottom: 10, }}>
                    <div style={{ marginRight: 5 }}>Размер</div>
                    <Button style={{ marginRight: 5 }} shape="circle" icon={<PlusOutlined />} onClick={plusImageSize} />
                    <Button style={{ marginRight: 5 }} shape="circle" icon={<MinusOutlined />} onClick={minusImageSize} />
                </div>
                    <UploadFile setImage={setImage} />
                </>}
            </div>
            <div className='image-desc__text'>
                <DivInput
                    onInput={(value) => updateData("headerText", value)}
                    onChangeSize={(value) => updateData("headerSize", value)}
                    isReadOnly={isReadOnly()}
                    value={values.current.headerText}
                    fontSize={values.current.headerSize}
                />
                <DivInput
                    containerStyle={{}}
                    onInput={(value) => updateData("description", value)}
                    onChangeSize={(value) => updateData("descriptionSize", value)}
                    isReadOnly={isReadOnly()}
                    value={values.current.description}
                    fontSize={values.current.descriptionSize}
                />


            </div>
        </div >
    )
};

export default ImageWithDescCard;