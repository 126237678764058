import React, { useState, useEffect } from "react";
import { Input, Button } from "antd";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";

type Props = { onChange: any; minValue: number; value?: number };

export default function CustomInputNumber({ onChange, minValue, value }: Props) {
  const [num, setNum] = useState<any>(minValue);
  const [numTrigger, setNumTrigger] = useState<boolean>(false);

  //
  //
  // Перевести на этот компонент Catalog/Product и CreateOrder/OrderListItem
  //
  //

  useEffect(() => {
    if (value) {
      setNum(value);
    }
  }, [value]);

  useEffect(() => {
    // if (num === "init") return;
    if (num === minValue) return;
    onChange(num);
  }, [numTrigger]);

  function handleKeyboardInput(e: any): void {
    const value = e.target.value.replace(/\D/g, "");
    if (value < 1000) {
      setNum(value);
      setNumTrigger(!numTrigger);
    }
  }

  function handleMinus() {
    if (num > 0) {
      setNum(+num - 1);
      setNumTrigger(!numTrigger);
    }
  }

  function handlePlus() {
    if (num < 999) {
      if (num === "init") {
        setNum(2);
      } else {
        setNum(+num + 1);
      }
      setNumTrigger(!numTrigger);
    }
  }

  return (
    <div
      // className="create-order__number"
      className="number-input"
      style={{ width: 120 }}
    >
      <Input
        prefix={
          <Button
            type="text"
            shape="circle"
            size="small"
            icon={<MinusOutlined />}
            onClick={handleMinus}
            // disabled={props.productsMode === "prefill" ? false : props.disabled}
          />
        }
        suffix={
          <Button
            type="text"
            shape="circle"
            size="small"
            icon={<PlusOutlined />}
            onClick={handlePlus}
            // disabled={props.productsMode === "prefill" ? false : props.disabled}
          />
        }
        style={{ width: "100%", textAlign: "center" }}
        size="middle"
        // defaultValue="1"
        value={num}
        // disabled={props.productsMode === "prefill" ? false : props.disabled}
        onChange={handleKeyboardInput}
        className="num-picker"
        onBlur={() => {
          if (num === "") {
            setNum(1);
          }
        }}
      />
    </div>
  );
}
