import React, { useState, useEffect } from "react";
import {
  Card,
  Typography,
  Divider,
  Button,
  Tooltip,
  Empty,
  Pagination,
  Input,
  Spin,
  Select,
  Radio,
  Checkbox,
  Popconfirm,
  message,
  Space,
  Badge,
} from "antd";
import { PlusOutlined, UnorderedListOutlined, SyncOutlined, InboxOutlined, PayCircleOutlined } from "@ant-design/icons";
import { ResponsivePie } from "@nivo/pie";
import { useHistory } from "react-router-dom";
import { useTypedDispatch, useTypedSelector } from "../../redux/hooks";
import { setHeaderTitle } from "../../redux/slices/layoutSlice";

import "../../styles/Card.scss";
import "../../styles/FilterField.scss";
import "./Orders.scss";

import OrdersWidget from "./OrdersWidget";



export interface IOrderProps { }

export default function Order(props: IOrderProps) {
  const dispatch = useTypedDispatch();

  const radioFilterOption = [
    { label: "Все", value: "all" },
    //{ label: "На оплате", value: "wait" },
    { label: "В работе", value: "processing" },
    //{ label: "Оплаченные", value: "fullPaid" },
    { label: "На отгрузке", value: "preFullSold" },
    { label: "Дебиторка", value: "waitingPayment" },
    { label: "Успешные", value: "finished" },
    //{ label: "Отменённые", value: "canceled" },
    //{ label: "Черновики", value: "drafts" },
  ];

  useEffect(() => {
    dispatch(setHeaderTitle("Заказы"));
  }, []);





  // const convertGoodAmount = (arr: any[]): any[] => {
  //   const keks = arr.slice();
  //   keks.splice(3);
  //   return keks;
  // };





  return (
    <OrdersWidget orgFilter={undefined} />
  );
}
