import React, { useEffect, useState } from 'react';
import { useTypedSelector } from '../../../../redux/hooks';

const useProductsLoad = () => {
    const products = useTypedSelector((state) => state.handbook.products);

    // const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(false);
    const fetchProducts = () => {
        setLoading(true);

    }

    useEffect(() => {

    }, [])
    return { products }
};

export default useProductsLoad;