import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { App } from './App';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { ConfigProvider } from 'antd';
import ruRU from 'antd/lib/locale/ru_RU';
import moment from 'moment';
import 'moment/locale/ru';
moment.locale('ru');
import 'antd/dist/antd.less';
global.SiteUrlAuth = 'https://auth.vakio.ru';

global.SiteUrlPortal = 'https://portal.vakio.ru/api';
global.ResponsiveTitleLevel = window.screen.width > 450 ? 3 : 4;

global.formatMoney = function (money: number | string): string {
	const decimal = (Math.round(+money * 100) / 100).toFixed(2);
	const removedDec = decimal.split('.');
	const fullOutput = removedDec[0] + (removedDec[1] == '00' ? '' : '.' + removedDec[1]);
	return fullOutput.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

if (process.env.NODE_ENV === 'production') {
	console.log = function () {};
	global.domain = 'https://dealers.vakio.ru';
	global.SiteUrlDealer = 'https://dealers.vakio.ru/api';
} else {
	global.SiteUrlDealer = 'https://dealers.vakio.ru/api';
	// global.SiteUrlDealer = "http://vakio-dealer.loc/api";
	global.domain = 'http://localhost:8083';
}

ReactDOM.render(
	<ConfigProvider locale={ruRU}>
		<Provider store={store}>
			<App />
		</Provider>
	</ConfigProvider>,
	document.getElementById('root')
);
