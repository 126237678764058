import React, { useState, useEffect } from "react";
import { useTypedDispatch, useTypedSelector } from "../../redux/hooks";
import { Button, Space, Empty, Spin, message } from "antd";
import { PlusOutlined, PictureTwoTone } from "@ant-design/icons";
import { useRouteMatch, Link, useHistory } from "react-router-dom";
import { setHeaderTitle } from "../../redux/slices/layoutSlice";
import PromotionItem from "./PromotionItem";
import { IPromotions } from "./types";

import "../../styles/Card.scss";
import "./Promotions.scss";

type Props = {};

export default function Promotions({ }: Props) {
  const handbookRedux = useTypedSelector((state) => state.handbook);

  const dispatch = useTypedDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const history = useHistory();

  const [promotions, setPromotions] = useState<IPromotions[]>([]);

  useEffect(() => {
    let isCancelled = false;
    dispatch(setHeaderTitle("Промоакции"));
    setIsLoading(true);

    fetch(`${SiteUrlDealer}/${(handbookRedux.d_isManager || handbookRedux.d_isAdminManager) ? "promotion" : "user_prom_index"}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""}`,
      },
      method: (handbookRedux.d_isManager || handbookRedux.d_isAdminManager) ? "GET" : "POST",
    })
      .then((res) => res.json())
      .then((json) => {
        if (!isCancelled) {
          setPromotions(json);
          console.log("promo", json);
          setIsLoading(false);
        }
      })
      .catch(() => message.error("Что-то пошло не так"));

    return () => {
      isCancelled = true;
    };
  }, []);

  if (isLoading) {
    return (
      <div className="prom-spin">
        <Spin size="large" />
      </div>
    );
  } else {
    return (
      <div className="promotions">
        {(handbookRedux.d_isManager || handbookRedux.d_isAdminManager) && (
          <Space>
            <Button type="primary" icon={<PlusOutlined />} onClick={() => history.push("/promotions/new")}>
              Новая акция
            </Button>
            <Button type="primary" onClick={() => history.push("/conditions")}>
              Редактирование условий
            </Button>
          </Space>
        )}
        {promotions.length > 0 ? (
          <div className="promotions__items-container">
            {promotions.map((itm) => {
              return (
                <div key={itm.id}>
                  <PromotionItem itm={itm} />
                </div>
              );
            })}
          </div>
        ) : (
          <Empty className="promotions__empty" description="Нет активных акций" />
        )}
      </div>
    );
  }
}
