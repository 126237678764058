import React from 'react';
import ReactDOM from 'react-dom'
import { FontColorsOutlined } from "@ant-design/icons"

import TextComponent from './TextComponent';
class TextBlock {
    static get toolbox() {
        return {
            title: 'Текст',
            icon: '<svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.660511 1.50781V0.0909088H9.10334V1.50781H5.69957V11H4.05895V1.50781H0.660511Z" fill="black" /></svg>'

        };
    }
    static get enableLineBreaks() {
        return true;
    }
    constructor({ readOnly, data }) {
        this.readOnly = readOnly ?? false;

        this.data = {
            text: data?.text ?? null,
            textSize: data?.textSize ?? 15,
            align: data?.align ?? "flex-start",
        }

        this.nodes = {
            holder: null,
        };
    }
    static get isReadOnlySupported() {
        return true;
    }

    static get sanitize() {
        return {
            url: true,
            br: true,
            video: true,
            h1: true,
            h2: true,
            h3: true,
            h4: true,
            h5: true,
            h6: true,
            div: true,
            b: true,
            a: true,
            i: true,
            strong: true
        }
    }
    render() {
        const rootNode = document.createElement('div');
        this.nodes.holder = rootNode;

        const onDataChange = (newData) => {
            this.data = {
                ...newData
            };
        }


        ReactDOM.render(<TextComponent data={this.data} readOnly={this.readOnly} onDataChange={onDataChange} />,
            rootNode);

        return this.nodes.holder;
    }

    save(blockContent) {
        return this.data;
    }

}

export default TextBlock;