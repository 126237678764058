import React, { useState, useEffect, useMemo } from "react";
import { useTypedDispatch, useTypedSelector } from "../../redux/hooks";
import { Typography, message, Button, Select, Divider, InputNumber } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { IProductItem, ISumItem } from "./types";

type Props = {
  itm: ISumItem;
  fullData: ISumItem[];
  setFullData: any;
  ind: number;
  role: "term" | "benefit";
  trigger: boolean;
  setTrigger: any;
};

export default function MoneyItem({ itm, fullData, setFullData, ind, role, trigger, setTrigger }: Props) {
  const handbookRedux = useTypedSelector((state) => state.handbook);

  function handleDeleteItem(item: any) {
    console.log(item);
    const filtered = fullData.filter((f: any) => f.id != item.id);
    setFullData(filtered);
    setTrigger(!trigger);
  }

  function handleSelectItemType(val: "product" | "money") {
    console.log(val);
    const newArr = fullData.map((obj: any) => {
      if (obj.id === itm.id) {
        return {
          ...obj,
          type: val == "product" ? [""] : ["money"],
        };
      } else {
        return obj;
      }
    });
    setFullData(newArr);
    setTrigger(!trigger);
  }

  function handleDiscountType(itm: ISumItem, val: "percent" | "money") {
    console.log(val);
    const newArr = fullData.map((obj: any) => {
      if (obj.id === itm.id) {
        return {
          ...obj,
          type: [val],
          comparison: "=",
        };
      } else {
        return obj;
      }
    });
    setFullData(newArr);
    setTrigger(!trigger);
  }

  function handleSelectComparison(val: ">" | "<" | "=" | "%") {
    console.log(val);
    const newArr = fullData.map((obj: any) => {
      if (obj.id === itm.id) {
        return {
          ...obj,
          comparison: val,
        };
      } else {
        return obj;
      }
    });
    setFullData(newArr);
    setTrigger(!trigger);
  }

  function handleSumAmount(itm: ISumItem, val: number) {
    console.log(val);
    const newArr = fullData.map((obj: any) => {
      if (obj.id === itm.id) {
        return {
          ...obj,
          amount: val !== null ? val.toString() : 1,
        };
      } else {
        return obj;
      }
    });
    setFullData(newArr);
    setTrigger(!trigger);
  }

  const comparison = (
    <Select
      placeholder="Тип сравнения"
      className="promotion-details__comparison"
      value={itm.comparison}
      disabled={role == "benefit" && (itm.type[0] == "money" || itm.type[0] == "percent")}
      onChange={handleSelectComparison}
      getPopupContainer={(trigger) => trigger.parentNode}
    >
      {role == "term" ? (
        <>
          <Select.Option value="=">Равно</Select.Option>
          <Select.Option value="%">Кратно</Select.Option>
          <Select.Option value=">">Больше</Select.Option>
          <Select.Option value="<">Меньше</Select.Option>
        </>
      ) : (
        <>
          <Select.Option value="=">Равно</Select.Option>
          <Select.Option value="%">Кратно</Select.Option>
        </>
      )}
    </Select>
  );

  return (
    <div>
      <div className="promotion-details__item">
        <Button
          icon={<DeleteOutlined />}
          className="promotion-details__delete"
          shape="circle"
          type="text"
          disabled={
            fullData.length == 1 ||
            (fullData.length != 1 && (fullData[0].type[0] == "" || fullData[0].type[0] == undefined))
          }
          onClick={() => handleDeleteItem(itm)}
        />
        <div className="promotion-details__items-container">
          <div className="promotion-details__type">
            <Select
              placeholder="Тип условия"
              className="promotion-details__type-input"
              value={itm.type[0] == "money" || itm.type[0] == "percent" ? "money" : "product"}
              onChange={handleSelectItemType}
              getPopupContainer={(trigger) => trigger.parentNode}
            >
              <Select.Option value="product">{role == "term" ? "Товары в заказе" : "Подарок"}</Select.Option>
              <Select.Option value="money">{role == "term" ? "Сумма заказа" : "Скидка"}</Select.Option>
            </Select>
          </div>
          <div className="promotion-details__item-select">
            {role == "term" ? (
              <></>
            ) : (
              <Select
                // allowClear
                placeholder="Выберите тип скидки"
                style={{ width: "100%" }}
                value={itm.type[0]}
                onChange={(prods: "money" | "percent") => handleDiscountType(itm, prods)}
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                <Select.Option value="percent">В процентах (%)</Select.Option>
                <Select.Option value="money">В рублях (₽)</Select.Option>
              </Select>
            )}
          </div>
          <div className="promotion-details__item-number">
            <InputNumber
              min={1}
              max={role == "benefit" ? (itm.type[0] == "percent" ? 100 : 10000000) : 10000000}
              defaultValue={1}
              value={itm.amount}
              addonBefore={comparison}
              addonAfter={role == "benefit" ? (itm.type[0] == "money" ? "₽" : "%") : "₽"}
              onChange={(amount) => handleSumAmount(itm, amount)}
            />
          </div>
        </div>
      </div>

      {ind != fullData.length - 1 && <Divider orientation="left">И</Divider>}
    </div>
  );
}
