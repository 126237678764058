import React, { useState, useEffect } from "react";
import { Layout, Button, Typography, message } from "antd";
import { MenuOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { useTypedDispatch, useTypedSelector } from "../../redux/hooks";
import { useHistory, useLocation } from "react-router-dom";
import CartPreview from "./CartPreview";

import "./Header.scss";

export interface IHeaderProps {
  state: boolean;
  setState: any;
}

export default function Header(props: IHeaderProps) {
  const history = useHistory();
  const dispatch = useTypedDispatch();
  const layoutRedux = useTypedSelector((state) => state.layout);
  const location = useLocation()

  return (
    <Layout.Header className="site-layout-background header">
      <Button
        className="header__menu-button"
        shape="default"
        type="text"
        icon={<MenuOutlined />}
        onClick={() => {
          props.setState(!props.state);
        }}
      />
      <div className="header__title-container">
        <Typography.Title
          level={(layoutRedux.showHeaderSubtitle || window.screen.width) < 450 ? 4 : 2}
          ellipsis={true}
          style={{ maxWidth: "100%" }}
          className="header__title"
        >
          {layoutRedux.headerTitle}
        </Typography.Title>
        {layoutRedux.showHeaderSubtitle && (
          <Typography.Text className="header__subtitle">{layoutRedux.headerSubtitle}</Typography.Text>
        )}
      </div>
      {location?.pathname !== "/dealer-projects" && <CartPreview />}
      <div className="header__actions">
        {/* {layoutRedux.headerButtonData && layoutRedux.headerButtonData.action != "" && (
          <Button
            type="primary"
            icon={<EditOutlined />}
            style={{
              marginLeft: "auto",
              marginRight: 16,
            }}
            onClick={() => {
              if (layoutRedux.headerButtonData.action == "editOrder") {
                history.push(`/create-order/${layoutRedux.headerButtonData.data.id}`, {
                  mode: "edit",
                  data: layoutRedux.headerButtonData.data,
                });
              }
              if (layoutRedux.headerButtonData.action == "editOrderReject") {
                message.error("Редактирование заказа недоступно");
              }
            }}
          >
            {window.screen.width > 450 ? "Редактировать" : ""}
          </Button>
        )} */}
        {location?.pathname !== "/dealer-projects" && <Button
          type="primary"
          // icon={<SyncOutlined />}
          icon={<PlusOutlined />}
          style={{
            marginLeft: "auto",
            marginRight: 16,
          }}
          // onClick={() => {
          //   dispatch(fetchProducts());
          // }}
          onClick={() => history.push("/create-order/new", { mode: "create", data: false })}
        >
          {window.screen.width > 450 ? "Новый заказ" : ""}
        </Button>}
      </div>
    </Layout.Header>
  );
}
