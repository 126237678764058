import React, { useEffect, useState } from 'react';
import { Button, Select, Table, InputNumber, Typography, Empty, Divider, Input } from "antd";
import { PlusOutlined, MinusOutlined, SearchOutlined } from "@ant-design/icons";
import { IOneProduct } from './CreateOrderListItem';



type Props = {
    amount: any;
    productsMode: "classic" | "prefill";
    itm: any;
    fullData: any[];
    setParentData: any;
    disabled: boolean;
    addTrigger: boolean;
    setAddTrigger: any;
    setModifedProduct: any;
    modifedProduct: any;
};

const ProductAmount = ({ setModifedProduct, modifedProduct, amount, productsMode, disabled, fullData, itm, setParentData, setAddTrigger, addTrigger }: Props) => {
    const [num, setNum] = useState<any>("init")
    const [numTrigger, setNumTrigger] = useState<boolean>(false);
    useEffect(() => {
        setNum(amount)
    }, [amount])

    useEffect(() => {
        if (num === "init") return;
        const newArr = fullData.map((obj) => {
            if (obj.ref_item == itm.ref) {
                return {
                    ...obj,
                    qty: num,
                };
            } else {
                return obj;
            }
        });
        setParentData(newArr);
        setAddTrigger(!addTrigger);
    }, [numTrigger]);

    function handleMinus() {
        console.log("itm", itm);

        if (!modifedProduct.includes(itm.ref_item)) {
            setModifedProduct([...modifedProduct, itm.ref])
        }
        if (num > 0) {
            setNum(+num - 1);
            setNumTrigger(!numTrigger);
        }
    }

    function handlePlus() {
        if (!modifedProduct.includes(itm.ref_item)) {
            setModifedProduct([...modifedProduct, itm.ref])
        }
        if (num < 999) {
            if (num === "init") {
                setNum(2);
            } else {
                setNum(+num + 1);
            }
            setNumTrigger(!numTrigger);
        }
    }

    function handleKeyboardInput(e: any): void {
        const value = e.target.value.replace(/\D/g, "");
        if (value < 1000) {
            setNum(value);
            setNumTrigger(!numTrigger);
        }
    }

    return (
        <div>

            <Input
                prefix={
                    <Button
                        type="text"
                        shape="circle"
                        size="small"
                        icon={<MinusOutlined />}
                        onClick={handleMinus}
                        disabled={productsMode === "prefill" ? false : disabled}
                    />
                }
                suffix={
                    <Button
                        type="text"
                        shape="circle"
                        size="small"
                        icon={<PlusOutlined />}
                        onClick={handlePlus}
                        disabled={productsMode === "prefill" ? false : disabled}
                    />
                }
                style={{ width: "100%", textAlign: "center" }}
                size="middle"
                // defaultValue="1"
                value={num}
                disabled={productsMode === "prefill" ? false : disabled}
                onChange={handleKeyboardInput}
                className="num-picker"
                onBlur={() => {
                    if (num === "") {
                        setNum(0);
                    }
                }}
            />
        </div>
    );
};

export default ProductAmount;