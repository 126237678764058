import React, { useEffect, useState } from 'react';
import { Select } from "antd"
import "./SelectContact.scss"
const SelectCreatedContact = ({ addContact }) => {
    const [loading, setLoading] = useState(true);
    const [searchResult, setSearchResult] = useState([])
    useEffect(() => {
        onSearch("")
    }, [])
    function onSearch(search) {
        console.log(search);
        setLoading(true);
        setTimeout(() => {
            fetch(SiteUrlPortal + "/get_contacts_with_filters", {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""
                        }`,
                },
                method: "POST",
                body: JSON.stringify({
                    "filters": [
                        {
                            "value": search,
                            "condition": "or",
                            "comparison": "like",
                            "field": "phone"
                        },
                        {
                            "value": search,
                            "condition": "or",
                            "comparison": "like",
                            "field": "name"
                        },
                        {
                            "value": search,
                            "condition": "or",
                            "comparison": "like",
                            "field": "email"
                        }
                    ]
                })
            }).then(res => res.json())
                .then((response) => {
                    setSearchResult(response);
                })
        }, 600)

    }
    const onChange = (newValue) => {
        // тут есть возможность выводить результат выбора в виде объекта, а не строки
        // const converted = {
        //   id: +newValue.split(".")[0],
        //   type: newValue.split(".")[1],
        // };
        // setRegion(converted);
        const newContact = searchResult.find(item => item.id === newValue);
        addContact({
            ...newContact,
            phone: newContact?.phone ? newContact.phone.split(",") : [],
            email: newContact?.email ? newContact.email.split(",") : []
        });
        console.log("Set ", newContact);

    };




    return (
        <Select
            showSearch
            onSearch={onSearch}
            style={{ width: "100%" }}
            value={null}
            dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
            placeholder="Выберите контакт"
            allowClear
            // treeExpandedKeys={expanded}
            onChange={onChange}

            //onTreeExpand={onExpand}
            getPopupContainer={(trigger) => trigger.parentNode}
            filterOption={(input, option) => {
                return String(option.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
            }}
        >
            {searchResult.map(item => {
                return <Select.Option key={item.id} value={item.id} >
                    <div className='contact-option'>
                        <div className='contact-option__name'>
                            {item.name}
                        </div>
                        {item.phone && <div className='contact-option__phone'>
                            {`Телефон: ${item.phone}`}
                        </div>}
                        {item.email && <div className='contact-option__email'>
                            {`Почта: ${item.email}`}
                        </div>}
                    </div>
                </Select.Option>
            })}
        </Select>
    );
};

export default SelectCreatedContact;