import React, { useState } from 'react';
import PatternEntity from './PatternEntity';
import { Input, Typography, Button } from 'antd';
import TypeEntitySelect from '../TypeEntitySelect/TypeEntitySelect';

const PatternEntityEditForm = ({ entity, setEntity, local }) => {
	const onChangeGroupId = (id) => {
		setEntity({
			...entity,
			group_id: id,
		});
	};
	return (
		<div>
			{!local && (
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						flexDirection: 'row',
						background: 'white',
						alignItems: 'flex-end',
						marginBottom: 15,
						borderRadius: 10,
					}}
				>
					<div style={{ display: 'flex', flexDirection: 'row' }}>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								marginRight: 20,
							}}
						>
							<Typography.Text style={{ marginBottom: 4 }}>
								Имя блока
							</Typography.Text>
							<Input
								style={{ width: 300 }}
								value={entity.name}
								onChange={(e) =>
									setEntity({ ...entity, name: e.target.value })
								}
							/>
						</div>
						<TypeEntitySelect
							onChangeGroupId={onChangeGroupId}
							groupId={entity?.group_id}
						/>
					</div>
				</div>
			)}
			<PatternEntity
				patternEntity={entity}
				setPatternEntity={setEntity}
				holder={'form'}
				readOnly={false}
			/>
		</div>
	);
};

export default PatternEntityEditForm;
