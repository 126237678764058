import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Cookies from 'universal-cookie';

const cookies = new Cookies();
export interface AuthState {
  authorized: boolean;
  checked: boolean;
  token: string;
}

const initialState: AuthState = {
  authorized: false,
  checked: false,
  token: "",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    signIn: (state, action: PayloadAction<string>) => {
      localStorage.setItem("accessToken", action.payload);
      // cookies.set("accessToken", action.payload)
      cookies.set("accessToken", action.payload, { domain: '.vakio.ru' })
      state.checked = true;
      console.log("AUTH NORM!!!");

      state.token = action.payload;
    },
    signOut: (state) => {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("defaultPartner");
      localStorage.removeItem("user");
      cookies.remove("accessToken", { domain: '.vakio.ru' })
      cookies.remove("user", { domain: '.vakio.ru' })
      cookies.remove("accessToken")
      cookies.remove("user")
      state.authorized = false;
      state.checked = false;
    },
    setAuthorizeted: (state, action: PayloadAction<boolean>) => {
      state.authorized = action.payload;
      console.log("SET authorized", action.payload);

    },
    setChecked: (state, action: PayloadAction<boolean>) => {
      state.checked = action.payload;
      console.log("SET authorized", action.payload);
    }
  },
});

// Action creators are generated for each case reducer function
export const { signIn, signOut, setAuthorizeted, setChecked } = authSlice.actions;

export default authSlice.reducer;
