import { Form, Input, Select, message } from 'antd';
import Button from 'antd/lib/button';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLegals } from '../../redux/slices/handbookSlice';
import { RootState } from '../../redux/store';
import { IRegion } from '../PromotionSettings/types';
import CitySelect from './CitySelect';
import { defaultCompanyInfo } from './InnInput';
import "./MyCompanies.scss"
import BitrixFormLists from './BitrixFormLists';
import SelectContact from './SelectContact/SelectContact';
import { useTypedSelector } from '../../redux/hooks';
interface Props {
    setStage: any;
    setCompanyInfo: any;
    companyInfo: any;
    setVisible: any;
}
const CheckCompanyInfo = ({ setStage, setCompanyInfo, setVisible, companyInfo }: Props) => {
    const [region, setRegion] = useState<IRegion>("");
    const [contacts, setContacts] = useState<any[]>([]);
    const dispatch = useDispatch();
    const legals = useSelector((state: RootState) => state.handbook.legals)
    const [loading, setLoading] = useState<boolean>(false);
    const [form] = Form.useForm();
    const [user_id, setUserId] = useState(0);
    const d_isAdminManager = useTypedSelector((state) => state.handbook.d_isAdminManager);

    useEffect(() => {
        const userRaw = localStorage.getItem("user");
        if (userRaw) {
            const user_id = JSON.parse(userRaw)?.id;
            setUserId(user_id)
        }
    }, [])
    useEffect(() => {
        if (companyInfo?.city && companyInfo?.location) {
            setRegion({ label: companyInfo?.city, value: companyInfo?.location })
        }
    }, [companyInfo])
    useEffect(() => {
        form.resetFields()
        form.setFieldsValue({ deffered_period: 0 });
    }, [])
    useEffect(() => {
        form.setFieldsValue({ city: region.value })
    }, [region?.value])

    const goToPrev = () => {
        setStage(0);
    }

    const bindOrganization = () => {

        console.log();
        const newContacts = contacts.filter(contact => contact?.tmp_id);
        const updateContacts = contacts.filter(contact => contact?.tmp_id == undefined);
        const orgContacts = {
            "newContacts": newContacts,
            "updateContacts": updateContacts,
        };

        form
            .validateFields()
            .then((values) => {
                console.log(values);
                setLoading(true);

                setCompanyInfo(defaultCompanyInfo);
                // dispatch(setLegals([{ ...values, fact_address: values.jur_address, location: region.value, id: 123123 }, ...legals]));
                // return;

                fetch(`${SiteUrlDealer}/bind_organization`,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""
                                }`,
                        },
                        body: JSON.stringify({ "organizations": [{ ...values, contacts: orgContacts, user_id: user_id, fact_address: values.jur_address, location: region.value }] })
                    })
                    .then((res) => res.json())
                    .then(company => {
                        if (company.company_is_found) {

                            message.error("Такая компания уже добавлена!")
                        } else {

                            message.success("Компания отправлена на синхронизацию!")
                            //setLegals(legals => [...company, ...legals])
                            dispatch(setLegals([...company, ...legals]));
                            setVisible(false);
                            setCompanyInfo(defaultCompanyInfo);
                        }
                    }).catch((e) => {
                        console.log(e);

                        message.error("Не удалось добавить компанию!")

                    }).finally(() => {
                        setLoading(false);

                    })

            }).catch(() => {

                console.log("Not valid");
            })
    }
    return (
        <>
            <Form
                name="wrap"
                labelCol={{ flex: "110px" }}
                labelAlign="left"
                labelWrap
                initialValues={companyInfo}
                wrapperCol={{ flex: 1 }}
                colon={false}
                form={form}
            >



                <Form.Item
                    label="ИНН"
                    required
                    name="inn"
                    rules={[
                        {
                            required: true,
                            message: "Поле обязательно к заполнению",
                        },
                    ]}
                >
                    <Input placeholder="Введите ИНН" />
                </Form.Item>
                {form.getFieldValue("jurfiz") === "jur" && <Form.Item
                    label="КПП"
                    required
                    name="kpp"
                    rules={[
                        {
                            required: true,
                            message: "Поле обязательно к заполнению",
                        },
                    ]}
                >
                    <Input placeholder="Введите КПП" />
                </Form.Item>}

                <Form.Item
                    label="Имя"
                    required
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: "Поле обязательно к заполнению",
                        },
                    ]}
                >
                    <Input placeholder="Введите имя" />
                </Form.Item>

                <Form.Item
                    label="Полное имя"
                    required
                    name="full_name"
                    rules={[
                        {
                            required: true,
                            message: "Поле обязательно к заполнению",
                        },
                    ]}
                >
                    <Input placeholder="Введите полное имя" />
                </Form.Item>

                <Form.Item
                    label="Юридический адрес"
                    required
                    name="jur_address"
                    rules={[
                        {
                            required: true,
                            message: "Поле обязательно к заполнению",
                        },
                    ]}
                >

                    <Input placeholder="Введите адрес" />
                </Form.Item>

                <Form.Item
                    label="Тип покупателя"
                    required
                    name="jurfiz"
                    rules={[
                        {
                            required: true,
                            message: "Поле обязательно к заполнению",
                        },
                    ]}
                >
                    <Select
                        placeholder={"Выберите тип покупателя"}
                        options={[
                            { value: "jur", label: "Юридическое лицо" },
                            { value: "fiz", label: "ИП/Физическое лицо" },
                        ]} />
                </Form.Item>
                <Form.Item
                    label="Город"
                    required
                    name="city"
                    rules={[
                        {
                            required: true,
                            message: "Поле обязательно к заполнению",
                        },
                    ]}
                >
                    <div className="promotion-settings__input-item">
                        <CitySelect region={region} setRegion={setRegion} />
                    </div>
                </Form.Item>
                <BitrixFormLists />
                <Form.Item
                    label="Контакты"
                    name="contact"
                >
                    <div className="promotion-settings__input-item">
                        <SelectContact user_id={user_id} contacts={contacts} setContacts={setContacts} />
                    </div>
                </Form.Item>


                {d_isAdminManager && <Form.Item
                    label="Отсрочка (дни)"
                    required
                    name="deffered_period"
                    rules={[
                        {
                            required: true,
                            message: "Поле обязательно к заполнению",
                        },
                    ]}
                >
                    <Input placeholder="Введите количество дней отсрочки" />
                </Form.Item>}
            </Form>
            <div className='check-company-actions'>
                <Button
                    onClick={goToPrev}
                    type="link">
                    Назад
                </Button>
                <Button
                    onClick={bindOrganization}
                    type="primary"
                    loading={loading}>
                    Добавить
                </Button>
            </div>
        </>
    );
};

export default CheckCompanyInfo;