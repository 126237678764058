import { message } from 'antd';
import React, { useEffect, useState } from 'react';

const useSaveCOForm = ({ withLoad }) => {
	const [loading, setLoading] = useState(false);

	const [formItems, setFormItems] = useState([]);
	const [refresh, setRefresh] = useState(false);

	const refreshData = () => {
		setRefresh(!refresh);
	};
	const getFormItems = () => {
		setLoading(true);
		fetch(SiteUrlDealer + '/commercial_offer/form', {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${localStorage.getItem('accessToken')
						? localStorage.getItem('accessToken')
						: ''
					}`,
			},
			method: 'GET',
		})
			.then((res) => res.json())
			.then((response) => {
				setFormItems(response);
			})
			.catch((e) => {
				console.log(e);
			})
			.finally(() => {
				setLoading(false);
			});
	};
	useEffect(() => {
		if (withLoad) getFormItems();
	}, [refresh]);
	const create = (items) => {
		setLoading(true);
		return fetch(SiteUrlDealer + '/commercial_offer', {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${localStorage.getItem('accessToken')
						? localStorage.getItem('accessToken')
						: ''
					}`,
			},
			body: JSON.stringify(items),
			method: 'POST',
		})
			.then((res) => res.json())
			.then((response) => {
				return response;
			})
			.catch((e) => {
				console.log(e);
			})
			.finally(() => {
				setLoading(false);
			});
	};
	const update = (items) => {
		return fetch(SiteUrlDealer + '/commercial_offer/' + items.id, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${localStorage.getItem('accessToken')
						? localStorage.getItem('accessToken')
						: ''
					}`,
			},
			body: JSON.stringify(items),
			method: 'PUT',
		})
			.then((response) => { })
			.catch((e) => {
				console.log(e);
			})
			.finally(() => {
			});
	};
	const getUrl = (items) => {
		setLoading(true);
		return fetch(SiteUrlDealer + '/commercial_offer/geturl', {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${localStorage.getItem('accessToken')
						? localStorage.getItem('accessToken')
						: ''
					}`,
			},
			body: JSON.stringify(items),
			method: 'POST',
		})
			.then((response) => response.json())
			.then((response) => {
				console.log('GET URL', response);
				navigator.clipboard.writeText(
					`https://dealers.vakio.ru/commercial-offer/${response.uuid}`
				);
				message.success('Ссылка скопирована в буфер обмена!');
			})
			.catch((e) => {
				console.log(e);
			})
			.finally(() => {
				setLoading(false);
			});
	};
	const deleteItem = (id) => {
		setLoading(true);
		fetch(SiteUrlDealer + '/commercial_offer/' + id, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${localStorage.getItem('accessToken')
						? localStorage.getItem('accessToken')
						: ''
					}`,
			},
			method: 'DELETE',
		})
			.then((res) => res.json())
			.then((response) => {
				setFormItems(formItems.filter((item) => item.id != id));
			})
			.catch((e) => {
				console.log(e);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return { loading, formItems, refreshData, create, update, deleteItem, getUrl };
};

export default useSaveCOForm;
