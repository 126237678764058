import React, { useState, useEffect, useReducer, useContext, useMemo } from "react";
import { Card, Typography, Divider, Button, Input, message, Switch, Pagination, Radio, Segmented, Select, Checkbox } from "antd";
import {
  PictureTwoTone,
  UnorderedListOutlined,
  MinusOutlined,
  UpOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { useTypedDispatch, useTypedSelector } from "../../redux/hooks";

import { setHeaderTitle } from "../../redux/slices/layoutSlice";
import { addCartItem } from "../../redux/slices/cartSlice";

import Product from "./Product";

import "../../styles/Card.scss";
import "./Catalog.scss";
import { useHistory } from "react-router-dom";
import { shallowEqual } from "react-redux";
import useProductInfoService from "../EditPatternPage/MainInfoBlock/useProductInfoService";
import { setProducts } from "../../redux/slices/handbookSlice";
import ProductRoomDescription from "./ProductRoomDescription";

export interface ICatalogProps { }


export default function Catalog(props: ICatalogProps) {
  const dispatch = useTypedDispatch();
  const { updateProductInfo } = useProductInfoService();
  const [visible, setVisible] = useState(false);
  const [rooms, setRooms] = useState<any>([]);
  const [productRoomDesc, setProductRoomDesc] = useState(null)

  const [radioFilterOption, setRadioFilterOptions] = useState<any[]>([
    { label: "Все", value: "all" }])
  const handbookRedux = useTypedSelector((state) => state.handbook, shallowEqual);
  // @ts-ignore
  const cartRedux = useTypedSelector((state) => state.cart);
  //const handbookSlice = useTypedSelector((state) => state.handbook);
  interface IProducts {
    ref: string;
    full_name: string;
    amount: number;
    price: number | null;
    unit?: string;
    id?: number;
    info: any;
    room_desc: any;
    enable: 0 | 1;
  }
  const [filtered, setFiltered] = useState<IProducts[]>([]);
  const [role, setRole] = useState<any>("d_user");
  const [listView, setListView] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [searchData, setSearchData] = useState<string | null>("");
  const [searchTrigger, setSearchTrigger] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(undefined);
  const [pageSize, setPageSize] = useState<number>(50);
  const [filterParentId, setFilterParentId] = useState("all")
  const [filterSort, setFilterSort] = useState<string | number>("DESC")
  const [filterBy, setFilterBy] = useState<string | number>("full_name")
  const [onPriceFilter, setOnPriceFilter] = useState<boolean>(false)
  const [onPortalFilter, setOnPortalFilter] = useState<boolean>(false)
  useEffect(() => {
    dispatch(setHeaderTitle("Каталог товаров"));

    setRole(JSON.parse(localStorage.getItem("user")).capabilities);
  }, []);

  // useEffect(() => {
  //   if (cartRedux.cartItems.length > 0 && filtered.length > 0) {
  //     const outer = filtered.map((out) => {
  //       const found = cartRedux.cartItems.find((f: any) => f.ref === out.ref);
  //       if (found) {
  //         return {
  //           ...out,
  //           amount: found.amount,
  //         };
  //       } else {
  //         return out;
  //       }
  //     });
  //     console.log("otter", outer);

  //     setFiltered(outer);
  //   }
  // }, []);
  useEffect(() => {
    const filterBy_ = localStorage.getItem("filterBy");
    const filterSort_ = localStorage.getItem("filterSort");
    console.log("Filter sort", filterSort_);
    console.log("Filter by", filterBy_);

    if (filterBy_)
      setFilterBy(filterBy_)
    if (filterSort_)
      setFilterSort(filterSort_)
  }, [])

  const updateProducts = (newProductInfo) => {
    dispatch(setProducts(handbookRedux.products.map(item => {
      if (item.id === Number(newProductInfo.product_id || 0)) {
        return {
          ...item,
          info: newProductInfo
        }
      }
      return item;
    })))
  }
  const updateProductsDesc = (newRoomDesc) => {
    dispatch(setProducts(handbookRedux.products.map(item => {
      if (item.id === Number(newRoomDesc.product_id || 0)) {
        return {
          ...item,
          room_desc: item.room_desc.map(item => {
            if (item.id === newRoomDesc.id)
              return newRoomDesc
            return item;
          })
        }
      }
      return item;
    })))
    setFiltered(filtered.map(item => {
      if (item.id === Number(newRoomDesc.product_id || 0)) {
        return {
          ...item,
          room_desc: item.room_desc.map(item => {
            if (item.id === newRoomDesc.id)
              return newRoomDesc
            return item;
          })
        }
      }
      return item;
    }))
  }

  useEffect(() => {


    const timeout = setTimeout(() => {
      const body: any = {
        filters: [
          {
            field: "name",
            comparison: "like",
            value: searchData,
            condition: "and",
          },
        ],
      };
      if (handbookRedux.d_isUser) {
        body.filters.push({
          field: "enable",
          comparison: "=",
          value: "1",
          condition: "and",
        });
        body.filters.push({
          field: "price",
          comparison: "!=",
          value: "0",
          condition: "and",
        });
      }
      if (filterParentId != "all") {
        body.filters.push({
          field: "parent",
          comparison: "=",
          value: filterParentId,
          condition: "and",
        });
      }
      if (onPortalFilter) {
        body.filters.push({
          field: "enable",
          comparison: "=",
          value: 1,
          condition: "and",
        });
      }
      if (onPriceFilter) {
        body.filters.push({
          field: "pricelist_enable",
          comparison: "=",
          value: 1,
          condition: "and",
        });
      }

      fetch(`${SiteUrlDealer}/product_with_filter?limit=${pageSize ? pageSize : "50"}&page=${page}&order_by=${filterBy}${filterSort === "none" ? "" : "&order_type=" + filterSort}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""}`,
        },
        body: JSON.stringify(body),
        method: "POST",
      })
        .then((res) => res.json())
        .then((json) => {
          console.log(json);
          //const defaultFiltered = json.products.filter((f: any) => f.price != 0 && f.price != null);

          // if (handbookRedux.d_isManager) {
          //   // setFiltered(defaultFiltered);
          //   return defaultFiltered;
          // }
          // if (handbookRedux.d_isUser) {
          //   const filteredForUser = defaultFiltered.filter((f: any) => f.enable === 1);
          //   // setFiltered(filteredForUser);
          //   return filteredForUser;
          // }
          setTotal(json.count);
          return json;
        })
        .then((json) => {
          const data = json.products;
          const categories = json.product_categories;
          if (cartRedux.cartItems.length > 0 && data.length > 0) {
            const outer = data.map((out: any) => {
              const found = cartRedux.cartItems.find((f: any) => f.ref === out.ref);
              if (found) {
                return {
                  ...out,
                  amount: found.amount,
                };
              } else {
                return out;
              }
            });
            setFiltered(outer);
          } else {
            setFiltered(data);

          }
        });
    }, 300)
    return () => clearTimeout(timeout);
  }, [searchTrigger, role, page, pageSize, filterSort, filterBy, onPortalFilter, onPriceFilter]);
  const handleRadioButton = (value: any) => {
    console.log(value);
    setFilterParentId(value)
    setSearchTrigger(!searchTrigger);
  }

  useEffect(() => {
    fetch(`${SiteUrlDealer}/product_categies`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""}`,
      },
      method: "GET",
    })
      .then((res) => res.json())
      .then((json) => {
        setRadioFilterOptions([{ label: "Все", value: "all" }, ...json.filter(item => !item.label.toLowerCase().includes("архив"))]);
      })
  }, []);
  useEffect(() => {
    fetch(`${SiteUrlDealer}/product_rooms`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""}`,
      },
      method: "GET",
    })
      .then((res) => res.json())
      .then((json) => {

        setRooms(json)
      })
  }, []);

  return (
    <div className="catalog">
      <div className="catalog__search-container ">
        <Input
          placeholder="Поиск по названию"
          allowClear
          style={{ marginRight: 10 }}
          onChange={(e) => {
            setSearchData(e.target.value);
            setSearchTrigger(!searchTrigger);
          }}
          className="catalog__search"
        />
        <Select
          style={{ width: 100, marginRight: 10 }}
          value={filterBy}

          onChange={(value) => {
            setFilterBy(value);
            localStorage.setItem("filterBy", value + "");
          }
          }
          options={[
            {
              value: "price",
              label: "Цена"
            },
            {
              value: "full_name",
              label: "Имя"
            }
          ]}
        />
        <Segmented
          value={filterSort}
          onChange={(value) => {
            setFilterSort(value);
            localStorage.setItem("filterSort", value + "");
          }}
          options={[
            // {
            //   label: '',
            //   value: 'none',
            //   icon: <MinusOutlined />,
            // },
            {
              label: '',
              value: 'ASC',
              icon: <UpOutlined />,
            },
            {
              label: '',
              value: 'DESC',
              icon: <DownOutlined />,
            },
          ]}
        />
        {/* <Button
          type={listView ? "dashed" : "text"}
          shape="default"
          icon={<UnorderedListOutlined />}
          onClick={() => setListView(!listView)}
          disabled={false}
          style={{
            marginLeft: window.screen.width > 450 ? 20 : 10,
            borderRadius: 8,
          }}
        /> */}
        <div style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 15
        }}>

          <Checkbox value={onPortalFilter} onChange={(e) => setOnPortalFilter(e.target.checked)}>На портале</Checkbox>
          <Checkbox value={onPriceFilter} onChange={(e) => setOnPriceFilter(e.target.checked)}>В прайсе</Checkbox>
        </div>
      </div>
      {window.screen.width > 450 && handbookRedux.d_isAdminManager && <Card
        key={0}
        style={{ marginBottom: 20 }}
        bordered={false}
        className="card catalog__product product-hide hide"
      // onClick={(e) => history.push(`/order-${itm.id}`, { data: itm })}
      >

        <div className="catalog__switch" style={{ visibility: "visible", zIndex: 111, width: "100%" }}>
          <div style={{ display: "flex", height: "100%", alignItems: "center", fontSize: 16, marginRight: -10 }}>
            <div className="over-shadow" style={{
              display: "flex",
              background: "#fefefeff",
              width: "100%",
              padding: 10,
              borderRadius: 10,
              alignItems: "center",
              justifyContent: "center"
            }}>
              <div style={{ paddingRight: 15, borderRight: "1px solid #1890ff77" }}>Прайс</div>
              <div style={{ marginLeft: 15 }}>Портал</div>
            </div>
          </div>
        </div>
      </Card>}

      <Radio.Group
        options={radioFilterOption}
        style={{ paddingLeft: 10, marginTop: 10 }}
        onChange={(e: any) => handleRadioButton(e.target.value)}
        optionType="button"
        defaultValue={"all"}
        buttonStyle="solid"
      />

      <div className={listView ? "catalog__list-product-container" : "catalog__product-container"}>



        {filtered.map((itm) => {
          return (
            <Product
              productRoomDesc={productRoomDesc}
              setProductRoomDesc={setProductRoomDesc}
              setVisible={setVisible}
              key={itm.ref}
              filtered={filtered}
              itm={itm}
              role={role}
              listView={listView}
              updateProducts={updateProducts}
              updateProductInfo={updateProductInfo} />
          );
        })}
      </div>
      {total !== undefined && (
        <Pagination
          style={{ width: "fit-content", marginTop: 20, marginLeft: "auto" }}
          defaultCurrent={1}
          total={total}
          current={page}
          showSizeChanger
          pageSize={pageSize}
          onShowSizeChange={(page: any, size: any) => {
            setPageSize(size);
          }}
          onChange={(p: any) => {
            setPage(p);
          }}
        />
      )}
      <ProductRoomDescription
        updateProductsDesc={updateProductsDesc}
        rooms={rooms}
        productRoomDesc={productRoomDesc}
        setProductRoomDesc={setProductRoomDesc}
        setVisible={setVisible}
        visible={visible}
      />
    </div>
  );
}
