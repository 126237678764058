import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface LayoutState {
  cartItems: any;
  coItems: any;
  showCart: boolean;
}

const initialState: LayoutState = {
  cartItems: [],
  coItems: null,
  showCart: false,
};

export const cartSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    addCartItem: (state, action: PayloadAction<any>) => {
      localStorage.setItem("cart", JSON.stringify(action.payload));
      state.cartItems = action.payload;
      //   const isOld = state.cartItems.find((item: any) => item.id === action.payload.id);
      //   let edited: any = [];
      //   if (isOld) {
      //     const items = state.cartItems.map((item: any) => {
      //       if (item.id === isOld.id) {
      //         return {
      //           ...item,
      //           qty: action.payload.qty,
      //         };
      //       }
      //       return item;
      //     });
      //     edited = items;
      //   } else {
      //     edited = [...state.cartItems, action.payload];
      //   }
      //   state.cartItems = edited;
    },
    addCoItems: (state, action: PayloadAction<any>) => {
      state.coItems = action.payload
    },
    showHideCart: (state, action: PayloadAction<any>) => {
      state.showCart = !action.payload;
    },
  },
});

export const { addCartItem, showHideCart, addCoItems } = cartSlice.actions;

export default cartSlice.reducer;
