import React, { useState, useEffect, useMemo } from "react";
import { Input, AutoComplete, Button, Divider, Select } from "antd";
import { SearchOutlined, MinusOutlined, PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { useTypedDispatch, useTypedSelector } from "../../redux/hooks";

import "../../styles/Card.scss";
// import "./CreateOrder.scss";

export interface IOneProduct {
  // ref?: string;
  ref_item: string;
  ref_org?: string;
  name: string;
  qty: string | number;
  sum: number | null;
  id?: number;
  price?: number;
  custom_price: boolean;
  custom_sum: boolean;
  default_price?: number;
}

interface ICreateOrderListItemProps {
  itm: IOneProduct;
  ind: number;
  fullData: IOneProduct[];
  setParentData: any;
  disabled: boolean;
  addTrigger: boolean;
  setAddTrigger: any;
  productsMode: "classic" | "prefill";
  setModifedProduct: any;
  modifedProduct: any;
  setTableData: any;
  tableData: any;
}

export default function CreateOrderListItem(props: ICreateOrderListItemProps) {
  const handbookRedux = useTypedSelector((state) => state.handbook);
  const [num, setNum] = useState<any>("init");
  const [numTrigger, setNumTrigger] = useState<boolean>(false);
  const [products, setProducts] = useState(handbookRedux.products);
  const [customPriceTrigger, setCustomPriceTrigger] = useState<boolean>(false);
  const [customPrice, setCustomPrice] = useState<number | "init">("init");
  const [customSumTrigger, setCustomSumTrigger] = useState<boolean>(false);
  const [customSum, setCustomSum] = useState<number | "init">("init");

  useEffect(() => {
    // удаление дупликтов
    // const filteredProducts = handbookRedux.products.filter((filtered) => {
    //   if (props.fullData.find((found) => found.ref_item === filtered.ref)) {
    //     return false;
    //   } else {
    //     return true;
    //   }
    // });
    const filteredProducts = handbookRedux.products;

    const noZeroPriceProds = filteredProducts.filter((f: any) => f.price != 0 && f.price != null);

    if (handbookRedux.d_isManager || handbookRedux.d_isAdminManager) {
      setProducts(noZeroPriceProds);
    }

    if (handbookRedux.d_isUser) {
      const filteredForUser = noZeroPriceProds.filter((f) => f.enable === 1);
      setProducts(filteredForUser);
    }
  }, [handbookRedux.products]);

  useEffect(() => {
    if (props?.itm?.qty != undefined) {
      console.log("Set item qty", props.itm.qty);

      setNum(props.itm.qty);
    }
  }, [props?.itm?.qty])

  useEffect(() => {
    if (num === "init") return;
    const newArr = props.fullData.map((obj) => {
      if (obj.ref_item == props.itm.ref_item) {
        return {
          ...obj,
          qty: num,
        };
      } else {
        return obj;
      }
    });
    console.log(12312, newArr);

    props.setParentData(newArr);
    props.setAddTrigger(!props.addTrigger);
  }, [numTrigger]);

  useEffect(() => {
    // console.log(props.itm.price);
    setNum(props.itm.qty);
    setCustomPrice(props.itm.price === null ? 0 : props.itm.price);
    setCustomSum(props.itm.sum === null ? 0 : props.itm.sum);
  }, [props.itm]);

  useEffect(() => {
    if (customPrice === "init") return
    if (props.itm.ref_item.includes("temporary")) return;

    const timeout = setTimeout(() => {
      props.setAddTrigger(!props.addTrigger);
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [customPriceTrigger, customSumTrigger]);

  function handleKeyboardInput(e: any): void {
    const value = e.target.value.replace(/\D/g, "");
    if (value < 1000) {
      setNum(value);
      setNumTrigger(!numTrigger);
    }
  }

  function handleMinus() {
    if (num > 0) {
      if ((+num - 1) === 0) {
        props.setTableData(props.tableData.filter((item) => props.modifedProduct.includes(item.ref_item)))
      }
      setNum(+num - 1);
      setNumTrigger(!numTrigger);
    }
  }

  function handlePlus() {

    if (num < 999) {
      if (num === "init") {
        setNum(2);
      } else {
        setNum(+num + 1);
      }
      setNumTrigger(!numTrigger);
    }
  }

  function handleSelectProduct(value: string, fullData: any) {
    const newArr = props.fullData.map((obj) => {
      if (obj.ref_item == props.itm.ref_item) {
        return {
          // id: ???
          name: fullData.children,
          qty: obj.qty,
          ref_item: value,
          sum: obj.sum,
          custom_price: false,
          custom_sum: false,
          default_price: obj.default_price,
        };
      } else {
        return obj;
      }
    });
    props.setParentData(newArr);
    props.setAddTrigger(!props.addTrigger);
  }

  const handleManualPriceInput = (val: string) => {
    const reg = /^-?\d*(\.\d*)?$/;
    if (reg.test(val) || val === "" || val === "-") {
      setCustomPrice(+val);
      const newArr = props.fullData.map((obj) => {
        if (obj.ref_item == props.itm.ref_item) {
          return {
            ...obj,
            price: +val,
            custom_price: true,
            custom_sum: false,
            default_price: obj.default_price,
          };
        } else {
          return obj;
        }
      });

      props.setParentData(newArr);
      setCustomPriceTrigger(!customPriceTrigger);
    }
  };
  const deleteProduct = () => {
    // props.onDelete();
    const deleted = props.fullData.filter((dataItem) => dataItem.ref_item != props.itm.ref_item);
    props.setParentData(deleted);
    props.setAddTrigger(!props.addTrigger);
  }
  const handleManualSumInput = (val: string) => {
    const reg = /^-?\d*(\.\d*)?$/;
    // const reg = /^[0-9]*\.?[0-9]*$/;
    if (reg.test(val) || val === "" || val === "-") {
      setCustomSum(+val);
      const newArr = props.fullData.map((obj) => {
        if (obj.ref_item == props.itm.ref_item) {
          return {
            ...obj,
            sum: +val,
            custom_sum: true,
            custom_price: false,
            default_price: obj.default_price,
          };
        } else {
          return obj;
        }
      });
      props.setParentData(newArr);
      setCustomSumTrigger(!customSumTrigger);
    }
  };

  return (
    <div>
      <div className="create-order__item">
        <Button
          className="create-order__delete"
          type="text"
          shape="circle"
          icon={<DeleteOutlined />}
          size="middle"
          style={{
            opacity: props.fullData.length == 1 ? 0.3 : 1,
            pointerEvents: props.fullData.length == 1 ? "none" : "auto",
          }}
          onClick={deleteProduct}
        />
        <div
          className={`create-order__item-grid ${(handbookRedux.d_isManager || handbookRedux.d_isAdminManager)
            ? "create-order__item-grid--manager"
            : "create-order__item-grid--dealer"
            }`}
        >
          <div style={{ display: 'flex', alignItems: "center" }}>


            <div className="create-order__select">
              <Select
                showSearch
                // allowClear
                style={{ width: "100%" }}
                placeholder="Поиск по товарам"
                optionFilterProp="children"
                onChange={(a, b) => handleSelectProduct(a, b)}
                disabled={props.productsMode === "prefill" ? false : props.disabled}
                filterOption={(input, option) =>
                  // @ts-ignore
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                getPopupContainer={(trigger) => trigger.parentNode}
                value={!props.itm.ref_item.includes("temporary") ? props.itm.ref_item : undefined}
              // defaultValue={!props.itm.ref_item.includes("temporary") ? props.itm.name : undefined}
              >
                {products.map((itm) => (
                  <Select.Option
                    key={itm.ref}
                    value={itm.ref}
                    style={{ width: "100%" }}
                  // className="create-order__select"
                  >
                    {itm.name}
                  </Select.Option>
                ))}
              </Select>

            </div>
            {(!(handbookRedux.d_isManager || handbookRedux.d_isAdminManager) && window.screen.width > 450) && <>
              {props.itm.default_price && <div className="create-order__select__price" style={props.itm.price ? { textDecoration: "line-through", color: "#afafaf" } : {}}>{formatMoney(props.itm.default_price)}&nbsp;₽</div>}
              {props.itm?.price > 0 && <div className="create-order__select__disc">{formatMoney(props.itm.price)}&nbsp;₽</div>}
            </>}
          </div>
          <div className="create-order__empty-top"></div>

          <div
            // className="create-order__number"
            className={`create-order__number ${(handbookRedux.d_isManager || handbookRedux.d_isAdminManager)
              ? "create-order__number--manager"
              : "create-order__number--manager"
              }`}
          >
            <Input

              prefix={
                <Button
                  type="text"
                  shape="circle"
                  size="small"
                  icon={<MinusOutlined />}
                  onClick={handleMinus}
                  disabled={props.productsMode === "prefill" ? false : props.disabled}
                />
              }
              suffix={
                <Button
                  type="text"
                  shape="circle"
                  size="small"
                  icon={<PlusOutlined />}
                  onClick={handlePlus}
                  disabled={props.productsMode === "prefill" ? false : props.disabled}
                />
              }
              style={{ width: 120, textAlign: "center" }}
              size="middle"
              // defaultValue="1"
              value={num}
              disabled={props.productsMode === "prefill" ? false : props.disabled}
              onChange={handleKeyboardInput}
              className="num-picker"
              onBlur={() => {
                if (num === "") {
                  setNum(1);
                }
              }}
            />
            {((handbookRedux.d_isManager || handbookRedux.d_isAdminManager) || window.screen.width < 450) && <div style={{ display: "flex" }}>
              {props.itm.default_price && <div className="create-order__select__price" style={props.itm.price ? { textDecoration: "line-through", color: "#afafaf" } : {}}>{formatMoney(props.itm.default_price)}&nbsp;₽</div>}
              {props.itm?.price > 0 && <div className="create-order__select__disc">{formatMoney(props.itm.price)}&nbsp;₽</div>}
            </div>}
          </div>

          <div
            className={`create-order__item-price create-order__money-input ${(handbookRedux.d_isManager || handbookRedux.d_isAdminManager)
              ? "create-order__money-input--manager"
              : "create-order__money-input--dealer"
              }`}
          >
            {/* {handbookRedux.d_isManager ? ( */}
            <Input
              prefix={<div style={{ width: 42 }}>Цена</div>}
              suffix={<div>&nbsp;₽</div>}
              size="middle"
              // value={props.itm.sum === null ? 0 : props.itm.sum}
              maxLength={7}
              value={customPrice}
              disabled={props.productsMode === "prefill" ? false : props.disabled}
              onChange={(e) => handleManualPriceInput(e.target.value)}
            />
          </div>

          {!(!(handbookRedux.d_isManager || handbookRedux.d_isAdminManager) && window.screen.width > 450) && <div className="create-order__empty-bottom"></div>}

          <div
            className={`create-order__item-total create-order__money-input ${(handbookRedux.d_isManager || handbookRedux.d_isAdminManager)
              ? "create-order__money-input--manager"
              : "create-order__money-input--dealer"
              }`}
          >
            {/* {handbookRedux.d_isManager ? ( */}
            <Input
              prefix={<div style={{ width: 42 }}>Сумма</div>}
              suffix={<div>&nbsp;₽</div>}
              size="middle"
              // value={props.itm.sum === null ? 0 : props.itm.sum}
              maxLength={7}
              value={customSum}
              disabled={props.productsMode === "prefill" ? false : props.disabled}
              onChange={(e) => handleManualSumInput(e.target.value)}
            />
          </div>
        </div>
      </div>
      {props.ind !== props.fullData.length - 1 && <Divider />}
    </div>
  );
}
