import { Select, message } from 'antd';
import React, { useEffect, useState } from 'react';
import EntityItem from './EntityItem';

const Entity = ({ item, form, formValues, setFormValues, default_entity_id }) => {
	const [entities, setEntities] = useState([]);
	const [loadedEntities, setLoadedEntities] = useState([]);
	const [trigger, setTrigger] = useState(false);

	useEffect(() => {
		setEntities(form.getFieldValue(item.key) ?? []);
	}, []);

	useEffect(() => {
		fetch(SiteUrlDealer + '/pattern_entity', {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${
					localStorage.getItem('accessToken')
						? localStorage.getItem('accessToken')
						: ''
				}`,
			},
			method: 'GET',
		})
			.then((res) => res.json())
			.then((response) => {
				console.log('response', response);
				setLoadedEntities(
					response.filter(
						(entities) => String(entities.group_id) == String(item.group_id)
					)
				);
			})
			.catch((e) => {
				console.log('ERROR', e);
				message.error('Не удалось загрузить блоки');
			})
			.finally(() => {});
	}, []);

	const onChange = (value) => {
		const newEntity = loadedEntities.find((item) => item.id === value);
		const checkEntity = entities.find((item) => item.id === value);
		if (checkEntity) return;
		setEntities([...entities, newEntity]);
		setTrigger(!trigger);
	};
	useEffect(() => {
		if(default_entity_id && form.getFieldValue(item.key)?.length == 0){

			loadedEntities.forEach((loadedEntity) => {
				if (loadedEntity.id == default_entity_id) {
					onChange(default_entity_id);

				}
			});
		}
	}, [loadedEntities]);

	useEffect(() => {
		form.setFieldsValue({ [item.key]: entities });
		setFormValues({ ...formValues, [item.key]: entities });
	}, [trigger]);

	const updateEntity = (updatedEntity) => {
		if (
			updatedEntity?.body?.blocks?.[0]?.data?.text == null &&
			updatedEntity?.body?.blocks?.[0]?.type == 'text'
		) {
			setEntities(entities.filter((item) => item.id != updatedEntity.id));
		} else {
			setEntities(
				entities.map((item) => {
					if (updatedEntity.id == item.id) return updatedEntity;
					return item;
				})
			);
		}
		setTrigger(!trigger);
	};
	return (
		<div>
			<Select
				options={loadedEntities.map((item) => {
					return {
						label: item.name,
						value: item.id,
					};
				})}
				placeholder="Выберите блок"
				style={{
					maxWidth: 370,
				}}
				value={null}
				onChange={onChange}
			/>

			{entities &&
				entities.map((entity) => {
					return (
						<EntityItem
							key={entity.id}
							updateEntity={updateEntity}
							entity={entity}
						/>
					);
				})}
		</div>
	);
};

export default Entity;
