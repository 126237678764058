import { Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { getFormatedValue } from '../lib';

const MultiplicationItem = ({ item, form, formValues, setFormValues }) => {
    const [value, setValue] = useState(0);

    useEffect(() => {
        const value1 = formValues[item.fields[0]] ?? 0
        const value2 = formValues[item.fields[1]] ?? 0
        console.log(item.fields, value1, value2);
        const result = (value1 * value2).toFixed();
        setValue(result);
        form.setFieldsValue({ [item.key]: result })
        setFormValues({ ...formValues, [item.key]: result })
    }, [formValues[item.fields[0]], formValues[item.fields[1]]])

    return (
        <Input
            id={`${item.key}`}
            onChange={(e) => {
                e.stopPropagation();
            }}
            value={value}
            addonBefore={getFormatedValue(item?.addonBefore)}
            addonAfter={getFormatedValue(item?.addonAfter)}
            placeholder={item?.placeholder}
        />
    );
};

export default MultiplicationItem;