import { message, Popconfirm, Select } from 'antd';
import Button from 'antd/lib/button';
import Divider from 'antd/lib/divider';
import Input from 'antd/lib/input';
import Space from 'antd/lib/space';
import React, { useEffect, useRef, useState } from 'react';
import { createSphere, deleteSphere, getSpheres } from '../../ProjectService';
import { PlusOutlined } from "@ant-design/icons";

const SelectSphere = ({ sphere, setSphere, d_isManager }) => {
    const [spheres, setSpheres] = useState([])
    const inputRef = useRef(null);
    const [sphereInput, setSphereInput] = useState("")
    useEffect(() => {
        getSpheres().then(response => {
            setSpheres(response.data);
        })
    }, [])
    const addSphere = () => {
        createSphere({ title: sphereInput }).then((response) => {
            setSphereInput('');
            setSpheres([...spheres, response.data])
            setTimeout(() => {
                inputRef.current?.focus();
            }, 0);
        }).catch(e => {
            console.log(e);
            message.error("Не удалось добавить сферу")
        })
    }

    const onChangeSelect = (value) => {
        setSphere(value);
    }
    const removeSphere = (e, id) => {
        e.stopPropagation();
        // e.preventDefault();
        deleteSphere(id).then((response) => {
            setSpheres(spheres.filter(item => item.id !== id))

        }).catch(e => {
            console.log(e);
            message.error("Не удалось удалить сферу")
        })
    }
    return (
        <Select
            style={{ width: "100%" }}
            value={sphere}
            onChange={onChangeSelect}
            placeholder="Выбор сферы проекта"
            dropdownRender={(menu) => (
                <>
                    {menu}


                    {d_isManager && <>
                        <Divider style={{ margin: '8px 0' }} />
                        <Space style={{ padding: '0 8px 4px' }}>
                            <Input
                                placeholder="Создать новую сферу"
                                ref={inputRef}
                                value={sphereInput}
                                onChange={(e) => setSphereInput(e.target.value)}
                            />
                            <Button type="text" icon={<PlusOutlined />} onClick={addSphere}>
                                Добавить
                            </Button>
                        </Space></>}
                </>
            )}

        >
            {spheres.map(item => {
                return <Select.Option key={item.id} label={item.title} value={item.id}>
                    <div className='delete-sphere-select'>

                        {item.title}
                        {sphere !== item.id && d_isManager && <Button
                            onClick={(e) => removeSphere(e, item.id)}
                            className='delete-sphere-button'
                            type="link"
                            size="small">Удалить</Button>}
                    </div>
                </Select.Option>
            })}
        </Select>
    );
};

export default SelectSphere;