import {
	EyeInvisibleOutlined,
	EyeOutlined,
	FormatPainterOutlined,
	MinusOutlined,
	PlusOutlined,
} from '@ant-design/icons';
import { Button, Input, Spin, Tooltip } from 'antd';
import React, { useRef, useState } from 'react';
import DivInput from '../DivInput/DivInput';
import UploadFile from '../UploadFile';
import './HeaderBlockCard.scss';
import { HexColorPicker } from 'react-colorful';

const HeaderBlockCard = ({ readOnly, data, onDataChange }) => {
	const [readOnlyEdit, setReadOnlyEdit] = useState(false);
	const [imageState, setImageState] = useState(data?.image);
	const [imageSize, setImageSize] = useState(data?.imageSize);
	const [backgroundColor, setBackgroundColor] = useState(data?.backgroundColor ?? '#81A9B0');
	const [textColor, setTextColor] = useState(data?.textColor ?? '#ffffff');
	const values = useRef(data);
	const onChangeBackground = (value) => {
		updateData("backgroundColor", value)
		setBackgroundColor(value)
	}
	const onChangeTextColor = (value) => {
		setTextColor(value)
		updateData("textColor", value)
	}
	const updateData = (key, value) => {
		const newData = {
			...values.current,
			[key]: value,
		};
		onDataChange(newData);
		values.current = newData;
		//setValues(newData)
	};
	const changeReadOnly = () => {
		setReadOnlyEdit(!readOnlyEdit);
	};
	const isReadOnly = () => {
		return readOnlyEdit || readOnly;
	};

	const setImage = (image) => {
		setImageState(image);
		updateData('image', image);
	};

	const plusImageSize = () => {
		if (values.current.imageSize < 5) {
			updateData('imageSize', values.current.imageSize + 1);
			setImageSize(values.current.imageSize + 1);
		}
	};
	const minusImageSize = () => {
		if (values.current.imageSize > 1) {
			updateData('imageSize', values.current.imageSize - 1);
			setImageSize(values.current.imageSize - 1);
		}
	};

	const getImageSize = () => {
		switch (values.current.imageSize) {
			case 0:
				return 60;
			case 1:
				return 100;
			case 2:
				return 140;
			case 3:
				return 200;
			case 4:
				return 250;
			case 5:
				return 300;
		}
	};

	return (
		<>
			<div
				className="header-block"
				style={{ background: backgroundColor ? backgroundColor : '#81A9B0' }}
			>
				{!readOnly && (
					<>
						<div style={{ position: 'absolute', right: 50, top: 16 }}>
							<Tooltip
								title={
									<>
										<HexColorPicker
											style={{
												marginRight: 16,
												width: 128,
												height: 128,
											}}
											color={backgroundColor}
											onChange={onChangeBackground}
										/>
										<Input
											style={{ width: 120, marginTop: 8 }}
											value={backgroundColor}
											onChange={(event) => {
												onChangeBackground(event.target.value);
											}}
										/>
									</>
								}
							>
								<FormatPainterOutlined
									style={{ fontSize: 24, color: '#1890ff' }}
								/>
							</Tooltip>
						</div>
						<Button
							onClick={changeReadOnly}
							type="link"
							icon={
								isReadOnly() ? <EyeInvisibleOutlined /> : <EyeOutlined />
							}
						/>
					</>
				)}
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<div
						className="header-block__image"
						style={{
							width: getImageSize(),
							height: getImageSize() / 3,
							borderRadius: imageState?.link
								? '1px solid #FFFFFF00'
								: '1px solid rgb(218, 218, 218, 0.4)',
						}}
					>
						{imageState?.status == 'uploading' ? (
							<Spin />
						) : (
							<>
								{imageState?.link && (
									<img
										src={imageState.link}
										alt="image"
										width={'100%'}
										height={'100%'}
									/>
								)}
							</>
						)}
					</div>
				</div>
				<div className="header-block__text">
					{/* <div className="header-block__header">
						<DivInput
							containerStyle={{ padding: 0, margin: 0 }}
							onInput={(value) => updateData('headerText', value)}
							onChangeSize={(value) => updateData('headerSize', value)}
							isReadOnly={isReadOnly()}
							value={values.current.headerText}
							fontSize={values.current.headerSize}
						/>
					</div> */}
					<div className="header-block__description">
						<DivInput
							extraNode={
								<Tooltip
									title={
										<>
											<HexColorPicker
												style={{
													marginRight: 16,
													width: 128,
													height: 128,
												}}
												textColor
												onChange={onChangeTextColor}
											/>
											<Input
												style={{ width: 120, marginTop: 8 }}
												value={textColor}
												onChange={(event) => {
													onChangeTextColor(event.target.value);
												}}
											/>
										</>
									}
								>
									<FormatPainterOutlined style={{ color: '#1890ff' }} />
								</Tooltip>
							}
							containerStyle={{ color: textColor }}
							onInput={(value) => updateData('description', value)}
							onChangeSize={(value) => updateData('descriptionSize', value)}
							isReadOnly={isReadOnly()}
							value={values.current.description}
							fontSize={values.current.descriptionSize}
						/>
					</div>
				</div>
			</div>
			{!isReadOnly() && (
				<div
					style={{
						marginLeft: 16,
						marginTop: 8,
					}}
				>
					{' '}
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							flexDirection: 'row',
							marginBottom: 10,
						}}
					>
						<div style={{ marginRight: 5 }}>Размер</div>
						<Button
							style={{ marginRight: 5 }}
							shape="circle"
							icon={<PlusOutlined />}
							onClick={plusImageSize}
						/>
						<Button
							style={{ marginRight: 16 }}
							shape="circle"
							icon={<MinusOutlined />}
							onClick={minusImageSize}
						/>
					</div>
					<UploadFile setImage={setImage} />
				</div>
			)}
		</>
	);
};

export default HeaderBlockCard;
