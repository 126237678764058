import { Button, Input, message, Typography } from 'antd';
import React, { useState } from 'react';

interface Props {
    setStage: any;
    setCompanyInfo: any;
}

export const defaultCompanyInfo = {
    "name": "",
    "full_name": "",
    "inn": "",
    "kpp": "",
    "jur_address": "",
    "fact_address": "",
    "jurfiz": "",
    "city": "",
    "location": null,
    "founded": true
}

const InnInput = ({ setStage, setCompanyInfo }: Props) => {
    const [inn, setInn] = useState<string>('')
    const [innLoad, setInnLoad] = useState(false)
    const [error, setError] = useState(false)
    const getCompanyInfo = () => {
        if (inn.length === 0) {
            setError(true);
            return;
        }
        setInnLoad(true);
        fetch(`${SiteUrlDealer}/organization_info/${inn}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""
                        }`,
                },
            })
            .then((res) => res.json())
            .then((json) => {
                if (json?.founded) {
                    setCompanyInfo(json);
                    setStage(1);
                } else {
                    throw "error"
                }
            })
            .catch(e => {
                console.log("Dont get cuty");
                message.error("Не удалось найти компанию!");
            }).finally(() => {
                setInnLoad(false);

            });
    }
    const changeInn = (event) => {
        const value = event.target.value;
        setError(false);
        setInn(value);
    }

    const goToNext = () => {
        setCompanyInfo(defaultCompanyInfo)
        setStage(1);
    }
    return (
        <div>
            <div className='companies-inn-auto'>
                <div className='companies-inn-auto__input'>
                    <Typography.Text className='companies-inn-auto__label'>
                        Введите ИНН
                    </Typography.Text>
                    <Input
                        status={error ? "error" : ""}
                        placeholder='ИНН'
                        onChange={changeInn}
                        value={inn} />
                </div>

                <Button
                    type='primary'
                    onClick={getCompanyInfo}
                    loading={innLoad}
                >
                    Далее
                </Button>
            </div>
            <Typography.Link
                onClick={goToNext}
            >
                Заполнить самостоятельно
            </Typography.Link>
        </div>
    );
};

export default InnInput;