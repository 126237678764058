import { EditOutlined } from '@ant-design/icons';
import { Button, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useSaveCOForm from '../../ConstructorPage/useSaveCOForm';
import EditMainBlockModal from './EditMainBlockModal';
import './MainBlock.scss';
import MainItem from './MainItem';


const MainInfoBlock = ({ mainInfo, trigger, entity, updateMainEntity, setEntity, updateEntityModal, updateEntity, isReadOnly }) => {
	const [visible, setVisible] = useState(false);

	const { formItems, loading } = useSaveCOForm({ withLoad: true });
	const [preparedFormItems, setPreparedFormItems] = useState([]);
	const [initialStage, setInitialStage] = useState(0);

	useEffect(() => {
		if (!loading) {
			let items = [];
			formItems.forEach((formItem) => {
				items = [...items, ...formItem.content];
			});
			setPreparedFormItems(items);
		}
		return () => setPreparedFormItems([]);
	}, [loading]);

	const getFormValue = (key) => {
		if (key == 'room_size')
			return {
				height: mainInfo?.['height'],
				square: mainInfo?.['square'],
				volume: mainInfo?.['volume'],
			};
		return mainInfo[key];
	};
	const checkValuesUnd = (content) => {
		let result = false;
		content.forEach((item) => {
			if (mainInfo[item.key] != undefined && mainInfo[item.key] != '') {
				result = true;
			}
		});
		return result || !isReadOnly;
	};
	const openMainEditModal = () => {
		setVisible(true);
		setInitialStage(0);
	};

	const openMainEditProductsModal = (stage) => {
		console.log('STAEGE', stage);
		setVisible(true);
		setInitialStage(stage);
	};

	const location = useLocation();
	useEffect(() => {
		if (location.pathname.includes('edit')) {
			openMainEditProductsModal(0);
		}
	}, []);
	return (
		<div className="main">
			{loading || preparedFormItems.length == 0 ? (
				<div className="main__spin">
					<Spin />
				</div>
			) : (
				<>
					{formItems.map((formItem, index) => {
						if (formItem.custom_render)
							return (
								<div key={index + '_'}>
									{!isReadOnly && (
										<div className="main__edit">
											<Button
												type="link"
												icon={<EditOutlined />}
												onClick={() =>
													openMainEditProductsModal(index)
												}
											/>
										</div>
									)}
									<div
										style={{
											marginBottom: -10,
										}}
										className="main__title"
									>
										Решение
									</div>
									<MainItem
										trigger={trigger}
										setEntity={setEntity}
										isReadOnly={isReadOnly}
										updateMainEntity={updateMainEntity}

										key={'nomenclature'}
										updateEntity={updateEntity}
										entity={entity}
										item={{
											key: "nomenclature",
											label: "",
											type: "nomenclature",
											extraValue: getFormValue("room_type"),

											value: getFormValue("nomenclature"),
										}}
									/>

									<MainItem
										trigger={trigger}
										setEntity={setEntity}
										updateMainEntity={updateMainEntity}
										isReadOnly={isReadOnly}
										key={
											preparedFormItems.find(
												(item) => item.type == 'room_type'
											).key
										}
										updateEntity={updateEntity}
										entity={entity}
										item={{
											...preparedFormItems.find(
												(item) => item.type == 'room_type'
											),
											value: getFormValue(
												preparedFormItems.find(
													(item) => item.type == 'room_type'
												).key
											),

											extraValue: getFormValue("nomenclature")

										}}
									/>
								</div>
							);
						return (
							<div key={index + '_'}>
								{checkValuesUnd(formItem.content) &&
									(formItem?.hideTitle != true || !isReadOnly) && (
										<div className="main__title">
											{formItem.title}
										</div>
									)}
								{!isReadOnly && (
									<div className="main__edit">
										<Button
											type="link"
											icon={<EditOutlined />}
											onClick={() =>
												openMainEditProductsModal(index)
											}
										/>
									</div>
								)}
								<div className="main__columns">
									{formItem.content
										.flat()
										.filter((item) => !item.custom_render)
										.filter(
											(item) =>
												getFormValue(item.key) != '' &&
												getFormValue(item.key) &&
												getFormValue(item.key) != null
										)
										.map((item, index, arr) => {
											return (
												<div key={index}>
													<MainItem
														trigger={trigger}
														setEntity={setEntity}
														index={index}
														length={arr.length - 1}
														isReadOnly={isReadOnly}
														key={item.key}
														updateEntity={updateEntity}
														updateMainEntity={updateMainEntity}
														entity={entity}
														item={{
															...item,
															value: getFormValue(item.key),
														}}
													/>
												</div>
											);
										})}
								</div>
							</div>
						);
					})}
				</>
			)}
			<EditMainBlockModal
				initialStage={initialStage}
				visible={visible}
				setVisible={setVisible}
				mainInfo={mainInfo}
				entity={entity}
				updateEntity={updateEntityModal}
			/>
		</div>
	);
};

export default MainInfoBlock;
