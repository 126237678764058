import React, { useState } from 'react';
import { Select } from "antd"

const ProductSelect = ({ products, onChange, item }) => {


    return (
        <Select
            showSearch
            // allowClear
            style={{ width: "100%" }}
            placeholder="Поиск по товарам"
            optionFilterProp="children"
            onChange={onChange}
            filterOption={(input, option) =>
                // @ts-ignore
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            value={item?.ref ?? null}
        // defaultValue={!props.itm.ref_item.includes("temporary") ? props.itm.name : undefined}
        >
            {products.filter(item => item.pricelist_enable).sort((a, b) => {
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                    return -1;
                }
                if (a.name.toLowerCase() > b.name.toLowerCase()) {
                    return 1;
                }
                return 0;
            }).map((itm) => (
                <Select.Option
                    key={itm.ref}
                    value={itm.ref}
                    style={{ width: "100%" }}
                // className="create-order__select"
                >
                    {itm?.full_name}
                </Select.Option>
            ))}
        </Select>
    );
};

export default ProductSelect;