import React, { useEffect, useState } from 'react';
import { Typography, Modal, Button, Select, Input, message } from "antd"
const ProductRoomDescription = ({ visible, updateProductsDesc, rooms, setVisible, productRoomDesc, setProductRoomDesc }) => {
    const [room, setRoom] = useState(1);
    const [loading, setLoading] = useState(false);
    const [roomDescItem, setRoomDescItem] = useState({
        room_id: 1,
        product_id: productRoomDesc?.id,
        description: ""
    });


    const setRoomItem = (room, productRoomDesc) => {
        const item = productRoomDesc?.room_desc?.find(item => item.room_id === room);
        console.log("SET ITEM item", productRoomDesc);
        if (item) {
            setRoomDescItem(item);

        } else {

            setRoomDescItem({
                room_id: room,
                product_id: productRoomDesc.id,
                description: ""
            })
        }

    }

    useEffect(() => {
        if (visible) {
            setRoomItem(room, productRoomDesc);


        }
    }, [visible])


    const onChangeDescription = (event) => {
        const value = event.target.value;
        setRoomDescItem({
            ...roomDescItem,
            description: value
        })
    }
    const onChangeRoom = (room) => {
        setRoom(room);
        setRoomItem(room, productRoomDesc);
    }

    const onOk = () => {
        setVisible(false)
    }
    const saveDescription = () => {
        setLoading(true);
        fetch(`${SiteUrlDealer}/product_description${roomDescItem?.id ? "/" + roomDescItem?.id : ""}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""}`,
            },
            body: JSON.stringify(roomDescItem),
            method: roomDescItem?.id ? "PUT" : "POST",
        })
            .then((res) => res.json())
            .then((json) => {
                message.success("Описание успешно сохранено!")
                updateProductsDesc(json)
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {
                setLoading(false)
            })

    }
    return (
        <Modal
            centered
            visible={visible}
            // visible={true}
            title={
                <Typography.Title level={4} style={{ marginBottom: 0 }}>
                    {productRoomDesc?.name}
                </Typography.Title>
            }
            bodyStyle={{
                display: "flex",
                flexDirection: "column"
            }}
            onCancel={onOk}
            closable={true}
            footer={
                <Button
                    onClick={onOk}>
                    Закрыть
                </Button>
            }
        >
            <Typography.Text
                style={{
                    fontSize: 15,
                    marginBottom: 3,
                }}
            >
                Комната
            </Typography.Text>
            <Select
                style={{
                    width: "100%",
                    maxWidth: 270
                }}
                onChange={onChangeRoom}
                value={room}
                options={rooms.map(room => {
                    return {
                        label: room.name,
                        value: room.id,
                    }
                })}
            />

            <Typography.Text
                style={{
                    marginTop: 15,
                    marginBottom: 3,
                    fontSize: 15
                }}>
                Описание товара
            </Typography.Text>
            <Input.TextArea
                value={roomDescItem.description}
                onChange={e => onChangeDescription(e)}
                placeholder={`Описания для команты ${rooms.find(item => item.id === room)?.name ?? ""}`}
            />
            <Button
                style={{
                    marginTop: 15,
                    width: 200
                }}
                type="primary"
                loading={loading}
                onClick={saveDescription}
            >
                Сохранить описание
            </Button>
        </Modal>
    );
};

export default ProductRoomDescription;