import React from 'react';
import { Button } from "antd"
import Contact from './Contact';
import SelectCreatedContact from './SelectCreatedContact';
import moment from 'moment';



const SelectContact = ({ contacts, user_id, setContacts }) => {
    const addContact = (contact) => {
        setContacts([...contacts, { ...contact }])
    }
    const deleteContact = (id) => {
        setContacts(contacts.filter(item => item.id !== id))
    }
    const updateContact = (contact) => {
        setContacts(contacts.map(item => {
            if (item.id === contact.id) return contact;
            return item;
        }))
    }

    const createContact = () => {
        const check = contacts.find(item => (item?.tmp_id != undefined && !(item.email.length > 0 || item.phone.length > 0)))
        if (check) {
            return;
        }
        setContacts([...contacts, {
            tmp_id: moment().valueOf(),
            id: moment().valueOf(),
            name: "",
            asygned_by_id: user_id,
            email: [],
            phone: []
        }])
    }
    return (
        <div>
            <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ width: "100%", marginRight: 10 }}>
                    <SelectCreatedContact addContact={addContact} />
                </div>
                <Button onClick={createContact}>Новый</Button>
            </div>
            {contacts.map(contact => {
                return <Contact
                    key={contact.id}
                    contact={contact}
                    updateContact={updateContact}
                    deleteContact={deleteContact} />
            })}
        </div>
    );
};

export default SelectContact;