import React, { useEffect, useState } from 'react';
import { Spin, Checkbox } from "antd"
import "./CreateOrder.scss"
interface Props {
    handlePrefill: any;
};
const LoadPrice = ({ handlePrefill }: Props) => {
    const [loading, setLoading] = useState(true);
    const [selected, setSelected] = useState([])
    const [radioFilterOption, setRadioFilterOptions] = useState<any[]>([
        { label: "Все", value: "all" }])

    useEffect(() => {
        setLoading(true);
        fetch(`${SiteUrlDealer}/product_categies`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""}`,
            },
            method: "GET",
        })
            .then((res) => res.json())
            .then((json) => {
                console.log("prices", json);

                setRadioFilterOptions([{ label: "Все", value: "all" }, ...json.filter(item => !item.label.toLowerCase().includes("архив"))]);
            }).then(() => {

                setLoading(false);
            })
    }, []);

    useEffect(() => {

    }, [selected.length])
    const updateSelected = (e, category) => {


        e.preventDefault()
        let newSelected;
        if (selected.includes(category.value)) {
            newSelected = selected.filter(parent => parent != category.value)
        } else {
            newSelected = [...selected, category.value]
        }
        console.log("Set selected newSelected", newSelected);
        setSelected(newSelected)
        if (newSelected.length < 1) {
            handlePrefill([])
        } else {

            handlePrefill(newSelected);
        }

    }
    return (
        <div>
            <>
                {
                    loading ?
                        <Spin />
                        :

                        radioFilterOption.map(category => <div className='category-item' key={category.value} onClick={(e) => updateSelected(e, category)}>
                            <Checkbox checked={selected.includes(category.value)} onChange={(e) => { e.stopPropagation() }}>
                                {category.label}
                            </Checkbox>
                        </div>)

                }
            </>
        </div>
    );
};

export default LoadPrice;