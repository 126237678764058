import React, { useEffect, useState } from 'react';
import { Form, Select } from 'antd';

const BitrixFormLists = () => {
    const [bitrixLists, setBitrixLists] = useState<any[]>([])

    useEffect(() => {
        fetch(SiteUrlPortal + "/lists", {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""
                    }`,
            },
            method: "POST",
            body: JSON.stringify({
                lists_keys: [
                    "industry_id",
                    "category_dealer",
                    "contact_company"
                ]
            })
        }).then(res => res.json())
            .then((response) => {
                setBitrixLists(response);
            })
    }, [])

    return (
        <>
            {bitrixLists.map(list => {
                return <Form.Item
                    key={list.id}
                    label={list.title}
                    required
                    name={list.key}
                    rules={[
                        {
                            required: true,
                            message: "Поле обязательно к заполнению",
                        },
                    ]}
                >
                    <Select
                        showSearch
                        filterOption={(input: any, option) => {
                            return (String(option.children).toLowerCase().includes(input.toLowerCase()))
                        }}
                        placeholder={"Выберите поле"}
                    >
                        {list.bitrix_values.map(option => {
                            return <Select.Option

                                key={option.value}
                                value={option.value}>
                                {option.label}
                            </Select.Option>
                        })}
                    </Select >
                </Form.Item>
            })}
        </>
    );
};

export default BitrixFormLists;