import React, { useState, useEffect } from "react";
import { Button, Divider, message, Radio, Space, Modal, Typography, Tree, Input } from "antd";
import type { DataNode } from "antd/lib/tree";
import { CaretUpOutlined, CaretDownOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import { routePropsTypes, toSendType, productsType, promoType } from "./types";
import { useTypedDispatch, useTypedSelector } from "../../redux/hooks";
import { addCartItem } from "../../redux/slices/cartSlice";
import { useHistory, useLocation } from "react-router-dom";

import "./CreateOrderModal.scss";

import ModalForm from "./ModalForm";

type Props = {
  toSend: toSendType;
  setToSend: any;
  modalVis: boolean;
  setModalVis: any;
  state: any;
  setBizProcId?: (id: any) => void;
  setAgreeModalVisible?: (state: boolean) => void;
  agreeModalVisible?: boolean;
};

export default function DeliveryModal({
  toSend,
  setToSend,
  modalVis,
  setModalVis,
  state,
  setBizProcId,
  setAgreeModalVisible,
  agreeModalVisible
}: Props) {
  const dispatch = useTypedDispatch();
  const history = useHistory();
  const handbookRedux = useTypedSelector((state) => state.handbook);
  const [currentOption, setCurrentOption] = useState<
    "no_delivery" | "on_payment" | "on_demand" | "delivery_now"
  >("no_delivery");
  const [openForm, setOpenForm] = useState<string | undefined | null>(undefined);
  const [openSecondForm, setOpenSecondForm] = useState<boolean>(false);
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const [isEmail, setIsEmail] = useState<boolean>(true);
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>(["send"]);
  const [checkedKeys, setCheckedKeys] = useState<React.Key[]>(["account"]);
  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]);
  const [autoExpandParent, setAutoExpandParent] = useState<boolean>(true);
  const [customEmail, setCustomEmail] = useState<string>("");
  const [emailInputError, setEmailInputError] = useState<boolean>(false);


  const defaultEmail = JSON.parse(localStorage.getItem("user")).email;
  const deff: any = toSend.partner_data.deffered_period;
  // const deff: any = 232;

  const treeData: DataNode[] = [
    {
      title: "Отправить счёт на E-mail",
      key: "send",
      children: [
        { title: defaultEmail, key: "account" },
        {
          title: (
            <Input
              placeholder="Введите e-mail"
              value={customEmail}
              onChange={(e) => handleAddEmail(e.target.value)}
              style={{ padding: 0, display: "inline" }}
              size="small"
              bordered={false}
            // prefix={emailInputError && <ExclamationCircleFilled style={{ color: "#f5222d" }} />}
            />
          ),
          key: "custom",
        },
      ],
    },
  ];

  useEffect(() => {
    if (deff === undefined) return;
    setCurrentOption("no_delivery");
  }, [modalVis]);

  function changeRadio(e: any) {
    console.log(e.target.value)
    setCurrentOption(e.target.value);
    const target = e.target.value;
    setOpenForm(target);

  }

  function validateData() {

    if (currentOption === "on_payment" || currentOption === "delivery_now") {
      if (toSend.store === "" || toSend.location === null || toSend.location === undefined || toSend.store === undefined || toSend.delivery_method === "" || toSend.delivery_method === undefined || toSend.track_phone === "" || toSend.track_phone === undefined) {
        message.error("Не заполнены необходимые данные отгрузки");
      } else {
        handleSend();
      }
    } else {
      handleSend();
    }
  }

  function handleSend() {

    toSend = { ...toSend, invoice_type: currentOption };
    toSend = { ...toSend, invoice_email: [] };

    if (checkedKeys.includes("account")) {
      toSend.invoice_email.push(defaultEmail);
    }
    if (checkedKeys.includes("custom")) {
      toSend.invoice_email.push(customEmail);
    }
    // console.log(toSend);
    // return;
    setBtnLoading(true);
    fetch(
      `${SiteUrlDealer}/order${state && (state.mode == "edit" || state.mode == "view") && handbookRedux.products.length ? `/${toSend.id}` : ""
      }`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""
            }`,
        },
        body: JSON.stringify((state.mode == "edit" || state.mode == "view") ? { ...toSend, avaliable_shipment: undefined } : { content: [{ ...toSend, avaliable_shipment: undefined }] }),
        method: (state.mode == "edit" || state.mode == "view") ? "PUT" : "POST",
      }
    )
      .then((res) => res.json())
      .then((json) => {
        // добавить лоадер
        console.log(json);


        message.success("Заказ отправлен");

        setModalVis(false);
        localStorage.removeItem("cart");
        dispatch(addCartItem([]));
        if (json?.bizproc_id) {
          setBizProcId(json.bizproc_id);
          setAgreeModalVisible(true);
        } else {
          history.push(`/orders`)
        }
      })
      .catch((err) => {
        message.error("Что-то пошло не так");
        console.log(err);
      })
      .finally(() => {
        setBtnLoading(false);
      });
  }

  const onEmailExpand = (expandedKeysValue: React.Key[]) => {
    console.log("onEmailExpand", expandedKeysValue);
    setExpandedKeys(expandedKeysValue);
    setAutoExpandParent(false);
  };

  const onEmailCheck = (checkedKeysValue: React.Key[]) => {
    console.log("onCheck", checkedKeysValue);
    setCheckedKeys(checkedKeysValue);
  };

  const onEmailSelect = (selectedKeysValue: React.Key[], info: any) => {
    console.log("onSelect", info);
    setSelectedKeys(selectedKeysValue);
  };

  const handleAddEmail = (val: string) => {
    setCustomEmail(val);
  };

  return (
    <Modal
      centered
      visible={modalVis}
      // visible={true}
      title={
        <Typography.Title level={4} style={{ marginBottom: 0 }}>
          Оформление заказа
        </Typography.Title>
      }
      onCancel={() => setModalVis(false)}
      onOk={() => {
        validateData();
      }}
      okButtonProps={{ loading: btnLoading }}
      okText="Отправить"
      closable={true}
    >
      <div className="create-order-modal">
        <div className="create-order-modal__def">Ваша отсрочка: {deff} дн.</div>
        <div className="create-order-modal__radio-container">
          {/* 1 */}
          {state.mode == 'edit' && (
            <div className="create-order-modal__item">
              <Radio value={"no_delivery"} onChange={changeRadio} checked={currentOption === "no_delivery"}>
                Выставить счёт
              </Radio>
            </div>
          )}
          {/* 2 */}
          {((deff === 0 || handbookRedux.d_isAdminManager) || toSend.avaliable_shipment != true) ? (
            <div className="create-order-modal__item">
              <Radio value={"on_payment"} onChange={changeRadio} checked={currentOption === "on_payment"}>
                Выставить счёт и отгрузить при получении оплаты
              </Radio>
              {openForm == "on_payment" ? (
                <CaretUpOutlined style={{ marginLeft: 5, fontSize: "10px" }} />
              ) : (
                <CaretDownOutlined style={{ marginLeft: 5, fontSize: "10px" }} />
              )}
              <div
                className={`create-order-modal__form-container ${openForm == "on_payment" && "create-order-modal__form-container--expanded"
                  }`}
              >
                <ModalForm toSend={toSend} setToSend={setToSend} deliveryType={'on_payment'} />
              </div>
            </div>
          ) : (deff === 0 || handbookRedux.d_isAdminManager) && state.mode == 'view' ? (
            <div className="create-order-modal__item">
              <Radio value={"on_payment"} onChange={changeRadio} checked={currentOption === "on_payment"}>
                Отгрузить при получении оплаты
              </Radio>
              {openForm == "on_payment" ? (
                <CaretUpOutlined style={{ marginLeft: 5, fontSize: "10px" }} />
              ) : (
                <CaretDownOutlined style={{ marginLeft: 5, fontSize: "10px" }} />
              )}
              <div
                className={`create-order-modal__form-container ${openForm == "on_payment" && "create-order-modal__form-container--expanded"
                  }`}
              >
                <ModalForm toSend={toSend} setToSend={setToSend} deliveryType={'on_payment'} />
              </div>
            </div>
          ) : <></>}
          {
            (deff > 0 || handbookRedux.d_isAdminManager) && state.mode == 'edit' ? (
              <> {/* delivery_on_payment */}
                <div className="create-order-modal__item">
                  <Radio value={"delivery_now"} disabled={toSend.avaliable_shipment != true && (!handbookRedux.d_isAdminManager && !handbookRedux.d_isManager)} onChange={changeRadio} checked={currentOption === "delivery_now"}>
                    Выставить счёт и отгрузить сразу
                  </Radio>

                  {openForm == "delivery_now" ? (
                    <CaretUpOutlined style={{ marginLeft: 5, fontSize: "10px" }} />
                  ) : (
                    <CaretDownOutlined style={{ marginLeft: 5, fontSize: "10px" }} />
                  )}
                  {toSend.avaliable_shipment != true && <div className={"text-error"}>
                    Есть неоплаченные в период отсрочки заказы.
                    {handbookRedux.d_isManager && " Заказ будет отправлен на согласование руководителю."}</div>}
                  <div
                    className={`create-order-modal__form-container ${(openForm == "delivery_now" && (toSend.avaliable_shipment == true || (handbookRedux.d_isAdminManager || handbookRedux.d_isManager))) && "create-order-modal__form-container--expanded"
                      }`}
                  >
                    <ModalForm toSend={toSend} setToSend={setToSend} deliveryType={'delivery_now'} />
                  </div>
                </div>
              </>
            ) : ((deff > 0 || handbookRedux.d_isAdminManager) && state.mode == 'view') || (toSend.payments_amount == toSend.items_amount && state.mode == 'view') ? (
              <div className="create-order-modal__item">
                <Radio value={"delivery_now"} onChange={changeRadio} checked={currentOption === "delivery_now"}>
                  Отгрузить сейчас
                </Radio>
                {openForm == "delivery_now" ? (
                  <CaretUpOutlined style={{ marginLeft: 5, fontSize: "10px" }} />
                ) : (
                  <CaretDownOutlined style={{ marginLeft: 5, fontSize: "10px" }} />
                )}
                <div
                  className={`create-order-modal__form-container ${openForm == "delivery_now" && "create-order-modal__form-container--expanded"
                    }`}
                >
                  <ModalForm toSend={toSend} setToSend={setToSend} deliveryType={'delivery_now'} />
                </div>
              </div>
            ) : (
              <></>
            )
          }
          {/* 3 */}
          {state.mode == 'edit' && (
            <div className="create-order-modal__item">
              <Radio value={"on_demand"} onChange={changeRadio} checked={currentOption === "on_demand"}>
                Выставить счёт и отгрузить по требованию
              </Radio>
              {openForm == "on_demand" ? (
                <CaretUpOutlined style={{ marginLeft: 5, fontSize: "10px" }} />
              ) : (
                <CaretDownOutlined style={{ marginLeft: 5, fontSize: "10px" }} />
              )}
              <div
                className={`create-order-modal__form-container ${openForm == "on_demand" && "create-order-modal__form-container--expanded"
                  }`}
              >
                <ModalForm toSend={toSend} setToSend={setToSend} deliveryType={'on_demand'} />
              </div>
            </div>
          )}
        </div>
        <div className="create-order-modal__email">
          <Tree
            checkable
            showIcon
            onExpand={onEmailExpand}
            expandedKeys={expandedKeys}
            autoExpandParent={autoExpandParent}
            onCheck={onEmailCheck}
            checkedKeys={checkedKeys}
            onSelect={onEmailSelect}
            selectedKeys={selectedKeys}
            selectable={false}
            treeData={treeData}
          />
        </div>
      </div>
    </Modal>
  );
}
