import React from 'react';
import { Button, Popconfirm } from "antd"
import { DeleteOutlined, EditOutlined, CopyOutlined } from "@ant-design/icons"
import "./OfferItem.scss"
import { useHistory } from 'react-router-dom';
import useSaveCOForm from './useSaveCOForm';
const OfferItem = ({ offer, deleteItem }) => {
    const history = useHistory();
    const openCo = () => {
        history.push(`/commercial-offer/${offer.uuid}`)
    }
    return (
        <div className='offer'
            onClick={openCo}
        >
            <div className='offer__name'>
                {offer.name}
            </div>
            <div className='offer__actions'
                onClick={e => {
                    e.stopPropagation();
                }}
            >
                <Button
                    type="link"
                    style={{
                        marginRight: 10
                    }}
                    onClick={openCo}
                    icon={<EditOutlined />}
                />
                <Popconfirm

                    placement="top"
                    title="Удалить коммерческое предложение?"
                    onConfirm={(e) => {
                        e.stopPropagation();
                        deleteItem(offer.id);
                    }}
                    onCancel={(e) => e.stopPropagation()}
                    okText="Удалить"
                    cancelText="Отмена"
                >
                    <Button
                        danger
                        type="link"
                        icon={<DeleteOutlined />}
                    />
                </Popconfirm>
            </div>
        </div>
    );
};

export default OfferItem;