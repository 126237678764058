import React, { useState, useEffect } from 'react';
import {
	Card,
	Typography,
	Avatar,
	Button,
	Popconfirm,
	Empty,
	Spin,
	Collapse,
	Pagination,
	Input,
	Skeleton,
	List,
	Divider,
	Radio,
	Select,
} from 'antd';
import { useTypedDispatch, useTypedSelector } from '../../redux/hooks';
import { setAuthorizeted, signOut } from '../../redux/slices/authSlice';
import { setHeaderTitle } from '../../redux/slices/layoutSlice';
import { setOrders, setPromotions } from '../../redux/slices/handbookSlice';
import { ResponsiveLine } from '@nivo/line';

import { PlusOutlined, LogoutOutlined, EditOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import JurInfoItem from './JurInfoItem';
import logo from './images/service.png';
import '../../styles/Card.scss';
import './User.scss';
import './MyCompanies.scss';
import moment from 'moment';
import CitySelect from '../MyCompanies/CitySelect';
import { IRegion } from '../PromotionSettings/types';

const promoType = [
	{ label: 'Федеральная акция', value: 'federal' },
	{ label: 'Внутренняя акция', value: 'inner' },
];

export interface IUserProps {}

export default function User(props: IUserProps) {
	const history = useHistory();
	const dispatch = useTypedDispatch();
	const handbookRedux = useTypedSelector((state) => state.handbook);

	interface IDealer {
		title: string;
		name: string;
		email: string;
		phone: string;
	}
	const dealerDefault = {
		title: 'super dealer',
		name: 'super dealer',
		email: 'email',
		phone: 'phone',
	};

	const [dealer, setDealer] = useState<IDealer>(dealerDefault);
	const [role, setRole] = useState<any>('d_user');
	const [legals, setLegals] = useState<any[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [fullCount, setFullCount] = useState<number>(10);
	const [page, setPage] = useState<number>(1);
	const [pageSize, setPageSize] = useState<number>(50);
	const [searchData, setSearchData] = useState<string | null>('');
	const [searchTrigger, setSearchTrigger] = useState<boolean>(false);
	const [searchOrgData, setSearchOrgData] = useState<string | null>('');
	const [searchOrgTrigger, setSearchOrgTrigger] = useState<boolean>(false);
	const [promotions, setPromotions] = useState<[]>([]);
	const [pieData, setPieData] = useState<any>(undefined);
	const [mainOrg, setMainOrg] = useState<any>('');
	const [orgsForMainFilter, setOrgsForMainFilter] = useState(handbookRedux.partners);
	const [region, setRegion] = useState<IRegion>('');

	const [initLoading, setInitLoading] = useState(true);
	const [chartLoading, setChartLoading] = useState(true);
	const [data, setData] = useState([]);
	const [list, setList] = useState([]);
	const [head, setHead] = useState<any>(undefined);
	const [mode, setMode] = useState('currentYear');

	const bitrixData = useTypedSelector((state) => state.handbook.bitrixData);

	const [chartData, setChartData] = useState([]);

	useEffect(() => {
		dispatch(setHeaderTitle('Мои компании'));
		setRole(JSON.parse(localStorage.getItem('user')).capabilities);
	}, []);

	useEffect(() => {
		if (bitrixData && bitrixData.contacts) {
			const contacts = bitrixData.contacts.map((item: any, index: any) => {
				return {
					key: index,
					...item,
				};
			});
			setData(contacts);
			setList(contacts);
		}
		setInitLoading(false);
	}, [bitrixData]);

	useEffect(() => {
		setIsLoading(true);

		const timeout = setTimeout(() => {
			const body = {
				filters: [
					{
						field: 'name',
						comparison: 'like',
						value: searchData,
					},
				],
			};

			fetch(
				`${SiteUrlDealer}/organization_with_filters?limit=${
					pageSize ? pageSize : '10'
				}&page=${page ? page : 1}&order_by=id&order_type=DESC`,
				{
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${
							localStorage.getItem('accessToken')
								? localStorage.getItem('accessToken')
								: ''
						}`,
					},
					body: JSON.stringify(body),
					method: 'POST',
				}
			)
				.then((res) => res.json())
				.then((json) => {
					if (json.partners.length > 0) {
						const partners = json.partners.map((item: any) => item.ref);
						setHead(partners);
					}
					// if (json.partners[0].main_organization && json.partners[0].main_organization != "") {
					//   setHead(json.partners[0].main_organization);
					// }
					setLegals(json.partners);
					setFullCount(json.count);
					setIsLoading(false);
				});
		}, 1000);
		return () => {
			clearTimeout(timeout);
			setIsLoading(false);
		};
	}, [searchTrigger, page, pageSize]);

	useEffect(() => {
		setIsLoading(true);
		fetch(`${SiteUrlDealer}/user_prom_index`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${
					localStorage.getItem('accessToken')
						? localStorage.getItem('accessToken')
						: ''
				}`,
			},
			method: 'POST',
		})
			.then((res) => res.json())
			.then((json) => {
				setPromotions(json);
			})
			.catch((error) => {
				history.push('/');
			});
	}, []);

	useEffect(() => {
		dispatch(setHeaderTitle('Главная'));

		fetch(`${SiteUrlDealer}/order?limit=4&page=1&order_by=id&order_type=DESC`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${
					localStorage.getItem('accessToken')
						? localStorage.getItem('accessToken')
						: ''
				}`,
			},
			method: 'GET',
		})
			.then((res) => res.json())
			.then((json) => {
				dispatch(setOrders(json.orders));
			});

		setDealer({
			title: JSON.parse(localStorage.getItem('user')).title,
			name: JSON.parse(localStorage.getItem('user')).name,
			email: JSON.parse(localStorage.getItem('user')).email,
			phone: JSON.parse(localStorage.getItem('user')).phone,
		});
	}, []);

	useEffect(() => {
		if (!head) return;
		setChartLoading(true);
		const currentDate =
			mode == 'currentYear' ? moment() : moment().subtract(1, 'year');
		const firstDate = currentDate.year() + '-01-01';
		const lastDate = currentDate.year() + '-12-31';

		fetch(
			`${SiteUrlPortal}/data/selfStats?period=${firstDate},${lastDate}&executeInMonths`,
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${
						localStorage.getItem('accessToken')
							? localStorage.getItem('accessToken')
							: ''
					}`,
				},
				body: JSON.stringify({
					filters: [
						{
							field: 'org_id',
							comparison: 'in',
							value: head.join(','),
							condition: 'and',
						},
					],
					out_values: ['sum'],
					type: 'linear',
					x_key: 'date',
					id_key: 'name',
				}),
				method: 'POST',
			}
		)
			.then((res) => res.json())
			.then((json) => {
				const chartData = { ...json[0], id: 'Сумма' };
				setChartData([chartData]);
				setChartLoading(false);
			});

		fetch(
			`${SiteUrlPortal}/data/selfStats?period=${firstDate},${lastDate}&executeInMonths`,
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${
						localStorage.getItem('accessToken')
							? localStorage.getItem('accessToken')
							: ''
					}`,
				},
				method: 'POST',
				body: JSON.stringify({
					filters: [
						{
							field: 'org_id',
							comparison: 'in',
							value: head.join(','),
							condition: 'and',
						},
					],
					out_values: ['sum'],
					type: 'sum',
					x_key: 'date',
					id_key: 'name',
				}),
			}
		)
			.then((res) => res.json())
			.then((json) => {
				setPieData(json[0]['Сумма']);
				setChartLoading(false);
			});
	}, [mode, head]);

	const setNewRegion = (value) => {
		setRegion(value);
		const userRaw = localStorage.getItem('user');
		if (userRaw) {
			const user_id = JSON.parse(userRaw).id;

			fetch(`${SiteUrlAuth}/api/user_location/${user_id}`, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${
						localStorage.getItem('accessToken')
							? localStorage.getItem('accessToken')
							: ''
					}`,
				},
				method: 'POST',
				body: JSON.stringify({
					location: value.value,
					city_name: value.label,
				}),
			});
		}
	};
	useEffect(() => {
		const userRaw = localStorage.getItem('user');
		if (userRaw) {
			const user = JSON.parse(userRaw);
			setRegion({ label: user.city_name, value: user.location });
		}
	}, []);
	return (
		<div className="user">
			<div className="user__layout-top">
				<Card
					title={
						<Typography.Title level={ResponsiveTitleLevel}>
							Профиль
							<Popconfirm
								placement="topLeft"
								title="Вы дейтсвительно хотите выйти из аккаунта?"
								onConfirm={() => {
									dispatch(setAuthorizeted(false));

									dispatch(signOut());
									history.push('/login');
								}}
								onCancel={(e) => e.stopPropagation()}
								okText="Выйти"
								cancelText="Отмена"
							>
								<Button
									type="link"
									icon={<LogoutOutlined />}
									danger
									onClick={(e) => e.stopPropagation()}
									style={{
										marginLeft: 'auto',
										alignSelf: 'flex-start',
									}}
								>
									Выход
								</Button>
							</Popconfirm>
						</Typography.Title>
					}
					bordered={false}
					// extra={
					//   <Button
					//     type="default"
					//     // shape="circle"
					//     icon={<EditOutlined />}
					//     // onClick={() => console.log(1)}
					//   >
					//     Редактировать
					//   </Button>
					// }
					className="card user__layout-profile"
				>
					<div className="user__profile">
						<div className="user__ava-name">
							<Avatar
								style={{ verticalAlign: 'middle' }}
								size={70}
								className="user__avatar"
							>
								{/* {dealer.title.slice(0, 1).toUpperCase()} */}
							</Avatar>

							<div className="user__name-info">
								<div className="user__name">{dealer.title}</div>
								<div className="user__email">{dealer.name}</div>
								<div className="user__email">{dealer.email}</div>
								<div className="user__phone">{dealer.phone}</div>

								{/* <List
                  className="demo-loadmore-list"
                  loading={initLoading}
                  itemLayout="horizontal"
                  loadMore={onLoadMore}
                  dataSource={list}
                  renderItem={(item) => (
                    <List.Item
                      //actions={[<a key="list-loadmore-edit">edit</a>, <a key="list-loadmore-more">more</a>]}
                    >
                        <List.Item.Meta
                          title={<a href="https://ant.design">{item.name}</a>}
                          description={<><div className="user__promo-desc">{item.phones ? item.phones.join(", ") : ""}</div><div className="user__promo-desc">{item.emails ? item.emails.join(", ") : ""}</div></>}
                        />
                    </List.Item>
                  )}
                /> */}
								{initLoading && handbookRedux.d_isUser ? (
									<div className="user__contacts-title">
										<Spin />
									</div>
								) : handbookRedux.d_isUser ? (
									<div>
										<div className="user__contacts-title">
											Данные дилера:
										</div>
										<div></div>
										<div className="user__contacts-container">
											{handbookRedux.d_isUser && (
												<div style={{ marginBottom: 15 }}>
													<div className="user__bitrix-data">
														Город
													</div>
													<div className="user__contact-desc">
														{region?.label
															? region?.label
															: 'Не выбран'}
													</div>
												</div>
											)}
											{bitrixData?.category_head_organization && (
												<div className="user__bitrix-data">
													{
														bitrixData
															.category_head_organization
															.title
													}
												</div>
											)}
											{bitrixData?.web && (
												<div className="user__contact-desc">
													{bitrixData?.web.map((itm: any) => {
														return (
															<a
																key={itm}
																target="_blanc"
																href={'http://' + itm}
															>
																{itm}
															</a>
														);
													})}
												</div>
											)}
											{!initLoading ? (
												bitrixData?.contacts?.map(
													(itm: any, ind: any) => {
														return (
															<div
																className="user__contact"
																key={ind}
															>
																<div className="user__contact-text">
																	{itm?.name ?? ''}
																</div>
																{/* <div className="user__contact-desc">{itm?.phones.join(", ") ?? ""}</div> */}
																{itm?.phones && (
																	<div className="user__contact-desc">
																		{itm?.phones.map(
																			(
																				phone: any
																			) => {
																				return (
																					<a
																						key={
																							phone
																						}
																						href={
																							'tel:' +
																							phone
																						}
																					>
																						{
																							phone
																						}
																					</a>
																				);
																			}
																		)}
																	</div>
																)}
																{/* <div className="user__contact-desc">{itm?.emails.join(", ") ?? ""}</div> */}
																{itm?.emails && (
																	<div className="user__contact-desc">
																		{itm?.emails.map(
																			(
																				email: any
																			) => {
																				return (
																					<a
																						key={
																							email
																						}
																						href={
																							'mailto:' +
																							email
																						}
																					>
																						{
																							email
																						}
																					</a>
																				);
																			}
																		)}
																	</div>
																)}
															</div>
														);
													}
												)
											) : (
												<div className="user__contacts-container">
													<Spin />
												</div>
											)}
										</div>
									</div>
								) : (
									<></>
								)}
							</div>
						</div>
						{/* <a href="#" className="user__edit-link">
              Редактировать профиль
            </a> */}
					</div>
				</Card>
				<Card
					title={
						<Typography.Title level={ResponsiveTitleLevel}>
							Действующие акции
						</Typography.Title>
					}
					bordered={false}
					// extra={
					//   <Button
					//     type="default"
					//     icon={<PlusOutlined />}
					//     onClick={() => history.push("/create-order/new", { mode: "create", data: false })}
					//   >
					//     Новый заказ
					//   </Button>
					// }
					className="card user__layout-orders"
				>
					<div className="user__promo-container">
						{promotions.length ? (
							promotions.map((itm: any, ind) => (
								<div className="user__promo" key={ind}>
									<div className="user__promo-type">
										{itm.type
											? promoType.find((i) => i.value == itm.type)
													?.label
											: ''}
									</div>
									<div className="user__promo-text">
										{itm.title ? itm.title : ''}
									</div>
									<div className="user__promo-desc">
										{itm.description ? itm.description : ''}
									</div>
								</div>
							))
						) : (
							<div style={{ marginLeft: 21, marginBottom: 30 }}>
								Акции отсутствуют
							</div>
						)}
					</div>
					{initLoading && handbookRedux.d_isUser ? (
						<div>
							<Spin />
						</div>
					) : handbookRedux.d_isUser ? (
						<div>
							<div className="user__contacts-block">
								<Divider />

								<div className="user__manager-block">
									<div
										style={{
											marginBottom: 15,
											marginRight: 20,
											height: 100,
											width: 100,
											overflow: 'hidden',
											borderRadius: 50,
										}}
									>
										<img
											style={{ marginBottom: 15 }}
											src={
												bitrixData.responsible
													? bitrixData?.responsible?.photo
													: ''
											}
											width={100}
											alt="Logo"
										/>
									</div>
									<div>
										<div className="user__manager">Ваш менеджер</div>
										<div className="user__manager-text">
											{bitrixData?.responsible?.name ?? ''}
										</div>
										<div className="user__promo-desc">
											<a
												href={
													'tel:' +
														bitrixData?.responsible?.phone ??
													''
												}
											>
												{bitrixData?.responsible?.phone ?? ''}
											</a>
										</div>
										<div className="user__promo-desc">
											<a href={'tel:8 800 500 3931'}>
												8 800 500 3931
											</a>
										</div>
									</div>
								</div>
								<div className="user__manager-block">
									<div
										style={{
											marginBottom: 15,
											marginRight: 20,
											height: 100,
											width: 100,
											overflow: 'hidden',
											borderRadius: 50,
										}}
									>
										<img
											style={{ marginBottom: 15 }}
											src={
												bitrixData.chief
													? bitrixData?.chief?.photo
													: ''
											}
											width={100}
											alt="Logo"
										/>
									</div>
									<div>
										<div className="user__manager">
											Руководитель отдела продаж
										</div>
										<div className="user__manager-text">
											{bitrixData?.chief?.name ?? ''}
										</div>
										<div className="user__promo-desc">
											<a
												href={
													'tel:' + bitrixData?.chief?.phone ??
													''
												}
											>
												{bitrixData?.chief?.phone ?? ''}
											</a>
										</div>
										<div className="user__promo-desc">
											<a href={'tel:8 800 500 3931'}>
												8 800 500 3931
											</a>
										</div>
									</div>
								</div>
								<div className="user__manager-block">
									<div
										style={{
											marginBottom: 15,
											marginRight: 20,
											height: 100,
											width: 100,
											overflow: 'hidden',
											borderRadius: 50,
										}}
									>
										<img
											style={{ marginBottom: 15 }}
											src={logo}
											width={100}
											height={100}
											alt="service"
										/>
									</div>
									<div>
										<div className="user__manager">
											Ваш сервисный инженер
										</div>
										<div className="user__manager-text">
											Алимпиев Александр
										</div>
										<div className="user__promo-desc">
											<a href="tel:+79069068118">+79069068118</a>
										</div>
										<div className="user__promo-desc">
											<a href={'mailto:service@vakio.ru'}>
												service@vakio.ru
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					) : (
						<></>
						// <Empty description="Нет данных" />
					)}
				</Card>
			</div>
			{(handbookRedux.d_isManager || handbookRedux.d_isAdminManager) && (
				<div className="user__sales-line">
					<Card
						title={
							<Typography.Title level={ResponsiveTitleLevel}>
								<div className="user__sales">Фильтр по организациям</div>
							</Typography.Title>
						}
						bordered={false}
					>
						<div className="filter-field">
							<div className="filter-field__item">
								<Select
									style={{ width: '100%' }}
									placeholder="Выберите организацию"
									showSearch
									//size="small"
									// @ts-ignore prettier-ignore
									filterOption={(input, option) =>
										option.children //@ts-ignore
											.toLowerCase()
											.indexOf(input.toLowerCase()) >= 0
									}
									onSearch={(search) => {
										fetch(
											`${SiteUrlDealer}/organization_with_filters?limit=50&page=1&order_by=id&order_type=DESC`,
											{
												headers: {
													'Content-Type': 'application/json',
													// prettier-ignore
													Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""}`,
												},
												// prettier-ignore
												body: JSON.stringify({
                          filters: [{
                            field: "name",
                            comparison: "like",
                            value: search,
                          }]
                        }),
												method: 'POST',
											}
										)
											.then((res) => res.json())
											.then((json) =>
												setOrgsForMainFilter(json.partners)
											);
									}}
									// notFoundContent="Начните вводить название"
									onChange={(data, full) => {
										const rr = [];
										// @ts-ignore
										rr.push(full.value);
										setHead(rr);
									}}
								>
									{orgsForMainFilter &&
										orgsForMainFilter.map((itm, ind) => (
											<Select.Option
												key={ind}
												value={itm.main_organization}
												disabled={itm.main_organization === null}
											>
												{itm.name}
											</Select.Option>
										))}
								</Select>
							</div>
						</div>
					</Card>
				</div>
				// <Empty description="Нет данных" />
			)}

			<div className="user__sales-line">
				<Card
					title={
						<Typography.Title level={ResponsiveTitleLevel}>
							{pieData ? (
								<div className="user__sales">
									Отгружено на {formatMoney(pieData)} ₽
								</div>
							) : (
								<div className="user__sales">Данные отсутствуют</div>
							)}
						</Typography.Title>
					}
					bordered={false}
				>
					<div style={{ marginLeft: 35 }}>
						<Radio.Group defaultValue="a" buttonStyle="solid">
							<Radio.Button value="b" onClick={() => setMode('lastYear')}>
								{moment().subtract(1, 'year').year()}
							</Radio.Button>
							<Radio.Button
								value="a"
								onClick={() => setMode('currentYear')}
							>
								{moment().year()}
							</Radio.Button>
						</Radio.Group>
					</div>
					{chartData ? (
						<div style={{ height: 350, width: '100%' }}>
							{chartLoading ? (
								<div className="user__spin">
									<Spin />
								</div>
							) : (
								<ResponsiveLine
									data={chartData}
									enableSlices={false}
									margin={{ top: 27, right: 35, bottom: 45, left: 35 }}
									lineWidth={3}
									xScale={{ type: 'point' }}
									yScale={{
										type: 'linear',
										min: 0,
										max: 'auto',
										reverse: false,
									}}
									yFormat={(value) =>
										`${Number(value).toLocaleString('ru-RU', {
											minimumFractionDigits: 2,
										})} ₽`
									}
									axisTop={null}
									axisRight={null}
									axisLeft={null}
									// axisBottom={{
									//   tickRotation: -50,
									//   tickSize: 0,
									// }}
									pointSize={10}
									pointColor={{ theme: 'background' }}
									pointBorderWidth={2}
									pointBorderColor={{ from: 'serieColor' }}
									pointLabelYOffset={-12}
									useMesh={true}
									axisBottom={{
										tickSize: 10,
										tickPadding: 10,
										tickRotation: 0,
										legend: '',
										legendPosition: 'middle',

										renderTick: ({
											tickIndex,
											textAnchor,
											textBaseline,
											textX,
											textY,
											value,
											x,
											y,
										}) => {
											return (
												<>
													<g
														transform={`translate(${x} ${y})`}
														rotate={20}
													>
														<text
															alignmentBaseline={
																textBaseline as
																	| 'auto'
																	| 'baseline'
																	| 'before-edge'
																	| 'text-before-edge'
																	| 'middle'
																	| 'central'
																	| 'after-edge'
																	| 'text-after-edge'
																	| 'ideographic'
																	| 'alphabetic'
																	| 'hanging'
																	| 'mathematical'
																	| 'inherit'
																	| undefined
															}
															textAnchor={textAnchor}
															transform={`translate(${textX}, ${textY} ) rotate(19 0 0)`}
															id={'text' + tickIndex}
														>
															<tspan
																opacity={1}
																style={{
																	fontSize: 12,
																	overflow: 'hidden',
																}}
															>
																{moment(value).format(
																	'MMM YY'
																)}
															</tspan>
														</text>
													</g>
												</>
											);
										},
									}}
									tooltip={({ point }) => {
										return (
											<div
												style={{
													background: 'white',
													padding: '9px 12px',
													border: '1px solid #ccc',
													borderRadius: 9,
												}}
											>
												<div
													style={{
														display: 'flex',
														flexDirection: 'column',
													}}
												>
													<div
														style={{
															fontSize: 15,
															fontWeight: 500,
														}}
													>
														{`${formatMoney(
															point.data.y.toString()
														)} ₽`}
													</div>
													<div
														style={{
															marginRight: 5,
															color: '#9f9f9f',
															marginTop: -5,
														}}
													>
														{moment(point.data.x).format(
															'MMMM YYYY'
														)}
													</div>
												</div>
											</div>
										);
									}}
								/>
							)}
						</div>
					) : (
						<div style={{ height: 'auto', width: '100%' }}>
							данные отсутствуют
						</div>
					)}
				</Card>
			</div>

			{/* <Card
        title={<Typography.Title level={ResponsiveTitleLevel}>Статистика</Typography.Title>}
        bordered={false}
        className="card user__layout-stats"
      >
        <div className="user__stats">
          <Empty description="Статистика пока недоступна" />
        </div>
      </Card> */}
		</div>
	);
}
