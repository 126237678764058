import { Select, Typography } from 'antd';
import React from 'react';

const PatternSelect = ({ patterns, setNewPattern, pattern, width }) => {

    const selectPattern = (id) => {
        setNewPattern(patterns.find(pattern => pattern.id === id));
    }

    return (
        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Typography.Text style={{ marginBottom: 5 }}>
                Выберите шаблон КП
            </Typography.Text>
            <Select
                style={{ width: width ?? 300 }}
                value={pattern}
                onChange={selectPattern}
                placeholder="Выберите шаблон КП"
                options={patterns.map((item) => {
                    return {
                        label: item.name,
                        value: item.id
                    }
                })}
            />
        </div>
    );
};

export default PatternSelect;