import React, { useState, useEffect, useMemo } from "react";
import { Select, TreeSelect } from "antd";

export default function CitySelect({ region, setRegion, disabled = false }) {
    const [value, setValue] = useState(undefined);
    const [expanded, setExpanded] = useState([]);
    const [cities, setCities] = useState([]);
    const [showPlaceholders, setShowPlaceholders] = useState({
        cities: true,
        regions: true,
        districts: true,
        countries: true,
    });
    const [isFetching, setIsFetching] = useState(false);
    useEffect(() => {
        if (region !== "") {
            // setValue(region);
            setValue({ label: region?.label, value: region?.value });
        }
    }, [region]);


    useEffect(() => {
        onSearch("")
    }, [])
    function onSearch(search) {
        // console.log(search);
        setShowPlaceholders({
            cities: false,
            regions: false,
            districts: false,
            countries: false,
        });
        const timeout = setTimeout(() => {
            onExpand(["city"], search);
        }, 700);

        if (isFetching) {
            clearTimeout(timeout);
        }
    }
    const onChange = (newValue) => {
        // тут есть возможность выводить результат выбора в виде объекта, а не строки
        // const converted = {
        //   id: +newValue.split(".")[0],
        //   type: newValue.split(".")[1],
        // };
        // setRegion(converted);
        console.log("Set region", newValue);
        if (newValue === undefined) {

            setRegion({ label: null, value: null });
        } else {

            setRegion({ label: newValue?.label[0], value: newValue?.value });
        }
    };





    // console.log(showPlaceholders);

    function onExpand(allTypes, search) {
        // console.log(allTypes);
        setExpanded(allTypes[0] == "all" ? ["city", "region", "district", "country"] : allTypes);
        const type = "city"//allTypes[allTypes.length - 1];

        // console.log(type);
        const uri = `https://dealers.vakio.ru/api/location_with_filter?type=city${search && search !== "" ? "&value=" + search : ""
            }`;
        // console.log(uri);
        setIsFetching(true);
        if (type !== undefined) {
            fetch(uri, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""
                        }`,
                },
                method: "GET",
            })
                .then((res) => res.json())
                .then((json) => {
                    setIsFetching(false);
                    const citiesJson = json.filter((f) => f.city);


                    if (type === "city" || type === "all") {
                        setCities(
                            citiesJson.map((i) => ({
                                id: `${i.id}`,
                                city: i.city.includes("(") ? i.city : i.city + ` (${i.region})`,
                            }))
                        );
                    }


                });
        }
    }

    return (
        <div>
            <Select
                showSearch
                onSearch={onSearch}
                style={{ width: "100%" }}
                value={value}
                dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                placeholder="Выберите город"
                allowClear
                disabled={disabled}
                // treeExpandedKeys={expanded}
                onChange={onChange}
                //onTreeExpand={onExpand}
                labelInValue
                getPopupContainer={(trigger) => trigger.parentNode}
                filterOption={(input, option) => {
                    return option.children.includes(input) >= 0
                }}

            >
                {cities.map((itm) => (
                    <Select.Option value={itm.id} key={itm.id} >{itm.city} </Select.Option>
                ))}
            </Select>
        </div>
    );
}
