export default class FileService {
	static async requestApi(uri, parameters) {
		const token = await localStorage.getItem('accessToken');
		parameters.headers.Authorization = 'Bearer ' + token;
		console.log('SiteUrlDealer', SiteUrlDealer);
		const response = await fetch(SiteUrlDealer + uri, { ...parameters });
		if (!response.ok) {
			throw response;
		}
		const contentType = response.headers.get('content-type');
		if (contentType && contentType.indexOf('application/json') !== -1) {
			const json = await response.json();
			return { data: json, status: response.status };
		}

		return { status: response.status };
	}

	static async uploadFile(base, folder) {
		try {
			const response = await this.requestApi('/file', {
				body: JSON.stringify({
					type: 'dealer_co',
					co_image: base,
					folder: folder,
				}),
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
			});
			return response;
		} catch (error) {
			throw error;
		}
	}
	static async uploadProductFile(id, base) {
		try {
			const response = await this.requestApi('/product_info_file/' + id, {
				body: JSON.stringify({
					image: base,
				}),
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
			});
			return response;
		} catch (error) {
			throw error;
		}
	}
	static async deleteFile(id) {
		try {
			const response = await requestApi('/file/' + id, {
				method: 'DELETE',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
			});
			return response;
		} catch (error) {
			throw error;
		}
	}
}
