import React, { useEffect, useState } from 'react';
import { useTypedDispatch } from '../../redux/hooks';
import { setHeaderTitle } from '../../redux/slices/layoutSlice';
import { Input, Table, message, Pagination, Typography, Select, Radio, Button } from "antd";
import "./Remains.scss"
import styled from "styled-components";
import { SettingOutlined, MinusSquareOutlined, PlusSquareOutlined } from "@ant-design/icons"
import SettingsModal from './components/SettingsModal/SettingsModal';
const StyledTable = styled(Table)`
.ant-table-thead .ant-table-cell {
    background-color: #bebebe;
  }
`
const Remains = ({ }) => {
    const dispatch = useTypedDispatch();
    const [products, setProducts] = useState([])
    const [stores, setStores] = useState([])
    const [count, setCount] = useState(0)
    const [page, setPage] = useState(1)
    const [visible, setVisible] = useState(false);
    const [pageSize, setPageSize] = useState(25)
    const [expandedKeys, setExpandedKeys] = useState([])
    const [loading, setLoading] = useState(false);
    const [nameFilter, setNameFilter] = useState("");
    const [storeFilter, setStoreFilter] = useState("");
    const [filterParentId, setFilterParentId] = useState("all")
    const [expandedRow, setExpandedRow] = useState(null);
    const [updateStocsFlag, setUpdateFlag] = useState(0);
    const [parentIdFilter, setParentIdFilter] = useState([
        { label: "Все", value: "all" }])
    const [activeInput, setActiveInput] = useState(null)
    const [activeInputStore, setActiveInputStore] = useState(null)
    const [updatedStock, setUpdatedStock] = useState(null);
    const [lastStock, setLastStock] = useState(null);

    useEffect(() => {
        setLoading(true);
        const timeout = setTimeout(
            () => {
                getProducts()

            }, 200
        )
        return () => {
            setLoading(false);
            clearTimeout(timeout)
        }
    }, [nameFilter, storeFilter, filterParentId])

    const getProducts = () => {
        setLoading(true);

        let filters = []
        if (nameFilter.length > 0) {
            filters.push({
                "field": "full_name",
                "comparison": "like",
                "value": nameFilter
            })
        }
        if (storeFilter.length > 0) {
            filters.push({
                "field": "stock_ref",
                "comparison": "whereIn",
                "value": storeFilter
            })
        }

        if (filterParentId !== "all") {
            filters.push({
                "field": "parent",
                "comparison": "=",
                "value": filterParentId
            })

        }
        fetch(`${SiteUrlDealer}/product_with_filter?stocks=1&stock_count=1&user_critical_stocks=1&limit=${pageSize}&page=${page}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""}`,
            },
            method: "POST",
            body: JSON.stringify({
                "filters": filters
            })
        })
            .then((res) => res.json())
            .then((json) => {

                setProducts(json.products)
                setCount(json.count)

            })
            .catch(() => message.error("Ошибка при загрузке продуктов")).finally(() => {
                setLoading(false);
            });
    }

    useEffect(() => {
        fetch(`${SiteUrlDealer}/product_categies`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""}`,
            },
            method: "GET",
        })
            .then((res) => res.json())
            .then((json) => {
                setParentIdFilter([{ label: "Все", value: "all" }, ...json.filter(item => !item.label.toLowerCase().includes("архив"))]);
            })
    }, []);


    useEffect(() => {
        dispatch(setHeaderTitle("Остатки"));
        setLoading(true);
        fetch(`${SiteUrlDealer}/stores`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""}`,
            },
            method: "GET",
        })
            .then((res) => res.json())
            .then((json) => {
                setStores(json);
                getProducts();

            })
            .catch(() => message.error("Ошибка при загрузке складов")).finally(() => {
                setLoading(false);
            });
        return () => setLoading(false);
    }, [page])

    const onShowSizeChange = (current, pageSize) => {
        setPageSize(pageSize);
        setPage(1);
    }

    const onExpand = (expanded, record) => {
        if (expanded) {
            setExpandedRow(record);
            setExpandedKeys([...expandedKeys, record.id])
        } else {
            setExpandedKeys(expandedKeys.filter(key => key !== record.id))
        }

    }
    const handleRadioButton = (value) => {
        setFilterParentId(value)
    }

    const addStockToProduct = (product) => {
        console.log("add product", product);

        const newProducts = products.map(item => {

            if (item.ref === product.product_ref) {
                return {
                    ...item, critical_stocks: item.critical_stocks.map(innerItem => {
                        if (innerItem.stock_ref === product.stock_ref) {
                            return product
                        } else {
                            return innerItem
                        }
                    })
                }
            } else {
                return item;
            }
        })
        console.log(newProducts);
        setProducts(newProducts)
    }
    const removeStockFromProduct = (product) => {
        console.log("remove product", product);

        const newProducts = products.map(item => {

            if (item.ref === product.product_ref) {
                return { ...item, critical_stocks: item.critical_stocks.filter(innerItem => innerItem.stock_ref !== product.stock_ref) }
            } else {
                return item;
            }
        })
        console.log(newProducts);

        setProducts(newProducts)
    }
    const setCtiticalStockLink = (newCriticals) => {
        if (newCriticals.quantity !== "") {


            fetch(`${SiteUrlDealer}/user_critical_stock_link`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""}`,
                },
                method: "POST",
                body: JSON.stringify({ ...newCriticals, quantity: Number(newCriticals.quantity) })
            })
                .then((res) => res.json())
                .then((json) => {
                    setUpdatedStock(null)
                    setLastStock(null)

                    addStockToProduct(json)
                    message.success("Крит. значения успешно обновлены!")

                })
                .catch(() => message.error("Ошибка при обновлении значений")).finally(() => {

                });
        } else {
            if (newCriticals?.id)
                fetch(`${SiteUrlDealer}/user_critical_stock_link/` + newCriticals.id, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""}`,
                    },
                    method: "DELETE",
                })

                    .then((json) => {
                        setUpdatedStock(null)
                        setLastStock(null)
                        removeStockFromProduct(newCriticals);
                        console.log(json);
                        message.success("Крит. значение успешно обновлено!")

                    })
                    .catch(() => message.error("Ошибка при обновлении значений")).finally(() => {

                    });
        }
    }

    const onChangeCtiticalStock = (value, full) => {
        const re = /^[0-9\b]+$/;

        if (value !== '' && !re.test(value)) {
            return
        }
        //full.critical_stocks.find(item => item.stock_ref === "parent")?.quantity
        let newStock;
        let updatedStock_ = null;
        let lastStock = null;
        setProducts(products.map(product => {
            if (product.id === full.id) {


                if ("parent" !== updatedStock?.stock_ref) {
                    lastStock = product.critical_stocks.find(item => item?.stock_ref === "parent")?.quantity
                }
                updatedStock_ = product.critical_stocks.find(item => item?.stock_ref === "parent") ?
                    { ...product.critical_stocks.find(item => item?.stock_ref === "parent"), quantity: value }
                    :
                    {
                        "product_ref": full.product_ref,
                        "stock_ref": "parent",
                        "quantity": value,
                        "finished": 0,
                    }
                newStock = {
                    ...product, critical_stocks: product.critical_stocks.find(item => item?.stock_ref === "parent") ?
                        product.critical_stocks.map(item => {
                            if (item.stock_ref === "parent")
                                return { ...item, quantity: value }
                            else return item;
                        }) :
                        [...product.critical_stocks, {

                            "product_ref": full.ref,
                            "stock_ref": "parent",
                            "quantity": value,
                            "finished": 0,

                        }]
                }
                return newStock;
            } else {
                return product;
            }
        }))
        // addStock(newStock);
        setUpdatedStock(updatedStock_);
        if (lastStock)
            setLastStock(lastStock);
    }
    const expandAll = (state) => {
        if (state) {
            setExpandedKeys(products.map(item => item.id))
        } else {
            setExpandedKeys([])

        }
    }
    const onChangeCtiticalStockStore = (value, full, expandedRow) => {
        //full.critical_stocks.find(item => item.stock_ref === "parent")?.quantity
        const re = /^[0-9\b]+$/;

        if (value !== '' && !re.test(value)) {
            return
        }

        let newExpandedRow;
        let updatedStock_;
        let lastStock;
        setProducts(products.map(product => {
            if (product.id === expandedRow.id) {
                if (full.stock_ref !== updatedStock?.stock_ref) {
                    lastStock = product.critical_stocks.find(item => item?.stock_ref === full.stock_ref)?.quantity
                }
                updatedStock_ = product.critical_stocks.find(item => item?.stock_ref === full.stock_ref) ?
                    { ...product.critical_stocks.find(item => item?.stock_ref === full.stock_ref), quantity: value }
                    :
                    {
                        "product_ref": full.product_ref,
                        "stock_ref": full.stock_ref,
                        "quantity": value,
                        "finished": 0,
                    }

                newExpandedRow = {
                    ...product, critical_stocks: product.critical_stocks.find(item => item?.stock_ref === full.stock_ref) ?
                        product.critical_stocks.map(item => {
                            if (item.stock_ref === full.stock_ref)
                                return { ...item, quantity: value }
                            else return item;
                        }) :
                        [...product.critical_stocks, {

                            "product_ref": full.product_ref,
                            "stock_ref": full.stock_ref,
                            "quantity": value,
                            "finished": 0,

                        }]
                }
                return newExpandedRow;
            } else {
                return product;
            }
        }))
        setUpdatedStock(updatedStock_);
        setExpandedRow(newExpandedRow);
        if (lastStock)
            setLastStock(lastStock);
        // addStock(newExpandedRow);
    }
    return (
        <div className='remains'>
            <div className='remains__filters'>
                <Input
                    size="large"
                    className='remains__filters__input'
                    value={nameFilter}
                    placeholder="Имя продукта"
                    onChange={(e) => setNameFilter(e.target.value)} />
                <Select
                    size="large"
                    className='remains__filters__select'
                    allowClear
                    options={stores.map(store => {
                        return { value: store.ref, label: store.name }
                    })}
                    placeholder="Склад"
                    onChange={setStoreFilter}

                />
                <Radio.Group
                    size="large"
                    className='remains__filters__radio'
                    options={parentIdFilter}
                    onChange={(e) => handleRadioButton(e.target.value)}
                    optionType="button"
                    defaultValue={"all"}
                    buttonStyle="solid"
                />
                <div className='remains__filters__settings'>

                    <Button
                        onClick={() => setVisible(true)}
                        type="link"
                        shape="circle"
                        icon={<SettingOutlined />}
                        size={"large"} />

                </div>
            </div>

            <div className='remains__table'>

                <div style={{ display: "flex", width: "100%", justifyContent: "flex-end", marginTop: 15, marginBottom: 15 }}>

                    <Pagination
                        current={page}
                        total={count}
                        onChange={setPage}
                        pageSize={pageSize}
                        onShowSizeChange={onShowSizeChange}
                        showSizeChanger
                    />
                </div>

                <Table
                    bordered
                    rowClassName={(record, index) => expandedKeys.includes(record.id) ? 'expanded-row' : ''}
                    onExpand={onExpand}
                    loading={loading}
                    expandable={{
                        expandedRowKeys: expandedKeys,
                        expandIconColumnIndex: -1,
                        expandRowByClick: true,
                        columnWidth: "5%",

                        expandedRowRender: (record) => <StyledTable
                            showHeader={false}
                            pagination={false}
                            bordered


                            rowKey={"id"}
                            style={{ marginBottom: 10 }}
                            columns={[
                                {
                                    title: "Имя товара",
                                    dataIndex: "stock_ref",
                                    key: "stock_ref",
                                    width: "40%",
                                    render: (stock_ref) => (
                                        <Typography.Text style={{ fontWeight: 500 }}>
                                            {stores.find(item => item.ref === stock_ref)?.name}
                                        </Typography.Text>
                                    )
                                },
                                // {
                                //     title: "Цена",
                                //     dataIndex: "price",
                                //     key: "price",
                                // },
                                {
                                    title: "Категория",
                                    dataIndex: "parent_name",
                                    key: "parent_name",
                                    width: "22%",
                                },
                                {
                                    title: "Всего",
                                    dataIndex: "quantity",
                                    key: "quantity",
                                    width: "7%",
                                    render: (quantity) => (
                                        <Typography.Text style={{ fontWeight: 500 }}>
                                            {quantity}
                                        </Typography.Text>
                                    )
                                },
                                {
                                    title: "Резерв",
                                    dataIndex: "reserve",
                                    key: "reserve",
                                    width: "7%",
                                    render: (reserve) => (
                                        <Typography.Text style={{ fontWeight: 500 }}>
                                            {reserve}
                                        </Typography.Text>
                                    )
                                },
                                {
                                    title: "Итого",
                                    dataIndex: "result_total",
                                    key: "result_total",
                                    width: "7%",

                                    render: (_, full) => (
                                        <Typography.Text style={{ fontWeight: 500 }}>
                                            {Number.isNaN((full?.quantity - full?.reserve)) ? 0 : full?.quantity - full?.reserve}
                                        </Typography.Text>
                                    )


                                },
                                {
                                    title: "Крит. остаток",
                                    dataIndex: "critical_stocks",
                                    key: "critical_stocks",
                                    width: "10%",
                                    onCell: (record, rowIndex) => {
                                        return {
                                            onClick: (e) => {
                                                setActiveInputStore(record.id)
                                                e.stopPropagation();
                                            },
                                        };
                                    },
                                    render: (_, full) => (
                                        <>
                                            {
                                                activeInputStore === full.id ?
                                                    <Input
                                                        size='large'
                                                        autoFocus
                                                        onBlur={() => {
                                                            setActiveInputStore(null);
                                                            if (lastStock != updatedStock?.quantity && updatedStock) {
                                                                setCtiticalStockLink(updatedStock);
                                                            }
                                                        }}
                                                        onChange={(e) => onChangeCtiticalStockStore(e.target.value, full, record)}
                                                        onClick={(e) => {
                                                            setActiveInputStore(full.id)
                                                            e.stopPropagation();
                                                        }}
                                                        value={record.critical_stocks.find(item => item?.stock_ref === full?.stock_ref)?.quantity} />
                                                    :
                                                    <Typography.Text

                                                        onClick={(e) => {
                                                            setActiveInputStore(full.id)
                                                            e.stopPropagation();
                                                        }}
                                                    >
                                                        {record.critical_stocks.find(item => item?.stock_ref === full?.stock_ref)?.quantity}
                                                    </Typography.Text>
                                            }
                                        </>

                                    )
                                },
                            ]}
                            dataSource={record.stocks}
                        />,

                    }}
                    pagination={false}
                    columns={[
                        {
                            title: (expandedKeys.length !== products.length ?
                                <PlusSquareOutlined onClick={() => expandAll(true)} />
                                :
                                <MinusSquareOutlined onClick={() => expandAll(false)} />
                            ),
                            dataIndex: "expand",
                            key: "expand",
                            render: (_, record) => expandedKeys.includes(record.id) ? (
                                <MinusSquareOutlined onClick={() => { }} />
                            ) : (
                                <PlusSquareOutlined onClick={() => { }} />
                            ),
                        },
                        {
                            title: "Имя товара",
                            dataIndex: "name",
                            key: "name",
                            width: "40%",
                            render: (name) => (
                                <Typography.Text >
                                    {name}
                                </Typography.Text>
                            )
                        },
                        // {
                        //     title: "Цена",
                        //     dataIndex: "price",
                        //     key: "price",
                        // },
                        {
                            title: "Категория",
                            dataIndex: "parent_name",
                            key: "parent_name",
                            width: "25%",
                        },
                        {
                            title: "Всего",
                            dataIndex: "stock_total",
                            key: "stock_total",
                            width: "7%",
                            render: (_, full) => (
                                <Typography.Text>
                                    {full?.stock_count?.stock_total}
                                </Typography.Text>
                            )
                        },
                        {
                            title: "Резерв",
                            dataIndex: "reserve_total",
                            key: "reserve_total",
                            width: "7%",
                            render: (_, full) => (
                                <Typography.Text>
                                    {full?.stock_count?.reserve_total}
                                </Typography.Text>
                            )
                        },
                        {
                            title: "Итого",
                            dataIndex: "result_total",
                            key: "result_total",
                            width: "7%",
                            render: (_, full) => (
                                <Typography.Text>
                                    {Number.isNaN((full?.stock_count?.stock_total - full?.stock_count?.reserve_total)) ? 0 : full?.stock_count?.stock_total - full?.stock_count?.reserve_total}
                                </Typography.Text>
                            )
                        },
                        {
                            title: "Крит. остаток",
                            dataIndex: "critical_stocks",
                            key: "critical_stocks",
                            width: "9%",
                            onCell: (record, rowIndex) => {
                                return {
                                    onClick: (e) => {
                                        setActiveInput(record.id)
                                        e.stopPropagation();
                                    },
                                };
                            },
                            render: (_, full) => (
                                <>
                                    {
                                        activeInput === full.id ?
                                            <Input
                                                size='large'
                                                onBlur={() => {
                                                    setActiveInput(null)
                                                    console.log(lastStock, updatedStock?.quantity, updatedStock);
                                                    if (lastStock != updatedStock?.quantity && updatedStock) {
                                                        setCtiticalStockLink(updatedStock);
                                                    }
                                                }}

                                                autoFocus
                                                onChange={(e) => onChangeCtiticalStock(e.target.value, full)}
                                                onClick={(e) => {
                                                    setActiveInput(full.id)
                                                    e.stopPropagation();
                                                }}
                                                value={full.critical_stocks.find(item => item?.stock_ref === "parent")?.quantity} />
                                            :
                                            <Typography.Text

                                                onClick={(e) => {
                                                    setActiveInput(full.id)
                                                    e.stopPropagation();
                                                }}
                                            >
                                                {full.critical_stocks.find(item => item?.stock_ref === "parent")?.quantity}
                                            </Typography.Text>
                                    }
                                </>

                            )
                        },
                    ]}
                    dataSource={products}
                    rowKey={"id"}
                />
            </div>
            <SettingsModal setVisible={setVisible} visible={visible} />
        </div>
    );
};

export default Remains;