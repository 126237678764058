import React, { useState, useEffect, useMemo } from "react";
import { Card, Typography, Button, Collapse, message, Empty, Input, Pagination, Spin } from "antd";
import { useTypedDispatch, useTypedSelector } from "../../redux/hooks";
import { setHeaderTitle } from "../../redux/slices/layoutSlice";
// import { signOut } from "../../redux/slices/authSlice";
// import { setOrders } from "../../redux/slices/handbookSlice";
import { SyncOutlined, MenuOutlined } from "@ant-design/icons";
import JurInfoItem from "./JurInfoItem";

import "./MyCompanies.scss";
import AddCompany from "./AddCompany";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { setLegals } from "../../redux/slices/handbookSlice";
import { useLocation } from "react-router-dom";
import OrdersWidget from "../Orders/OrdersWidget";
import UpdateCompany from "./UpdateCompany";

type Props = {};

export default function MyCompanies({ }: Props) {
  const dispatch = useTypedDispatch();
  const legals = useSelector((state: RootState) => state.handbook.legals)
  const [editCompanyVisible, setEditCompanyVisible] = useState<boolean>(false);
  const [editCompany, setEditCompany] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fullCount, setFullCount] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(50);
  const [searchData, setSearchData] = useState<string | null>("");
  const [searchTrigger, setSearchTrigger] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const handbook = useTypedSelector((state) => state.handbook);
  const [companyInfo, setCompanyInfo] = useState<any>({});
  const search = useLocation().search
  const searchParams = new URLSearchParams(search)


  const [current, setCurrent] = useState<number>(0);


  useEffect(() => {
    if ((handbook.d_isManager || handbook.d_isAdminManager) && searchParams.get("inn")) {
      setVisible(true);
      fetch(`${SiteUrlDealer}/organization_info/${searchParams.get("inn")}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""
              }`,
          },
        })
        .then((res) => res.json())
        .then((json) => {
          if (json?.founded) {
            setCompanyInfo(json);
            setCurrent(1);
          } else {
            throw "error"
          }
        })
        .catch(e => {
          console.log("Dont get cuty");
          //message.error("Не удалось найти компанию!");
        }).finally(() => {

        });
    }
  }, [])
  useEffect(() => {
    dispatch(setHeaderTitle("Мои компании"));
  }, []);



  useEffect(() => {
    setIsLoading(true);

    const timeout = setTimeout(() => {
      const body = {
        filters: [
          {
            field: "name",
            comparison: "like",
            value: searchData,
          },
        ],
      };

      fetch(
        `${SiteUrlDealer}/organization_with_filters?limit=${pageSize ? pageSize : "10"}&page=${page ? page : 1
        }&order_by=id&order_type=DESC`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""
              }`,
          },
          body: JSON.stringify(body),
          method: "POST",
        }
      )
        .then((res) => res.json())
        .then((json) => {
          console.log(json);
          dispatch(setLegals(json.partners.map(org => {

            return {
              ...org,
              location: org?.location?.[0]?.id,
              city: org?.location?.[0]?.city,
            }
          })))

          setFullCount(json.count);
          setIsLoading(false);
        });
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [searchTrigger, page, pageSize]);

  // const deleteItem = () => {

  // }

  return (
    <div className="my-companies">
      <Card
        // title={<Typography.Title level={ResponsiveTitleLevel}>Фильтры</Typography.Title>}
        bordered={false}
        className="card my-companies__filters"
        style={{ marginBottom: 16 }}
      >
        <div className="my-companies__search-container" style={{ width: "100%" }}>
          <Input
            placeholder="Поиск по названию"
            allowClear
            size="middle"
            // onChange={(e) => handleSearch(e.target.value)}
            className="my-companies__search"
            onChange={(e) => {
              // setNameFilter(e.target.value);
              setSearchData(e.target.value);
              setSearchTrigger(!searchTrigger);
            }}
          />
          {(handbook.d_isManager || handbook.d_isAdminManager) && <div>
            <Button
              size="middle"
              onClick={() => setVisible(true)}
            >
              Добавить компанию
            </Button>
          </div>}
        </div>
      </Card>
      <Card
        // title={<Typography.Title level={ResponsiveTitleLevel}>Юридические лица</Typography.Title>}
        bordered={false}
        className="card"
      >
        {isLoading ? (
          <div className="my-companies__spin">
            <Spin />
          </div>
        ) : legals.length ? (
          <Collapse>
            {legals
              // .filter((f) => f.deleted === 0)
              .map((itm, ind) => (
                <Collapse.Panel
                  collapsible={!itm?.ref?.length ? "disabled" : undefined}
                  style={itm?.ref?.length ? {} : { background: "#edf4fc" }}
                  header={itm.name}
                  key={itm.id}
                  extra={<div>
                    {itm?.ref?.length ?
                      <>
                        <Button type="link" icon={<MenuOutlined />} onClick={(e) => {
                          e.stopPropagation();
                          setEditCompany(itm);
                          setEditCompanyVisible(true);
                        }} />
                      </>
                      :
                      <div className="my-companies__sync">
                        <div className="my-companies__sync__text">Синхронизация</div>
                        <SyncOutlined spin />
                      </div>
                    }
                  </div>}
                >
                  <OrdersWidget orgFilter={itm.ref} />
                </Collapse.Panel>
              ))}
          </Collapse>
        ) : (
          <Empty description="Нет данных" />
        )}

        <div className="my-companies__pagination-container">
          {/* <Pagination defaultCurrent={1} total={50} showSizeChanger /> */}
          <Pagination
            defaultCurrent={1}
            total={fullCount}
            showSizeChanger
            pageSizeOptions={["3", "5", "10", "15", "20", "25", "50", "100"]}
            pageSize={pageSize}
            onShowSizeChange={(page: any, size: any) => {
              // console.log("ps", size);
              setPageSize(size);
            }}
            current={page}
            onChange={(p: any) => {
              // console.log("page", p);
              setPage(p);
            }}
          />
        </div>
      </Card>
      {(handbook.d_isManager || handbook.d_isAdminManager) && <AddCompany current={current} setCurrent={setCurrent} setCompanyInfo={setCompanyInfo} companyInfo={companyInfo} visible={visible} setVisible={setVisible} />}
      {(handbook.d_isManager || handbook.d_isAdminManager) && <UpdateCompany setCompanyInfo={setEditCompany} companyInfo={editCompany} visible={editCompanyVisible} setVisible={setEditCompanyVisible} />}


    </div>
  );
}
