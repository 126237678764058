import React, { useEffect, useState } from 'react';
import { Input, Button } from "antd"
import { PlusCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import "./SelectContact.scss"

const ContactsEmailPhone = ({ contact, updateContact }) => {

    const addItem = (key) => {
        updateContact({
            ...contact,
            [key]: [...contact[key], ""]
        })
    }
    const changeItem = (key, value, index) => {
        updateContact({
            ...contact,
            [key]: contact[key].map((item, index_) => {
                if (index == index_)
                    return value;
                else
                    return item;
            })
        })
    }

    const deleteItem = (key, index) => {
        updateContact({
            ...contact,
            [key]: contact[key].filter((item, index_) => index != index_)
        })
    }
    return (
        <div className='email-phone'>
            <div className='email-phone__item'>
                <div className='email-phone__item__header'>
                    <div>Почта</div>
                    <Button onClick={() => addItem("email")} icon={<PlusCircleOutlined />} />
                </div>
                <div className='email-phone__item__inputs'>
                    {contact.email.map((email, index) => {
                        return <div key={index} className='email-phone__item__inputs__input'>
                            <Input value={email} onChange={(e) => changeItem("email", e.target.value, index)} style={{ marginRight: 6 }} />
                            <Button icon={<DeleteOutlined />} onClick={() => deleteItem("email", index)} danger />
                        </div>
                    })}</div>
            </div>
            <div className='email-phone__item'>
                <div className='email-phone__item__header'>
                    <div>Телефон</div>
                    <Button onClick={() => addItem("phone")} icon={<PlusCircleOutlined />} />
                </div>
                <div className='email-phone__item__inputs'>
                    {contact.phone.map((phone, index) => {
                        return <div key={index} style={{ marginTop: 8 }} className='email-phone__item__inputs__input'>
                            <Input value={phone} onChange={(e) => changeItem("phone", e.target.value, index)} style={{ marginRight: 6 }} />
                            <Button icon={<DeleteOutlined />} onClick={() => deleteItem("phone", index)} danger />
                        </div>
                    })}
                </div>
            </div>
        </div>
    );
};

export default ContactsEmailPhone;