import React, { useState, useEffect, useMemo } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu, message, Spin } from "antd";

type Props = { addGroup: any };

export default function AddDropdown({ addGroup }: Props) {
  const [menuItems, setMenuItems] = useState<any[]>([]);

  useEffect(() => {
    let isCancelled = false;
    const newItem = {
      title: "Новое условие",
      id: Math.random(),
      input_data: [
        {
          input_type: [""],
          input_comparison: "=",
          input_value: "1",
        },
      ],
      output_data: [
        {
          output_type: [""],
          output_comparison: "=",
          output_value: "1",
        },
      ],
    };
    setMenuItems([...menuItems, newItem]);

    fetch(`${SiteUrlDealer}/promotion_condition`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""}`,
      },
      // signal: controller.signal,
      method: "GET",
    })
      .then((res) => res.json())
      .then((json) => {
        if (!isCancelled) {
          // console.log(json);
          setMenuItems([newItem, ...json.content]);
        }
      })
      .catch(() => message.error("Ошибка при загрузке условий"));

    return () => {
      isCancelled = true;
    };
  }, []);

  return (
    <Dropdown
      trigger={["click"]}
      overlay={
        menuItems.length > 0 ? (
          <Menu
            items={menuItems.map((itm) => ({
              key: itm.id,
              label: <div onClick={() => addGroup(itm)}>{itm.title}</div>,
            }))}
          />
        ) : (
          <div style={{ background: "white", height: 60, textAlign: "center", borderRadius: 8 }}>
            <Spin style={{ marginTop: 20 }} />
          </div>
        )
      }
      placement="bottomLeft"
    >
      <Button type="primary" icon={<PlusOutlined />}>
        Добавить
      </Button>
    </Dropdown>
  );
}
