import React, { useState, useEffect, useMemo } from "react";
import { Card, Typography, Select, message, Input, Checkbox } from "antd";
import { PlusOutlined, DeleteOutlined, SendOutlined } from "@ant-design/icons";
import { useTypedDispatch, useTypedSelector } from "../../redux/hooks";
import { editDataType, toSendType } from "./types";

import deliveryJson from "../../json/delivery.json";

import "./LegalAndDelivery.scss";

type Props = {
  mode: "edit" | "display";
  editData?: editDataType;
  toSend: toSendType;
  setAddTrigger: any,
  setToSend?: any;
  isCo?: any;
};

export default function LegalAndDelivery({ mode, editData, toSend, setToSend, isCo = false, setAddTrigger }: Props) {
  const handbookRedux = useTypedSelector((state) => state.handbook);
  const [fetchedOrgs, setFetchedOrgs] = useState<any[]>([]);
  const [comment, setComment] = useState<string>("");
  const [stores, setStores] = useState<any[]>([]);

  // const delivery = deliveryJson;
  useEffect(() => {

    if (editData.mode != "co" && !isCo) {

      const store = localStorage.getItem("defaultStock");
      if (store) {
        setToSend({ ...toSend, store })
      }
      else {
        setToSend({ ...toSend, store: "3cf46df0-c735-11e6-8731-001e6730f61b" })

      }
    }

  }, [])
  useEffect(() => {
    if (toSend?.comment_for_store)
      setComment(
        toSend.comment_for_store !== null && toSend.comment_for_store !== ""
          ? toSend.comment_for_store.toString()
          : undefined
      );
  }, []);

  useEffect(() => {
    if (handbookRedux.partners.length === 0) return;
    let isCancelled = false;
    console.log("editData", editData);

    if ((editData && (editData.mode === "create" || editData.mode === "cart" || editData.mode === "co")) || !editData) {
      // setFetchedOrgs(handbookRedux.partners.length > 0 ? handbookRedux.partners : []);
      fetch(`${SiteUrlDealer}/organization_with_filters`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""
            }`,
        },
        body: JSON.stringify({
          filters: [
            {
              field: "ref",
              comparison: "like",
              value: editData.orgFilter ? editData.orgFilter : localStorage.getItem("defaultPartner"),
            },
          ],
        }),
        method: "POST",
      })
        .then((res) => res.json())
        .then((json) => {
          if (!isCancelled) {

            // setFetchedOrgs(json.partners);
            const orgs = [
              json.partners[0],
              ...(handbookRedux.partners.length > 0
                ? handbookRedux.partners.filter((f) => f.ref !== json.partners[0].ref)
                : []),
            ];


            setFetchedOrgs(orgs);

            // setToSend({
            //   ...toSend,
            //   partner: editData.orgFilter,
            //   organization: orgs.find((f) => f.ref === editData.orgFilter)?.main_contract_organization,
            //   partner_data: orgs.find((f) => f.ref === editData.orgFilter),
            // });
          }
        });
    } else if (editData && editData.mode === "edit") {
      setFetchedOrgs([
        editData.data.partner_data,
        ...(handbookRedux.partners.length > 0 ? handbookRedux.partners : []),
      ]);
    }

    return () => {
      isCancelled = true;
    };
  }, [handbookRedux.partners]);

  useEffect(() => {
    // сделать шобы для менеджера были видны все
    // if (handbookRedux.stores.length !== 0) {
    //   if (handbookRedux.d_isUser) {
    //     const allowedStores = handbookRedux.stores.filter((f) => f.visibility);
    //     const arrOfStoresRefs = allowedStores.map((itm) => itm.ref);

    //     if (!arrOfStoresRefs.includes(toSend.store)) {
    //       const thisStore = handbookRedux.stores.find((f) => f.ref === toSend.store);
    //       setStores(allowedStores.concat(thisStore));
    //     } else {
    //       setStores(allowedStores);
    //     }
    //   } else if (handbookRedux.d_isManager || handbookRedux.d_isAdminManager) {
    //     setStores(handbookRedux.stores);
    //   }
    // }
    setStores(handbookRedux.stores);
  }, [handbookRedux.stores]);

  function handleSearchPartner(search: any) {
    fetch(`${SiteUrlDealer}/organization_with_filters?limit=50&page=1&order_by=id&order_type=DESC`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""}`,
      },
      body: JSON.stringify({
        filters: [
          {
            field: "name",
            comparison: "like",
            value: search,
          },
        ],
      }),
      method: "POST",
    })
      .then((res) => res.json())
      .then((json) => setFetchedOrgs(json.partners));
  }

  return (
    <div className="legal-and-delivery">
      <Card
        title={isCo ? undefined : <Typography.Title level={ResponsiveTitleLevel}>Контрагенты</Typography.Title>}
        bordered={false}
        className="card legal"
      >
        <div className="legal__select-container">
          <div className="legal__select-text">Поставщик</div>

          <div className="legal__select">
            <Select
              placeholder="Не выбран"
              className="legal__select"
              style={{ width: "100%" }}
              value={
                toSend.organization !== null && toSend.organization !== "" ? toSend.organization : undefined
              }
              disabled={(!handbookRedux.d_isManager && !handbookRedux.d_isAdminManager) || mode === "display"}
              bordered={(!handbookRedux.d_isManager && !handbookRedux.d_isAdminManager) || mode === "edit"}
              onChange={(data, full) => {
                // console.log(products, full);
                if (!isCo) {
                  setToSend({
                    ...toSend,
                    organization: data,

                  });
                } else {
                  setToSend({
                    ...toSend,
                    organization: data,
                    organization_name: handbookRedux.orgs.find(item => item.ref === data)?.name
                  });
                }

              }}
            >
              {handbookRedux.orgs.map((itm, ind) => {
                return (
                  <Select.Option key={ind} value={itm.ref}>
                    {itm.name}
                  </Select.Option>
                );
              })}
            </Select>
          </div>
        </div>

        <div className="legal__select-container legal__select-container--margin">
          <div className="legal__select-text">Покупатель</div>
          <div className="legal__select">
            <Select
              placeholder="Не выбран"
              className="legal__select"
              style={{ width: "100%" }}
              showSearch
              // allowClear
              filterOption={(input, option) =>
                // @ts-ignore
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onSearch={handleSearchPartner}
              disabled={mode === "display"}
              bordered={mode === "edit"}
              value={toSend.partner !== null && toSend.partner !== "" ? toSend.partner : undefined}
              onChange={(data, full) => {
                // console.log(products, full);
                //////////////////////////////

                setToSend({
                  ...toSend,
                  partner: data,
                  organization: fetchedOrgs.find((f) => f.ref === data)?.main_contract_organization,
                  partner_data: fetchedOrgs.find((f) => f.ref === data),
                });
                console.log({
                  ...toSend,
                  partner: data,
                  organization: fetchedOrgs.find((f) => f.ref === data)?.main_contract_organization,
                  partner_data: fetchedOrgs.find((f) => f.ref === data),
                });

                if (!isCo)
                  localStorage.setItem("defaultPartner", data);
              }}
            >
              {fetchedOrgs &&
                fetchedOrgs.map((itm, ind) => (
                  <Select.Option key={ind} value={itm.ref}>
                    {itm.name}
                  </Select.Option>
                ))}
            </Select>
          </div>
        </div>
        <div className="legal__select-container legal__select-container--margin">
          <div className="legal__select-text">Склад</div>
          <div className="legal__select">
            <Select
              placeholder="Не выбран"
              className="legal__select"
              style={{ width: "100%" }}
              showSearch
              // allowClear
              filterOption={(input, option) =>
                // @ts-ignore
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onSearch={handleSearchPartner}
              disabled={mode === "display"}
              bordered={mode === "edit"}
              value={toSend?.store}
              onChange={(data, full) => {
                // console.log(products, full);
                //////////////////////////////
                console.log();
                if (!isCo) {
                  setToSend({
                    ...toSend,
                    store: data,
                  });
                } else {
                  setToSend({
                    ...toSend,
                    store: data,
                    store_name: stores.find((f) => f.ref === data)?.name
                  });
                }

                setAddTrigger(prev => {
                  return !prev;
                })
                if (!isCo)
                  localStorage.setItem("defaultStock", data);
              }}
            >
              {stores &&
                stores.map((itm, ind) => (
                  <Select.Option key={ind} value={itm.ref}>
                    {itm.name}
                  </Select.Option>
                ))}
            </Select>
          </div>
        </div>
      </Card>
    </div>
  );
}
