import React, { useState, useEffect, useMemo } from "react";
import { useTypedDispatch, useTypedSelector } from "../../redux/hooks";
import { message, Button, Upload, Input, Select, DatePicker, Switch, Popconfirm, TreeSelect } from "antd";
import { PlusOutlined, SaveOutlined, LoadingOutlined } from "@ant-design/icons";
import type { DatePickerProps, RangePickerProps } from "antd/es/date-picker";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import moment from "moment";
import { IPromotions, conditionItem, IGeneral, IRegion } from "./types";

import RegionSelect from "./RegionSelect";

type Props = { mainPromotionData: IPromotions; generalData: IGeneral; setGeneralData: any };

const beforeUpload = (file: RcFile) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("Вы можете загрузить файл только в форматах JPG/PNG!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Изображенте должно быть менее 2MB!");
  }
  return isJpgOrPng && isLt2M;
};

export default function PromotionGeneralData({ mainPromotionData, generalData, setGeneralData }: Props) {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>();
  const [region, setRegion] = useState<IRegion>("");

  useEffect(() => {
    if (mainPromotionData?.image) {
      setImageUrl(mainPromotionData.image);
    }
    if (mainPromotionData?.region) {
      setRegion(mainPromotionData.region);
    }
  }, [mainPromotionData]);

  useEffect(() => {
    setGeneralData({
      ...generalData,
      region: region,
    });
  }, [region]);

  const onDateChange: DatePickerProps["onChange"] = (date, dateString) => {
    console.log(date, dateString);
    editGeneralData<string>("expire", dateString);
  };

  function editGeneralData<T>(type: string, value: T) {
    const copy: any = { ...generalData };
    copy[type] = value;
    setGeneralData(copy);
  }

  function onImageChange(fileq: any) {
    setLoading(true);
    const file = fileq.file.originFileObj;
    console.log(file);
    let reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = function () {
      // console.log(reader.result);
      fetchOnImageApi(reader.result as string);
    };

    reader.onerror = function () {
      console.log(reader.error);
      setLoading(false);
      message.error("При загрузке иображения произршла ошибка, попробуйте ещё раз.");
    };
  }

  async function fetchOnImageApi(base: string) {
    return fetch(`${SiteUrlDealer}/decode_save_image`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""}`,
      },
      body: JSON.stringify({
        image: base,
      }),
      method: "POST",
    })
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        setImageUrl(json);
        editGeneralData<string>("image", json);
      })
      .catch(() => {
        message.error("При загрузке иображения произошла ошибка, попробуйте ещё раз.");
      })
      .finally(() => setLoading(false));
  }

  function deleteQuery() {
    console.log("delete");
    fetch(`${SiteUrlDealer}/promotion/${mainPromotionData.id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""}`,
      },
      method: "DELETE",
    })
      .then((res) => console.log(res.status))
      .then((json) => {
        // setPromotions(json);
        console.log("promo", json);
        message.success("Удалено");
      })
      .catch((err) => {
        console.error(err);
        message.error("Что-то пошло не так");
      });
  }

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Загрузить изображение</div>
    </div>
  );

  return (
    <div className="promotion-settings__general">
      <div className="promotion-settings__image">
        <Upload
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          beforeUpload={beforeUpload}
          onChange={onImageChange}
        >
          {imageUrl ? (
            <img
              src={imageUrl}
              alt="avatar"
              style={{ width: "100%", height: "100%", borderRadius: 7, objectFit: "cover" }}
            />
          ) : (
            uploadButton
          )}
        </Upload>
      </div>

      <div className="promotion-settings__inputs">
        <Input
          placeholder="Название"
          className="promotion-settings__input-item"
          maxLength={60}
          value={generalData.title}
          onChange={(e: any) => editGeneralData<string>("title", e.target.value)}
        />

        <Select
          placeholder="Тип"
          className="promotion-settings__input-item"
          value={generalData.type != "" ? generalData.type : undefined}
          onChange={(e: any) => editGeneralData<string>("type", e)}
        >
          <Select.Option value="federal">Федеральная акция</Select.Option>
          <Select.Option value="inner">Внутренняя акция</Select.Option>
        </Select>

        <div className="promotion-settings__input-item">
          <RegionSelect region={region} setRegion={setRegion} />
        </div>
      </div>

      <div className="promotion-settings__description">
        <Input.TextArea
          placeholder="Описание"
          value={generalData.description}
          maxLength={100}
          autoSize={true}
          onChange={(e: any) => editGeneralData<string>("description", e.target.value)}
        />
      </div>
      <div className="promotion-settings__other">
        <div className="promotion-settings__date">
          <DatePicker
            value={generalData.expire != "" ? moment(generalData.expire, "YYYY-MM-DD") : undefined}
            picker="date"
            format="YYYY-MM-DD"
            style={{ width: "100%" }}
            placeholder="Дата окончания"
            onChange={onDateChange}
          />
        </div>

        <div className="promotion-settings__switch">
          {/* <div>Суммируется</div>
              <Switch
                size="small"
                checked={generalData.summable}
                onChange={(e: any) => editGeneralData<boolean>("summable", e)}
              /> */}
        </div>
        <Select
          placeholder="Суммируемость"
          className="promotion-settings__input-item"
          value={generalData.summable}
          onChange={(e: any) => editGeneralData<string>("summable", e)}
        >
          <Select.Option value="yes">Суммируется</Select.Option>
          <Select.Option value="no">Не суммируется</Select.Option>
          <Select.Option value="similar_no">Не суммируется с похожими</Select.Option>
        </Select>
        <div className="promotion-settings__switch">
          <div>Активна</div>
          <Switch
            size="small"
            checked={generalData.enable}
            onChange={(e: any) => editGeneralData<boolean>("enable", e)}
          />
        </div>

        <div className="promotion-settings__delete-container">
          <Popconfirm
            placement="topRight"
            title="Вы действительно хотите удалить акцию?"
            onConfirm={deleteQuery}
            onCancel={(e) => e.stopPropagation()}
            okText="Удалить"
            cancelText="Не удалять"
          >
            <Button type="link" danger size="small">
              Удалить
            </Button>
          </Popconfirm>
        </div>
      </div>
    </div>
  );
}
