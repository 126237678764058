import React, { useState, useEffect } from "react";
import { useTypedDispatch, useTypedSelector } from "../../redux/hooks";
import { Card, Typography, Table, Modal, Button, Input, message, Select, Form } from "antd";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";
import { MaskedInput } from "antd-mask-input";
import { setHeaderTitle } from "../../redux/slices/layoutSlice";


import "../../styles/Card.scss";
import CitySelect from "../MyCompanies/CitySelect";
import { IRegion } from "../PromotionSettings/types";
import CreateStoreModal from "./CreateStoreModal";
import UpdateStoreModal from "./UpdateStoreModal";

type Props = {};

export default function Stores({ }: Props) {
  const dispatch = useTypedDispatch();
  const [activeUser, setActiveUser] = useState<any>({});
  const [reloadPage, setReloadPage] = useState<any>(true);
  const [editStore, setEditStore] = useState<any>(undefined);
  const [modalVis, setModalVis] = useState<boolean>(false);
  const [users, setUsers] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [updateModalVis, setUpdateModalVis] = useState<boolean>(false);
  const [createModalVis, setCreateModalVis] = useState<boolean>(false);
  const [createNew, setCreateNew] = useState<boolean>(true);
  const [filterName, setFilterName] = useState<string>("");
  const [stores, setStores] = useState<any[]>([])
  interface ICreate {
    email: string;
    name: string;
    pass: string;
    role: any;
  }
  const [createData, setCreateData] = useState<ICreate>({
    email: "",
    name: "",
    pass: "",
    role: "d_user",
  });
  const [form] = Form.useForm();
  const [updatedIndex, setUpdatedIndex] = useState<number>(0);


  useEffect(() => {
    dispatch(setHeaderTitle("Настройки"));
    setIsLoading(true);
    // такая еботня нужна, чтобы убрать странную ошибку cant update unmounted component
    const timeout = setTimeout(() => {
      let filters = []
      if (filterName != "") {
        filters.push({
          "comparison": "like",
          "field": "name",
          "value": filterName
        })
      }
      // fetch(SiteUrlDealer + "/user_org_index", {
      fetch(SiteUrlDealer + "/stores_with_filters", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""
            }`,
        },
        body: JSON.stringify({
          "filters": filters
        }),
        method: "POST",
      })
        .then((res) => res.json())
        .then((json) => {
          setStores(json);
        }).finally(() => {
          setIsLoading(false);
        });
      // .catch((err) => console.log(err));
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [reloadPage, filterName]);





  return (
    <div>
      <Card
        title={<Typography.Title level={ResponsiveTitleLevel}>Склады</Typography.Title>}
        style={{ marginBottom: 20 }}
        extra={
          <Button
            type="default"
            // shape="circle"
            icon={<PlusOutlined />}
            onClick={() => {
              setCreateModalVis(true);
            }}
          >
            {/* {window.screen.width > 450 && "Добавить пользователя"} */}
            Добавить
          </Button>
        }
        bordered={false}
        className="card"
      >

        <div className="settings_filters">
          <div className="settings_filters__input">
            <Input placeholder="Имя скада" value={filterName} onChange={(e) => setFilterName(e.target.value)} />
          </div>
        </div>
        <Table
          rowKey={"id"}
          //   pagination={true}
          pagination={{
            defaultCurrent: 1,
            total: 500,
            pageSize: 500,
          }}
          rowClassName={(record, index) => record.id === updatedIndex ? 'table-row start-animate ' : 'table-row'}
          bordered={true}
          loading={isLoading}
          columns={[
            {
              title: "Название склада",
              dataIndex: "name",
              key: "name",
            },
            {
              title: "Битрикс ID",
              dataIndex: "bitrix_id",
              key: "bitrix_id",
            },
            {
              title: "Транзитный склад",
              dataIndex: "transit_store",
              key: "transit_store",
              render: (ref, full) => {
                const store = stores.find(store => store.ref === ref);
                return <div>
                  {store ? store?.name : ref}
                </div>
              }
            },
            {
              title: "Отображется на портале",
              dataIndex: "portal_visible",
              key: "portal_visible",
              render: (visible, full) => (
                <div>
                  {visible ? "Да" : "Нет"}
                </div>
              ),

            },
            {
              title: "Доступен для дилеров",
              dataIndex: "dealer_visible",
              key: "dealer_visible",
              render: (visible, full) => (
                <div>
                  {visible ? "Да" : "Нет"}
                </div>
              ),
            },

            {
              title: "",
              dataIndex: "action",
              key: "action",
              render: (_, full) => (
                <a
                  href="#"
                  onClick={(e) => {

                    e.preventDefault();
                    setEditStore(full);
                    setUpdateModalVis(true);
                  }}
                >
                  <EditOutlined />
                </a>
              ),
            },
          ]}
          dataSource={stores}
          scroll={{ x: 300 }}
        />
      </Card>
      <CreateStoreModal
        stores={stores}
        setStores_={setStores}
        visible={createModalVis}
        setUpdatedIndex={setUpdatedIndex}
        setVisible={setCreateModalVis} />
      <UpdateStoreModal
        stores={stores}
        store={editStore}
        setStores_={setStores}
        visible={updateModalVis}
        setUpdatedIndex={setUpdatedIndex}
        setVisible={setUpdateModalVis} />
    </div>
  );
}
