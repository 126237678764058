import React, { useEffect, useState } from 'react';
import { message } from "antd"
const useLoadEntityType = () => {
    const [entityTypes, setEntityTypes] = useState([])
    const [loading, setLoading] = useState(false);
    const createEntityType = (entityType) => {
        setLoading(true);
        fetch(SiteUrlDealer + "/entity_type", {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""
                    }`,
            },
            method: "POST",
            body: JSON.stringify(entityType)
        }).then(res => res.json()).then(response => {
            setEntityTypes([...entityTypes, response]);
        }).catch(e => {
            message.error("Не удалось создать группу");
        }).finally(() => {
            setLoading(false);
        })
    }
    const updateEntityType = (entityType) => {
        setLoading(true);
        fetch(SiteUrlDealer + "/entity_type/" + entityType.id, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""
                    }`,
            },
            method: "PUT",
            body: JSON.stringify(entityType)
        }).then(res => res.json()).then(response => {
            const updatedType = response;
            setEntityTypes(entityTypes.map(type => {
                if (type.id == updatedType.id) {
                    return updatedType;
                }
                return type;
            }));
        }).catch(e => {
            message.error("Не удалось обновить группу");
        }).finally(() => {
            setLoading(false);
        })
    }
    const deleteEntityType = (id) => {
        setLoading(true);
        fetch(SiteUrlDealer + "/entity_type/" + id, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""
                    }`,
            },
            method: "DELETE"
        }).then(response => {
            setEntityTypes(entityTypes.filter(type => type.id != id));
        }).catch(e => {
            message.error("Не удалось удалить группу");
        }).finally(() => {
            setLoading(false);
        })
    }
    const getEntityTypes = () => {
        setLoading(true);
        fetch(SiteUrlDealer + "/entity_type", {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""
                    }`,
            },
            method: "GET"
        }).then(res => res.json()).then(response => {
            console.log("response", response);
            setEntityTypes(response);
        }).catch(e => {
            console.log("ERROR", e);
            message.error("Не удалось загрузить группы");
        }).finally(() => {
            setLoading(false);
        })
    }
    useEffect(() => {
        getEntityTypes();
        return () => {
            setLoading(false);
        }
    }, [])
    return {
        loading,
        entityTypes,
        getEntityTypes,
        createEntityType,
        updateEntityType,
        deleteEntityType
    }
};

export default useLoadEntityType;