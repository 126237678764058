import React, { useEffect } from 'react';
import ProductSelect from './ProductSelect';
import { DeleteOutlined } from "@ant-design/icons"
import { Button, Input, } from 'antd';
import "./RoomSelect.scss"
import CustomInputNumber from '../../../CustomInputNumber/CustomInputNumber';
const ProductItemNomenclature = ({ nomenclature, setNomenclature, product, setFormValues, products }) => {

    const onChangeProduct = (productId) => {
        const newProduct = products.find(item => item.ref === productId);
        const newValue = nomenclature.map(item => {
            if (item.ref === product.ref)
                return {
                    ...newProduct,
                    default_price: newProduct.price
                };
            return item;
        })


        setNomenclature(newValue)
        setFormValues(newValue)


    }
    const onChange = (values) => {

        const newValue = nomenclature.map(item => {
            if (item.ref === product.ref) {
                return {
                    ...item,
                    ...values
                };
            }
            return item;
        })
        setFormValues(newValue);
        setNomenclature(newValue)
    }


    const onChangeNumber = (key, value) => {
        const reg = /^\d*(\.\d*)?$/;
        if (reg.test(value)) {
            if (key == "price") {

                onChange({
                    [key]: Number(value),
                    "discount": -((product.default_price == 0 ? 0 : value * 100 / product.default_price) - 100).toFixed()
                })

            } else {
                onChange({ [key]: Number(value) })
            }

        }
    }


    const onChangeDiscount = (value, max) => {
        const reg = /^-?\d+$/;;
        if (reg.test(value) || value == "" || value == "-") {
            let setted_value = value != "-" && value != "" ? Number(value) : value;
            if (setted_value > max && max) setted_value = max;
            if (setted_value == "") setted_value = 0;
            onChange({
                "price": (product.default_price - product.default_price * (setted_value / 100)).toFixed(),
                "discount": setted_value
            })
        }
    }
    const deleteProduct = (ref) => {
        const newValue = nomenclature.filter(item => item.ref != ref)


        setFormValues(newValue)
        setNomenclature(newValue)
    }
    return (
        <div className='product'>
            <div className='product__row'>
                <Button type="text" icon={<DeleteOutlined />} style={{ marginRight: 5 }} onClick={() => deleteProduct(product.ref)} />
                <div className='product__row__select'>

                    <ProductSelect item={product} products={products} onChange={onChangeProduct} />
                </div>
                <Input
                    style={{
                        width: 150
                    }}
                    prefix={<div style={{ width: 50 }}>Цена</div>}
                    suffix={<div>&nbsp;₽</div>}
                    size="middle"
                    // value={props.itm.sum === null ? 0 : props.itm.sum}
                    maxLength={7}
                    value={product.price}
                    onChange={(e) => onChangeNumber("price", e.target.value)}
                />


            </div>
            <div className='product__row-2'>
                <div className='product__row-2__info'>

                    <CustomInputNumber
                        onChange={(value) => onChangeNumber("amount", value)}
                        minValue={1}
                        value={product?.amount}
                    />
                    <div className="create-order__select__price"
                        style={product.price ? { textDecoration: "line-through", color: "#afafaf" } : {}}>
                        {formatMoney(product.default_price)}&nbsp;₽
                    </div>
                    {product.price && product.price > 0 && <div className="create-order__select__disc">
                        {formatMoney(product.price)}&nbsp;₽
                    </div>}


                </div>
                <div className='product__row-2__sum'>
                    <Input
                        style={{
                            width: 150
                        }}
                        prefix={<div style={{ width: 50 }}>Скидка</div>}
                        suffix={<div>&nbsp;%</div>}
                        size="middle"
                        // value={props.itm.sum === null ? 0 : props.itm.sum}
                        maxLength={7}
                        value={product.discount}
                        onChange={(e) => onChangeDiscount(e.target.value, 100)}
                    />
                </div>
            </div>
        </div>
    );
};

export default ProductItemNomenclature;