import React, { useEffect, useRef, useState } from 'react';
import RoomSelectSelect from './RoomSelectSelect';
import useProductsLoad from './useProductsLoad';
import ProductSelect from './ProductSelect';
import "./RoomSelect.scss"
import { DeleteOutlined } from "@ant-design/icons"
import { Divider, Typography, Button, Input } from "antd"
import ProductItem from './ProductItem';
import autoAnimate from '@formkit/auto-animate';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import ProductItemNomenclature from './ProductItemNomenclature';
const RoomSelect = ({ form, item, formValues, setFormValues }) => {
    const [parent, enableAnimations] = useAutoAnimate(/* optional config */)
    const parentRef = useRef();
    useEffect(() => {
        if (parentRef.current) {
            autoAnimate(parentRef.current);
        }
    }, [parentRef])
    const devRef = useRef();
    useEffect(() => {
        if (devRef.current) {
        }
    }, [devRef])


    const [roomInfo, setRoomInfo_] = useState([]);
    const [nomenclature, setNomenclature] = useState([]);
    const { products } = useProductsLoad();

    useEffect(() => {
        setRoomInfo_(form.getFieldValue("room_type"));
        setNomenclature(form.getFieldValue("nomenclature") ?? []);
    }, [])

    const setRoomInfo = (newRoomInfo) => {
        form.setFieldsValue({ "room_type": newRoomInfo });
        setFormValues({ ...formValues, "room_type": newRoomInfo });
        setRoomInfo_(newRoomInfo);
    }
    const setNomenclatureInfo = (nomenclature) => {
        form.setFieldsValue({ "nomenclature": nomenclature });
        setFormValues({ ...formValues, "nomenclature": nomenclature });
        setNomenclature(nomenclature);
    }
    const updateRoomInfo = (newRoomInfo) => {
        setRoomInfo(newRoomInfo);
    }
    const onChangeRoomName = (room, newName) => {
        setRoomInfo(roomInfo.map((item) => {
            if (item.id === room.id) {
                return {
                    ...item,
                    name: newName
                }
            }
            return item;
        }))

    }

    const selectProduct = (room_id, productId) => {
        const product = products.find(item => item.ref === productId);
        const productAdded = roomInfo?.find(item => item.id == room_id)?.products.find(item => item.ref == productId);
        if (productAdded) return;
        setRoomInfo(roomInfo.map((item) => {
            if (item.id === room_id) {
                const roomDesc = product?.room_desc?.find(item => item.room_id == room_id)?.description;
                const description = roomDesc && roomDesc != "" ? roomDesc : product?.info?.description;
                return {
                    ...item,
                    products: [
                        ...item.products,
                        {
                            ...product,
                            description: description,
                            default_price: product.price,
                            discount: 0,
                            amount: 1
                        }
                    ]
                }
            }
            return item;
        }))

    }
    const onSelectNomenclature = (productId) => {
        const product = products.find(item => item.ref === productId);
        const productAdded = nomenclature?.find(item => item.id == room_id)?.products.find(item => item.ref == productId);
        if (productAdded) return;
        const description = product?.info?.description;


        setNomenclatureInfo([
            ...nomenclature,
            {
                ...product,
                description: description,
                default_price: product.price,
                discount: 0,
                amount: 1
            }
        ])

    }
    const onChangeNumber = (room, key, value) => {
        const reg = /^[+-]?\d+(\.\d+)?$/;
        if (reg.test(value) || value == "" || (value?.[value.length - 1] == "." && value.split('').filter(item => item == ".").length <= 1)) {
            setRoomInfo(roomInfo.map((item) => {
                if (item.id === room.id) {
                    return {
                        ...item,
                        [key]: value
                    }
                }
                return item;
            }))
        }
    }

    const deleteRoom = (room_id) => {
        console.log(room_id);
        setRoomInfo(roomInfo.filter(item => item.id != room_id));
    }

    console.log("nomenclature", nomenclature);
    return (
        <div className='room' ref={parent}>
            <div className='room__item__product-select'>
                <ProductSelect products={products} onChange={onSelectNomenclature} />
            </div>
            <div >
                {nomenclature && nomenclature.map(product => {
                    return <ProductItemNomenclature key={product.ref} product={product} setNomenclature={setNomenclatureInfo} setFormValues={(nomenclature) => form.setFieldsValue({ "nomenclature": nomenclature })} products={products} nomenclature={nomenclature} />
                })}
            </div>

            <Divider >
                Номенклатура по комнатам
            </Divider>
            <RoomSelectSelect item={item} setRoomInfo={updateRoomInfo} roomInfo={roomInfo} />
            {roomInfo && roomInfo.map((room, index) => {
                return <div key={`${room?.id}-${index}`} className='room__item' >
                    <div
                        className='room__item__head'
                    >
                        <Divider>
                            <Typography.Text
                                style={{
                                    minWidth: 150
                                }}
                                editable={
                                    {
                                        onChange: (value) => onChangeRoomName(room, value)
                                    }
                                }
                            >
                                {room.name}
                            </Typography.Text>

                        </Divider>
                        <Button
                            type="link"
                            danger
                            style={{ marginLeft: 20, marginRight: 10 }}
                            icon={<DeleteOutlined />}
                            onClick={() => deleteRoom(room.id)}
                        />
                    </div>
                    <div className='room__sizes'>
                        <Input
                            style={{
                                width: 150
                            }}
                            prefix={<div style={{ width: 50 }}>Длина</div>}
                            suffix={<div>&nbsp;м</div>}
                            size="middle"
                            // value={props.itm.sum === null ? 0 : props.itm.sum}
                            maxLength={7}
                            value={room?.length ?? "0"}
                            onChange={(e) => onChangeNumber(room, "length", e.target.value)}
                        />
                        <Input
                            style={{
                                width: 150
                            }}
                            prefix={<div style={{ width: 60 }}>Ширина</div>}
                            suffix={<div>&nbsp;м</div>}
                            size="middle"
                            // value={props.itm.sum === null ? 0 : props.itm.sum}
                            maxLength={7}
                            value={room?.width ?? "0"}
                            onChange={(e) => onChangeNumber(room, "width", e.target.value)}
                        />
                        <Input
                            style={{
                                width: 150
                            }}
                            prefix={<div style={{ width: 50 }}>Высота</div>}
                            suffix={<div>&nbsp;м</div>}
                            size="middle"
                            // value={props.itm.sum === null ? 0 : props.itm.sum}
                            maxLength={7}
                            value={room?.height ?? "0"}
                            onChange={(e) => onChangeNumber(room, "height", e.target.value)}
                        />
                    </div>
                    <div ref={devRef}>

                        {room?.products.map(product => {
                            return <ProductItem key={product.ref} product={product} setRoomInfo={setRoomInfo} setFormValues={(newRoomInfo) => form.setFieldsValue({ "room_type": newRoomInfo })} products={products} room={room} roomInfo={roomInfo} />
                        })}
                    </div>
                    <div className='room__item__product-select'>
                        <ProductSelect products={products} onChange={(product) => selectProduct(room.id, product)} />
                    </div>
                </div>
            })}
        </div>
    );
};

export default RoomSelect;