export const api_url = "https://projects.vakio.ru/";
//export const api_url = "http://vakio-projects.loc/";

const requestApi = async (uri, parameters) => {
    const token = await localStorage.getItem("accessToken");
    parameters.headers.Authorization = "Bearer " + token
    const response = await fetch(api_url + uri,
        { ...parameters });
    if (!response.ok) {

        throw response
    }
    const contentType = response.headers.get("content-type");
    if (contentType && contentType.indexOf("application/json") !== -1) {

        const json = await response.json();
        return { data: json, status: response.status };
    }

    return { status: response.status };

}

export async function getSpheres() {
    try {
        const response = await requestApi("api/project_shpere",
            {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
            });
        return response
    } catch (error) {

        throw error
    }
}
export async function getProjects() {
    try {
        const response = await requestApi("api/project_dealer",
            {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
            });
        return response
    } catch (error) {

        throw error
    }
}
export async function createProject(project) {
    try {
        const response = await requestApi("api/project_dealer",
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(project)
            });
        return response
    } catch (error) {

        throw error
    }
}
export async function updateProject(project) {
    try {
        const response = await requestApi("api/project_dealer/" + project.id,
            {
                method: 'PUT',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(project)
            });
        return response
    } catch (error) {

        throw error
    }
}
export async function getDevices() {
    try {
        const response = await requestApi("api/project_device",
            {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
            });
        return response
    } catch (error) {

        throw error
    }
}
export async function updateProjects(id, project) {
    try {
        const response = await requestApi("api/project/" + id,
            {
                body: JSON.stringify({ ...project }),
                method: 'PUT',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
            });
        return response
    } catch (error) {
        throw error
    }
}
export async function deleteProject(id) {
    try {
        const response = await requestApi("api/project/" + id,
            {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                method: 'DELETE',
            });
        return response
    } catch (error) {
        throw error
    }
}
export async function getProject(id) {
    try {
        const response = await requestApi("api/project/" + id,
            {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
            });
        return response
    } catch (error) {
        throw error
    }
}
export async function deleteProjects(id) {
    try {
        const response = await requestApi("api/project/" + id,
            {

                method: 'DELETE',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
            });
        return response
    } catch (error) {
        throw error
    }
}

export async function createProjects(project) {
    try {
        const response = await requestApi("api/project",
            {
                body: JSON.stringify({ ...project }),
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
            });
        return response
    } catch (error) {
        throw error
    }
}

export async function createSphere(sphere) {
    try {
        const response = await requestApi("api/project_shpere",
            {
                body: JSON.stringify({ ...sphere }),
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
            });
        return response
    } catch (error) {
        throw error
    }
}

export async function deleteSphere(id) {
    try {
        const response = await requestApi("api/project_shpere/" + id,
            {

                method: 'DELETE',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
            });
        return response
    } catch (error) {
        throw error
    }
}