import { Form, Input, Select } from 'antd';
import React from 'react';
import RoomSelect from './RoomSelect/RoomSelect';
import ClientInfo from './ClientInfo/ClientInfo';
import MultiplicationItem from './MultiplicationItem';
import RoomSizeInput from './RoomSize/RoomSizeInput';
import { getFormatedValue } from '../lib';
import FormCitySelect from './FormCitySelect';
import Entity from './Entity/Entity';

const FormInput = ({
	style,
	item,
	form,
	formValues,
	setFormValues,
	default_entity_id,
}) => {
	return (
		<Form.Item
			key={`${item.key}`}
			label={`${item.label ?? ''}`}
			required={item.required}
			name={`${item.key}`}
			style={style}
			htmlFor={`${item.key}`}
			rules={item.rules}
		>
			{item.type == 'input' ? (
				<Input
					id={`${item.key}`}
					addonBefore={getFormatedValue(item?.addonBefore)}
					addonAfter={getFormatedValue(item?.addonAfter)}
					placeholder={item?.placeholder}
				/>
			) : item.type == 'select' ? (
				<Select
					id={`${item.key}`}
					options={item?.options}
					mode={item.mode}
					placeholder={item?.placeholder}
				/>
			) : item.type == 'city' ? (
				<FormCitySelect
					item={item}
					form={form}
					formValues={formValues}
					setFormValues={setFormValues}
				/>
			) : item.type == 'room_type' ? (
				<RoomSelect
					item={item}
					form={form}
					formValues={formValues}
					setFormValues={setFormValues}
				/>
			) : item.type == 'client_info' ? (
				<ClientInfo item={item} form={form} />
			) : item.type == 'multiplication' ? (
				<MultiplicationItem
					item={item}
					form={form}
					formValues={formValues}
					setFormValues={setFormValues}
				/>
			) : item.type == 'room_size' ? (
				<RoomSizeInput
					item={item}
					form={form}
					formValues={formValues}
					setFormValues={setFormValues}
				/>
			) : item.type == 'entity' ? (
				<Entity
					default_entity_id={default_entity_id}
					item={item}
					form={form}
					formValues={formValues}
					setFormValues={setFormValues}
				/>
			) : (
				<></>
			)}
		</Form.Item>
	);
};

export default FormInput;
