import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface LayoutState {
  headerTitle: any;
  showHeaderSubtitle: boolean;
  headerSubtitle: string;
  headerButtonData: {
    action: string;
    data: any;
  };
}

const initialState: LayoutState = {
  headerTitle: "",
  showHeaderSubtitle: false,
  headerSubtitle: "",
  headerButtonData: {
    action: "",
    data: {},
  },
};

export const layoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    setHeaderTitle: (state, action: PayloadAction<string>) => {
      state.headerTitle = action.payload;
    },
    setHeaderSubtitle: (state, action: PayloadAction<string>) => {
      state.showHeaderSubtitle = true;
      state.headerSubtitle = action.payload;
    },
    hideHeaderSubtitle: (state) => {
      state.showHeaderSubtitle = false;
    },
    setHeaderButtonData: (state, action: PayloadAction<any>) => {
      state.headerButtonData = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setHeaderTitle, setHeaderSubtitle, hideHeaderSubtitle, setHeaderButtonData } =
  layoutSlice.actions;

export default layoutSlice.reducer;
