import React from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Upload, message, Button } from 'antd';
import { useState } from 'react';
import styled from 'styled-components'
import FileService from '../../Editor/components/UploadFile/FileService';

const StyledUpload = styled(Upload)`
    .ant-upload-list-picture-circle-container {
      display: none;
    }
  `;

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
const UploadProduct = ({ product_id, setProductInfo }) => {
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [fileList, setFileList] = useState([
    ]);
    const handleCancel = () => setPreviewOpen(false);
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };

    // {
    //     "type": "dealer_co",
    //     "folder": "image",
    //     "link": "https://dealers.vakio.ru/storage/app/image/7ef3f0bf2761b6a466d346a59a951cba.png",
    //     "thumb_link": "https://dealers.vakio.ru/storage/app/image/thumb_7ef3f0bf2761b6a466d346a59a951cba.png",
    //     "updated_at": "2023-05-29T10:38:50.000000Z",
    //     "created_at": "2023-05-29T10:38:50.000000Z",
    //     "id": 3
    // }

    function onImageChange(fileq) {
        if (fileq.file.status === 'removed') {
            // FileService.deleteFile(fileq.file.uid).then((response) => {
            //     if (response.status === 204) {


            //     }
            // }).catch(error => console.log("DELETE FILE", error));
        } else {
            const file = fileq.file.originFileObj;
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                FileService.uploadFile(reader.result, "device").then(response => {
                    console.log("RESPONSE ", response);
                    setProductInfo(response.data);
                    //setFileList([...fileList.filter(file => file.status !== "uploading"), response.data])
                    setFileList([])

                }).catch(error => {
                    setFileList([])
                    message.error("При загрузке иображения произошла ошибка, попробуйте ещё раз.");
                });
            };

            reader.onerror = function () {
                console.log(reader.error);
                setFileList([])

                message.error("При загрузке иображения произошла ошибка, попробуйте ещё раз.");
            };
        }
    }

    const beforeUpload = (file) => {

        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
        if (!isJpgOrPng) {
            setFileList([])
            message.error("Вы можете загрузить файл только в форматах JPG/PNG!");
            return false;
        }
        const newImage = {
            uid: "123123123",
            name: "",
            url: "",
            status: 'uploading',
        };
        setFileList([...fileList, newImage])
        return isJpgOrPng && 1;

        //const isLt2M = file.size / 1024 / 1024;
        // if (!isLt2M) {
        //     message.error("Изображенте должно быть менее 2MB!");
        // }
        return 1;
    };
    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };
    const uploadButton = (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Button type='link' icon={<UploadOutlined />}>
                Загрузить изображние
            </Button>
        </div>
    );
    return (
        <>
            <StyledUpload
                listType="picture-circle"
                fileList={fileList.map(file => {
                    return { ...file, uid: file.id, status: file?.status ?? "done" }
                })}
                dummyRequest={dummyRequest}
                onPreview={handlePreview}
                onChange={(fileq) => onImageChange(fileq)}
                beforeUpload={(fileq) => beforeUpload(fileq)}
            >
                {uploadButton}
            </StyledUpload>

        </>
    );
};

export default UploadProduct;