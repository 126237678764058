import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from 'universal-cookie';

const cookies = new Cookies();
export const fetchJur = createAsyncThunk("handbook/fetchJur", async (_, { rejectWithValue }) => {
  // тут можно использовать dispatch
  try {
    const response = await fetch(`${SiteUrlDealer}/user_index`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""}`,
      },
      method: "POST",
    });
    if (!response.ok) {
      throw new Error();
    }

    const data = await response.json();
    return data;
  } catch (error) {

    return rejectWithValue(error.message);
  }
});

export const fetchProducts = createAsyncThunk("handbook/fetchProducts", async (_, { rejectWithValue }) => {
  // тут можно использовать dispatch
  try {
    const response = await fetch(`${SiteUrlDealer}/product`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""}`,
      },
      // body: JSON.stringify({
      //   filters: [],
      // }),
      method: "GET",
    });
    if (!response.ok) {
      throw new Error();
    }

    const data = await response.json();
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const fetchPromotions = createAsyncThunk(
  "handbook/fetchPromotions",
  async (_, { rejectWithValue }) => {
    // тут можно использовать dispatch
    try {
      const response = await fetch(`${SiteUrlDealer}/promotion`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""
            }`,
        },
        // body: JSON.stringify({
        //   filters: [],
        // }),
        method: "GET",
      });
      if (!response.ok) {
        throw new Error();
      }

      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export interface HandbookState {
  orders: any[];
  orgs: any[];
  partners: any[];
  legals: any[];
  stores: any[];
  products: any[];
  promotions: any[];
  dealerDiscount: number;
  loading: boolean;
  error: boolean;
  isProjects: boolean;
  user: {},
  d_isUser: boolean;
  d_isManager: boolean;
  d_isAdminManager: boolean;
  p_isUser: boolean;
  p_isSupervisor: boolean;
  p_isBrigadier: boolean;
  p_isCoordinator: boolean;
  p_isAdmin: boolean;
  isSuperAdmin: boolean;
  role: any;
  cartItems: any[];
  bitrixData: any;
}

const initialState: HandbookState = {
  orders: [],
  orgs: [],
  partners: [],
  stores: [],
  legals: [],
  products: [],
  promotions: [],
  dealerDiscount: 0,
  loading: false,
  error: false,
  isProjects: false,
  p_isUser: false,
  p_isSupervisor: false,
  p_isBrigadier: false,
  p_isCoordinator: false,
  p_isAdmin: false,
  user: {},
  role: "d_user",
  d_isUser: false,
  d_isAdminManager: false,
  d_isManager: false,
  isSuperAdmin: false,
  cartItems: [],
  bitrixData: {},
};

export const handbookSlice = createSlice({
  name: "handbook",
  initialState,
  reducers: {
    setOrders: (state, action: PayloadAction<any[]>) => {
      state.orders = action.payload;
    },
    setLegals: (state, action: PayloadAction<any[]>) => {
      state.legals = action.payload;
    },
    setOrgs: (state, action: PayloadAction<any[]>) => {
      state.orgs = action.payload;
    },
    setPartners: (state, action: PayloadAction<any[]>) => {
      state.partners = action.payload;
    },
    setStores: (state, action: PayloadAction<any[]>) => {
      state.stores = action.payload;
    },
    setProducts: (state, action: PayloadAction<any[]>) => {

      state.products = action.payload;
    },
    setPromotions: (state, action: PayloadAction<any[]>) => {
      state.products = action.payload;
    },
    setDealerDiscount: (state, action: PayloadAction<number>) => {
      state.dealerDiscount = action.payload;
    },
    setUserData: (state, action: PayloadAction<any>) => {
      state.user = action.payload;
      // cookies.set("user", action.payload)
      cookies.set("user", action.payload, { domain: '.vakio.ru' })
    },
    setIsProjects: (state, action: PayloadAction<any>) => {
      state.isProjects = action.payload;
    },
    setRole: (state, action: PayloadAction<any>) => {
      state.role = action.payload;
      state.d_isManager = action.payload.includes("d_manager") || false;
      state.d_isAdminManager = action.payload.includes("d_admin_manager") || false;
      state.d_isUser = action.payload.includes("d_user") || false;
      state.p_isAdmin = action.payload?.includes("p_admin") || false;
      state.p_isUser = action.payload?.includes("p_user") || false;
      state.p_isSupervisor = action.payload?.includes("p_supervisor") || false;
      state.p_isBrigadier = action.payload?.includes("p_brigadier") || false;
      state.p_isCoordinator = action.payload?.includes("p_coordinator") || false;
      state.isSuperAdmin = action.payload?.includes("super_admin") || false;
      if (state.isSuperAdmin) {
        state.d_isAdminManager = true;
      }

    },
    setBitrixData: (state, action: PayloadAction<any>) => {
      state.bitrixData = action.payload;
    },
    // addCartItem: (state, action: PayloadAction<any>) => {
    //   const isOld = state.cartItems.find((item: any) => item.id === action.payload.id);
    //   let cartItems1 = null;
    //   if (isOld) {
    //     const items = state.cartItems.map((item: any) => {
    //       if (item.id === isOld.id) {
    //         return {
    //           ...item,
    //           qty: action.payload.qty,
    //         };
    //       }
    //       return item;
    //     });
    //     cartItems1 = [...items];
    //   } else {
    //     cartItems1 = [...state.cartItems, action.payload];
    //   }
    //   // return {
    //   //   ...state,
    //   //   cartItems: cartItems,
    //   // };
    //   state.cartItems = cartItems1;
    // },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchJur.pending, (state) => {
      console.log("pending");
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchJur.fulfilled, (state, action) => {
      state.partners = action.payload.partners;
      state.orgs = action.payload.organizations;
      state.bitrixData = action.payload.bitrix_data;
      state.loading = false;
    });
    builder.addCase(fetchJur.rejected, (state, action) => {
      console.log("thunk reject ", action.payload);
      state.loading = false;
      state.error = true;
    });
    //
    //
    builder.addCase(fetchProducts.pending, (state) => {
      console.log("pending");
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchProducts.fulfilled, (state, action) => {
      // state.products = action.payload.filter((f: any) => f.price != 0 && f.price != null);
      state.products = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchProducts.rejected, (state, action) => {
      console.log("thunk reject", action.payload);
      state.loading = false;
      state.error = true;
    });
    //
    //
    builder.addCase(fetchPromotions.pending, (state) => {
      console.log("pending");
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchPromotions.fulfilled, (state, action) => {
      // state.products = action.payload.filter((f: any) => f.price != 0 && f.price != null);
      state.promotions = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchPromotions.rejected, (state, action) => {
      console.log("thunk reject", action.payload);
      state.loading = false;
      state.error = true;
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  setOrders,
  setOrgs,
  setPartners,
  setStores,
  setProducts,
  setPromotions,
  setDealerDiscount,
  setUserData,
  setRole,
  setBitrixData,
  setLegals,
  setIsProjects
  // addCartItem,
} = handbookSlice.actions;

export default handbookSlice.reducer;
