import autoAnimate from '@formkit/auto-animate';
import { Form } from 'antd';
import React, { useEffect, useRef } from 'react';
import FormInput from './FormInput';

export const FormConstructor = ({
	formItems,
	form,
	setFormValues,
	formValues,
	formItem,
}) => {
	const parentRef = useRef();
	useEffect(() => {
		if (parentRef.current) {
			autoAnimate(parentRef.current);
		}
	}, [parentRef]);
	const default_entity_id = formItem?.default_entity_id ?? null;
	return (
		<Form
			name="form-c"
			layout="vertical"
			form={form}
			onValuesChange={(_, values) => setFormValues(values)}
		>
			<div>
				{formItems
					.filter((item) => item.hide != true)
					.map((item, index) => {
						if (Array.isArray(item)) {
							return (
								<div
									key={index}
									style={{
										display: 'flex',
										width: '100%',
										justifyContent: 'space-between',
									}}
								>
									{item
										.filter((item) => item.hide != true)
										.map((innitem, index, fullArr) => {
											return (
												<FormInput
													default_entity_id={default_entity_id}
													formValues={formValues}
													setFormValues={setFormValues}
													key={innitem.key}
													form={form}
													style={{
														marginBottom: 12,
														width: 100 / fullArr.length + '%',
														marginRight:
															index != fullArr.length - 1
																? 10
																: 0,
														display: 'flex',
														flexDirection: 'column',
													}}
													item={innitem}
												/>
											);
										})}
								</div>
							);
						} else {
							return (
								<FormInput
									default_entity_id={default_entity_id}
									setFormValues={setFormValues}
									formValues={formValues}
									key={item.key}
									form={form}
									style={{ marginBottom: 12 }}
									item={item}
								/>
							);
						}
					})}
			</div>
		</Form>
	);
};
