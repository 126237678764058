import { Button, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setHeaderTitle } from '../../redux/slices/layoutSlice';
import ProjectItem from './components/ProjectItem/ProjectItem';
import "./ProjectPage.scss"
import { getDevices, getProjects, getSpheres } from './ProjectService';
import { PlusOutlined } from '@ant-design/icons';
import UpdateProjectModal from './components/ProjectUpdateModal/UpdateProjectModal';

const ProjectsPage = () => {
    const [projects, setProjects] = useState([]);
    const [devices, setDevices] = useState([])
    const [spheres, setSpheres] = useState([])
    const [targetProject, setTargetProject] = useState({});
    const dispatch = useDispatch();
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [rerenderDevice, setRerenderDevice] = useState(0);
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        dispatch(setHeaderTitle("Проекты"));
    }, []);
    useEffect(() => {


        Promise.all([
            new Promise((resolve, reject) => {
                getProjects().then(response => {
                    console.log("response", response);
                    setProjects(response.data)
                }).finally(() => {
                    resolve();
                })
            })

            ,
            new Promise((resolve, reject) => {
                getSpheres().then(response => {
                    console.log("response", response);
                    setSpheres(response.data)
                }).finally(() => {
                    resolve();
                })
            })

            ,
            new Promise((resolve, reject) => {
                getDevices().then(response => {
                    console.log("response", response);
                    setDevices(response.data)
                }).finally(() => {
                    resolve();
                })
            })
        ]).then(() => {

        }).finally(() => {
            setLoading(false);
        })
    }, [])

    return (
        <div className='projects-list'>
            <Button

                icon={<PlusOutlined />}
                className="create-button"
                style={{ marginBottom: 15 }}
                size="large"
                onClick={() => { console.log("THERE"); setShowCreateModal(true); }}
            >
                Новый проект
            </Button>
            <UpdateProjectModal
                create={true}
                project={{ title: "", description: "" }}
                devices={devices}
                setProjects={setProjects}
                showUpdateModal={showCreateModal}
                setShowUpdateModal={setShowCreateModal} />
            <UpdateProjectModal
                create={false}
                setRerenderDevice={setRerenderDevice}
                devices={devices}
                project={targetProject}
                setProjects={setProjects}
                showUpdateModal={showUpdateModal}
                setShowUpdateModal={setShowUpdateModal} />
            {
                !loading ? <>
                    {projects.length > 0 ?
                        projects.map(project => {
                            return <ProjectItem
                                targetProject={targetProject}
                                setTargetProject={setTargetProject}
                                setShowUpdateModal={setShowUpdateModal}
                                showUpdateModal={showUpdateModal}
                                setProjects={setProjects}
                                key={project.id}
                                setRerenderDevice={setRerenderDevice}
                                rerenderDevice={rerenderDevice}
                                project={project} devices={devices} spheres={spheres} />
                        })
                        :
                        <div>Проекты не найдены</div>}
                </> :
                    <div className='project-spin-container'>
                        <div className='project-spin'>
                            <Spin />
                        </div>
                    </div>
            }
        </div>
    );
};

export default ProjectsPage;