import { Form, Input, Select, message, Modal, Typography } from 'antd';
import Button from 'antd/lib/button';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLegals } from '../../redux/slices/handbookSlice';
import { RootState } from '../../redux/store';
import { IRegion } from '../PromotionSettings/types';
import CitySelect from './CitySelect';
import "./MyCompanies.scss"
import BitrixFormLists from './BitrixFormLists';
import SelectContact from './SelectContact/SelectContact';
import { useTypedSelector } from '../../redux/hooks';
const UpdateCompany = ({ companyInfo, setCompanyInfo, visible, setVisible }) => {
    const [region, setRegion] = useState<IRegion>("");
    const [contacts, setContacts] = useState<any[]>([]);
    const dispatch = useDispatch();
    const legals = useSelector((state: RootState) => state.handbook.legals)
    const [loading, setLoading] = useState<boolean>(false);
    const [form] = Form.useForm();
    const [user_id, setUserId] = useState(0);
    const d_isAdminManager = useTypedSelector((state) => state.handbook.d_isAdminManager);

    useEffect(() => {
        if (visible) {
            fetch(`${SiteUrlDealer}/get_org_contacts`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""
                            }`,
                    },
                    body: JSON.stringify({ "org_ref": companyInfo?.ref })
                })
                .then((res) => res.json())
                .then(res => {
                    console.log(res);
                    setContacts(res.map(contact => {
                        return {
                            ...contact,
                            phone: contact?.phone ? contact?.phone.split(",") : [],
                            email: contact?.email ? contact?.email.split(",") : [],
                        }
                    }));
                }).catch((e) => {

                }).finally(() => {

                })
        }
    }, [visible])

    useEffect(() => {
        const userRaw = localStorage.getItem("user");
        if (userRaw) {
            const user_id = JSON.parse(userRaw)?.id;
            setUserId(user_id)
        }
    }, [])
    useEffect(() => {
        if (visible)
            console.log("companyInfo?.location", companyInfo);
        if (companyInfo?.city && companyInfo?.location) {

            setRegion({ label: companyInfo?.city, value: companyInfo?.id })
        }
    }, [companyInfo, visible])
    useEffect(() => {
        if (visible) {

            form.setFieldsValue({ deffered_period: companyInfo?.deffered_period });
            form.setFieldsValue({ industry_id: companyInfo?.industry_id });
            form.setFieldsValue({ contact_company: companyInfo?.contact_company });
            form.setFieldsValue({ category_dealer: companyInfo?.category_dealer });
        } else {
            form.resetFields()

        }
    }, [visible])
    useEffect(() => {
        form.setFieldsValue({ city: region.value })
    }, [region?.value, visible])


    const updateOrganization = () => {

        console.log();
        const newContacts = contacts.filter(contact => contact?.tmp_id);
        const updateContacts = contacts.filter(contact => contact?.tmp_id == undefined);
        const orgContacts = {
            "newContacts": newContacts,
            "updateContacts": updateContacts,
        };

        form
            .validateFields()
            .then((values) => {
                console.log(values);
                setLoading(true);

                // dispatch(setLegals([{ ...values, fact_address: values.jur_address, location: region.value, id: 123123 }, ...legals]));
                // return;

                fetch(`${SiteUrlDealer}/organization/${companyInfo.ref}`,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""
                                }`,
                        },
                        body: JSON.stringify({ organization: { ...companyInfo, ...values, contacts: orgContacts, user_id: user_id, fact_address: values.jur_address, location: region.value } })
                    })
                    .then((res) => res.json())
                    .then(company => {
                        if (company.company_is_found) {
                            message.error("Такая компания уже добавлена!")
                        } else {

                            message.success("Компания отправлена на синхронизацию!")
                            //setLegals(legals => [...company, ...legals])
                            dispatch(setLegals([...company, ...legals]));
                            setVisible(false);
                        }
                    }).catch((e) => {
                        console.log(e);

                        message.error("Не удалось добавить компанию!")

                    }).finally(() => {
                        setLoading(false);

                    })

            }).catch(() => {

                console.log("Not valid");
            })
    }
    return (
        <Modal
            centered
            visible={visible}
            // visible={true}
            title={
                <Typography.Title level={4} style={{ marginBottom: 0 }}>
                    Обновить компанию
                </Typography.Title>
            }
            onCancel={() => setVisible(false)}
            // onOk={() => {
            //   validateData();
            // }}

            onOk={() => updateOrganization()}
            okText="Сохранить"
            confirmLoading={loading}
            cancelText="Отмена"
            closable={true}
        >
            <Form
                name="wrap"
                labelCol={{ flex: "110px" }}
                labelAlign="left"
                labelWrap
                initialValues={companyInfo}
                wrapperCol={{ flex: 1 }}
                colon={false}
                form={form}
            >



                <Form.Item
                    label="ИНН"
                    required
                    name="inn"
                    rules={[
                        {
                            required: true,
                            message: "Поле обязательно к заполнению",
                        },
                    ]}
                >
                    <Input placeholder="Введите ИНН" />
                </Form.Item>
                {form.getFieldValue("jurfiz") === "jur" && <Form.Item
                    label="КПП"
                    required
                    name="kpp"
                    rules={[
                        {
                            required: true,
                            message: "Поле обязательно к заполнению",
                        },
                    ]}
                >
                    <Input placeholder="Введите КПП" />
                </Form.Item>}

                <Form.Item
                    label="Имя"
                    required
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: "Поле обязательно к заполнению",
                        },
                    ]}
                >
                    <Input placeholder="Введите имя" />
                </Form.Item>

                <Form.Item
                    label="Полное имя"
                    required
                    name="full_name"
                    rules={[
                        {
                            required: true,
                            message: "Поле обязательно к заполнению",
                        },
                    ]}
                >
                    <Input placeholder="Введите полное имя" />
                </Form.Item>

                <Form.Item
                    label="Юридический адрес"
                    required
                    name="jur_address"
                    rules={[
                        {
                            required: true,
                            message: "Поле обязательно к заполнению",
                        },
                    ]}
                >

                    <Input placeholder="Введите адрес" />
                </Form.Item>

                <Form.Item
                    label="Тип покупателя"
                    required
                    name="jurfiz"
                    rules={[
                        {
                            required: true,
                            message: "Поле обязательно к заполнению",
                        },
                    ]}
                >
                    <Select
                        placeholder={"Выберите тип покупателя"}
                        options={[
                            { value: "jur", label: "Юридическое лицо" },
                            { value: "fiz", label: "ИП/Физическое лицо" },
                        ]} />
                </Form.Item>
                <Form.Item
                    label="Город"
                    required
                    name="city"
                    rules={[
                        {
                            required: true,
                            message: "Поле обязательно к заполнению",
                        },
                    ]}
                >
                    <div className="promotion-settings__input-item">
                        <CitySelect region={region} setRegion={setRegion} />
                    </div>
                </Form.Item>
                <BitrixFormLists />
                <Form.Item
                    label="Контакты"
                    name="contact"
                >
                    <div className="promotion-settings__input-item">
                        <SelectContact user_id={user_id} contacts={contacts} setContacts={setContacts} />
                    </div>
                </Form.Item>


                {d_isAdminManager && <Form.Item
                    label="Отсрочка (дни)"
                    required
                    name="deffered_period"
                    rules={[
                        {
                            required: true,
                            message: "Поле обязательно к заполнению",
                        },
                    ]}
                >
                    <Input placeholder="Введите количество дней отсрочки" />
                </Form.Item>}
            </Form>
        </Modal>
    );
};

export default UpdateCompany;