import React, { useEffect, useState } from 'react';
import PatternEntity from '../../../EditPatternPage/PatternEntity/PatternEntity';

const EntityItem = ({ entity, updateEntity }) => {
    const [localEntity, setLocalEntity] = useState(entity);

    useEffect(() => {
        updateEntity(localEntity)
    }, [localEntity])


    return <PatternEntity patternEntity={entity} setPatternEntity={setLocalEntity} holder={"EntityItem" + entity?.id} readOnly={false} />
};

export default EntityItem;