import React, { useEffect, useState } from 'react';
import { Form, Modal, Input, Button } from "antd"
import { FormConstructor } from '../FormСonstructor';
import useSaveCOForm from './useSaveCOForm';
import PatternSelect from '../EditPatternPage/PatternSelect/PatternSelect';
import useLoadPattern from '../EditPatternPage/Pattern/useLoadPattern';
import { useHistory } from 'react-router-dom';

const COFormModal = ({ visible, setVisible, }) => {
    const { patterns } = useLoadPattern({ withLoad: true });
    const [pattern, setPattern] = useState({})
    const { loading: loading, formItems, refreshData, create } = useSaveCOForm({ withLoad: false });
    const [name, setName] = useState("Коммерческое предложение")
    const [form] = Form.useForm();
    const history = useHistory();
    useEffect(() => {
        if (visible) {
            form.resetFields();
            refreshData();
        }
    }, [visible])
    const closeModal = () => {
        setVisible(false);
    }

    const submit = () => {
        form.validateFields()
            .then(values => {
                const modifiedPattern = pattern?.id ? {
                    ...pattern,
                    pattern: pattern.pattern.map(item => {
                        if (item.type === "main") {
                            return {
                                ...item,
                                body: values
                            }
                        }
                        return item;
                    })
                }
                    :
                    {
                        id: new Date().getTime() + 1,
                        name: "Новый шаблон",
                        pattern: [
                            {
                                type: "main",
                                id: new Date().getTime()
                            }
                        ]
                    }
                create({
                    name: name,
                    pattern: modifiedPattern.pattern,
                }).then(newCo => {
                    history.push("/commercial-offer/edit/" + newCo.uuid)
                });
            })
            .catch(e => {
                console.log(e);
            })
    }
    const openPatternEditor = () => {
        window.open(domain + "/edit-pattern/" + (pattern.id ? pattern.id : ""), '_blank', 'noreferrer')
    }
    return (
        <Modal
            title={"Создать КП"}
            style={{ top: 20 }}
            visible={visible}
            confirmLoading={loading}
            onCancel={closeModal}
            onOk={submit}
            okText={"Далее"}
        >



            <div style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: 12
            }}>
                <div style={{
                    paddingBottom: 8,
                    fontSize: 14
                }}>
                    Название
                </div>
                <Input value={name} onChange={(e) => setName(e.target.value)} />
            </div>
            <div style={{
                width: "100%",
                display: "flex",
                alignItems: "flex-end"
            }}>
                <PatternSelect patterns={patterns} pattern={pattern?.id} setNewPattern={setPattern} />
                <Button
                    onClick={openPatternEditor}
                    style={{ marginLeft: 10 }}
                >
                    Редактор шаблонов
                </Button>
            </div>
        </Modal>
    );
};

export default COFormModal;