import React, { useState, useEffect, useMemo } from "react";
import { useTypedDispatch, useTypedSelector } from "../../redux/hooks";
import { Card, Typography, message, Button, Input, Select, Divider, InputNumber } from "antd";
import { PlusOutlined, SaveOutlined, DeleteOutlined } from "@ant-design/icons";
import { useRouteMatch, Link, useHistory } from "react-router-dom";
import { IProductItem } from "./types";

type Props = {
  itm: IProductItem;
  fullData: IProductItem[];
  setFullData: any;
  ind: number;
  role: "term" | "benefit";
  trigger: boolean;
  setTrigger: any;
};

export default function ProductItem({ itm, fullData, setFullData, ind, role, trigger, setTrigger }: Props) {
  const handbookRedux = useTypedSelector((state) => state.handbook);

  function handleDeleteItem(item: any) {
    // console.log(item);
    const filtered = fullData.filter((f: any) => f.id != item.id);
    setFullData(filtered);
    setTrigger(!trigger);
  }

  function handleSelectItemType(val: "product" | "money") {
    // console.log(val);
    const newArr = fullData.map((obj: any) => {
      if (obj.id === itm.id) {
        return {
          ...obj,
          type: val == "product" ? [""] : ["percent"],
        };
      } else {
        return obj;
      }
    });
    setFullData(newArr);
    setTrigger(!trigger);
  }

  function handleSelectProducts(itm: IProductItem, val: string[] | []) {
    // console.log("sel prod", val);
    const newArr = fullData.map((obj: any) => {
      if (obj.id === itm.id) {
        return {
          ...obj,
          type: val,
        };
      } else {
        return obj;
      }
    });
    setFullData(newArr);
    setTrigger(!trigger);
  }

  function handleSelectComparison(val: ">" | "<" | "=" | "%" | "=*") {
    // console.log(val);
    const newArr = fullData.map((obj: any) => {
      if (obj.id === itm.id) {
        return {
          ...obj,
          comparison: val,
        };
      } else {
        return obj;
      }
    });
    setFullData(newArr);
    setTrigger(!trigger);
  }

  function handleProductsAmount(itm: IProductItem, val: number) {
    // console.log(val);
    const newArr = fullData.map((obj: any) => {
      if (obj.id === itm.id) {
        return {
          ...obj,
          amount: val !== null ? val : 1,
        };
      } else {
        return obj;
      }
    });
    setFullData(newArr);
    setTrigger(!trigger);
  }

  // const comparisonOptions = role == 'term' ?

  const comparison = (
    <Select
      placeholder="Тип сравнения"
      className="promotion-details__comparison"
      value={itm.comparison}
      onChange={handleSelectComparison}
      getPopupContainer={(trigger) => trigger.parentNode}
    >
      {role == "term" ? (
        <>
          <Select.Option value="=">Равно</Select.Option>
          <Select.Option value="%">Кратно</Select.Option>
          <Select.Option value=">">Больше</Select.Option>
          <Select.Option value="<">Меньше</Select.Option>
        </>
      ) : (
        <>
          <Select.Option value="=">Равно</Select.Option>
          <Select.Option value="%">Кратно</Select.Option>
          <Select.Option value="=*">За каждый</Select.Option>
        </>
      )}
    </Select>
  );

  return (
    <div>
      <div className="promotion-details__item">
        <Button
          icon={<DeleteOutlined />}
          className="promotion-details__delete"
          disabled={
            fullData.length == 1 ||
            (fullData.length != 1 && (fullData[0].type[0] == "" || fullData[0].type[0] == undefined))
          }
          shape="circle"
          type="text"
          onClick={() => handleDeleteItem(itm)}
        />
        <div className="promotion-details__items-container">
          <div className="promotion-details__type">
            <Select
              placeholder="Тип условия"
              className="promotion-details__type-input"
              value={itm.type[0] == "money" ? "money" : "product"}
              onChange={handleSelectItemType}
              getPopupContainer={(trigger) => trigger.parentNode}
            >
              <Select.Option value="product">
                {role == "term" ? "Товары в заказе" : "В подарок"}
              </Select.Option>
              <Select.Option value="money">{role == "term" ? "Сумма заказа" : "Скидка"}</Select.Option>
            </Select>
          </div>
          <div className="promotion-details__item-select">
            {itm.type[0] != "money" && (
              <Select
                mode="multiple"
                // allowClear
                placeholder="Выберите товары"
                style={{ width: "100%" }}
                value={itm.type.filter((f: any) => f != "")}
                // value={itm.type}
                onChange={(prods) => handleSelectProducts(itm, prods)}
                filterOption={(input, option) =>
                  // @ts-ignore
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                {handbookRedux.products.map((itm) => (
                  <Select.Option value={itm.ref} key={itm.id}>
                    {itm.full_name}
                  </Select.Option>
                ))}
              </Select>
            )}
          </div>
          <div className="promotion-details__item-number">
            <InputNumber
              min={1}
              max={1000}
              defaultValue={1}
              value={itm.amount}
              addonBefore={comparison}
              addonAfter="шт."
              onChange={(amount) => handleProductsAmount(itm, amount)}
            />
          </div>
        </div>
      </div>

      {ind != fullData.length - 1 && <Divider orientation="left">И</Divider>}
    </div>
  );
}
