import React from 'react';
import ReactDOM from 'react-dom'
import ImageWithDescCard from './ImageWithDescCard';

class ImageWithDesc {
    static get toolbox() {
        return {
            title: 'Изображение с описанием',
            icon: '<svg width="17" height="15" viewBox="0 0 336 276" xmlns="http://www.w3.org/2000/svg"><path d="M291 150V79c0-19-15-34-34-34H79c-19 0-34 15-34 34v42l67-44 81 72 56-29 42 30zm0 52l-43-30-56 30-81-67-66 39v23c0 19 15 34 34 34h178c17 0 31-13 34-29zM79 0h178c44 0 79 35 79 79v118c0 44-35 79-79 79H79c-44 0-79-35-79-79V79C0 35 35 0 79 0z"/></svg>'
        };
    }
    static get enableLineBreaks() {
        return true;
    }
    static get pasteConfig() {
        return true;
    }

    static get isReadOnlySupported() {
        return true;
    }
    static get sanitize() {
        return {
            url: true,
            video: true,
            h1: true,
            h2: true,
            h3: true,
            h4: true,
            h5: true,
            h6: true,
            div: true,
            b: true,
            a: true,
            i: true,
            strong: true
        }
    }
    constructor({ readOnly, data }) {
        this.readOnly = readOnly;

        this.data = {
            image: data?.image ?? null,
            imageSize: data?.imageSize ?? 2,
            headerText: data?.headerText ?? "Заголовок!",
            description: data?.description ?? "Описание!",
            headerSize: data?.headerSize ?? 20,
            descriptionSize: data?.descriptionSize ?? 15
        }

        this.nodes = {
            holder: null,
        };
    }

    render() {
        const rootNode = document.createElement('div');
        this.nodes.holder = rootNode;

        const onDataChange = (newData) => {
            this.data = {
                ...newData
            };
        }


        ReactDOM.render(<ImageWithDescCard data={this.data} readOnly={this.readOnly} onDataChange={onDataChange} />,
            rootNode);

        return this.nodes.holder;
    }

    save(blockContent) {
        return this.data;
    }

}

export default ImageWithDesc;