import React, { useEffect, useState } from 'react';
import LegalAndDelivery from '../../../LegalAndDelivery/LegalAndDelivery';

const ClientInfo = ({ item, form }) => {
    const [clientInfo, setClientInfo] = useState({});
    const [addTrigger, setAddTrigger] = useState(false);

    useEffect(() => {
        setClientInfo(form.getFieldValue("client_info"));
    }, [])

    const setNewClientInfo = (info) => {
        setClientInfo(info);
        form.setFieldsValue({ "client_info": info });
        console.log("info", info);
    }
    return (
        <div style={{ width: "100%" }}>
            <LegalAndDelivery mode="edit" editData={{
                mode: "cart"
            }}
                isCo={true}
                toSend={clientInfo}
                setToSend={setNewClientInfo}
                setAddTrigger={setAddTrigger}
            />
        </div>
    );
};

export default ClientInfo;