import React, { useState, useEffect, useMemo } from "react";
import { Card, Typography, Select, InputNumber, Divider, Button, Table, Input, Space, Modal } from "antd";
import { SearchOutlined, DeleteOutlined, PlusOutlined, PercentageOutlined } from "@ant-design/icons";
import { useTypedDispatch, useTypedSelector } from "../../redux/hooks";
import { setHeaderTitle } from "../../redux/slices/layoutSlice";

import "./PersonalDiscounts.scss";

type Props = {};

export default function PersonalDiscounts({}: Props) {
  const handbookRedux = useTypedSelector((state) => state.handbook);
  const dispatch = useTypedDispatch();

  interface IDiscounts {
    ref: string;
    discount: number;
    id: number;
    name: string;
  }
  const discountDefault = {
    ref: Math.random().toString(),
    discount: +localStorage.getItem("discount"),
    id: 0,
    name: "",
  };
  const [discounts, setDiscounts] = useState<IDiscounts[]>([discountDefault]);
  const [lastIndex, setLastIndex] = useState<number>(0);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [inputDiscount, setInputDiscount] = useState<number>(26);
  const [showModal, setshowModal] = useState<boolean>(false);
  const [filteredOrgs, setFilteredOrgs] = useState<any[]>([]);

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any) => {
      setSelectedRows(selectedRows);
      // console.log(
      //   `selectedRowKeys: ${selectedRowKeys}`,
      //   "selectedRows: ",
      //   selectedRows
      // );
    },
    getCheckboxProps: (record: any) => ({
      disabled: record.name === "Disabled User", // Column configuration not to be checked
      name: record.name,
    }),
  };

  useEffect(() => {
    console.log(selectedRows);
  }, [selectedRows]);

  useEffect(() => {
    dispatch(setHeaderTitle("Спецскидки"));
  }, []);

  useMemo(() => {
    setLastIndex(lastIndex + 1);
  }, [discounts.length]);

  const handleSearch = (val: string) => {
    console.log(val);
  };

  const handleConfirmDiscount = () => {
    console.log("rows", selectedRows, "discount", inputDiscount);
  };

  return (
    <div>
      <Card
        title={<Typography.Title level={ResponsiveTitleLevel}>Скидки по товарам</Typography.Title>}
        bordered={false}
        className="card personal-discounts"
        extra={
          <Button type="primary" icon={<PlusOutlined />} onClick={() => setshowModal(true)}>
            Новая скидка
          </Button>
        }
      >
        <Table
          // className="creating-table"
          bordered={true}
          pagination={{ showSizeChanger: true }}
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
          dataSource={[
            {
              key: "1",
              item_ref: "7a6e0c3b-3fc1-11e9-b3dc-001e6730f61a",
              organization_ref: "4d9835fc-da36-11e7-b633-001e6730f61a",
              discount: 32,
            },
            {
              key: "2",
              item_ref: "5dffb5ef-0807-11e9-b536-001e6730f61a",
              organization_ref: "a3acdb2d-db0c-11e7-b633-001e6730f61a",
              discount: 37,
            },
            {
              key: "3",
              item_ref: "c4d6b0b5-1a12-11e9-b536-001e6730f61a",
              organization_ref: "4d9835fc-da36-11e7-b633-001e6730f61a",
              discount: 12,
            },
            {
              key: "4",
              item_ref: "04c02925-bac1-11e7-b633-001e6730f61a",
              organization_ref: "a3acdb2d-db0c-11e7-b633-001e6730f61a",
              discount: 32,
            },
            {
              key: "5",
              item_ref: "2f182f78-c820-11e7-b633-001e6730f61a",
              organization_ref: "4d9835fc-da36-11e7-b633-001e6730f61a",
              discount: 32,
            },
          ]}
          columns={[
            {
              title: "Товар",
              dataIndex: "item_ref",
              key: "item_ref",
              render: (key) => <div>{handbookRedux.products.find((f) => f.ref == key)?.name}</div>,
            },
            {
              title: "Юр. лицо",
              dataIndex: "organization_ref",
              key: "organization_ref",
              render: (key) => <div>{handbookRedux.partners.find((f) => f.ref == key)?.name}</div>,
            },
            {
              title: "Скидка",
              dataIndex: "discount",
              key: "discount",
              width: window.screen.width > 450 ? 100 : undefined,
              align: "right",
              render: (key) => <div>{key}%</div>,
            },
          ]}
          scroll={{ x: 500 }}
          summary={() => (
            <Table.Summary fixed={"bottom"}>
              <Table.Summary.Row style={{ background: "#FAFAFA" }}>
                <Table.Summary.Cell index={0} colSpan={4}>
                  <div className="personal-discounts__control">
                    <Space>
                      <Button
                        type="primary"
                        icon={<DeleteOutlined />}
                        disabled={selectedRows.length === 0}
                        onClick={() => console.log(1)}
                      >
                        Удалить
                      </Button>

                      {/* <Button
                        type="primary"
                        icon={<EditOutlined />}
                        disabled={selectedRows.length === 0}
                        onClick={() => console.log(1)}
                      >
                        Редактировать
                      </Button> */}
                    </Space>

                    <Space>
                      <div style={{ marginLeft: "auto" }}>Редактировать скидку:</div>
                      <InputNumber
                        min={1}
                        max={99}
                        disabled={selectedRows.length === 0}
                        value={inputDiscount}
                        onChange={(val: any) => setInputDiscount(val)}
                        addonAfter={<PercentageOutlined />}
                        style={{ width: "100px" }}
                      />
                      <Button
                        type="primary"
                        disabled={selectedRows.length === 0}
                        onClick={() => {
                          handleConfirmDiscount();
                        }}
                      >
                        Отправить
                      </Button>
                    </Space>
                  </div>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          )}
          sticky
        />
      </Card>
      <Modal
        title="Добавить скидку"
        visible={showModal}
        onOk={() => console.log(1)}
        confirmLoading={false}
        onCancel={() => setshowModal(false)}
      >
        <Select
          showSearch
          placeholder="Выберите товар"
          optionFilterProp="children"
          className="personal-discounts__select"
          // onChange={onChange}
          // onSearch={onSearch}
          filterOption={(input, option) =>
            (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
          }
        >
          {handbookRedux.products.map((itm) => (
            <Select.Option key={itm.ref} value={itm.ref} className="personal-discounts__select">
              {itm.name}
            </Select.Option>
          ))}
        </Select>
        <div className="personal-discounts__search">
          <Input
            placeholder="Поиск по организациям"
            onChange={(e) => handleSearch(e.target.value)}
            prefix={<SearchOutlined />}
          />
        </div>

        <Table
          // className="creating-table"
          bordered={true}
          pagination={{ showSizeChanger: true }}
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
          dataSource={
            [
              // {
              //   key: "1",
              //   organization_ref: "4d9835fc-da36-11e7-b633-001e6730f61a",
              //   discount: 32,
              // },
              // {
              //   key: "2",
              //   organization_ref: "a3acdb2d-db0c-11e7-b633-001e6730f61a",
              //   discount: 37,
              // },
              // {
              //   key: "3",
              //   organization_ref: "4d9835fc-da36-11e7-b633-001e6730f61a",
              //   discount: 12,
              // },
              // {
              //   key: "4",
              //   organization_ref: "a3acdb2d-db0c-11e7-b633-001e6730f61a",
              //   discount: 32,
              // },
              // {
              //   key: "5",
              //   organization_ref: "4d9835fc-da36-11e7-b633-001e6730f61a",
              //   discount: 32,
              // },
            ]
          }
          columns={[
            {
              title: "Юр. лицо",
              dataIndex: "organization_ref",
              key: "organization_ref",
            },
            {
              title: "Скидка",
              dataIndex: "discount",
              key: "discount",
              width: window.screen.width > 450 ? 100 : undefined,
              align: "right",
            },
          ]}
        />
      </Modal>
    </div>
  );
}
