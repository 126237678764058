import React, { useState, useEffect } from "react";
import { Form, Input, Button, Checkbox, Image, Typography } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { useTypedDispatch, useTypedSelector } from "../../redux/hooks";
import { setAuthorizeted, setChecked, signIn, signOut } from "../../redux/slices/authSlice";
import storesJson from "../../json/stores.json";

import logo from "./logo.svg";
import "./SignIn.scss";
import Cookies from "universal-cookie";
import { fetchJur, fetchProducts, setRole, setStores } from "../../redux/slices/handbookSlice";
import { requestForToken } from "../../firebase";

export interface ISignInProps { }
const cookies = new Cookies();

export default function SignIn(props: ISignInProps) {
  const history = useHistory();
  const dispatch = useTypedDispatch();
  const [login, setLogin] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [isLoginAvailable, setIsLoginAvailable] = useState<boolean>(false);
  const [isPassAvailable, setIsPassAvailable] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [formRef] = Form.useForm();
  const handbookRedux = useTypedSelector((state) => state.handbook);


  useEffect(() => {
    // console.log(atob(localStorage.getItem("round")), atob(localStorage.getItem("square")));
    const loginStorage = localStorage.getItem("round");
    const passwordStorage = localStorage.getItem("square");
    console.log("LOGIN", atob(loginStorage));

    if (loginStorage !== null) {
      formRef.setFieldsValue({ "username": atob(loginStorage) })
    }

    if (passwordStorage !== null) {
      formRef.setFieldsValue({ "password": atob(passwordStorage) })
    }
  }, []);
  const checkAuth = async () => {

    try {
      fetch(SiteUrlAuth + "/api/user/get", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""
            }`,
        },
        method: "GET",
      })
        .then((res) => res.json())
        .then((json) => {
          console.log("aut data", json);
          requestForToken()
            .then((firebaseToken) => {
              // eslint-disable-next-line no-console
              console.log("GET TOKEN FIREBASE", firebaseToken);
              if (firebaseToken) {
                fetch(SiteUrlDealer + "/firebase_token", {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""
                      }`,
                  },
                  method: "POST",
                  body: JSON.stringify({
                    user_id: json.user.id,
                    token: firebaseToken
                  })
                })
              }
            })
            .catch((err) => {
              console.log("Error auth firebase", err);
              setLoading(false);
            });
          localStorage.setItem("user", JSON.stringify(json.user));
          // cookies.set("accessToken", localStorage.getItem("accessToken"))
          // cookies.set("user", json.user)
          cookies.set("accessToken", localStorage.getItem("accessToken"), { domain: '.vakio.ru' })
          cookies.set("user", json.user, { domain: '.vakio.ru' })
          dispatch(setRole(json.user.capabilities));

          dispatch(setChecked(true));

          // requestOrgs();
          fetch(
            `${SiteUrlDealer}/dealers-stores`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""
                  }`,
              }
            }
          ).then((res) => res.json())
            .then((res) => {
              console.log("stores req done");
              dispatch(setStores(res));
              return dispatch(fetchJur());
            })
            .then(() => {
              console.log("jur req done 22");
              return dispatch(fetchProducts());
            })
            .then(() => {
              if (!handbookRedux.error) {
                console.log("prods req done sign in", json.user);
                history.push("/user");
                dispatch(setAuthorizeted(true));
                setLoading(false);
              } else {
                // сделать вывод ошибки
                history.push("/login");
              }
            });
        })
        .catch((err) => {
          console.log(err);
          history.push("/login");
          localStorage.removeItem("user");
          cookies.remove("user")
          setLoading(false);
          dispatch(signOut());
        }).finally(() => {

        });
    } catch (error) {
      setLoading(false);
      history.push("/login");
      dispatch(signOut());
    }
  };
  const onFinish = (values: any) => {
    // console.log("Received values of form: ", values);
    const send = {
      grant_type: "password",
      client_id: "96558579-3866-4a28-b58b-b09dc7ce64ec",
      client_secret: "X9ZxsRoAIkzXe8Y1nUtfeBKy3OKec7EFpeNdFwQt",
      username: values.username,
      password: values.password,
    };
    // console.log(send);
    setLoading(true);
    fetch(SiteUrlAuth + "/oauth/token", {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(send),
    })
      .then((response) => response.json())
      .then((json) => {
        console.log("res", json);
        if (!json.error) {
          dispatch(signIn(json.access_token));

          // cookies.set("user", json.user, { domain: '.vakio.ru' })
          // dispatch(setRole(json.user.capabilities));

          // dispatch(setChecked(true));

          if (values.remember) {
            console.log("SAVE PASSWORD");
            base(values.username, values.password);
          }
          checkAuth();
        } else {
          setLoading(false);
          // console.log("error", json);
          setError(true);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("err", err)
      });
  };

  const base = (log: string, pass: string) => {
    localStorage.setItem("round", btoa(log));
    localStorage.setItem("square", btoa(pass));
  };

  return (
    <div className="signin">
      <Form
        form={formRef}
        name="normal_login"
        className="login-form signin__form"
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        <div className="signin__image-container">
          <div className="signin__image-frame">
            <Image preview={false} src={logo} />
          </div>
          <div className="signin__image-description">Портал для дилеров</div>
        </div>
        <Typography.Title level={2} style={{ textAlign: "center" }}>
          Вход
        </Typography.Title>

        <Form.Item
          name="username"
          validateStatus={error ? "error" : undefined}
          rules={[{ required: true, message: "Пожалуйста, заполните поле!" }]}
          help={error ? "Проверьте правильность данных" : undefined}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="E-mail"
            value={isLoginAvailable ? login : undefined}
          />
        </Form.Item>

        <Form.Item
          name="password"
          validateStatus={error ? "error" : undefined}
          rules={[{ required: true, message: "Пожалуйста, заполните поле!" }]}
          help={error ? "Проверьте правильность данных" : undefined}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Пароль"
            value={isPassAvailable ? password : undefined}
          />
        </Form.Item>
        <Form.Item>
          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox>Запомнить меня</Checkbox>
          </Form.Item>

          <a className="login-form-forgot" href="#">
            Восстановить пароль
          </a>
        </Form.Item>

        <Form.Item>
          <Button loading={loading} type="primary" htmlType="submit" className="login-form-button">
            Войти
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
