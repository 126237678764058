import React, { useEffect, useRef, useState } from 'react';
import { Typography, message } from "antd"
import { PictureTwoTone, CloseOutlined } from "@ant-design/icons";
import UploadProduct from './UploadProduct';
import useProductInfoService from './useProductInfoService';
import DivInput from '../../Editor/components/DivInput/DivInput';


const test = {
    "id": 6,
    "ref": "04c02925-bac1-11e7-b633-001e6730f61a",
    "name": "Труба ПНД 132х3,5 1,2 метра",
    "type": "item",
    "unit": "шт",
    "price": 0,
    "enable": 0,
    "parent": "4bad100e-e659-11e8-ba23-001e6730f61a",
    "stocks": [],
    "article": "",
    "info": null,
    "deleted": 0,
    "discount": 0,
    "head_ref": "04c02925-bac1-11e7-b633-001e6730f61a",
    "vat_rate": "20%",
    "bitrix_id": "10427",
    "full_name": "Труба ПНД 132х3,5 1,2 метра",
    "purchased": 0,
    "created_at": "2022-05-23T10:15:42.000000Z",
    "updated_at": "2023-01-26T12:22:32.000000Z",
    "parent_name": "Архив вентиляция",
    "stock_count": null,
    "default_price": 0,
    "pricelist_enable": 0,
    "remote_store_increase": 0
}
const ProductCardNomenclature = ({ product, isReadOnly, updateEntity, entity }) => {

    const { updateProductInfo } = useProductInfoService();
    const [productInfo, setProductInfo_] = useState(product?.info ?? {});
    const [description, setDescription] = useState(product?.description ?? "");
    const descriptionRef = useRef(product?.description ?? "");
    const [initialValue, setInitialValue] = useState(product?.description ?? "");
    useEffect(() => {
        setInitialValue(initialValue);
    }, [isReadOnly])

    useEffect(() => {
        setDescription(product?.description ?? "")
        descriptionRef.current = product?.description ?? ""
    }, [product.description])
    const setProductInfo = (info) => {
        setProductInfo_(info);
        updateEntity({
            ...entity,
            body: {
                ...entity.body,
                "nomenclature": entity.body.nomenclature.map(item => {
                    if (item.id == product.id) {
                        return {
                            ...product,
                            info: info
                        }
                    }
                    return item;
                })
            }
        })
    }



    const onChangeDescription = (value) => {
        descriptionRef.current = value;
        setDescription(description);
        updateEntity({
            ...entity,
            body: {
                ...entity.body,
                "nomenclature": entity.body.nomenclature.map(item => {
                    if (item.id == product.id) {
                        return {
                            ...product,
                            description: value
                        }
                    }
                    return item;
                })
            }
        })

    }

    const setProductFileInfo = (info) => {
        setProductInfo({
            ...productInfo,
            link: info.link,
            thumb_link: info.thumb_link
        })
    }

    function htmlDecode(input) {
        var e = document.createElement('div');
        e.innerHTML = input;
        return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
    }

    return (
        <div className='product-card'>

            <div style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column"
            }}>

                {productInfo?.link ?
                    <div className='product-card__image'>
                        <img src={productInfo?.link} alt="image" width={"100%"} height={"100%"} />

                    </div>
                    :
                    <div className='product-card__image'>
                        <PictureTwoTone
                            style={{

                                fontSize: 112,
                                color: "#1890FF",
                                padding: 5,
                                borderRadius: 8,
                            }}
                        />
                    </div>}
                {!isReadOnly && <UploadProduct product_id={product.id} setProductInfo={setProductFileInfo} />}
            </div>
            <div style={{}}>
                <div className='product-card__header'>
                    <div className='product-card__header__title'>
                        {product.full_name}
                    </div>
                    <div className={`product-card__header__price`}>
                        <div className='price-amount'>
                            {product.amount}
                        </div>
                        <div className='price-unit'>
                            {product.unit ?? "шт."}
                        </div>
                        <div className='price-x'>
                            <CloseOutlined style={{
                                fontSize: 14
                            }} />
                        </div>
                        <div className="create-order__select__price"
                            style={product.price < product.default_price ? { textDecoration: "line-through", color: "#afafaf", marginLeft: 5 } : { marginLeft: 5 }}>
                            {formatMoney(product.default_price)}&nbsp;₽
                        </div>
                        {product.price < product.default_price && <div className="create-order__select__disc">
                            {formatMoney(product.price)}&nbsp;₽
                        </div>}

                    </div>
                </div>

                <DivInput
                    html={false}
                    containerStyle={{}}
                    onInput={(e) => onChangeDescription(e)}
                    isReadOnly={isReadOnly}
                    value={description}
                />


            </div>
        </div >
    );
};

export default ProductCardNomenclature;