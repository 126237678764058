import React, { Fragment, useEffect, useState } from 'react';
import { Button, Form, Modal } from 'antd';

import { FormConstructor } from '../../FormСonstructor';
import useSaveCOForm from '../../ConstructorPage/useSaveCOForm';
import './MainBlock.scss';

const EditMainBlockModal = ({
	visible,
	initialStage = 0,
	setVisible,
	mainInfo,
	entity,
	updateEntity,
}) => {
	const {
		loading: loading,
		formItems,
		refreshData,
		create,
	} = useSaveCOForm({ withLoad: true });
	const [stage, setStage] = useState(initialStage);
	const [form] = Form.useForm();
	const [result, setResult] = useState(mainInfo);
	const [formValues, setFormValues] = useState({});
	useEffect(() => {
		if (visible) {

			setStage(initialStage);
			form.resetFields();
			form.setFieldsValue(mainInfo);
			setResult(mainInfo);
			setFormValues(mainInfo);
			refreshData();
		} else {
			setStage(0);
		}
		return () => setStage(0);
	}, [visible]);

	const save = () => {
		form.validateFields()
			.then((values) => {
				const newMainInfo = { ...result, ...formValues };
				setResult(newMainInfo);
				console.log("newMainInfo", newMainInfo);
				console.log("values", values);

				updateEntity({
					...entity,
					body: { ...entity.body, ...newMainInfo },
				});
				setVisible(false);
			})
			.catch((e) => {
				console.log(e);
			});
	};
	const next = () => {
		form.validateFields(formItems[stage].content.flat().map((item) => item.key))
			.then((values) => {
				setStage(stage + 1);
				const newMainInfo = { ...result, ...formValues };
				setResult(newMainInfo);
				updateEntity({
					...entity,
					body: { ...entity.body, ...newMainInfo },
				});
			})
			.catch((e) => {
				console.log(e);
			});
	};
	const submit = () => {

		if (stage == formItems.length - 1) {
			form.validateFields()
				.then((values) => {
					const newMainInfo = { ...result, ...formValues };
					console.log("values", values);

					setResult(newMainInfo);
					updateEntity({
						...entity,
						body: { ...entity.body, ...newMainInfo },
					});
					setVisible(false);
				})
				.catch((e) => {
					console.log(e);
				});
		} else {
			form.validateFields(formItems[stage].content.flat().map((item) => item.key))
				.then((values) => {
					setStage(stage + 1);
					console.log("newMainInfo", newMainInfo);

					setResult({ ...result, ...formValues });
				})
				.catch((e) => {
					console.log(e);
				});
		}
	};
	const back = () => { };
	const closeModal = () => {
		if (stage == 0) {
			setVisible(false);
		} else {
			setStage(stage - 1);
		}
	};
	return (
		<Modal
			title={formItems[stage]?.title}
			style={{ top: 20 }}
			visible={visible}
			width={formItems[stage]?.width}
			confirmLoading={loading}
			destroyOnClose
			cancelButtonProps={{ onClick: closeModal }}
			onOk={submit}
			onCancel={() => setVisible(false)}
			cancelText={stage == 0 ? 'Закрыть' : 'Назад'}
			okText={stage == formItems.length - 1 ? 'Сохранить' : 'Далее'}
			footer={[
				<Button key="back" onClick={closeModal}>{stage == 0 ? 'Закрыть' : 'Назад'}</Button>,
				<Button key="save" type="primary" onClick={save}>
					Сохранить
				</Button>,
				<Fragment key="next">
					{stage != formItems.length - 1 && (
						<Button onClick={next} type="primary">
							{'Далее'}
						</Button>
					)}
				</Fragment>,
			]}
		>
			<div
				className="edit-main"
				style={{
					display: 'flex',
					flexDirection: 'column',
					marginBottom: 12,
				}}
			>
				<FormConstructor
					formItems={formItems[stage]?.content ?? []}
					form={form}
					setFormValues={setFormValues}
					formValues={formValues}
					formItem={formItems[stage]}
				/>
			</div>
		</Modal>
	);
};

export default EditMainBlockModal;
