import React, { useState, useEffect } from "react";
import { Layout, Menu, Avatar, Button } from "antd";
import {
  HomeFilled,
  ShoppingFilled,
  PieChartFilled,
  BookFilled,
  ProjectOutlined,
  ArrowLeftOutlined,
  SettingFilled,
  QuestionCircleOutlined
} from "@ant-design/icons";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import logo from "./logo.svg";
import "./Sidebar.scss";
import { useTypedSelector } from "../../redux/hooks";
import { setIsProjects } from "../../redux/slices/handbookSlice";
import { signOut } from "../../redux/slices/authSlice";

export interface ISidebarProps {
  collapsed: boolean;
  setCollapsed: any;
  // authCallback: any;
}

export default function Sidebar(props: ISidebarProps) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [collapsed, setCollapsed] = useState(true);
  const [defaultSelected, setDefaultSelected] = useState<string>("user");
  const handbookRedux = useTypedSelector(state => state.handbook)
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [nav, setNav] = useState(JSON.parse(localStorage.getItem("navigation") || "[]"))
  // console.log(location.pathname);

  useEffect(() => {
    setCollapsed(props.collapsed);
  }, [props.collapsed]);

  const checkRouteAccess = () => {
    try {
      const currentRoute = location.pathname.split("/")[1]
      //const foundedMenu = nav.find(menu => menu.submenus.find(submenu => (submenu.url === window.location.origin + "/" && currentRoute === submenu.route)));
      const foundedMenu = nav.find(menu => undefined != menu.submenus.find(submenu => (submenu.url === window.location.origin + "/" && currentRoute === submenu.route)));
      if (foundedMenu && checkAccess(foundedMenu.roles)) {
        const foundedSubmenu = foundedMenu.submenus.find(submenu => (submenu.url === window.location.origin + "/" && currentRoute === submenu.route))
        console.log("foundedSubmenu", foundedSubmenu);
        return checkAccess(foundedSubmenu.roles)
      }
      return false
    } catch {
      return false
    }
  }
  useEffect(() => {


    // if (!checkRouteAccess())
    //   history.push("/")
    if (location.pathname.includes("order/") || location.pathname.includes("create-order/")) {
      setDefaultSelected("orders");
    } else if (location.pathname.includes("promotions") || location.pathname.includes("conditions")) {
      setDefaultSelected("promotions");
    } else {
      setDefaultSelected(location.pathname.slice(1));
    }
    console.log(location.pathname);
    dispatch(setIsProjects(location.pathname.includes("project")))
  }, [location.pathname]);

  useEffect(() => {

    if (nav.length > 0) {
      setLoading(false);
    }

    fetch("https://portal.vakio.ru/api/routes", {
      method: "GET",

      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: "Bearer " + localStorage.getItem("accessToken")
      }
    }).then((response) => response.json()).then(response => {
      console.log("RESPONSE ", response);
      localStorage.setItem("navigation", JSON.stringify(response));
      setNav(response);
    }).finally(() => setLoading(false));

    return () => setLoading(false);
  }, [])

  const checkAccess = (roles) => {

    const userRoles = handbookRedux.role.split(",")
    let access = false;
    roles.forEach(role => {
      if (userRoles.includes(role)) {
        access = true;
      }
    });
    return access;
  }

  useEffect(() => {
    fetch("https://portal.vakio.ru/api/routes", {
      method: "GET",
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: "Bearer " + localStorage.getItem("accessToken")
      }
    }).then((response) => response.json()).then(response => {
      console.log("RESPONSE ", response);
      localStorage.setItem("navigation", JSON.stringify(response));
      setNav(response);
    })

  }, [])

  return (
    <Layout.Sider
      className="sidebar"
      breakpoint="lg"
      collapsedWidth={window.screen.width < 450 ? 0 : undefined}
      collapsible={window.innerWidth > 450 ? true : false}
      width={window.innerWidth > 1400 || window.innerWidth < 450 ? 320 : 200}
      collapsed={collapsed}
      onCollapse={() => setCollapsed(!collapsed)}
      style={{
        overflow: "auto",
        height: "100vh",
        position: "sticky",
        // position: "absolute",
        top: 0,
        left: 0,
        zIndex: 999,
      }}
    >
      <img src={logo} alt="logo" className="sidebar__heading" />

      <Menu
        theme="dark"
        defaultSelectedKeys={["user"]}
        selectedKeys={[defaultSelected]}
        mode="inline"
        className="sidebar__menu"
        items={!loading ?
          nav.filter(menu => checkAccess(menu.roles)).length > 1 ?
            nav.filter(menu => checkAccess(menu.roles)).map(item => {


              return {
                label: item.title,
                key: item.value,

                //icon: (<BarsOutlined className="sidebar__menu-icon" />),
                className: "sidebar__menu-item sidebar__text",
                children: item.submenus.filter(submenu => checkAccess(submenu.roles)).map(submenu => {
                  return {
                    key: submenu.route === "in-developing" ? submenu.title : submenu.value + "_",
                    label: submenu.title,
                    onClick: () => {
                      //console.log(window.location.hostname, submenu.url, submenu.url.includes(window.location.hostname));
                      if (!submenu.url.includes(window.location.hostname) && process.env.NODE_ENV === "production") {
                        //history.push("/" + submenu.route)
                        window.open(submenu.url + submenu.route, submenu.blanc ? "_blank" : "_self")
                      } else {
                        history.push("/" + submenu.route)
                      }
                      //window.open("http://localhost:3000/" + submenu.route, "_self")
                    }
                  }
                })

              }
            })
            :
            nav.filter(menu => checkAccess(menu.roles))[0].submenus.filter(submenu => checkAccess(submenu.roles)).map(item => {
              return {
                label: item.title,
                key: item.value,
                className: "sidebar__menu-item sidebar__text",
                onClick: () => {
                  //console.log(window.location.hostname, submenu.url, submenu.url.includes(window.location.hostname));
                  if (!item.url.includes(window.location.hostname) && process.env.NODE_ENV === "production") {
                    //history.push("/" + item.route)
                    window.open(item.url + item.route, item.blanc ? "_blank" : "_self")
                  } else {
                    history.push("/" + item.route)
                  }
                }

              }
            })
          :
          []
        }

      />






    </Layout.Sider>
  );
}
