import { Divider } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTypedSelector } from '../../../redux/hooks';
import PatternEntity from '../PatternEntity/PatternEntity';
import './MainBlock.scss';
import ProductCard from './ProductCard';
import ProductCardNomenclature from './ProductCardNomenclature';
import ProductResultSum from './ProductResultSum';



const MainItem = ({ item, index, trigger, entity, updateMainEntity, updateEntity, isReadOnly, length }) => {
    const products = useTypedSelector((state) => state.handbook.products);
    const [mount, setMount] = useState(false);
    const [refreshId, setRefreshId] = useState(0);
    const getValue = () => {
        if (item.type === 'select') {
            try {
                if (item.mode == 'multiple') {
                    const value = item.value
                        .map((value) => {
                            return item.options.find((option) => option.value == value)
                                .label;
                        })
                        .join(', ');
                    return value;
                } else
                    return item.options.find((option) => option.value == item.value)
                        .label;
            } catch {
                return '-';
            }
        }

        return item.value;
    };

    const [value, setValue] = useState(getValue(item.value));
    useEffect(() => {
        setValue(getValue(item.value));
        if (!mount) {
            setMount(true);
            return;
        }
        if (item.type == 'entity') {
            setRefreshId(item?.value?.id);
        }
    }, [item?.value]);
    // const updateMainEntity = (updatedEntity) => {

    //     updateEntity()
    // }

    const updateEntity_ = (entity) => {
        console.log("item.key, entity", item.key, entity);
        try {

            updateMainEntity(item.key, entity)
        } catch (e) {
            console.log(e);
        }
    }

    if (value == undefined || value == '' || value == '-') return <></>;
    return (
        <>
            {
                item.type === 'room_type' ? (
                    <div className="room">

                        {value.map((room, index) => {
                            return (
                                <div className="room__item" key={room?.id + room.name}>
                                    <Divider
                                        style={{
                                            fontSize: 20,
                                        }}
                                    >
                                        {`${room.name}`}
                                        <span style={{ fontWeight: 400, fontSize: 14 }}>
                                            {room.width && room.height && room.length ? (
                                                <>
                                                    {` ${(
                                                        room.width *
                                                        room.height *
                                                        room.length
                                                    ).toFixed()} м`}{' '}
                                                    <sup>3</sup>
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                        </span>
                                    </Divider>
                                    <div className="room__products">
                                        {room.products.map((product) => {
                                            return (
                                                <ProductCard
                                                    isReadOnly={isReadOnly}
                                                    key={
                                                        product.id + room?.id + room.name
                                                    }
                                                    product={product}
                                                    room={room}
                                                    products={products}
                                                    updateEntity={updateEntity}
                                                    entity={entity}
                                                />
                                            );
                                        })}
                                    </div>
                                    <ProductResultSum products={room.products} />
                                </div>
                            );
                        })}
                        <ProductResultSum
                            label="Общая сумма: "
                            products={[...(value?.map((item) => item.products)?.flat() ?? []), ...(item.extraValue ?? [])]}
                        />
                    </div>
                ) : item.type == 'nomenclature' ?
                    <>
                        <div className="room__products">
                            {value.map((product) => {
                                return (
                                    <ProductCardNomenclature
                                        isReadOnly={isReadOnly}
                                        key={
                                            product.id
                                        }
                                        product={product}
                                        products={products}
                                        updateEntity={updateEntity}
                                        entity={entity}
                                    />
                                );
                            })}
                            {(item.extraValue ?? [])?.length === 0 && <ProductResultSum
                                label="Общая сумма: "
                                products={value ?? []}
                            />}

                        </div>
                    </>
                    : item.type == 'entity' ? (
                        <div style={{ marginLeft: -22 }}>
                            {(value).map((entity) => {
                                return (
                                    <PatternEntity
                                        trigger={trigger}
                                        key={entity.id}
                                        setRefreshId={setRefreshId}
                                        refreshId={refreshId}
                                        patternEntity={entity}
                                        setPatternEntity={updateEntity_}
                                        holder={'main_' + item.key}
                                        readOnly={isReadOnly}
                                    />
                                );
                            })}
                        </div>
                    ) : item.type === 'room_size' ? (
                        <div style={{ display: 'flex', fontSize: 15, width: '100%' }}>
                            {value?.square && (
                                <div>
                                    Общей площадью {value.square} м<sup>2</sup>
                                    {value?.height && ','}&nbsp;
                                </div>
                            )}
                            {value?.height && (
                                <div>
                                    {value?.square ? 'в' : 'В'}ысотой стен {value.height} м
                                    {value?.square && ','}&nbsp;
                                </div>
                            )}
                            {value?.height && value?.square && (
                                <div>
                                    объёмом воздуха {value.volume} м<sup>3</sup>&nbsp;
                                </div>
                            )}
                        </div>
                    ) : (
                        <div style={{ display: 'flex', fontSize: 15 }}>
                            {index == 0
                                ? (value?.label ?? value) + (index != length ? `,\u00A0` : '')
                                : index == length
                                    ? value?.label ?? value?.toLowerCase()
                                    : (value?.label ?? value?.toLowerCase()) + `,\u00A0`}
                        </div>
                    )
                // <div key={item.key} className='main__item'>

                //     <div className='main-item'>
                //         <div className='main-item__label'>
                //             {item?.label}
                //         </div>
                //         <div className='main-item__value'>
                //             {getFormatedValue(item?.addonBefore)}&nbsp;{value}&nbsp;{getFormatedValue(item?.addonAfter)}
                //         </div>
                //     </div>
                // </div>
            }
        </>
    );
};

export default MainItem;