import React, { useEffect, useState } from 'react';
import { Typography, message, Popconfirm, Tag, Popover } from "antd";
import { MoreOutlined } from "@ant-design/icons"
import "./ProjectItem.scss"
import SelectDevice from '../ProjectUpdateModal/SelectDevice';
import { deleteProject, updateProject } from '../../ProjectService';
import Button from 'antd/lib/button';
import { useTypedSelector } from '../../../../redux/hooks';
import styled from 'styled-components';
import 'antd/dist/antd.min.css'
const StyledPopover = styled(Popover)`
.ant-popover-inner-content{
padding: 0px !important;
}
.ant-popover-inner-content{
padding: 0px !important;
}
.ant-popover-inner-content{
    padding: 0px !important;
}
`

const ProjectItem = ({ project, setProjects, spheres, devices, setShowUpdateModal, showUpdateModal, setTargetProject, rerenderDevice, setRerenderDevice }) => {
    const [selectDevices, setSelectDevices] = useState(project.projectDevices?.map(item => item.project_device_id));
    const [projectDevices, setProjectDevices] = useState(project.projectDevices)
    const [triggerSend, setTriggerSend] = useState(false);
    const [mounted, setMounted] = useState(false);
    const [open, setOpen] = useState(false);
    const [user_id, setUserId] = useState(0);
    const d_isManager = useTypedSelector((state) => state.handbook.d_isManager);
    const isSuperAdmin = useTypedSelector((state) => state.handbook.isSuperAdmin);
    const d_isAdminManager = useTypedSelector((state) => state.handbook.d_isAdminManager);
    useEffect(() => {
        const userRaw = localStorage.getItem("user");
        if (userRaw) {
            const user_id = JSON.parse(userRaw)?.id;
            setUserId(user_id)
        }
    }, [])
    const [skip, setSkip] = useState(true);
    const [deletedDevices, setDeletedDevices] = useState([])
    useEffect(() => {
        if (rerenderDevice === project.id) {
            setProjectDevices(project.projectDevices);
            setSelectDevices(project.projectDevices?.map(item => item.project_device_id));
            console.log("project", project);
            setMounted(false);
            setRerenderDevice(0)
        }

    }, [rerenderDevice])
    useEffect(() => {
        if (!mounted) {
            setMounted(true);
            return;
        }

        console.log("deletedDevices", deletedDevices);
        const timeout = setTimeout(() => {
            updateProject({
                ...project,
                deletedDevices: deletedDevices,
                projectDevices: projectDevices
            }).then(response => {
                console.log(response.data);
                setDeletedDevices([])
                setProjects(projects => {
                    return projects.map(item => {
                        if (item.id === project.id) {
                            return response.data
                        } else {
                            return item;
                        }
                    })
                })
            })
        }, 500)
        return () => {
            if (timeout)
                clearTimeout(timeout)
        }
    }, [triggerSend, deletedDevices.length])

    useEffect(() => {
        if (skip) {
            setSkip(false);
            return;
        }
        setProjectDevices(selectDevices.map(device_id => {
            const device = projectDevices.find(item => item.project_device_id === device_id);
            if (device) {
                return device;
            } else {

                return {
                    project_device_id: device_id,
                    count: 1
                }
            }
        }))
        setTriggerSend(!triggerSend);
    }, [selectDevices])

    const getSphereName = (id) => {
        const sphere = spheres.find(item => item.id === id)
        if (sphere) {
            return sphere.title;
        }
        return "";
    }

    const deleteProject_ = () => {
        deleteProject(project.id).then((response) => {
            setProjects(projects => {
                return projects.filter(item => item.id !== project.id);
            })
            message.success(`Проект ${project.title} удалён!`)
        }).catch(e => {
            console.log(e);
            message.error("Не удалось удалить проект!")
        })
    }

    const handleOpenChange = (newOpen) => {
        setOpen(newOpen);
    };

    return (
        <div className='project' onClick={() => setOpen(false)}>
            <div style={{ width: "100%", justifyContent: (project.creator_id === user_id || (d_isManager || d_isAdminManager) || isSuperAdmin) ? "space-between" : "space-between", alignItems: "center", display: "flex", marginBottom: 10 }}>
                <div style={{ display: "flex", alignItems: "center" }}>

                    <Typography.Title level={3} className='project__header__title' >
                        {(project.creator_id === user_id || (d_isManager || d_isAdminManager) || isSuperAdmin) ? project.title : project.city}
                    </Typography.Title>
                    <Tag color={project?.status == 0 ? "success" : "processing"}>{project?.status == 0 ? "В работе" : "Завершен"}</Tag>
                </div>
                {(project.creator_id === user_id || (d_isManager || d_isAdminManager) || isSuperAdmin) && <Popover
                    overlayInnerStyle={{ padding: 0 }}
                    content={<div className='project_actions'>



                        <Button type="link" onClick={() => {
                            setOpen(false);
                            setTargetProject(project);
                            setShowUpdateModal(true);
                        }}>Редактировать</Button>
                        <Popconfirm
                            placement="topRight"
                            title={`Удалить проект ${project.title}?`}
                            onConfirm={() => {
                                setOpen(false);
                                deleteProject_();
                            }}
                            onCancel={(e) => {
                                setOpen(false);
                                e.stopPropagation()
                            }}
                            okText="Удалить"
                            cancelText="Отмена"
                        >
                            <Button type="link" >Удалить</Button>
                        </Popconfirm>
                    </div>}

                    trigger="click"
                    visible={open}
                    onVisibleChange={handleOpenChange}
                >
                    <Button type="link" icon={<MoreOutlined />} onClick={(e) => {
                        e.stopPropagation()
                        setOpen(true)
                    }} size='large' />
                </Popover>}

            </div>

            {(project.creator_id === user_id || (d_isManager || d_isAdminManager) || isSuperAdmin) && <>
                <div className='project__header'>
                    <div className='project__header__text'>
                        <div className='project__header__tag'>

                            <Typography.Title level={5} className='project__header__city'>
                                {project.city}
                            </Typography.Title>
                        </div>
                        <Typography.Text className='project__header__sphere'>
                            {getSphereName(project.sphere_id)}
                        </Typography.Text>
                    </div>




                </div>
                <Typography.Text className='project__description'>
                    {project?.description}
                </Typography.Text>
            </>
            }
            <SelectDevice
                setDeletedDevices={setDeletedDevices}
                setProjectDevices={setProjectDevices}
                projectDevices={projectDevices}
                selectDevices={selectDevices}
                canUpdate={false}
                setSelectDevices={setSelectDevices}
                setTriggerSend={setTriggerSend}
                devices={devices ?? []} />
        </div>
    );
};

export default ProjectItem;