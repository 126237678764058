import React, { useEffect, useState } from 'react';
import { message } from "antd"
const useLoadPattern = ({ withLoad }) => {
    const [patterns, setPatterns] = useState([])
    const [loading, setLoading] = useState(false);
    const createPattern = (pattern) => {
        setLoading(true);
        return fetch(SiteUrlDealer + "/pattern", {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""
                    }`,
            },
            method: "POST",
            body: JSON.stringify(pattern)
        }).then(res => res.json()).then(response => {
            setPatterns([...patterns, response]);
            return response;
        }).catch(e => {
            message.error("Не удалось создать блок");
        }).finally(() => {
            setLoading(false);
        })
    }
    const updatePattern = (pattern) => {
        setLoading(true);
        return fetch(SiteUrlDealer + "/pattern/" + pattern.id, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""
                    }`,
            },
            method: "PUT",
            body: JSON.stringify(pattern)
        }).then(res => res.json()).then(response => {
            const updatedType = response;
            setPatterns(patterns.map(type => {
                if (type.id == updatedType.id) {
                    return updatedType;
                }
                return type;
            }));
        }).catch(e => {
            message.error("Не удалось обновить блок");
        }).finally(() => {
            setLoading(false);
        })
    }
    const deletePattern = (id) => {
        setLoading(true);
        return fetch(SiteUrlDealer + "/pattern/" + id, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""
                    }`,
            },
            method: "DELETE"
        }).then(response => {
            setPatterns(patterns.filter(type => type.id != id));
        }).catch(e => {
            message.error("Не удалось удалить блок");
        }).finally(() => {
            setLoading(false);
        })
    }
    const getPatternEntities = () => {
        setLoading(true);
        return fetch(SiteUrlDealer + "/pattern", {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""
                    }`,
            },
            method: "GET"
        }).then(res => res.json()).then(response => {
            console.log("response", response);
            setPatterns(response);
        }).catch(e => {
            console.log("ERROR", e);
            message.error("Не удалось загрузить блоки");
        }).finally(() => {
            setLoading(false);
        })
    }
    useEffect(() => {
        if (withLoad)
            getPatternEntities();
        return () => {
            setLoading(false);
        }
    }, [])
    return {
        loading,
        patterns,
        getPatternEntities,
        createPattern,
        updatePattern,
        deletePattern
    }
};

export default useLoadPattern;