import React, { useEffect, useState } from 'react';
import { Button, Radio, Input, Typography, message, Col, Alert } from "antd"
import Editor from '../Editor/Editor';
import { useDispatch } from 'react-redux';
import { setHeaderTitle } from '../../redux/slices/layoutSlice';
import useLoadPattern from './Pattern/useLoadPattern';
import PatternSelect from './PatternSelect/PatternSelect';
import EditPattern from './Pattern/EditPattern';
import "./EditPatternPage.scss"
import { useParams } from 'react-router-dom';
const EditPatternPage = () => {
    const dispatch = useDispatch();
    const { patterns, loading, createPattern, updatePattern, deletePattern } = useLoadPattern({ withLoad: true });
    const [updateTrigger, setUpdateTrigger] = useState(false);

    const [isMount, setIsMount] = useState(false);
    const [newPattern, setNewPattern] = useState({
        name: "Новый шаблон",
        pattern: [
            {
                type: "main",
                id: new Date().getTime()
            }
        ]
    });
    const [action, setAction] = useState("new");
    const [visible, setVisible] = useState(false);
    const [successSave, setSuccessSave] = useState(false);
    const patternId = useParams().id;
    const openCreateModal = () => {
        setVisible(true);
    }

    useEffect(() => {
        if (!isMount) {
            setIsMount(true);
            return;
        }

        const timeout = setTimeout(() => {
            onSubmit();
        }, 1000)
        return () => {
            clearTimeout(timeout);
            setIsMount(false);
        };
    }, [updateTrigger])
    useEffect(() => {
        if (patternId && patterns.length > 0) {
            const pattern = patterns.find(item => item.id === Number(patternId));
            if (pattern) {
                setAction("update")
                setNewPattern(pattern);
            }
        }
    }, [patterns.length])
    useEffect(() => {
        dispatch(setHeaderTitle("Редактор шаблонов КП"));
    }, [])
    const onChange = (event) => {
        const value = event.target.value;
        if (value === "new") {
            setNewPattern({
                name: "Новый шаблон",
                pattern: [
                    {
                        type: "main",
                        id: new Date().getTime()
                    }
                ]
            })
        } else {

        }
        setAction(value);
    }

    const onSubmit = () => {
        new Promise((resolve, reject) => {
            if (newPattern.id) {
                updatePattern(newPattern).then(() => {
                    resolve();
                }).catch(e => {
                    reject(e)
                });
            } else {
                createPattern(newPattern).then((pattern) => {
                    resolve();
                    setAction("update");
                    console.log("set new patern", pattern);
                    setNewPattern(pattern)
                }).catch(e => {
                    reject(e)
                });
            }
        }).then(() => {
            setSuccessSave(true);
            setTimeout(() => {
                setSuccessSave(false);
            }, 1500)
            // message.success("Шаблон КП успешно сохранён!");
        }).catch((e) => {
            console.log("error", e);
        })
    }

    const onDeletePattern = () => {
        deletePattern(newPattern?.id).then(response => {
            setNewPattern(null);
        })
    }


    const insert = (arr, index, newItem) => [
        // part of the array before the specified index
        ...arr.slice(0, index),
        // inserted item
        newItem,
        // part of the array after the specified index
        ...arr.slice(index),
    ];

    const addToTop = (entity) => {
        const mainIndex = newPattern.pattern.findIndex((item) => item.type === 'main');
        console.log("ADD TO TOP",);

        setNewPattern(pattern => ({
            ...pattern,
            pattern: insert(pattern.pattern, mainIndex, entity),
        }));
        setUpdateTrigger(prev => !prev);


    };

    const addToBottom = (entity) => {
        // const mainIndex = pattern.pattern.findIndex((item) => item.type === 'main');
        setNewPattern(pattern => ({
            ...pattern,
            pattern: [...pattern.pattern, entity],
        }));
        setUpdateTrigger(prev => !prev);

    };
    const updateEntity = (entity) => {
        console.log("entity", entity);
        setNewPattern(pattern => ({

            ...pattern,
            pattern: pattern.pattern.map((item) => {
                if (item.id === entity.id) {
                    return entity;
                }
                return item;
            })
        }));



        setUpdateTrigger(prev => !prev);

    };
    const deleteEntity = (entity) => {
        setNewPattern(pattern => ({
            ...pattern,
            pattern: pattern.pattern.filter((item) => item.id !== entity.id),
        }));


        setUpdateTrigger(entity.id);
    };
    return (
        <div style={{ width: "100%", padding: 10, display: "flex", flexDirection: "column", alignItems: "center" }}>
            <div className='pattern-inputs'>
                <Radio.Group
                    style={{ marginBottom: 6, width: "100%" }}

                    onChange={onChange}
                    value={action}
                    optionType="button"
                    buttonStyle="solid"
                >
                    <Radio style={{ width: "50%" }} value={"new"}>Создать новый</Radio>
                    <Radio style={{ width: "50%" }} value={"update"}>Редактировать</Radio>
                </Radio.Group>
                {action === "update" ?
                    <PatternSelect patterns={patterns} pattern={newPattern?.id} setNewPattern={setNewPattern} />
                    :
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <Typography.Text style={{ marginBottom: 5 }}>
                            Введите имя шаблона
                        </Typography.Text>
                        <Input style={{ width: 300 }} value={newPattern?.name} onChange={e => setNewPattern({ ...newPattern, name: e.target.value })} />
                    </div>
                }
                {action === "new" && <Button
                    onClick={onSubmit}
                    loading={loading}
                    disabled={!(action === "new" || (action === "update" && newPattern?.id))}
                    type="primary"
                    style={{
                        marginTop: 10,
                        width: "100%"
                    }}
                >
                    Создать
                </Button>}
                {action === "update" && <Button
                    onClick={onDeletePattern}
                    danger
                    loading={loading}
                    disabled={!(action === "new" || (action === "update" && newPattern?.id))}
                    type="primary"
                    style={{
                        marginTop: 10,
                        width: "100%"
                    }}
                >
                    Удалить
                </Button>}
                {/* {successSave && <Alert style={{ marginTop: 15 }} message="Шаблон успешно сохр   анён" type="success" showIcon />} */}
            </div>
            {((action === "update" && newPattern?.id)) && <EditPattern pattern={newPattern}
                deleteEntity={deleteEntity}
                updateEntity={updateEntity}
                addToBottom={addToBottom}
                addToTop={addToTop}
            />}
        </div>
    );
};
export default EditPatternPage;