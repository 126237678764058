import React, { useEffect, useState } from 'react';
import { Select } from "antd"
import moment from 'moment';
const RoomSelectSelect = ({ item, setRoomInfo, roomInfo }) => {


    const [rooms, setRooms] = useState([])
    useEffect(() => {
        fetch(`${SiteUrlDealer}/product_rooms`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""}`,
            },
            method: "GET",
        })
            .then((res) => res.json())
            .then((json) => {

                setRooms(json.map(item => {
                    return {
                        label: item.name,
                        value: item.id
                    }
                }))
            })
    }, []);
    const onChange = (value) => {
        let newRoomInfo = {
            id: value,
            name: rooms.find(item => item.value === value).label,
            products: []
        };
        setRoomInfo([
            ...(roomInfo ?? []),
            newRoomInfo
        ])
    }
    return (
        <Select
            placeholder="Добавить комнату"
            value={null}
            options={rooms}
            onChange={onChange}
        />
    );
};

export default RoomSelectSelect;