import React, { useEffect, useState } from 'react';
import EditPattern from '../EditPatternPage/Pattern/EditPattern';
import useSaveCOForm from '../ConstructorPage/useSaveCOForm';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Checkbox, Switch } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useTypedSelector } from '../../redux/hooks';
import { setHeaderTitle } from '../../redux/slices/layoutSlice';
import { useDispatch } from 'react-redux';

const CommercialOfferPage = () => {
	const [loading, setLoading] = useState(false);
	const authRedux = useTypedSelector((state) => state.auth);
	const handbookRedux = useTypedSelector((state) => state.handbook);
	const history = useHistory();
	const [saveTrigger, setSaveTrigger] = useState(false);
	const [mounted, setMounted] = useState(false);
	const [offer, setOffer] = useState({});
	const { loading: loadingForm, getUrl, update } = useSaveCOForm({ withLoad: false });
	const offerId = useParams().offer;
	const [readOnly, setReadOnly] = useState(false);
	const dispatch = useDispatch();
	// useEffect(() => {

	// },[])

	const getCoForm = () => {
		setLoading(true);
		fetch(SiteUrlDealer + '/commercial_offer/' + offerId, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${localStorage.getItem('accessToken')
					? localStorage.getItem('accessToken')
					: ''
					}`,
			},
			method: 'GET',
		})
			.then((res) => res.json())
			.then((response) => {
				setOffer(response);
				dispatch(setHeaderTitle(response.name));
			})
			.catch((e) => {
				console.log(e);
			})
			.finally(() => {
				setLoading(true);
			});
	};

	useEffect(() => {
		if (!mounted) {
			setMounted(true);
			return;
		}
		const timeout = setTimeout(() => {
			save(offer);
		}, 1000);
		return () => clearTimeout(timeout);
	}, [saveTrigger]);

	useEffect(() => {
		getCoForm();
		return () => setLoading(false);
	}, []);

	const save = () => {
		update(offer);
		console.log("offer", offer?.form?.pattern);

	};
	const setPattern = (entity) => {


	};
	const deleteEntity = (entity) => {

		setOffer(offer => ({
			...offer,
			form: {
				...offer.form,
				pattern: offer.form.pattern.filter((item) => item.id !== entity.id),
			},
		}));
		setSaveTrigger(prev => !prev);


	};
	const insert = (arr, index, newItem) => [
		// part of the array before the specified index
		...arr.slice(0, index),
		// inserted item
		newItem,
		// part of the array after the specified index
		...arr.slice(index),
	];

	const addToTop = (entity) => {
		setOffer(offer => {
			const mainIndex = offer.form.pattern.findIndex((item) => item.type === 'main');
			return {
				...offer,
				form: {
					...offer.form,
					pattern: insert(offer.form.pattern, mainIndex, entity),
				},
			}
		});
		setSaveTrigger(prev => !prev);


	};

	const addToBottom = (entity) => {
		setOffer(offer => ({
			...offer,
			form: {
				...offer.form,
				pattern: [...offer.form.pattern, entity],
			},
		}));
		setSaveTrigger(prev => !prev);

	};
	const updateEntity = (entity) => {
		setOffer(offer => ({
			...offer,
			form: {
				...offer.form,
				pattern: offer.form.pattern.map((item) => {
					if (item.id === entity.id) {
						return entity;
					}
					return item;
				}),
			},
		}));
		setSaveTrigger(prev => !prev);
	};
	const updateMainEntity = (key, updatedEntity) => {

		setOffer(offer => ({
			...offer,
			form: {
				...offer.form,
				pattern: offer.form.pattern.map((entity) => {
					if (entity.type === "main") {
						return {
							...entity,
							body: {
								...entity.body,
								[key]: entity?.body?.[key].map(item => {
									console.log("!!!!!!!!!!! itemy", item);

									if (item.id === updatedEntity.id) {
										console.log("!!!!!!!!!!! entity key", updatedEntity);

										return updatedEntity;
									}
									return item
								})
							}
						};
					}
					console.log("item", entity);
					return entity;
				}),
			},
		}));
		setSaveTrigger(prev => !prev);

		// setSaveTrigger(prev => !prev);
	};
	const openOrder = () => {
		const mainInfo = offer?.form?.pattern?.find((item) => item.type === 'main')?.body;
		let products = [];
		let clientInfo = {};
		if (mainInfo) {
			products = mainInfo?.room_type?.map((room) => room?.products)?.flat() ?? [];
			clientInfo = mainInfo?.client_info ?? {};
		}
		// const coItems = {
		//     clientInfo,
		//     products
		// }
		console.log('clientInfo', clientInfo);
		history.push(`/create-order/new`, {
			mode: 'co',
			data: {
				items: products.map((itm) => {
					return {
						...itm,
						amount: itm.amount,
						item: itm.ref,
						sum: 0,
						manager_discount: 0,
						type: itm.type ? itm.type : 'default',
					};
				}),
			},
		});

		// console.log("coItems", coItems);
		// dispatch(addCoItems(coItems))
	};
	const downloadUrl = () => {
		getUrl(offer);
	};
	const isReadOnly = () =>
		!(
			authRedux.authorized &&
			(handbookRedux.d_isManager || handbookRedux.d_isAdminManager)
		);
	return (
		<div
			style={{
				backgroundColor: '#f0f2f5',
				width: '100%',
				minHeight: '100%',
				display: 'flex',
				justifyContent: 'center',
			}}
		>
			<div
				className="commercial-offer-page"
				style={isReadOnly() ? { width: 'fit-content' } : {}}
			>
				{!isReadOnly() && (
					<div
						style={{
							width: '100%',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'flex-end',
						}}
					>
						{/* <Button
							onClick={openOrder}
							icon={<PlusOutlined />}
							type="primary"
						>
							Новый заказ из КП
						</Button> */}
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<Button
								style={{ marginRight: 15 }}
								onClick={downloadUrl}
								loading={loadingForm}
							>
								Поделиться
							</Button>
							<div
								style={{
									marginRight: 5,
									fontSize: 16,
								}}
							>
								Режим просмотра
							</div>
							<Switch onChange={setReadOnly} />
						</div>
					</div>
				)}
				{/* {!isReadOnly() && <div>
                    <Button
                        loading={loadingForm}
                        onClick={save}
                    >
                        Сохранить изменения
                    </Button>
                </div>} */}
				<EditPattern
					readOnly={isReadOnly() || readOnly}
					mainInfo={{
						...(offer?.form?.pattern?.find((item) => item.type === 'main')
							?.body ?? {}),
						pattern: undefined,
						name: undefined,
					}}
					updateMainEntity={updateMainEntity}
					pattern={{ pattern: offer?.form?.pattern }}
					updateEntity={updateEntity}
					addToBottom={addToBottom}
					deleteEntity={deleteEntity}
					addToTop={addToTop}
				/>
			</div>
		</div>
	);
};

export default CommercialOfferPage;
