export default function calculateFilterBody(
  radioFilter: string,
  //   "all" | "processing" | "preFullSold" | "waitingPayment" | "finished",
  orgFromFilter: string,
  searchData: string
) {
  const body = {
    filters: [
      {
        field: "number",
        comparison: "like",
        value: searchData !== null ? searchData : "",
        condition: "and",
      },
      {
        field: "partner",
        comparison: "!=",
        value: "c9f80ef1-42c3-11e8-9a82-001e6730f61a",
        condition: "and",
      },
    ],
    // ref: [],
  };

  if (orgFromFilter !== "" && orgFromFilter !== undefined) {
    body.filters.push({
      field: "partner",
      comparison: "=",
      value: orgFromFilter,
      condition: "and",
    });
  }

  // if (radioFilter !== "all") {
  switch (radioFilter) {
    case "all":
      // все
      body.filters.push({
        field: "deleted",
        comparison: "!=",
        value: "1",
        condition: "and",
      });
      break;
    case "wait":
      // на оплате
      body.filters.push(
        {
          field: "payments_amount",
          comparison: "=",
          value: "0",
          condition: "and",
        },
        {
          field: "sales_amount",
          comparison: "=",
          value: "0",
          condition: "and",
        },
        {
          field: "posted",
          comparison: "=",
          value: "1",
          condition: "and",
        }
      );
      break;
    case "processing_old":
      // в работе
      body.filters.push(
        {
          field: "payments_amount",
          comparison: "<",
          value: "0,items_amount",
          condition: "or",
          type: "whereColumn",
        } as any,
        {
          field: "sales_amount",
          comparison: "<",
          value: "0,items_amount",
          condition: "or",
          type: "whereColumn",
        } as any,
        {
          field: "posted",
          comparison: "=",
          value: "1",
          condition: "and",
        }
      );
      break;
    case "processing":
      // в работе
      body.filters.push(
        {
          field: "deleted",
          comparison: "!=",
          value: "1",
          condition: "and",
        },
        {
          field: "sales_amount",
          comparison: "=",
          value: "0",
          condition: "and",
        }
      );
      break;

    case "fullPaid":
      // оплаченные
      body.filters.push(
        {
          field: "payments_amount",
          comparison: "=",
          value: "0,items_amount",
          condition: "and",
          type: "whereColumn",
        } as any,
        {
          field: "posted",
          comparison: "=",
          value: "1",
          condition: "and",
        }
      );
      break;
    case "preFullSold":
      // отгруженные
      body.filters.push(
        {
          field: "deleted",
          comparison: "!=",
          value: "1",
          condition: "and",
        },
        {
          field: "presales_amount",
          comparison: "=",
          value: "0,items_amount",
          condition: "and",
          type: "whereColumn",
        } as any,
        {
          field: "posted",
          comparison: "=",
          value: "1",
          condition: "and",
        }
      );
      break;
    case "fullSold":
      // отгруженные
      body.filters.push(
        {
          field: "sales_amount",
          comparison: "=",
          value: "0,items_amount",
          condition: "and",
          type: "whereColumn",
        } as any,
        {
          field: "posted",
          comparison: "=",
          value: "1",
          condition: "and",
        }
      );
      break;
    case "finished":
      // завершённые
      body.filters.push(
        {
          field: "sales_amount",
          comparison: "=",
          value: "0,items_amount",
          condition: "and",
          type: "whereColumn",
        } as any,
        {
          field: "posted",
          comparison: "=",
          value: "1",
          condition: "and",
        }
      );
      break;
    case "waitingPayment":
      // завершённые
      body.filters.push(
        {
          field: "deleted",
          comparison: "!=",
          value: "1",
          condition: "and",
        },
        {
          field: "sales_amount",
          comparison: ">",
          value: "0,payments_amount",
          condition: "and",
          type: "whereColumn",
        } as any,
        {
          field: "posted",
          comparison: "=",
          value: "1",
          condition: "and",
        }
      );
      break;
    case "canceled":
      // отменённые
      body.filters.push(
        {
          field: "deleted",
          comparison: "=",
          value: "1",
          condition: "and",
        }
        // {
        //   field: "posted",
        //   comparison: "=",
        //   value: "1",
        //   condition: "and",
        // }
      );
      break;
    case "drafts":
      // черновики
      body.filters.push(
        {
          field: "posted",
          comparison: "=",
          value: "0",
          condition: "and",
        },
        {
          field: "deleted",
          comparison: "=",
          value: "0",
          condition: "and",
        },
        {
          field: "type",
          comparison: "!=",
          value: "0",
          condition: "and",
        }
      );
      break;
  }
  // }

  console.log(body);

  return body;
}
