import React, { useEffect, useState } from 'react';
import { Card, Typography, Table, Modal, Button, Input, message, Checkbox, Form, Select } from "antd";
import { useTypedSelector } from '../../redux/hooks';
import { useDispatch } from 'react-redux';
import { setStores } from '../../redux/slices/handbookSlice';

const CreateStoreModal = ({ visible, setVisible, setStores_, setUpdatedIndex, stores }) => {

    const [form] = Form.useForm();
    const [portalVisible, setPortalVisible] = useState(false)
    const [dealerVisible, setDealerVisible] = useState(false)
    const handbookRedux = useTypedSelector((state) => state.handbook);
    const dispatch = useDispatch();
    const sendForm = () => {
        form
            .validateFields()
            .then((values) => {
                const store = {
                    ...values,
                    type: "store",
                    portal_visible: portalVisible || dealerVisible,
                    dealer_visible: dealerVisible
                }
                fetch(SiteUrlDealer + "/stores", {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""
                            }`,
                    },
                    body: JSON.stringify(store),
                    method: "POST",
                }).then((res) => res.json())
                    .then((json) => {
                        const id = json.id
                        setUpdatedIndex(id);
                        setTimeout(() => {
                            setUpdatedIndex(0);
                        }, 1500)
                        const createdStore = { ...store, id: id, portalVisible: store.portal_visible, dealerVisible: store.dealer_visible }

                        if (store.portal_visible) {
                            dispatch(setStores([createdStore, ...handbookRedux.stores]))
                        }
                        setStores_(stores => {
                            console.log("Stores", { createdStore, ...stores });
                            return [createdStore, ...stores]
                        })
                        setVisible(false);
                    })

            })
    }


    return (
        <Modal
            title={
                <Typography.Title level={4}>Создать склад</Typography.Title>
            }
            centered
            visible={visible}
            onOk={sendForm}
            okText={"Создать"}
            onCancel={() => setVisible(false)}
            closable={true}
            destroyOnClose
            width={window.screen.width < 450 ? "100%" : 450}
            // zIndex={10000}
            bodyStyle={{ paddingBottom: 16 }}
        >
            <Form
                name="wrap"
                // labelCol={{ flex: "110px" }}
                labelAlign="left"
                labelWrap
                wrapperCol={{ flex: 1 }}
                colon={false}
                form={form}
                layout="vertical"
            >
                <Form.Item
                    label="Название склада"
                    required
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: "Поле обязательно к заполнению",
                        },
                    ]}
                >
                    <Input placeholder="Введите название склада" className="settings__input" />
                </Form.Item>
                <Form.Item
                    label="Ref склада"
                    name="ref"
                >
                    <Input placeholder="Введите ref склада" className="settings__input" />
                </Form.Item>
                <Form.Item
                    label="Битрикс ID склада"
                    name="bitrix_id"
                >
                    <Input placeholder="Введите битрикс id склада" className="settings__input" />
                </Form.Item>
                <Form.Item
                    label="Транзитый склад"

                    name="transit_store"
                >
                    <Select

                        options={[{
                            label: "Не указан",
                            value: null
                        }
                            , ...stores.map(item => {
                                return {
                                    label: item.name,
                                    value: item.ref
                                }
                            })]}
                        placeholder="Введите транзитный склад"
                        className="settings__input" />
                </Form.Item>
                <Form.Item>
                    <Checkbox
                        value={portalVisible}
                        onChange={(e) => setPortalVisible(e.target.checked)}
                        style={{ marginLeft: 0 }}
                        className="settings__input"
                    >
                        {"Отображется на портале"}
                    </Checkbox>
                    <Checkbox
                        value={dealerVisible}
                        style={{ marginLeft: 0 }}
                        onChange={(e) => setDealerVisible(e.target.checked)}
                        className="settings__input" >
                        {"Доступен для дилеров"}
                    </Checkbox>
                </Form.Item>
            </Form>
        </Modal >
    );
};

export default CreateStoreModal;