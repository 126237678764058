import { Modal, Radio, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import PatternEntityEditForm from './PatternEntityEditForm';
import useLoadPatternEntity from './useLoadPatternEntity';
import moment from 'moment';

const PatternEntityModal = ({ setEntity, editing = false, entity, visible, setVisible, addEntity, local = false, parentAction = undefined }) => {
    const { loading, patternEntities, updatePatternEntity, updateEntity, createPatternEntity } = useLoadPatternEntity({ withLoad: true })
    const [action, setAction] = useState("new");

    const onOk = () => {
        if (local) {
            setVisible(false);
            const newEntity = {
                ...entity,
                id: entity?.id ? entity.id : moment().valueOf()
            };
            addEntity(newEntity);
            updateEntity(newEntity)
        } else {

            new Promise((resolve, reject) => {
                console.log(entity);
                if (entity.id) {
                    updatePatternEntity(entity).then((entity) => {
                        resolve(entity);
                    }).catch(e => {
                        reject()
                    });
                } else {
                    createPatternEntity(entity).then((entity) => {
                        resolve(entity);
                    }).catch(e => {
                        reject()
                    });
                }
            }).then((entity) => {
                setVisible(false);

                addEntity(entity);

            }).catch(() => {

            })
        }

    }
    const onChangeAction = (event) => {
        const newAction = event.target.value;
        setAction(newAction);
        if (newAction == "new") {
            setEntity({})
        }
    }

    const onCancel = () => {
        setVisible(false);
    }

    const selectEntity = (id) => {
        const selectedEntity = patternEntities.find(item => item.id === id);
        console.log(selectedEntity);
        setEntity(selectedEntity);
    }

    useEffect(() => {
        if (visible) {
            if (entity.id) setAction("update")
            console.log("entity", entity);
        }
    }, [visible])

    return (
        <Modal
            visible={visible}
            width="fit-content"
            onOk={onOk}

            destroyOnClose
            onCancel={onCancel}
            loading={loading}
            okText="Добавить"
            cancelText="Закрыть"
        >
            <div style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
                marginBottom: 10,

            }}>

                {!editing && <> <div style={{
                    display: "flex",
                    flexDirection: "column",
                    width: 300,
                    marginRight: 15
                }}>
                    <div>
                        Выбрать блок
                    </div>
                    <Select
                        disabled={action === "new"}
                        onChange={selectEntity}
                        value={entity?.id}
                        options={patternEntities.map((item) => {
                            return {
                                label: item.name,
                                value: item.id
                            }
                        })}
                    />
                </div>
                    <Radio.Group
                        style={{}}
                        options={[
                            {
                                label: "Создать новый",
                                value: "new"
                            },
                            {
                                label: "Выбрать",
                                value: "update"
                            }
                        ]}
                        onChange={onChangeAction}
                        value={action}
                        optionType="button"
                        buttonStyle="solid"
                    /></>}
            </div>
            {((parentAction ? parentAction === "new" : action === "new") || ((parentAction ? parentAction === "update" : action === "update") && entity?.id)) && <PatternEntityEditForm entity={entity} setEntity={setEntity} local={local} />}
        </Modal>
    );
};

export default PatternEntityModal;