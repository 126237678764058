import React, { useState, useEffect, useMemo, useRef } from "react";
import { Card, Button, Divider, Spin, message, Typography, Modal, Tag } from "antd";
import { useTypedDispatch, useTypedSelector } from "../../redux/hooks";
import { PictureTwoTone } from "@ant-design/icons";

type Props = { addPromos: any };

export default function ActivePromotions({ addPromos }: Props) {
  const handbookRedux = useTypedSelector((state) => state.handbook);
  const ref = useRef(null);
  const [promotions, setPromotions] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalData, setModalData] = useState<any>({});
  const [showMultiInput, setShowMultiInput] = useState(false);
  const [showMultiOutput, setShowMultiOutput] = useState(false);

  useEffect(() => {
    let isCancelled = false;
    setIsLoading(true);
    fetch(`${SiteUrlDealer}/${(handbookRedux.d_isManager || handbookRedux.d_isAdminManager) ? "promotion" : "user_prom_index"}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""}`,
      },
      method: (handbookRedux.d_isManager || handbookRedux.d_isAdminManager) ? "GET" : "POST",
    })
      .then((res) => res.json())
      .then((json) => {
        if (!isCancelled) {
          //   if (!isCancelled) {
          setPromotions(json);
          setIsLoading(false);
          //   }
        }
      })
      .catch(() => message.error("Что-то пошло не так"));
    return () => {
      isCancelled = true;
    };
  }, []);

  const showModal = (itm: any) => {
    setIsModalVisible(true);
    setModalData(itm);
  };

  const handleOk = () => {
    const converted = modalData.condition
      .map((itm: any) => {
        // return itm.input_data.map((inn: any) =>
        //   inn.input_type.map((in2: any) => ({
        //     ref: in2,
        //     qty: inn.input_value,
        //   }))
        // );
        return itm.input_data.map((inn: any) => ({
          ref: inn.input_type[0],
          qty: inn.input_value,
        }));
      })
      .flat(Infinity)
      .filter((f: any) => f.ref !== "money" && f.ref !== "percent");
    console.log("child", converted);
    if (converted.length > 0) {
      addPromos(converted);
      setIsModalVisible(false);
    } else {
      message.info("Недопустимый тип условия");
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  function handleCollapse() {
    setCollapsed(!collapsed);
  }

  function calculateCollapseContainerHeight() {
    var height = 0;
    const defaultHeight = (handbookRedux.d_isManager || handbookRedux.d_isAdminManager) ? 240 : 210;
    if (window.screen.width > 450) {
      height = collapsed ? defaultHeight : (promotions?.length / 4) * (ref?.current?.clientHeight + 16) + 40;
    } else {
      height = collapsed ? defaultHeight : (promotions?.length / 2) * (ref?.current?.clientHeight + 16) + 50;
    }

    return height ? height : 0;
  }

  function findProductName(ref: string) {
    return handbookRedux.products.find((f: any) => f.ref === ref).full_name;
  }

  function translateComparison(comp: string) {
    switch (comp) {
      case ">":
        return "Больше";
      case "<":
        return "Меньше";
      case "=":
        return "";
      case "%":
        return "Кратно";
      case "=*":
        return "За каждый";
      default:
        return "";
    }
  }

  function translateUnit(unit: string) {
    switch (unit) {
      case "percent":
        return "%";
      case "money":
        return "₽";
      default:
        return "шт.";
    }
  }

  function tags(data: any[], typeName: "На сумму" | "Скидка", type: any, setType: any) {
    if (data.length === 1) {
      return (
        <Tag color={typeName == "На сумму" ? "blue" : "green"} style={{ whiteSpace: "normal" }}>
          {data[0] === "money" || data[0] === "percent" ? typeName : findProductName(data[0])}
        </Tag>
      );
    } else {
      return (
        <div>
          {type ? (
            <>
              {data?.map((type: any, ind: number) => (
                <div key={ind} style={{ marginBottom: 5 }}>
                  <Tag color={typeName == "На сумму" ? "blue" : "green"} style={{ whiteSpace: "normal" }}>
                    {type === "money" || type === "percent" ? typeName : findProductName(type)}
                  </Tag>
                </div>
              ))}
            </>
          ) : (
            <Tag color={typeName == "На сумму" ? "blue" : "green"} style={{ whiteSpace: "normal" }}>
              {data[0] === "money" || data[0] === "percent" ? typeName : findProductName(data[0])}
            </Tag>
          )}
          <br />
          <Button type="link" size="small" onClick={() => setType(!type)}>
            Показать все
          </Button>
        </div>
      );
    }
  }

  if (isLoading) {
    return (
      <div style={{ height: 200, textAlign: "center", paddingTop: 80 }}>
        <Spin />
      </div>
    );
  } else if (promotions.length === 0) {
    return <div />;
  } else {
    return (
      <div style={{ marginBottom: 20 }}>
        <div
          style={{
            height: calculateCollapseContainerHeight(),
            overflow: "hidden",
            transition: "height .5s",
          }}
        >
          <div
            style={{
              display: "grid",
              margin: window.screen.width > 450 ? 0 : "0 16px",
              gridTemplateColumns: window.screen.width > 450 ? "repeat(4, 1fr)" : "repeat(2, 1fr)",
              gridAutoRows: "1fr",
              height: ((promotions?.length / 4) * (ref?.current?.clientHeight + 16) + 20) || 0,
              gap: 8,
            }}
          >
            {promotions.map((itm) => (
              <div key={itm.id}>
                <Card
                  cover={
                    <div
                      style={{
                        background: "#bae7ff",
                        textAlign: "center",
                        borderTopLeftRadius: 8,
                        borderTopRightRadius: 8,
                        position: "relative",
                      }}
                    >
                      {itm.image ? (
                        <img src={itm.image} style={{ width: "100%", height: 80, objectFit: "cover" }} />
                      ) : (
                        <PictureTwoTone style={{ fontSize: 80 }} />
                      )}
                    </div>
                  }
                  onClick={() => showModal(itm)}
                  style={{ cursor: "pointer" }}
                  ref={ref}
                >
                  {(handbookRedux.d_isManager || handbookRedux.d_isAdminManager) && (
                    <Tag color={itm.enable == "1" ? "green" : "error"} style={{ marginBottom: 10 }}>
                      {itm.enable == "1" ? "Активна" : "Не активна"}
                    </Tag>
                  )}
                  <Typography.Title level={5} style={{ height: 70 }} ellipsis={{ rows: 3 }}>
                    {itm.title}
                  </Typography.Title>
                </Card>
              </div>
            ))}
          </div>
        </div>

        <div style={{ width: "100%", textAlign: "center" }}>
          <Button type="link" onClick={handleCollapse}>
            Показать все
          </Button>
        </div>

        <Modal
          title={modalData?.title ? modalData?.title : "Акция"}
          visible={isModalVisible}
          onOk={handleOk}
          okText="Добавить к заказу"
          onCancel={handleCancel}
          width={window.screen.width > 450 ? "50%" : "100%"}
          className="create-modal-discounts"
          bodyStyle={{ overflowX: "hidden", overflowY: "auto" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: window.screen.width > 450 ? "row" : "column",
            }}
          >
            <div
              style={{
                background: "#bae7ff",
                textAlign: "center",
                position: "relative",
                padding: modalData.image ? 0 : "7px 10px",
                borderRadius: 8,
                width: window.screen.width > 450 ? "35%" : "100%",
              }}
            >
              {modalData.image ? (
                <img
                  src={modalData.image}
                  style={{ width: "100%", height: 120, borderRadius: 8, objectFit: "cover" }}
                />
              ) : (
                <PictureTwoTone style={{ fontSize: 120 }} />
              )}
            </div>

            <div
              style={{
                width: window.screen.width > 450 ? "60%" : "100%",
                marginTop: window.screen.width > 450 ? 0 : 10,
              }}
            >
              {modalData.description ? modalData.description : "Описание акции"}
            </div>
          </div>

          {modalData.condition?.map((itm: any, ind: number, arr: any[]) => (
            <span key={itm.id}>
              <div>
                <Typography.Title level={4} style={{ marginTop: 20, marginBottom: 20 }}>
                  Купи
                </Typography.Title>
                <div>
                  {itm.input_data?.map((inp: any, inpInd: number, inpArr: any[]) => (
                    <span key={inp.id}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          flexWrap: "wrap",
                        }}
                      >
                        <div style={{ marginLeft: 20, width: window.screen.width > 450 ? "60%" : "100%" }}>
                          {tags(inp.input_type, "На сумму", showMultiInput, setShowMultiInput)}
                        </div>
                        <div style={{ marginLeft: window.screen.width > 450 ? 0 : 20 }}>
                          {translateComparison(inp.input_comparison)}
                        </div>
                        <div>
                          {inp.input_value}
                          {translateUnit(inp.input_value[0])}
                        </div>
                      </div>
                      {inpInd !== inpArr.length - 1 && <Divider plain>и</Divider>}
                    </span>
                  ))}
                </div>

                <Typography.Title level={4} style={{ marginTop: 20, marginBottom: 20 }}>
                  Получи
                </Typography.Title>
                <div>
                  {itm.output_data?.map((inp: any, inpInd: number, inpArr: any[]) => (
                    <span key={inp.inpInd}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          flexWrap: "wrap",
                        }}
                      >
                        <div style={{ marginLeft: 20, width: window.screen.width > 450 ? "60%" : "100%" }}>
                          {tags(inp.output_type, "Скидка", showMultiOutput, setShowMultiOutput)}
                        </div>
                        <div>{translateComparison(inp.output_comparison)}</div>
                        <div>
                          {inp.output_value} {translateUnit(inp.output_type[0])}
                        </div>
                      </div>
                      {inpInd !== inpArr.length - 1 && <Divider plain>и</Divider>}
                    </span>
                  ))}
                </div>
              </div>
              {ind !== arr.length - 1 && <Divider />}
            </span>
          ))}
        </Modal>
      </div>
    );
  }
}
