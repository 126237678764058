import { Modal, Typography } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';

const AgreementModal = ({ visible, setVisible, bizprocId, setBizProcId }) => {
    const history = useHistory();
    const onOk = () => {
        window.open(`https://vakio.bitrix24.ru/company/personal/bizproc/${bizprocId}/`, '_blank', 'noreferrer');
        setVisible(false);
        setBizProcId(null);
        history.push(`/orders`)
    }

    return (
        <Modal
            centered
            visible={visible}
            // visible={true}
            title={
                <Typography.Title level={4} style={{ marginBottom: 0 }}>
                    Необходимо согласование
                </Typography.Title>
            }
            onCancel={() => setVisible(false)}
            onOk={onOk}
            okText="Перейти"
            closable={true}
        >
            <div>

                <div>
                    Для того, чтобы отправить отгрузку на согласование необходимо перейти по <a href={`https://vakio.bitrix24.ru/company/personal/bizproc/${bizprocId}/`} target='_blank'>
                        ссылке
                    </a>. Без этого отгрузка не будет согласована!
                </div>

            </div>
        </Modal>
    );
};

export default AgreementModal;