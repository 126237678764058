import React, { useRef, useState } from 'react';
import DivInput from '../DivInput/DivInput';

const TextComponent = ({ readOnly, data, onDataChange }) => {
    const [readOnlyEdit, setReadOnlyEdit] = useState(false);
    // const [values, setValues] = useState(data);
    const values = useRef(data);
    const isReadOnly = () => {
        return readOnlyEdit || readOnly;
    }
    const updateData = (key, value) => {
        const newData = {
            ...values.current,
            [key]: value
        }
        onDataChange(newData);
        //setValues(newData);
        values.current = newData;
    }


    return (
        <div>
            <DivInput
                containerStyle={{}}
                onInput={(value) => updateData("text", value)}
                onChangeSize={(value) => updateData("textSize", value)}
                isReadOnly={isReadOnly()}
                value={values.current.text}
                fontSize={values.current.textSize} />

        </div>
    );
};

export default TextComponent;