import React, { useState, useEffect } from "react";
import { useTypedDispatch, useTypedSelector } from "../../redux/hooks";
import { Button, Divider, Table, Input, Typography, Tag, Space, message, Select } from "antd";

import "../../styles/FilterField.scss";
import "./Settings.scss";

type Props = {
  userData: {
    id: number;
    name: string;
    email: string;
    capabilities: string;
    key: number;
  };
  visible: boolean;
  setVisible: any;
};

export default function LegalTable({ userData, visible, setVisible }: Props) {
  const handbookRedux = useTypedSelector((state) => state.handbook);
  const [legals, setLegals] = useState<any[]>([]);

  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(50);
  const [searchData, setSearchData] = useState<string | null>("");
  const [searchTrigger, setSearchTrigger] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fullCount, setFullCount] = useState<number>(50);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
  const [companies, setCompanies] = useState<any[]>([]);
  const [companiesCache, setCompaniesCache] = useState<any[]>([]);
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const [mainOrg, setMainOrg] = useState<any>("");
  const [orgsForMainFilter, setOrgsForMainFilter] = useState(handbookRedux.partners);

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys: React.Key[], selectedRows: any) => {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRows(selectedRows);
    },
    // getCheckboxProps: (record: any) => ({
    //   disabled: record.name === "Disabled User", // Column configuration not to be checked
    //   name: record.name,
    // }),
  };

  useEffect(() => { }, []);

  useEffect(() => {
    setIsLoading(true);

    const timeout = setTimeout(() => {
      const body = {
        filters: [
          {
            field: "name",
            comparison: "like",
            value: searchData,
          },
        ],
        // ref: [],
      };
      if (mainOrg !== "") {
        body.filters.push({
          field: "main_organization",
          comparison: "=",
          value: mainOrg,
        });
      }
      // console.log("sas", body, mainOrg);

      // все организации
      fetch(
        `${SiteUrlDealer}/organization_with_filters?limit=${pageSize ? pageSize : "50"}&page=${page ? page : 1
        }&order_by=id&order_type=DESC`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""
              }`,
          },
          body: JSON.stringify(body),
          method: "POST",
        }
      )
        .then((res) => res.json())
        .then((json) => {
          console.log(json);
          setLegals(
            json.partners.map((itm: any, ind: any) => ({
              ...itm,
              key: ind.toString(),
            }))
          );
          setFullCount(json.count);
          setIsLoading(false);
        })
        .catch((err) => setIsLoading(false));
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [searchTrigger, page, pageSize, mainOrg]);

  useEffect(() => {
    // fetch(`${SiteUrlDealer}/organizations_by_id`, {
    fetch(`${SiteUrlDealer}/index_relations_organizations`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""}`,
      },
      body: JSON.stringify({
        get_by: "user_id",
        value: userData.id,
      }),
      method: "POST",
    })
      .then((res) => res.json())
      .then((json) => {
        console.log("part", json);
        setCompanies(json);
        setCompaniesCache(json);
      });
  }, [userData]);

  const handleAddCompanies = () => {
    const qqq = selectedRows.filter((f) => {
      const refArr = companiesCache.map((i) => i.ref);
      if (refArr.includes(f.ref)) {
        return false;
      } else {
        return true;
      }
    });
    setCompanies(companies.concat(qqq));
    setSelectedRowKeys([]);
    setSelectedRows([]);
  };

  const handleSave = () => {
    setBtnLoading(true);
    const body = companies.map((itm) => ({
      user_id: userData.id,
      organization_ref: itm.ref,
    }));

    // console.log(companiesCache, "filtered", filteredBody);

    // сохраняшки
    fetch(`${SiteUrlDealer}/store_user_organization`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""}`,
      },
      body: JSON.stringify({ content: body }),
      method: "POST",
    })
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        // setCompanies(json.partners);
        setVisible(false);
        setBtnLoading(false);
        message.success("Данные сохранены");
      });
  };

  return (
    <div className="settings">
      <Typography.Title level={5}>Текущие организации</Typography.Title>
      <div className="settings__tags">
        {companies.length === 0 ? (
          <div>Нет организаций</div>
        ) : (
          companies.map((itm, ind) => (
            <Tag
              key={ind}
              color="blue"
              closable
              onClose={(e) => {
                e.preventDefault();
                setCompanies(companies.filter((f) => f.ref !== itm.ref));
                // setToDelete([...toDelete, { id: itm.id_relation }]);

                if (itm.id_relation) {
                  fetch(`${SiteUrlDealer}/user_organization/${itm.id_relation}`, {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""
                        }`,
                    },
                    method: "DELETE",
                  })
                    .then((res) => res.json())
                    .then((json) => {
                      // console.log(json);
                    })
                    .finally(() => message.success("Удалено"));
                }
              }}
              style={{ marginBottom: 5 }}
            >
              {itm.name}
            </Tag>
          ))
        )}
      </div>
      <Typography.Title level={5}>Добавить организацию</Typography.Title>

      <div className="filter-field">
        <div className="filter-field__item">
          <Input
            placeholder="Поиск по названию"
            allowClear
            size="small"
            onChange={(e) => {
              // setNameFilter(e.target.value);
              setPage(1);
              setSearchData(e.target.value);
              setSearchTrigger(!searchTrigger);
            }}
          />
        </div>

        <div className="filter-field__item">
          <Select
            style={{ width: "100%" }}
            placeholder="Головная организация"
            showSearch
            size="small"
            // @ts-ignore prettier-ignore
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            onSearch={(search) => {
              fetch(
                `${SiteUrlDealer}/organization_with_filters?limit=50&page=1&order_by=id&order_type=DESC`,
                {
                  headers: {
                    "Content-Type": "application/json",
                    // prettier-ignore
                    Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""}`,
                  },
                  // prettier-ignore
                  body: JSON.stringify({
                    filters: [{
                      field: "name",
                      comparison: "like",
                      value: search,
                    }]
                  }),
                  method: "POST",
                }
              )
                .then((res) => res.json())
                .then((json) => setOrgsForMainFilter(json.partners));
            }}
            // notFoundContent="Начните вводить название"
            onChange={(data, full) => {
              setPage(1);
              setMainOrg(data);
            }}
          >
            {orgsForMainFilter &&
              orgsForMainFilter.map((itm, ind) => (
                <Select.Option
                  key={ind}
                  value={itm.main_organization}
                  disabled={itm.main_organization === null}
                >
                  {itm.name}
                </Select.Option>
              ))}
          </Select>
        </div>
      </div>
      <Table
        pagination={{
          defaultCurrent: 1,
          total: fullCount,
          showSizeChanger: true,
          pageSizeOptions: ["3", "5", "10", "15", "20", "25", "50", "100"],
          pageSize: pageSize,
          onShowSizeChange: (page: any, size: any) => {
            setPageSize(size);
          },
          current: page,
          onChange: (p: any) => {
            setPage(p);
            setSelectedRowKeys([]);
            setSelectedRows([]);
          },
        }}
        size="small"
        rowSelection={{
          type: "checkbox",
          ...rowSelection,
        }}
        bordered={true}
        columns={[
          {
            title: "Имя",
            dataIndex: "name",
            key: "name",
          },
          {
            title: "Категория",
            dataIndex: "category_dealer",
            key: "category_dealer",
            render: (data) => <div>{data !== "" && data !== null ? data : "Не указано"}</div>,
          },
          {
            title: "Тип организации",
            dataIndex: "ref",
            key: "ref",
            render: (data, full) => {
              const type = data === full.main_organization ? "Головная" : "Филиал";
              return full.main_organization ? (
                <Tag color={type == "Головная" ? "blue" : "green"}>{type}</Tag>
              ) : (
                <div>Отсутствует</div>
              );
            },
            width: window.screen.width > 450 ? "15%" : "22%",
          },
        ]}
        dataSource={legals}
        scroll={{ y: 312, x: 500 }}
        loading={isLoading}
      />

      {legals && legals.length > 0 && (
        <div style={{ position: "absolute", bottom: 105 }}>
          <Button
            type="primary"
            size="small"
            onClick={() => {
              handleAddCompanies();
            }}
          >
            Добавить
          </Button>
        </div>
      )}
      <div style={{ position: "absolute", bottom: 105 }}>
        <Button
          type="primary"
          size="small"
          onClick={() => {
            handleAddCompanies();
          }}
        >
          Добавить
        </Button>
      </div>

      <Divider style={{ marginBottom: 16 }} />

      <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
        <Space>
          <Button
            type="default"
            onClick={() => {
              setVisible(false);
            }}
          >
            Отмена
          </Button>

          <Button type="primary" loading={btnLoading} onClick={() => handleSave()}>
            Сохранить
          </Button>
        </Space>
      </div>
    </div>
  );
}
