import { Button, Modal, Steps, Typography, message, Input } from 'antd';
import React, { useState } from 'react';
import CheckCompanyInfo from './CheckCompanyInfo';
import InnInput from './InnInput';
import "./MyCompanies.scss"
interface Props {
    visible: boolean;
    setVisible: any;
    setCompanyInfo: any;
    companyInfo: any;
    current: any;
    setCurrent: any;
}


const AddCompany = ({ visible, setVisible, companyInfo, setCompanyInfo, current, setCurrent }: Props) => {


    const next = () => {
        setCurrent(current + 1);
    };

    return (
        <Modal
            centered
            visible={visible}
            // visible={true}
            title={
                <Typography.Title level={4} style={{ marginBottom: 0 }}>
                    Добавить компанию
                </Typography.Title>
            }
            onCancel={() => setVisible(false)}
            // onOk={() => {
            //   validateData();
            // }}
            footer={null}
            okText="Отправить"
            closable={true}
        >
            <>
                {/* <Steps current={current} >
                    <Steps.Step title="Finished" description={"Finished"} />
                    <Steps.Step title="In Progress" description={"In Progress"} subTitle="Left 00:00:08" />
                    <Steps.Step title="Waiting" description={"Waiting"} />
                </Steps> */}
                {/* Первый шаг */}
                {current === 0 && <InnInput setCompanyInfo={setCompanyInfo} setStage={setCurrent} />}
                {current === 1 && <CheckCompanyInfo setVisible={setVisible} setCompanyInfo={setCompanyInfo} setStage={setCurrent} companyInfo={companyInfo} />}
                <div style={{ marginTop: 24 }}>

                </div>
            </>
        </Modal>
    );
};

export default AddCompany;