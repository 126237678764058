// осталось попроавить подстановку itm.sum/ fullprice и итого

import React, { useState, useEffect, useMemo } from "react";
import { Button, Select, Table, InputNumber, Typography, Empty, Divider, Input } from "antd";
import { PlusOutlined, MinusOutlined, SearchOutlined } from "@ant-design/icons";
import { SelectProps } from "antd/es/select";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/store";
import { useHistory, useLocation } from "react-router-dom";
import { routePropsTypes, toSendType, productsType, promoType } from "./types";

import "./CreateOrder.scss";
import ProductAmount from "./ProductAmount";
import { IOneProduct } from "./CreateOrderListItem";




interface ITableCreateOrderProps {
  products: productsType[];
  //   promo: promoType[];
  total: number;
  productsMode: "classic" | "prefill";
  fullData: IOneProduct[];
  setParentData: any;
  disabled: boolean;
  addTrigger: boolean;
  setAddTrigger: any;
  setModifedProduct: any;
  modifedProduct: any;
}

export default function OrderTable(props: ITableCreateOrderProps) {
  const location = useLocation();
  const handbookRedux = useSelector((state: RootState) => state.handbook);
  const [lastIndex, setLastIndex] = useState<number>(1);
  const [subtotal, setSubtotal] = useState<number | null>(null);
  const [inputTrigger, setInputTrigger] = useState<boolean>(false);
  const template = {
    key: 0,
    name: "",
    amount: 1,
    fullprice: "0",
    discount: "0",
    sum: "0",
    ref: "",
    delete: 0,
  };

  const [desktopView, setDesktopView] = useState<any[]>([template]);
  const [priceTotal, setPriceTotal] = useState<number>(0);
  const [mobileView, setMobileView] = useState<any[]>([]);

  useEffect(() => {
    const filtered = props.products.filter((f) => !f.ref_item.includes("temporary"));

    setMobileView(filtered);
  }, [props.products]);

  // подставляшки данных с родительского компонента
  useEffect(() => {
    if (!inputTrigger) {
      setFromParent();
    }
  }, [props.products]);


  function setFromParent() {
    const convertedProducts = props.products
      .filter((f) => !f.ref_item.includes("temporary") && f.discount !== undefined)
      .map((itm, ind) => ({
        key: ind,
        name: itm.name_item,
        amount: itm.qty,
        fullprice: itm.default_price,
        discount: itm.discount,
        ref: itm.ref_item,
        delete: ind,
        sum: itm.sum,
        type: itm.type,
      }));

    // console.log("setFromParent", convertedProducts);

    setDesktopView(convertedProducts);
    const asd = convertedProducts.reduce((n: any, { fullprice, amount }: any) => n + fullprice * amount, 0);
    setPriceTotal(asd);
  }

  if (window.screen.width > 450) {
    return (
      <div>
        <Table
          className="creating-table"
          pagination={false}
          bordered={true}
          columns={[
            {
              title: "№",
              dataIndex: "delete",
              key: "delete",
              render: (key) => key + 1,
              width: 40,
              align: "center",
              fixed: "left",
            },
            {
              title: "Название",
              dataIndex: "name",
              key: "name",
              render: (name, record, index) =>
                record.type == 'promo' ? (
                  <div>{name} <span className="promo-label">АКЦИЯ</span></div>
                ) : (
                  <div>{name}</div>
                ),
              width: 400,
              align: "left",
              fixed: "left",
            },
            {
              title: "Кол-во",
              dataIndex: "amount",
              key: "amount",
              width: 130,
              align: "center",
              render: (amount, full) => <ProductAmount
                modifedProduct={props.modifedProduct}
                setModifedProduct={props.setModifedProduct}
                setParentData={props.setParentData}
                addTrigger={props.addTrigger}
                setAddTrigger={props.setAddTrigger}
                itm={full}
                fullData={props.fullData}
                disabled={props.disabled}
                productsMode={props.productsMode}
                amount={amount} />
            },
            {
              title: "Цена",
              dataIndex: "fullprice",
              key: "fullprice",
              width: 100,
              render: (text) => <div>{formatMoney(text)}&nbsp;₽</div>,
              align: "center",
            },
            {
              title: "Скидка",
              dataIndex: "discount",
              key: "discount",
              width: 100,
              render: (text) => (
                <div>
                  <div>-{text}%</div>
                  {/* <div>{formatMoney(text)} ₽</div> */}
                </div>
              ),
              align: "center",
            },
            {
              title: "Сумма",
              dataIndex: "sum",
              key: "sum",
              width: 100,
              render: (text) => <div>{formatMoney(text)}&nbsp;₽</div>,
              align: "right",
              fixed: "right",
            },
          ]}
          dataSource={desktopView}
          summary={(pageData) => {
            if (desktopView.length > 0) {
              return (
                <Table.Summary>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={1} colSpan={3} align="center">
                      <Typography.Text strong>Итого</Typography.Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell align="center" index={3}>
                      <Typography.Text strong>{formatMoney(priceTotal)}&nbsp;₽</Typography.Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell align="center" index={3}>
                      <Typography.Text strong></Typography.Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell align="right" index={3}>
                      <Typography.Text strong>{formatMoney(props.total)}&nbsp;₽</Typography.Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              );
            } else {
              return <></>;
            }
          }}
        />
      </div>
    );
  } else {
    return (
      <div>
        {mobileView.length > 0 ? (
          mobileView.map(
            (itm, ind, arr) =>
              !itm.ref_item.includes("temporary") && (
                <div className="mobile-table" key={ind}>
                  <div className="mobile-table__title">
                    {itm.type == 'promo' ? (
                      <div>{itm.name_item} <span className="promo-label">АКЦИЯ</span></div>
                    ) : (
                      <div>{itm.name_item}</div>
                    )}
                  </div>
                  <div className="mobile-table__main">
                    <div className="mobile-table__item">
                      <div className="mobile-table__item-left">Количество</div>
                      <div className="mobile-table__item-right">{itm.qty}&nbsp;шт.</div>
                    </div>
                    <div className="mobile-table__item">
                      <div className="mobile-table__item-left">Цена</div>
                      <div className="mobile-table__item-right">{formatMoney(itm.default_price)}&nbsp;₽</div>
                    </div>

                    <div className="mobile-table__item">
                      <div className="mobile-table__item-left">Скидка</div>
                      <div className="mobile-table__item-right">{itm.discount}&nbsp;%</div>
                    </div>

                    <div className="mobile-table__item">
                      <div className="mobile-table__item-left">Сумма</div>
                      <div className="mobile-table__item-right">{formatMoney(itm.sum)}&nbsp;₽</div>
                    </div>
                  </div>
                  {ind !== arr.length - 1 && !itm.ref_item.includes("temporary") && <Divider />}
                </div>
              )
          )
        ) : (
          <Empty />
        )}
      </div>
    );
  }
}
