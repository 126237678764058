import React, { useEffect, useState } from 'react';
import { message } from "antd"
const useLoadPatternEntity = ({ withLoad }) => {
    const [patternEntities, setPatternEntities] = useState([])
    const [loading, setLoading] = useState(false);
    const createPatternEntity = (entityType) => {
        setLoading(true);
        return fetch(SiteUrlDealer + "/pattern_entity", {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""
                    }`,
            },
            method: "POST",
            body: JSON.stringify(entityType)
        }).then(res => res.json()).then(response => {
            setPatternEntities([...patternEntities, response]);
            return response;
        }).catch(e => {
            message.error("Не удалось создать блок");
        }).finally(() => {
            setLoading(false);
        })
    }

    const updateEntity = (updatedType) => {
        setPatternEntities(patternEntities => patternEntities.map(type => {
            if (type.id == updatedType.id) {
                return updatedType;
            }
            return type;
        }));

    }
    const addEntity = (entity) => {
        setPatternEntities([...patternEntities, entity]);

    }
    const updatePatternEntity = (entityType) => {
        setLoading(true);
        return fetch(SiteUrlDealer + "/pattern_entity/" + entityType.id, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""
                    }`,
            },
            method: "PUT",
            body: JSON.stringify(entityType)
        }).then(res => res.json()).then(response => {
            const updatedType = response;
            updateEntity(updatedType);
            return updatedType;
        }).catch(e => {
            message.error("Не удалось обновить блок");
        }).finally(() => {
            setLoading(false);
        })
    }
    const deletePatternEntity = (id) => {
        setLoading(true);
        return fetch(SiteUrlDealer + "/pattern_entity/" + id, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""
                    }`,
            },
            method: "DELETE"
        }).then(response => {
            setPatternEntities(patternEntities.filter(type => type.id != id));
        }).catch(e => {
            message.error("Не удалось удалить блок");
        }).finally(() => {
            setLoading(false);
        })
    }
    const getPatternEntities = () => {
        setLoading(true);
        return fetch(SiteUrlDealer + "/pattern_entity", {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""
                    }`,
            },
            method: "GET"
        }).then(res => res.json()).then(response => {
            console.log("response", response);
            setPatternEntities(response);
        }).catch(e => {
            console.log("ERROR", e);
            message.error("Не удалось загрузить блоки");
        }).finally(() => {
            setLoading(false);
        })
    }
    useEffect(() => {
        if (withLoad)
            getPatternEntities();
        return () => {
            setLoading(false);
        }
    }, [])
    return {
        loading,
        patternEntities,
        getPatternEntities,
        createPatternEntity,
        updatePatternEntity,
        updateEntity,
        addEntity,
        deletePatternEntity
    }
};

export default useLoadPatternEntity;