import React, { useRef, useState } from 'react';
import { Typography, Select, Button, Divider, Space, Input } from 'antd';
import { PlusOutlined } from "@ant-design/icons"
import useLoadEntityType from './useLoadEntityType';

const TypeEntitySelect = ({ onChangeGroupId, groupId, allowClear = false, create = true }) => {
    const { entityTypes, loading, createEntityType, deleteEntityType } = useLoadEntityType();
    const [name, setName] = useState('');
    const inputRef = useRef(null);
    const onNameChange = (event) => {
        setName(event.target.value);
    };
    const addItem = (e) => {
        e.preventDefault();
        createEntityType({
            name: name
        })
        setName('');
        setTimeout(() => {
            inputRef.current?.focus();
        }, 0);
    };
    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography.Text style={{ marginBottom: 4 }}>Группа блока</Typography.Text>
            <Select
                loading={loading}
                allowClear={allowClear}
                value={groupId ? Number(groupId) : groupId}
                style={{ width: 300 }}
                placeholder="Выберите группу блока"
                onChange={onChangeGroupId}
                dropdownRender={(menu) => (
                    <>
                        {menu}
                        {create && <><Divider style={{ margin: '8px 0' }} />
                            <Space style={{ padding: '0 8px 4px' }}>
                                <Input
                                    placeholder="Введите имя нового блока"
                                    ref={inputRef}
                                    value={name}
                                    onChange={onNameChange}
                                />
                                <Button
                                    loading={loading}
                                    type="text"
                                    icon={<PlusOutlined />}
                                    onClick={addItem}>
                                    Создать
                                </Button>
                            </Space></>}
                    </>
                )}
                options={entityTypes.map((item) => ({ label: item.name, value: item.id }))}
            />
        </div>
    );
};

export default TypeEntitySelect;