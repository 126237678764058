import React, { useState, useEffect } from "react";
import { useTypedDispatch, useTypedSelector } from "../../redux/hooks";
import { Card, message, Divider, Switch, Tag } from "antd";
import { PlusOutlined, PictureTwoTone } from "@ant-design/icons";
import { useLocation, Link, useHistory } from "react-router-dom";
import { IPromotions } from "./types";

import { setHeaderTitle } from "../../redux/slices/layoutSlice";

import "../../styles/Card.scss";

type Props = { itm: IPromotions };

export default function PromotionItem({ itm }: Props) {
  const handbookRedux = useTypedSelector((state) => state.handbook);
  const history = useHistory();
  const [enabled, setEnabled] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (itm.enable == "1") {
      setEnabled(true);
    }
    if (itm.enable == "0") {
      setEnabled(false);
    }

    if (itm.condition?.length > 0) {
      const tags = itm.condition
        .map((i) => {
          if (i.output_data[0].output_type[0] == "percent" || i.output_data[0].output_type[0] == "money") {
            return "Скидка";
          } else {
            return "Подарок";
          }
          // return i.output_data[0].output_type[0]
        })
        .filter((i, p, a) => a.indexOf(i) == p);
      itm.tags = tags;
    }
  }, [itm]);

  const handleHideProduct = (state: boolean, prod: any) => {
    const toSend = {
      ...prod,
      enable: state ? "1" : "0",
      condition_id: [],
    };
    delete toSend.condition;
    delete toSend.tags;
    console.log(state, toSend);

    setLoading(true);
    fetch(`${SiteUrlDealer}/promotion/${prod.id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""}`,
      },
      body: JSON.stringify(toSend),
      method: "PUT",
    })
      .then((res) => res.text())
      .then((json) => {
        console.log(json);
        setEnabled(!enabled);
      })
      .catch((err) => {
        console.log(err);
        message.error("Ошибка!");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function navToItem() {
    if (handbookRedux.d_isManager || handbookRedux.d_isAdminManager) {
      history.push(`/promotions/${itm.id}`, itm);
    }
  }

  return (
    <Card
      bordered={false}
      className="card promotions__item"
      style={{ cursor: (handbookRedux.d_isManager || handbookRedux.d_isAdminManager) ? "pointer" : "default" }}
      onClick={navToItem}
      cover={
        <div className="promotions__cover-container">
          {itm.image ? (
            <img src={itm.image} style={{ width: "100%", height: 150, objectFit: "cover" }} />
          ) : (
            <PictureTwoTone style={{ fontSize: 150 }} />
          )}
        </div>
      }
    >
      <div
        className={`promotions__item-inner ${handbookRedux.d_isUser && "promotions__item-inner--user"
          } `}
      >
        <div className="promotions__tag">
          {itm.tags?.length > 0 ? (
            itm.tags?.map((i) => (
              <Tag key={i} color={i == "Подарок" ? "green" : "purple"}>
                {i}
              </Tag>
            ))
          ) : (
            <Tag color="error">Нет условий</Tag>
          )}
        </div>
        <div className="promotions__item-name">{itm.title}</div>
        <div className="promotions__description">{itm.description}</div>
        {(handbookRedux.d_isManager || handbookRedux.d_isAdminManager) && (
          <>
            <Divider />
            <div className="promotions__onoff-container">
              <div>Активна</div>
              <div>
                <span
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  <Switch
                    checked={enabled}
                    size="small"
                    loading={loading}
                    onChange={(e) => handleHideProduct(!enabled, itm)}
                  />
                </span>
              </div>
            </div>
          </>
        )}
      </div>
    </Card>
  );
}
