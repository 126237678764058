import React from 'react';
import { PlusOutlined } from "@ant-design/icons"
import "./AddEntity.scss"

const AddEntity = ({ openModal, setPosition }) => {

    const onClick = () => {
        openModal();
        setPosition();
    }
    return (
        <div className='add-entity' onClick={onClick}>
            <div className='add-entity__icon'>
                <PlusOutlined />
            </div>
            <div className='add-entity__label'>
                Добавить блок
            </div>
        </div>
    );
};

export default AddEntity;